import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Layout, Row, Col, Button, Select, Typography, Card, message, Pagination, Spin, Form, Modal, Result } from 'antd'
import { UnorderedListOutlined, AppstoreFilled, PlusOutlined, ExclamationCircleOutlined, SmileOutlined } from '@ant-design/icons';
import axios from 'axios';
import qs from 'qs';
// componentes

import { IconHouseWarning } from './../../Widgets/Iconos'

import { CantidadDefinida, Busqueda, Precio, Extras, Venta, Tipos } from './../../Widgets/Filtros/Filtros'

import { InmuebleCardPro, InmuebleCardProBig } from './../../Widgets/cards/Cards'
import { User, SetUser } from '../../../Context/User'

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;


/**
 *
 *
 * @export
 * @class Propiedades
 * @extends {Component}
 */
export default class Propiedades extends Component {

	static contextType = User

	constructor(props) {
		super(props);
		axios.defaults.headers.common["Authorization"] = sessionStorage.getItem('token')
		this.state = {
			view: 'bloqs',

			propiedades: {
				data: [],
				page: 1,
				quantity: 0,
				limit: 12,
				total: 0,
				filters: {}
			},


			initial: true,
			loading: false,
		}
	}

	componentDidMount() {
		const user = this.context;
		if (user && this.state.initial && this.state.loading === false) {
			this.getPropiedades(1)
		}
	}


	/**
	 * 
	 * @method getPropiedades
	 * 
	 * @description Obtenemos las propiedades en cuestión.
	 * @param {*} address 
	 * @returns 
	 */
	getPropiedades = ({
		page = this.state.propiedades.page,
		quantity = this.state.propiedades.quantity,
		limit = this.state.propiedades.limit,
		filters = this.state.propiedades.filters
	} = this.state.propiedades) => {

		let { source } = this

		if (source)
			source.cancel()

		const CancelToken = axios.CancelToken;
		source = CancelToken.source();

		this.source = source;

		this.setState(state => {
			state.loading = true
			state.propiedades.page = page
			state.propiedades.quantity = quantity
			return state
		})
		axios.get('/propiedades/list', {
			params: {
				page,
				quantity,
				limit,
				filters: qs.stringify(filters)
			},
			cancelToken: source.token,
			onCancel: () => this.setState({ loading: false })
		})
			.then(({ data }) => {

				this.setState(state => {
					state.propiedades = {

						data: data.data.propiedades.data,
						page: data.data.propiedades.page,
						limit: data.data.propiedades.limit,

						quantity: data.data.propiedades.quantity,
						total: data.data.propiedades.total,

						filters
					}
					state.mapa = {
						data: data.data.markers,
					}
					state.loading = false
					state.first_load = false
					return state;
				})
			})
			.catch(() => this.setState({ loading: false }))
	}


	/**
	 * @memberof Propiedades
	 * @descrption Elimina una de las propiedades del usuario
	 *
	 */
	deletePropiedad = (id) => {

		Modal.confirm({
			title: '¿Realmente desea eliminar esta propiedad?',
			icon: <ExclamationCircleOutlined />,
			content: 'No podrá recuperarla una eliminada',
			onOk: () => {
				axios.delete('/propiedad/delete', {
					params: {
						id
					}
				})
					.then(x => {
						message.success("Eliminada correctamente.")
						this.getPropiedades();
					})
					.catch(x => {
						message.error("No fue posible eliminar.")
					})
			},
		})
	}


	/**
	 * @memberof Propiedades
	 * 
	 * @method renderView
	 * @description renderiza el tipo de vista
	 */
	renderView = (view, propiedades) => {

		if (propiedades.length === 0)
			return <Result
				// status="warning"
				icon={<IconHouseWarning color="#3333ca" style={{ maxWidth: "180px", width: '100%' }} />}
				title={[
					"No hay ninguna propiedad registrada. ",
					<Link to="/professionals/propiedad/crear">¡Agrega una!</Link>
				]}
				extra={
					<Link to="/professionals/propiedad/crear">
						<Button type="primary" icon={<PlusOutlined />} size={"large"} style={{ backgroundColor: "#3333cc", position: "relative", left: 10, bottom: 5 }} >Agrega una propiedad</Button>
					</Link>
				}
			/>


		const onEdit = (propiedad) => () => {
			if (propiedad.status !== 5)
				this.props.history.push('/professionals/propiedad/steps/' + propiedad._id)
			else
				this.props.history.push('/professionals/propiedad/' + propiedad._id)
		}

		if (view === 'bloqs') {
			return <Row gutter={[20, 20]}>
				{propiedades.map(propiedad => {

					return <Col xs={24} sm={12} lg={8} className="">
						<InmuebleCardPro
							onEdit={onEdit(propiedad)}
							onDelete={() => this.deletePropiedad(propiedad._id)} {...propiedad}
						/>
					</Col>
				})
				}
			</Row>
		} else {
			return <Row gutter={[20, 20]}>
				{
					propiedades.map(propiedad => {

						return <Col xs={24} className="">
							<InmuebleCardProBig
								onEdit={onEdit(propiedad)}
								onDelete={() => this.deletePropiedad(propiedad._id)} {...propiedad}
							/>
						</Col>
					})
				}
			</Row>
		}
	}


	preventCycle = false


	/*Este método es llamado en la vista pero no sé pa que o que */
	updateAddress = (obj) => {
		console.log(obj)
	}

	render() {

		const { view } = this.state;
		const user = this.context

		return (
			<Content className="container-pro">
				<User.Consumer>
					{value => {
						if (value?.agencia_id === null || value?.agencia_id === undefined) {
							return <Redirect to="/" />
						}
						if (value !== null && this.preventCycle == false) {
							this.preventCycle = true
							this.getPropiedades()
						}
					}}
				</User.Consumer>
				<Card>
					<Row>
						<Title level={3} style={{ fontWeight: '500', fontSize: '25px' }}>Propiedades
							<Link to="/professionals/propiedad/crear">
								<Button type="primary" icon={<PlusOutlined />} size={"large"} style={{ backgroundColor: "#3333cc", position: "relative", left: 10, bottom: 5 }} />
							</Link>
						</Title>

					</Row>
					<Form onValuesChange={(changedValues, allValues) => {
						this.getPropiedades({ filters: allValues })
					}}>
						<Row gutter={[12, 12]} className="row-filtros">
							<Col className="gutter-row" xl={5} xxl={8}>
								<Busqueda
									placeholder="Ingrese una dirección, vecindario, ciudad o código postal."
									enterButton={true}
									size=""
									className="w-100"
									inputClassName="input-search"
									onSelect={(text, option) => this.updateAddress(option?.object)}
								/>
							</Col>
							<Col className="gutter-row" xl={2}>
								<Form.Item noStyle name="tipo">
									<Venta />
								</Form.Item>
							</Col>
							<Col className="gutter-row" xl={2}>
								<Form.Item noStyle name="precio">
									<Precio />
								</Form.Item>
							</Col>
							<Col className="gutter-row" xl={4} xxl={3}>
								<Form.Item noStyle name="habitaciones">
									<CantidadDefinida
										nombre="Habitaciones"
										textoRango='O seleccione un rango de Habitaciones'
										textoExacta='O seleccione una cantidad de Habitaciones'
									/>
								</Form.Item>
							</Col>
							<Col className="gutter-row" xl={2}>
								<Form.Item noStyle name="banos">
									<CantidadDefinida
										nombre="Baños"
										textoRango='O seleccione un rango de baños'
										textoExacta='O seleccione una cantidad de baños'
									/>
								</Form.Item>
							</Col>
							<Col className="gutter-row" xl={2}>
								<Form.Item noStyle name="tipos">
									<Tipos />
								</Form.Item>
							</Col>
							<Col className="gutter-row center" xl={7} xxl={5}>
								<Form.Item noStyle name="extra">
									<Extras />
								</Form.Item>

								<Button className="btn-primary" htmlType="submit">
									Buscar
								</Button>
							</Col>
						</Row>
					</Form>

				</Card>
				<Row>
					<Col span={24} className="pt-2 pb-2 flex-end">
						<Select defaultValue="1" className="mr-1">
							<Option value="1">Más Recientes</Option>
							<Option value="2">Más Antiguos</Option>
						</Select>
						<Button onClick={() => { this.setState({ view: 'bloqs' }) }}>
							<AppstoreFilled
								style={{ color: view === 'bloqs' ? '#3333cc' : '#000' }}
							/>
						</Button>
						<Button onClick={() => { this.setState({ view: 'lista' }) }} >
							<UnorderedListOutlined
								style={{ color: view === 'lista' ? '#3333cc' : '#000' }}
							/>
						</Button>
					</Col>
				</Row>
				<Spin spinning={this.state.loading}>
					{this.renderView(view, this.state.propiedades.data)}
				</Spin>

				<Pagination className="mt-1"
					total={this.state.propiedades.total}
					current={this.state.propiedades.page}
					pageSize={this.state.propiedades.limit}
					onChange={(page) => {
						this.getPropiedades({ page });
					}} />

			</Content>
		)
	}
}