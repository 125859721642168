import React, { Component } from 'react'
import { Layout, Menu, Button, Badge, Divider, message } from 'antd';
import { Link, Redirect, useLocation } from "react-router-dom";
// import {  } from '@ant-design/icons';
import Cookies from 'universal-cookie';

//compoenentes
import ModalLogin from '../Auth/ModalLogin'
import ModalForgotPassword from '../Auth/ModalForgotPassword'
import Avatar from '../Widgets/Avatar/Avatar';
import { User, SetUser } from '../../Context/User'

import ModalContacto from './../Admin/Modales/ModalContacto'

import { IconBell } from '../Widgets/Iconos'

import axios from 'axios'

//ccs
import 'antd/dist/antd.css';
import './../../styles/header.css'

const { Header } = Layout;
const { SubMenu } = Menu;

class PublicHeaderContent extends Component {

    static contextType = User

    constructor(props) {
        super(props);
        this.state = {
            ModalLoginVisible: false,
            nombre: null,
        }
    }

    /**
     * @memberof PublicHeader
     * 
     * @method logOut
     * @description Borra el sessionStorage, el state y cookies
     */
    logOut = () => {
        let cookie = new Cookies()
        sessionStorage.clear();
        this.props.setUser(null)
        cookie.remove('token', { path: '/' })
        this.setState({ logOut: true })
    }


    render() {
        const user = this.context;


        const userID = sessionStorage.getItem("userId")



        return (
            <Header className="landing-header">
                <Menu className="landing-menu" mode="horizontal" style={{ lineHeight: '58px' }}>
                    <Menu.Item >
                        <Link to="/mapa?tipo=venta">Comprar</Link>
                    </Menu.Item>

                    <Menu.Item >
                        <Link to="/mapa?tipo=renta">Rentar</Link>
                    </Menu.Item>

                    {/* <Menu.Item disabled>
                        <Link>Préstamos</Link>
                    </Menu.Item> */}

                    <Menu.Item >
                        <Link to="/agentes">Agentes</Link>
                    </Menu.Item>

                    <Menu.Item className="itemLogo">
                        <Link to="/">
                            <img src={"/images/logo.svg"} height={60} alt="logo" />
                        </Link>
                    </Menu.Item>

                    {/* <Menu.Item disabled >
                        <Link > Gestionar Alquileres </Link>
                    </Menu.Item> */}
                    {/* 
                    <Menu.Item disabled >
                        <Link> Anuncios </Link>
                    </Menu.Item> */}

                    <Menu.Item disabled >
                        <Link> Ayuda </Link>
                    </Menu.Item>
                    {(user !== null) ?
                        <SubMenu key="SubMenu" title={[
                            <Avatar
                                name={[user.nombres, user.apellidos]}
                            // image={user.foto_perfil}
                            />
                        ]}>

                            <Menu.Item key="setting:propiedades-guardadas">
                                <Link to="/guardadas">
                                    Propiedades Guardadas
                                </Link>

                            </Menu.Item>
                            {/* <Menu.Item key="setting:busquedas-guardadas">Busquedas Guardadas</Menu.Item> */}


                            {(user && user.agencia_id && user.agencia_id !== null) ? <Menu.Item key="setting:mis-propiedades">
                                <Link to="/professionals/propiedades">
                                    Tus propiedades
                                </Link>
                            </Menu.Item> : null}


                            {/* <Menu.Item key="setting:ofertas-cierres">Ofertas y Cierres</Menu.Item>
                                <Menu.Item key="setting:rentas">Rentas</Menu.Item> */}
                            <Menu.Item key="setting:cuenta"><Link to="/professionals/ajustes">Configuración de la Cuenta</Link></Menu.Item>

                            {(user && user.agencia_id && user.agencia_id !== null) ? [
                                <Divider />,
                                <Menu.Item key="setting:back-office" > <Link to="/professionals/dashboard">Backoffice para Profesionales</Link></Menu.Item>,
                            ] : null}
                            <Divider />
                            <Menu.Item key="setting:cerrar-sesion" onClick={this.logOut}>
                                {/*<Link to="/">*/}
                                Cerrar Sesión
                                {/*</Link>*/}
                            </Menu.Item>


                        </SubMenu>
                        :
                        <Menu.Item className="login-opc"  >
                            <Button className="btn-limon" onClick={() => { this.setState({ ModalLoginVisible: true }) }}>Iniciar Sesión</Button>
                        </Menu.Item>


                    }

                </Menu>

                <ModalLogin
                    visible={this.state.ModalLoginVisible}
                    onCancel={() => { this.setState({ ModalLoginVisible: false }) }}
                    onForgot={() => { this.setState({ ModalForgotVisible: true, ModalLoginVisible: false }) }}
                />

                <ModalForgotPassword
                    visible={this.state.ModalForgotVisible}
                    onCancel={() => { this.setState({ ModalForgotVisible: false }) }}
                />
            </Header>
        )
    }
}



function PublicHeader(props) {

    return <PublicHeaderContent
        setUser={React.useContext(SetUser)}
        user={React.useContext(User)}
        {...props}
    />
}


class AdminHeader extends Component {

    static contextType = User

    constructor(props) {
        super(props);

        this.state = {
            ModalLoginVisible: false,
            nombre: null,
            dataUser: [],
            pasoInicial: 0,
            AbrirModalContacto: false
        }
    }


    /**
     * @memberof PublicHeader
     *   
     * @method logOut
     * @description Borra el sessionStorage, el state y cookies
     */
    logOut = () => {
        let cookie = new Cookies()
        sessionStorage.clear();
        this.props.setUser(null)
        cookie.remove('token', { path: '/' })
        this.setState({ logOut: true })
    }


    abrirModal = async () => {
        var token = sessionStorage.getItem('token');
        var id = sessionStorage.getItem('userId');
        //Usuario no logeado
        if (token === null || token === undefined || id === null || id === undefined) {
            this.setState({ ModalLoginVisible: true });
        }

        else {
            //Si hay sesion
            //Consultar datos usuario logeado
            axios.get('/usuario/get', {
                params: {
                    id
                }
            })
                .then(response => {
                    var dataUser = response.data.data;
                    this.setState({
                        dataUser: dataUser,
                        AbrirModalContacto: true
                    })

                }).catch(error => {
                    message.error('Error al cargar info del usuario')
                })

        }

    }


    cerrarModal = async () => {
        this.setState({
            AbrirModalContacto: false,
            ModalLoginVisible: false,
            pasoInicial: 0
        });
    }


    render() {
        const user = this.context;

        return (
            <Header className="landing-header" style={{}}>
                <Menu className="landing-menu" mode="horizontal" style={{ lineHeight: '58px', }}>
                    <Menu.Item className="itemLogo left" style={{ float: "left" }}>
                        <Link to="/">
                            <img src={"/images/logo-3.png"} height={60} alt="logo" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item style={{ float: "left" }}>
                        <Link to="/">Inicio</Link>
                    </Menu.Item>
                    <Menu.Item className="login-opc" style={{ float: "right", }}>
                        <Button className="btn-limon br-20px" onClick={this.abrirModal} id="btnAbrirModalContacto" >Contáctanos</Button>
                    </Menu.Item>
                </Menu>

                <ModalContacto
                    visible={this.state.AbrirModalContacto}
                    pasoInicial={this.state.pasoInicial}
                    dataUser={this.state.dataUser}
                    onCancel={this.cerrarModal}
                />


                <ModalLogin
                    visible={this.state.ModalLoginVisible}
                    onCancel={() => { this.setState({ ModalLoginVisible: false }) }}
                    onForgot={() => { this.setState({ ModalForgotVisible: true, ModalLoginVisible: false }) }}
                    redirectToLanding={() => { this.setState({ redirect: true }) }}
                />

                <ModalForgotPassword
                    visible={this.state.ModalForgotVisible}
                    onCancel={() => { this.setState({ ModalForgotVisible: false }) }}
                />

            </Header>
        )
    }
}


class AgenteHeaderContent extends Component {

    static contextType = User

    constructor(props) {
        super(props);
        this.state = {
            ModalLoginVisible: false,
            logOut: false,
            user: this.props.user,
        }
    }

    logOut = () => {
        let cookie = new Cookies()
        sessionStorage.clear();
        this.props.setUser(null)
        cookie.remove('token', { path: '/' })
        this.setState({ logOut: true })
    }

    render() {

        const user = this.context;
        const menu = (
            <Menu>
                <Menu.Item>
                    <Button> Cerrar Sesión</Button>
                </Menu.Item>
            </Menu>
        )


        const userID = sessionStorage.getItem("userId")


        return (
            <Header className="header-profesional">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Link to="/">
                        <img src={"/images/logo-2.svg"} height={50} alt="logo" />
                    </Link>
                    <Menu mode="horizontal">


                        <Menu.Item >
                            <Link to="/professionals/propiedades">Propiedades</Link>
                        </Menu.Item>

                        {/* <Menu.Item >
                            <Link to="">Mensajes</Link>
                        </Menu.Item>

                        <Menu.Item >
                            <Link to="">Prospectos</Link>
                        </Menu.Item> */}
                    </Menu>
                </div>
                <div className="header-extras">
                    <Link to="/" className="btn-back">
                        Regresar a <b>bauens</b>
                    </Link>
                    <Badge count={0} offset={[0, 5]}>
                        <IconBell />
                    </Badge>
                    <Menu mode="horizontal">

                        {user !== null ?
                            <SubMenu key="SubMenu" title={[
                                <Avatar
                                    name={[user?.nombres, user?.apellidos]}
                                // image={user.foto_perfil}
                                />
                            ]}>
                                <Menu.Item key="setting:propiedades-guardadas">
                                    <Link to="/guardadas">
                                        Propiedades Guardadas
                                    </Link>

                                </Menu.Item>
                                {/* <Menu.Item key="setting:busquedas-guardadas">Busquedas Guardadas</Menu.Item> */}
                                {(user && user.agencia_id && user.agencia_id !== null) ? <Menu.Item key="setting:mis-propiedades">
                                    <Link to="/professionals/propiedades">
                                        Tus propiedades
                                    </Link>

                                </Menu.Item> : null}
                                {/* <Menu.Item key="setting:ofertas-cierres">Ofertas y Cierres</Menu.Item>
                                <Menu.Item key="setting:rentas">Rentas</Menu.Item> */}
                                <Menu.Item key="setting:cuenta">
                                    <Link to="/professionals/ajustes">
                                        Configuración de la Cuenta
                                    </Link></Menu.Item>

                                {(user && user.agencia_id && user.agencia_id !== null) ? [
                                    <Divider />,
                                    <Menu.Item key="setting:back-office"> <Link to="/professionals/dashboard">Backoffice para Profesionales</Link></Menu.Item>,
                                ] : null}
                                <Divider />
                                <Menu.Item key="setting:cerrar-sesion" onClick={this.logOut}>Cerrar Sesión</Menu.Item>


                            </SubMenu>
                            :
                            <Menu.Item className="login-opc"  >
                                <Button className="btn-limon" onClick={() => { this.setState({ ModalLoginVisible: true }) }}>Iniciar Sesión</Button>
                            </Menu.Item>
                        }
                    </Menu>
                </div>

                <ModalLogin />
            </Header>
        )
    }
}



function AgenteHeader(props) {

    return <AgenteHeaderContent
        setUser={React.useContext(SetUser)}
        user={React.useContext(User)}
        {...props}
    />
}

export { PublicHeader, AdminHeader, AgenteHeader }