import React from "react";
import { Input, Select } from "antd";
import PropTypes from "prop-types";

import axios from 'axios';

const { Option } = Select;






/**
 *
 *
 * @export
 * @class InputPhone
 * @extends {React.Component}
 * @description Componente Input de telefonos con lada
 */
export default class InputPhone extends React.Component {


    static defaultProps = {
        value: {},
        onChange: () => { },
    }


    static propTypes = {
        value: PropTypes.object,
        onChange: PropTypes.func
    }

    state = {
        countries: [],
        value: {
            phonecode: '52',
            phone: null
        }
    }


    componentDidMount() {
        this.getCountries()
    }

    componentDidUpdate(){

        if (typeof this.props.value === "object" && (this.props.value.phonecode !== this.state.value.phonecode || this.props.value.phone !== this.state.value.phone)){

            this.setState({ 
                value: this.props.value
            })
        }



    }



    onChange = ({ phone = this.state.value.phone, phonecode = this.state.value.phonecode } = this.state.value) => {
        this.setState(state => {

            state.value.phone = phone
            state.value.phonecode = phonecode
            return state;
        })
        this.props?.onChange({
            phone,
            phonecode
        })
    }

    getCountries = () => {
        axios.get('/countries')
            .then(e => {this.setState({ countries: e.data.data })

        })
    }

    render() {
        const { countries } = this.state;

        
        return (
            <Input.Group compact>
                <Select
                    onChange={e => this.onChange({ phonecode: e })}
                    showSearch
                    value={(this.state.value.phonecode)? this.state.value.phonecode: '52'}
                    filterOption={(input, option) => {
                        return ((option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0) || option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                    }}

                    style={{ width: '115px', borderLeft: 'none' }} >
                    {countries.map(code => <Option value={code.phonecode} key={code.phonecode} name={code.name}>
                        <img src={axios.defaults.baseURL + '/flags/' + code.flag} alt={code.name} style={{ maxHeight: 18, marginRight: 4 }} /> <span style={{ fontSize: 15 }}>+ {code.phonecode}</span>
                    </Option>)}
                </Select>
                <Input
                    value={(this.state.value.phone) ? this.state.value.phone : ''}
                    onChange={e => this.onChange({ phone: e.target.value, })}
                    style={{ width: 'calc(100% - 115px)' }} />
            </Input.Group>
        )
    }
}
