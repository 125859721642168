import React, { Component } from 'react'

import { Layout, Row, Col, Select, Tabs, List, Button, Typography, Input, Pagination, Spin } from 'antd';

import { Link } from "react-router-dom";

import { IconUbicacion } from './../../Widgets/Iconos'

import { AgenteCard } from "../../Widgets/cards/Cards"

import MenuListas from "./MenuListas"

import axios from 'axios';

import './../../../styles/listas.css'


const { Content } = Layout;

const { Text, Title } = Typography;

const { Search } = Input;

const { TabPane } = Tabs;

const { Option } = Select;


export default class ListaAgenciasBR extends Component {

    constructor(props) {
        super(props);
        this.state = {

            loading: true,

            /* Paginado */
            page: 1,
            limit: 10,
            itemCount: 0,
            total: 0,

            data: [],
            search: "",

            ciudad: "",
            dataAgencias: [],
            dataTable: []
        }
    }


    /**
 * @static
 * @memberof ListaAgenciasBR
 * @method   componentDidMount
 * @description Se llaman las consultas iniciales para llenar tablas de agencias
 **/
    componentDidMount = async () => {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getTopAgenciasBR();
        this.getAgenciasBR();
        this.setState({ ciudad: "Estado de México", })
    }



    /**
    * @static
    * @memberof ListaAgenciasBR
    * @method   getAgenciasBR
    * @description Obtiene la paginación de agencias bienes raices
    * */
    getAgenciasBR = async (page = this.state.page, limit = this.state.limit) => {
        this.setState({
            loading: true,
        });

        axios.get("/agencias", {
            headers: {
                Authorization: sessionStorage.getItem("token")
            },
            params: {
                page,
                limit,
                search: this.state.search,
                pagination: true,
                tipo: 1   // 1 Agencias

            },
        })
            .then(({ data }) => {
                console.log(data)

                var itemsList = data.data.itemsList,
                    page = data.data.currentPage,
                    itemCount = data.data.itemCount;

                if (data.success) {
                    this.setState({
                        dataTable: itemsList,
                        loading: false,
                        page: page,
                        itemCount: itemCount
                    })
                }
                else {
                    this.setState({
                        page: 1,
                        itemCount: 0,
                        dataTable: [],
                        loading: false
                    })
                }
            })
            .catch((e) => {
                console.log('er', e)
                this.setState({
                    page: 1,
                    itemCount: 0,
                    dataTable: [],
                    loading: false
                })
            }
            )
    };



    /**
    * @static
    * @memberof ListaAgenciasBR
    * @method   getTopAgenciasBR
    * @description  Obtiene la paginación de TOP 3 agencias bienes raices
    * */
    getTopAgenciasBR = async () => {

        axios.get("/agencias/topList", {
            headers: {
                Authorization: sessionStorage.getItem("token")
            },
            params: {
                limit: 3,
                tipo: 1   // 1 Agencias
            },
        })
            .then(({ data }) => {
                if (data.success) {
                    var itemsList = data.data.itemsList, topAgencias = [];
                    for (let index = 0; index < itemsList.length; index++) {
                        const agencias = itemsList[index];
                        topAgencias.push({
                            _id: agencias._id,
                            nombre: agencias.nombre,
                            telefono: agencias.lada + " " + agencias.telefono,
                            calificacion: agencias.rating,
                            reviews: agencias.rating,
                            descripcion: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ",
                            logo: agencias.logo,
                            tipo:agencias.tipo,
                            insignia: true
                        });

                    }

                    this.setState({
                        dataAgencias: topAgencias,
                    })
                }

            })
            .catch((e) => {
                console.log('er', e)
                this.setState({
                    dataAgencias: [],
                })
            })
            .finally(() =>  this.setState({ loading: false }) )
    };


    /**
    * @static
    * @memberof ListaAgenciasBR
    * @method   renderCardAgencia
    * @description  Se crea card para agencia
    * */
    renderCardAgencia = (agencia) => {
        return <Col xs={20} md={10} xl={8} span={5} className="center col-landing-card">
            <AgenteCard
                _id={agencia._id}
                foto={agencia.logo}
                nombre={agencia.nombre}
                telefono={agencia.telefono}
                calificacion={agencia.calificacion}
                reviews={agencia.reviews}
                descripcion={agencia.descripcion}
                insignia={agencia.insignia}
                tipo={"contratistaCard"}
            />
        </Col>
    }


    /**
    * @static
    * @memberof ListaAgenciasBR
    * @method   renderRowFiltros
    * @description  Se crea row filtros
    * */
    renderRowFiltros = (tipo) => {
        return <Row className="row-filtros-x">
            <Col span={5} className="pdr-10px">
                <Search className="input-search search-ubicacion" placeholder="Localización" />
                <IconUbicacion className="IconUbicacion" />
            </Col>
            <Col span={5} className="pdr-10px">
                <Search className="input-search " placeholder="Busqueda"
                />
            </Col>
            <Col span={5} className="pdr-10px">
                <Select placeholder="Servicios" className="wd-100prs  ">
                    <Option value="a">A</Option>
                    <Option value="b">B</Option>
                    <Option value="c">C</Option>
                </Select>
            </Col>
            <Col span={5} className="pdr-10px">
                <Select placeholder="Tipos de casa" className="wd-100prs ">
                    <Option value="a">A</Option>
                    <Option value="b">B</Option>
                    <Option value="c">C</Option>
                </Select>
            </Col>
            <Col span={4} className="pdr-10px">
                <Button type="ghost" className="btn-gh-blue">Más filtros</Button>
            </Col>
        </Row>

    }


    /**
    * @static
    * @memberof ListaAgenciasBR
    * @method   renderRowCardAgencias
    * @description  Se crea row card para agencia
    * */
    renderRowCardAgencias = (agencia) => {
        console.log(agencia)

        return <Col xs={24} className="left col-landing-card ">
            <AgenteCard
                _id={agencia._id}
                foto={agencia.logo}
                nombre={agencia.nombre}
                telefono={agencia.telefono}
                calificacion={agencia.calificacion}
                reviews={agencia.reviews}
                descripcion={agencia.descripcion}
                insignia={agencia.insignia}
                cntCasas={agencia.cntCasas}
                cntAgencias={agencia.cntAgencias}
                agentes={agencia.agentes}
                tipo={"rowAgenciasBRCard-agentes"}
            />
        </Col>
    }


    render() {
        let dataAgencias = this.state.dataAgencias, dataTable = this.state.dataTable, ciudad = this.state.ciudad;

        return (
            <Layout className="landing-layout">

                <Content className="list-content" >
                    <Row className="row-bg-image" style={{ backgroundImage: "url('/images/img-lista.png')" }} >
                        <Title level={2} className="text-white mg-auto" >Mejores agencias de <br /> bienes raices</Title>
                    </Row>
                    <Row className="bg-white">
                        <Row className="cnt-center">
                            <Col span={24} >
                                <Title level={4} className="ml-50px">Mejores Agencias de “{ciudad}”</Title>
                            </Col>

                            <Col span={24} >
                                <Spin spinning={this.state.loading}>

                                    <Row className="mxw-1240px mg-auto"  >
                                        {dataAgencias.map((agencia, index) => (
                                            this.renderCardAgencia(agencia)
                                        ))}
                                    </Row>
                                </Spin>
                            </Col>

                            <Col xs={22} className="left  col-agentes-tabs ml-50px">

                                <MenuListas />
                            </Col>

                            <Col xs={22} span={5} className="left col-agentes-tabs ml-50px tabs-agentes" >
                                {this.renderRowFiltros(7)}
                            </Col>


                            <Col xs={22} className="left col-agentes-list  ml-50px">
                                <List
                                    itemLayout="horizontal"
                                    dataSource={dataTable}
                                    className="list-agentes"
                                    loading={this.state.loading}
                                    locale={{ emptyText: 'Sin Agentes' }}
                                    header={<List.Item className="component-list-item wd-100prs">
                                        <Row className="wd-100prs pdl-10px">
                                            <Col xs={22}>
                                                <Text>Agencias</Text>
                                            </Col>

                                        </Row>
                                    </List.Item>

                                    }
                                    renderItem={(agencia, index) => (
                                        <List.Item  >
                                            <Row className="wd-100prs pd-0px">
                                                {this.renderRowCardAgencias(agencia, index)}
                                            </Row>
                                        </List.Item>
                                    )}
                                />
                            </Col>

                            <Col xs={23} className="left col-paginado">
                                <Pagination
                                    className="paginado right"
                                    defaultCurrent={1}
                                    showSizeChanger={false}
                                    defaultPageSize={this.state.limit}
                                    current={this.state.page}
                                    total={this.state.itemCount}

                                    onChange={(page) => {
                                        this.getAgenciasBR(page)
                                    }}

                                />
                            </Col>
                        </Row>
                    </Row>
                </Content>
            </Layout>
        )
    }
}