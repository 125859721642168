import React, { Component } from 'react'
import { Route, Switch } from "react-router-dom";
import { Layout, Result } from 'antd';


// componentes
import { PublicHeader } from "../components/Header/Header";

import { _Footer, _FooterBlue } from "../components/Footer/Footer";

import Landing from "../components/Public/Landing";
import Mapa from "../components/Public/Mapa/Mapa";

import ListaAgentes from "../components/Public/Listas/ListaAgentes";
import ListaContratistas from "../components/Public/Listas/ListaContratistas";
import ListaEstudiosFotograficos from "../components/Public/Listas/ListaEstudiosFotograficos";
import ListaInspectores from "../components/Public/Listas/ListaInspectores";
import ListaConstructoras from "../components/Public/Listas/ListaConstructoras";
import ListaAgenciasBienesRaices from "../components/Public/Listas/ListaAgenciasBienesRaices";
import DetalleAgente from "../components/Public/Listas/DetalleAgente";
import PropiedadesGuardadas from "../components/Agente/Propiedades/PropiedadesGuardadas";


import Dashboard from "../components/Admin/Dashboard"



const { Content } = Layout;

export default class PublicRoutes extends Component {
	render() {
		return (
			<Layout className="layout">
				<Content>
					<PublicHeader />
					<Switch>
						<Route exact path="/" render={(props) => <Landing  {...props} />} />
						<Route exact path="/mapa" render={(props) => <Mapa key={"eliminate"} {...props} />} />

						<Route exact path="/mapa/:idPropiedad" render={(props) => <Mapa key={"eliminate"} {...props} />} />

						<Route exact path="/guardadas" render={(props) => <PropiedadesGuardadas  {...props} />} />

						<Route exact path="/agentes" render={(props) => <ListaAgentes  {...props} />} />
						<Route exact path="/contratistas" render={(props) => <ListaContratistas  {...props} />} />

						<Route exact path="/estudios-fotograficos" render={(props) => <ListaEstudiosFotograficos  {...props} />} />
						<Route exact path="/inspectores" render={(props) => <ListaInspectores  {...props} />} />
						<Route exact path="/constructoras" render={(props) => <ListaConstructoras  {...props} />} />
						<Route exact path="/agencias" render={(props) => <ListaAgenciasBienesRaices  {...props} />} />

						<Route exact path="/agente/detalle/:id" render={(props) => <DetalleAgente  {...props} />} />

						{/* <Route exact path="/professionals" render={(props) => <Dashboard  {...props} />} /> */}
						<Route render={props => <Result
							status="404"
							title="404"
							subTitle="La pagina a la que estás intentando acceder no existe."
							{...props}
						/>} />
					</Switch>
					<_Footer />
					<_FooterBlue />
				</Content>
			</Layout>
		)
	}
}
