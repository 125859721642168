import React, { Component } from 'react'
import { Layout, Row, Col, Select, Tabs, List, Button, Typography, Input, Pagination, Spin } from 'antd';
import { IconUbicacion } from './../../Widgets/Iconos'
import { AgenciasCard, AgenteCard, ComunidadCard, ContructoraCardLarge } from "../../Widgets/cards/Cards"
import MenuListas from "./MenuListas"
import axios from 'axios';
import './../../../styles/listas.css'

const { Content } = Layout;
const { Text, Title } = Typography;
const { Search } = Input;
const { Option } = Select;


export default class ListaConstructoras extends Component {

    constructor(props) {
        super(props);
        this.state = {

            loading: true,

            /* Paginado */
            page: 1,
            itemCount: 0,
            total: 0,

            data: [],
            search: "",


            ciudad: "",
            constructoras: [],
            recomendados: []
        }
    }

    /**
    * @static
    * @memberof ListaConstructoras
    * @method   componentDidMount
    * @description  Se llaman consultas iniciales de constructoras
    * */
    componentDidMount = async () => {

        this.getConstructoras();
        this.setState({ ciudad: "Estado de México" })
    }



    /**
    * @static
    * @memberof ListaConstructoras
    * @method   getConstructoras
    * @description Obtiene la paginación de constructoras
    * */
    getConstructoras = async (page = this.state.page, search = this.state.search) => {
        this.setState({ loading: true });

        axios.get("/agencias", {
            params: {
                page,
                search,
                tipo: 2 //contratistas
            },
        })
            .then(({ data }) => {
                console.log('constructoras', data)

                let itemsList = data.data.itemsList,
                    paginator = data.data.paginator;
                this.setState({
                    page: paginator.currentPage,
                    itemCount: paginator.itemCount,
                    constructoras: itemsList,
                    recomendados: data.recomendados
                })

            })
            .catch((e) => {
                console.log('error al traer los constructoras', e)
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    };

       /**
     *
     *
     * @memberof ListaConstructoras
     * @method searchContructora
     * @description Asigna al state.search el valor a buscar y se manda a llamar al metodo getConstructoras
     */
        searchContructora = (value) => {
            this.setState({ search: value }, () => {
                this.getConstructoras();
            })
        }



    /**
    * @static
    * @memberof ListaConstructoras
    * @method   renderRowFiltros
    * @description Se crea row filtros
    * */
    renderRowFiltros = (tipo) => {
        return <Row className="row-filtros-x">
            <Col span={5} className="pdr-10px">
                <Search className="input-search search-ubicacion" placeholder="Localización" />
                <IconUbicacion className="IconUbicacion" />
            </Col>
            <Col span={5} className="pdr-10px">
                <Search className="input-search " placeholder="Busqueda" onSearch={value => this.searchContructora(value)}
                />
            </Col>
            {/* <Col span={5} className="pdr-10px">
                <Select placeholder="Servicios" className="wd-100prs  ">
                    <Option value="a">A</Option>
                    <Option value="b">B</Option>
                    <Option value="c">C</Option>
                </Select>
            </Col>
            <Col span={5} className="pdr-10px">
                <Select placeholder="Tipos de casa" className="wd-100prs ">
                    <Option value="a">A</Option>
                    <Option value="b">B</Option>
                    <Option value="c">C</Option>
                </Select>
            </Col> */}
            {/* <Col span={4} className="pdr-10px">
                <Button type="ghost" className="btn-gh-blue">Más filtros</Button>
            </Col> */}
        </Row>

    }



    render() {
        let { constructoras, recomendados, ciudad } = this.state;

        return (
            <Layout className="landing-layout">
                <Content className="list-content" >
                    <Row className="row-bg-image" style={{ backgroundImage: "url('/images/img-lista.png')" }} >
                        <Title level={2} className="text-white mg-auto" >Constructoras</Title>
                    </Row>
                    <Row className="bg-white">
                        <Row className="cnt-center">
                            <Col span={24} >
                                <Title level={4} className="ml-50px">Mejores Constructoras de “{ciudad}”</Title>
                            </Col>
                            <Col span={24} >
                                <Spin spinning={this.state.loading}>
                                    <Row className="mxw-1240px mg-auto"  >
                                        {recomendados.map((constructora, index) => (
                                            <Col xs={20} md={10} xl={8} span={5} className="center col-landing-card">
                                                <AgenciasCard
                                                    _id={constructora._id}
                                                    foto={constructora.logo}
                                                    nombre={constructora.nombre}
                                                    telefono={constructora.lada + " " + constructora.telefono}
                                                    calificacion={constructora.rating}
                                                    reviews={constructora.reviews}
                                                    descripcion={constructora.descripcion}
                                                    insignia={constructora.insignia}
                                                    tipo={constructora.tipo}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                </Spin>
                            </Col>

                            <Col xs={22} className="left  col-agentes-tabs ml-50px">
                                <MenuListas />
                            </Col>

                            <Col xs={22} span={5} className="left col-agentes-tabs ml-50px tabs-agentes" >
                                {this.renderRowFiltros(7)}
                            </Col>
                            <Col xs={22} className="left col-agentes-list  ml-50px">
                                <List
                                    itemLayout="horizontal"
                                    dataSource={constructoras}
                                    className="list-agentes"
                                    loading={this.state.loading}
                                    locale={{ emptyText: 'Sin Constructoras' }}
                                    header={<List.Item className="component-list-item wd-100prs">
                                        <Row className="wd-100prs pdl-10px">
                                            <Col xs={22}>
                                                <Text>Constructoras</Text>
                                            </Col>

                                        </Row>
                                    </List.Item>

                                    }
                                    renderItem={(constructora, index) => (
                                        <List.Item  >
                                            <Row className="wd-100prs pd-0px">
                                                <Col xs={24} className="left col-landing-card">
                                                    <ContructoraCardLarge
                                                        _id={constructora._id}
                                                        foto={constructora.logo}
                                                        nombre={constructora.nombre}
                                                        telefono={constructora.telefono}
                                                        calificacion={constructora.calificacion}
                                                        reviews={constructora.reviews}
                                                        descripcion={constructora.descripcion}
                                                        insignia={constructora.insignia}
                                                        comunidades={constructora.comunidades}
                                                        cntCasas={constructora.cntCasas}
                                                        cntComunidades={constructora.cntComunidades}
                                                        tipo={constructora.tipo}
                                                    />
                                                </Col>
                                            </Row>
                                        </List.Item>
                                    )}
                                />
                            </Col>

                            <Col xs={23} className="left col-paginado">

                                <Pagination
                                    className="paginado right"
                                    defaultCurrent={1}
                                    showSizeChanger={false}
                                    defaultPageSize={this.state.limit}
                                    current={this.state.page}
                                    total={this.state.itemCount}

                                    onChange={(page) => {
                                        this.getConstructoras(page)
                                    }}

                                />
                            </Col>
                        </Row>
                    </Row>
                </Content>
            </Layout>
        )
    }
}