import { Component } from "react";
import { Layout, PageHeader, List, Row, Col, Typography, Card, Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { FaAngleRight } from "react-icons/fa";
import axios from 'axios'
import { User } from '../../../Context/User'

const { Content } = Layout;
const { Text, Title } = Typography;
const moment = require('moment');
/**
 *
 *
 * @export
 * @class AjustesPagos
 * @extends {Component}
 */
export default class Prospectos extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            currentPage: 1,
            itemCount: 0,
            prospectos: [],
        }
    }

    componentDidMount(){
        this.getProspectos()
    }

    /**
     *
     *
     * @memberof Dashboard_Agente
     * @description Lista los utlimos prospectos
     */
     getProspectos = (page = 1) => {
        axios.get('/prospectos/list',{
            params:{
                page: page,
                limit: 10
            }
        }).then(response => {
            console.log(response.data.data)
            this.setState({
                prospectos: response.data.data.data,
                page: response.data.data.page,
                total: response.data.data.total
            })
        }).catch(error => {
            console.log(error)
        })
     }



    render() {
        return (
            <Layout className="bg-gray no-overflow-x">
                <PageHeader title="Prospectos" className="bg-white mt-1 mr-2 ml-2 " />
                <Content className="bg-gray mr-2 ml-2 mt-1 ">
                    <List
                        loading={this.state.loading}
                        size="small"
                        className="bg-white component-list form-ajustes"
                        bordered={false}
                        locale={{emptyText:'Sin Prospectos'}}
                        dataSource={this.state.prospectos}
                        pagination={{
                            onChange: page => {
                                this.getprospectos(page)
                            },
                            pageSize: 10,
                            current: this.state.page,
                            total: this.state.total
                        }}

                        header={

                            <Row align="middle row-titleProspectos" >
                                <Col className="center" xxl={{ span: 6 }} xl={{ span: 6 }} sm={{ span: 6}} xs={{ span: 22 }}>
                                    <Title>Nombre</Title>
                                </Col>
                                <Col className="center" xxl={{ span: 4 }} xl={{ span: 4 }} sm={{ span: 4}} xs={{ span: 11 }}>
                                    <Title>Email</Title>
                                </Col>
                                <Col className="center" xxl={{ span: 4 }} xl={{ span: 4 }} sm={{ span: 4}} xs={{ span: 11 }}>
                                    <Title>Teléfono</Title>
                                </Col>
                                <Col className="center" xxl={{ span: 4 }} xl={{ span: 4 }} sm={{ span: 4}} xs={{ span: 11 }}>
                                    <Title>Propiedad</Title>
                                </Col>
                                <Col className="center" xxl={{ span: 3 }} xl={{ span: 3 }} sm={{ span: 4}} xs={{ span: 11 }}>
                                    <Title>Fecha</Title>
                                </Col>
                                <Col className="center" xxl={{ span: 3 }} xl={{ span: 3 }} sm={{ span: 2}} xs={{ span: 2  }}>
                                    <Title>Acciones</Title>
                                </Col>
                            </Row>
                        }

                        renderItem={item => <List.Item className="component-list-item">
                            <Card className="card-list shadow-sm">
                                <Row align="middle" >
                                    <Col className="center"  className="center" xxl={{ span: 6 }} xl={{ span: 6 }} sm={{ span: 6}} xs={{ span: 22 }} >
                                        <Text>{item.nombres} {item.apellidos}</Text>
                                    </Col>
                                    <Col  className="center" xxl={{ span: 4 }} xl={{ span: 4 }} sm={{ span: 4}} xs={{ span: 11 }} >
                                        <Text>{item.email}</Text>
                                    </Col>
                                    <Col  className="center" xxl={{ span: 4 }} xl={{ span: 4 }} sm={{ span: 4}} xs={{ span: 11 }} >
                                        <Text>+ {item.lada} {item.telefono}</Text>
                                    </Col>
                                    <Col  className="center" xxl={{ span: 4 }} xl={{ span: 4 }} sm={{ span: 4}} xs={{ span: 11 }} >
                                        <Text>{item.propiedad.nombre}</Text>
                                    </Col>
                                    <Col  className="center" xxl={{ span: 3 }} xl={{ span: 3 }} sm={{ span: 4}} xs={{ span: 11 }} >
                                        <Text>{moment(item.createdAt).utc().format('DD/MM/YYYY')}</Text>
                                    </Col>
                                    <Col  className="center" xxl={{ span: 3 }} xl={{ span: 3 }} sm={{ span: 2}} xs={{ span: 2  }} >
                                        <Button type="link"  className="ant-btn btn-yellow">Enviar mensaje</Button>
                                        {/* <Button type="link" icon={<FaAngleRight />} className="card-list-btn"></Button> */}
                                    </Col>
                                </Row>
                            </Card>
                        </List.Item>}
                    />
                </Content>
            </Layout>
        )
    }
}