import React, { Component } from 'react'
import { Typography, Menu } from 'antd';
import { Link } from "react-router-dom";
import './../../../styles/listas.css'


const { Text } = Typography;


/**
 *
 *
 * @export
 * @class MenuLista
 * @extends {Component}
 */
export default class MenuLista extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Menu mode="horizontal" className="menuLista">
                <Menu.Item key="0" >
                    <Link to={"/agentes"}>
                        <Text>Agentes </Text>
                    </Link>
                </Menu.Item>

                {/* <Menu.Item key="1" >
                    <Link to={"/contratistas"}>
                        <Text level={4}>Contratistas </Text>
                    </Link>
                </Menu.Item>

                <Menu.Item key="2" disabled>
                    <Link >
                        <Text level={4}>Administradores </Text>
                    </Link>
                </Menu.Item>

                <Menu.Item key="3" >
                    <Link to={"constructoras"}>
                        <Text level={4}>Constructoras </Text>
                    </Link>
                </Menu.Item>

                <Menu.Item key="4" disabled>
                    <Link to={"/inspectores"} >
                        <Text level={4}>Inspectores </Text>
                    </Link>
                </Menu.Item>

                <Menu.Item key="5" >
                    <Link to={"/estudios-fotograficos"}>
                        <Text level={4}>Fotográfos </Text>
                    </Link>
                </Menu.Item>

                <Menu.Item key="6" disabled >
                    <Link to={"/agencias"}>
                        <Text level={4}>Otros </Text>
                    </Link>
                </Menu.Item> */}

            </Menu>
        )
    }
}