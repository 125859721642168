import React, { Component } from 'react';
import { Layout, Row, Col, Button, Input, message, Select, Typography, Empty, Spin, Form } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import qs from 'qs';
import axios from 'axios';

//componentes
import { InmuebleCard, InmuebleCardSkeleton } from '../../Widgets/cards/Cards'
import { CantidadDefinida, Precio, Extras, Venta, Tipos, Busqueda } from '../../Widgets/Filtros/Filtros'
import ModalInmueble from '../Modals/ModalInmueble'
import MapaViewer from '../../Widgets/Mapa/Mapa'
import { User, SetUser } from '../../../Context/User'
import ModalLogin from '../../Auth/ModalLogin'
import ModalForgotPassword from '../../Auth/ModalForgotPassword'
import InfiniteScroll from "react-infinite-scroll-component";

//css
import '../../../styles/mapa.css'
import { logDOM } from '@testing-library/dom';




import MapboxGL from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { Link } from 'react-router-dom';
import { FaAddressCard } from 'react-icons/fa';
MapboxGL.accessToken = process.env.REACT_APP_MAPBOX_API



const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
const { Title, Text } = Typography;



let source

var arrayDataScroll = [];

export default class Mapa extends Component {

	static contextType = User

	constructor(props) {
		super(props)
		const address = this.getParams()
		this.state = {
			address,
			loading: false,
			first_load: true,
			mapa: {
				data: [],
			},

			tipo: (address !== null) ? address?.tipo : "",

			propiedades: {
				data: [],
				page: 1,
				quantity: 0,
				limit: 10,
				filters: { tipo: { tipo_pago: "" } }
			},
			arrayDataScroll: [],
			bounds: null,
			zoom: null,
			favs: undefined,
			initial: true,
			paramsTipo: undefined,

			filters: {
			},

			pais: "",
			estado: "",
			ciudad: "",
			sort: 'createdAt',
			idPropiedadInit: null,

			initLng: null,
			initLat: null
		}

	}

	componentDidMount = async () => {


		var ruta = this.props.location.search;


		if (ruta.includes("/")) {
			let idProp = ruta.split("/")[1];
			this.setState({
				idPropiedadInit: idProp,
			});

			setTimeout(() => {
				this.getPropInit();
			}, 1000);
		}







		const user = this.context;
		if (this.state.favs === undefined && user && this.state.initial) {
			this.getFavs()
		}


		if (typeof this.state.tipo === "string") {
			this.filterForms.setFieldsValue({ tipo: { tipo_pago: ((this.state.tipo == "venta") ? 2 : 1) } })
			this.getPropiedades({ filters: this.filterForms.getFieldsValue() })
			return;
		}

		this.getPropiedades()

	}



	componentDidUpdate(prevPros) {

		const params = this.getParams();


		if (params.tipo !== this.state.paramsTipo) {
			this.filterForms.setFieldsValue({ tipo: { tipo_pago: ((params.tipo === "venta") ? 2 : 1) } })
			this.getPropiedades({ filters: this.filterForms.getFieldsValue() })
			this.setState({ tipo: params.tipo, paramsTipo: params.tipo })
		}

		// if (params.tipo !== this.state.tipo){
		// }
		// const user = this.context;
		// if (this.state.favs === undefined && user && this.state.initial) {
		// 	this.getFavs()
		// }
	}


	getParams = () => {
		let search = this.props.location.search
		if (search?.length > 0)
			search = search.slice(1).split('/')[0]
		return qs.parse(search)
	}


	/**
	* @memberof PropiedadesGuardadas
	* 
	* @method getFavs
	* @description retorna las propiedades en el que el usuario haya daddo en favoritos
	*/
	getFavs = () => {
		const user = this.context;
		axios.get('/usuario/guardadas/min', {
			params: {
				user_id: user._id
			}
		}).then(response => {
			this.setState({
				initial: false,
				favs: response.data.favs
			})
		}).catch(error => {
			console.log(error)
		}).finally(() => {
			this.setState({ initial: false })
		})
	}



	/**
	 * 
	 * @description Dirección actual a
	 * @param {*} address 
	 * @returns 
	 */
	updateAddress = (address) => {

		this.setState({ address })
	}


	updateView = () => {
		this.getPropiedades();
	}

	/**
	 * 
	 * @method getPropiedades
	 * 
	 * @description Obtenemos las propiedades en cuestión.
	 * @param {*} address 
	 * @returns 
	 */
	getPropiedades = async ({
		page = this.state.propiedades.page,
		quantity = this.state.propiedades.quantity,
		limit = this.state.propiedades.limit,
		filters = this.filterForms.getFieldsValue(),

	} = this.state.propiedades, bounds = this.state.bounds, zoom = this.state.zoom) => {
		if (source)
			source.cancel()

		const CancelToken = axios.CancelToken;
		source = CancelToken.source();

		const tipos = {
			0: 'vendida',
			1: 'renta',
			2: 'venta'
		}


		this.setState({
			loading: true,
			tipo: tipos[filters.tipo.tipo_pago]
		})

		if (this.state.bounds !== null) {
			var x = this.getAddressByCordsLocal(this.state.bounds._ne.lng, this.state.bounds._ne.lat)
		}


		axios.get('/propiedades', {
			params: {
				page,
				quantity,
				limit: 10,
				bounds: qs.stringify(this.state.bounds),
				zoom: parseInt(zoom),
				filters: qs.stringify(filters),
				sort: this.state.sort,
			},
			cancelToken: source.token,
			onCancel: () => this.setState({ loading: false })
		})
			.then(({ data }) => {
				var array = [];

				if (page === 1) {
					array = data.data.propiedades.data;
					arrayDataScroll = array;
				}
				else {
					array = arrayDataScroll;
					var newData = data.data.propiedades.data;

					for (let index = 0; index < newData.length; index++) {
						const reg = newData[index];
						array.push(reg);
					}

					arrayDataScroll = array;

				}

				this.setState(state => {
					state.propiedades = {
						data: array, //data.data.propiedades.data,
						page: data.data.propiedades.page,
						quantity: data.data.propiedades.total,
						limit: data.data.propiedades.limit,
						filters
					}

					state.mapa = {
						data: data.data.markers,
					}

					state.loading = false
					state.first_load = false
					return state;
				})
			})

			.catch(error => {
				console.log(error);
			})
	}



	/**
	 * 
	 * @method renderPropiedades
	 * 
	 * @description renderiza los cards de las propiedades
	 */
	renderPropiedades = ({ propiedades, loading, first_load } = this.state) => {

		if (loading == true && first_load == true)
			return [
				<Col className="gutter-row" xs={24} md={12}>
					<InmuebleCardSkeleton />
				</Col>,
				<Col className="gutter-row" xs={24} md={12}>
					<InmuebleCardSkeleton />
				</Col>,
				<Col className="gutter-row" xs={24} md={12}>
					<InmuebleCardSkeleton />
				</Col>,
				<Col className="gutter-row" xs={24} md={12}>
					<InmuebleCardSkeleton />
				</Col>
			]
		else if (Array.isArray(propiedades?.data)) {
			if (propiedades?.data.length > 0)

				/*	return propiedades?.data.map((propiedad, index) =>
						<Col key={"propiedad" + index} className="gutter-row" xs={24} md={12}>
							<InmuebleCard
								{...propiedad}
								tipo={this.state.tipo}
								onClick={() => this.setState({
									ModalInmuebleVisible: true,
									propiedad_id: propiedad._id
								})}
								onAddressClick={address => this.setState(state => {
									// address.onChange = false;
									state.address = address;
									return state;
								})}
							/>
						</Col>
					);
					*/

				return <InfiniteScroll

					//dataLength={propiedades?.data.length} //This is important field to render the next data
					dataLength={propiedades?.data.length} //This is important field to render the next data
					next={() => {
						var actual = arrayDataScroll.length;
						var next = (actual / 10);
						next = parseInt(next) + 1;

						this.setState(state => {
							state.propiedades = {
								page: next
							}
							return state;
						})

						setTimeout(() => {
							this.getPropiedades();

						}, 250);
					}}
					hasMore={true}
					style={{ width: "100%" }}

				>
					<Row gutter={[20, 20]}>
						{
							propiedades?.data.map((propiedad, index) =>
								<Col key={"propiedad" + index} className="gutter-row" xs={24} md={12}>
									<InmuebleCard
										{...propiedad}

										tipo={this.state.tipo}
										onClick={() => this.setState({
											ModalInmuebleVisible: true,
											propiedad_id: propiedad._id
										})}

										onAddressClick={address => this.setState(state => {
											// address.onChange = false;
											state.address = address;
											return state;
										})}
									/>
								</Col>
							)}

					</Row>
				</InfiniteScroll>
			else
				return <Row align="center" style={{ width: '100%' }} >
					<Empty style={{ width: '100%' }} description={(this.state.pais !== "" || this.state.estado !== "" || this.state.ciudad !== "") ? ("No hay propiedades disponibles en " + this.state.ciudad + " " + this.state.estado + ", " + this.state.pais) : "No hay propiedades disponibles"} />

					{(sessionStorage.getItem("token") !== null && (this.state.pais !== "" || this.state.estado !== "" || this.state.ciudad !== "")) ?

						<Button className="btn-emailNotification ant-empty-description" onClick={(e) => this.registroNotificacionProp()}>
							Enviarme un correo para notificarme
							<br />
							cuando haya propiedades disponibles
							<br />

							<div class=" ant-empty-description">
								{(this.state.pais !== "" || this.state.estado !== "" || this.state.ciudad !== "") ? (" en " + this.state.ciudad + " " + this.state.estado + ", " + this.state.pais) : "Enviarme un correo para notificarme cuando haya propiedades disponibles"}
							</div>

						</Button>
						: null}
				</Row>
		}
	}



	/**
	 * @method getAddressByCordsLocal
	 * @description Busca la ubicación en el mapa a partir de las coordenadas del state
	 * @param string longitud, latitud = coordenadas seleccionadas desde el mapa por el usuario
	 * @returns 
	 */
	getAddressByCordsLocal = async (longitud, latitud) => {
		fetch(`${MapboxGL.config.API_URL}/geocoding/v5/mapbox.places/${longitud},${latitud}.json?access_token=${MapboxGL.accessToken}&language=es`)
			.then(response => {
				response.json().then(this.fetchAndUpdateAddressLocal)
			})
			.catch(error => {
				console.log(error);
			})
	}



	/**
	 * @method fetchAndUpdateAddressLocal
	 * @description Busca la dirección a partir del objeto que retorna la consulta de la api, para establecer el país, estado y ciudad donde se ubica el mapa.
	 * @param string data, es el arreglo de datos consultados por la api a partir de las coordenadas en el método getAddressByCordsLocal
	 * @returns 
	 */

	fetchAndUpdateAddressLocal = data => {

		var _df = data.features;
		if (Array.isArray(_df) && _df.length > 0 && _df[0].center.length > 1) {

			var pais = "", estado = "", ciudad = "";
			for (let index = 0; index < _df.length; index++) {
				const loc = _df[index];

				var tipo = loc.id.split('.')[0];

				switch (tipo) {
					case "country":
						pais = loc.text_es;
						break;

					case "region":
						estado = loc.text_es;
						break;

					case "place":
						ciudad = loc.text_es;
						break;
					default:
						break;
				}
			}


			this.setState({
				pais: pais,
				estado: estado,
				ciudad: ciudad
			})
		}
	}




	/**
	* @memberof Mapa
	* 
	* @method registroNotificacionProp
	* @description Registra la petición de notificacion de propiedades en el país, estado y ciudad seleccionadas en el mapa, 
		donde no hay ningún inmueble disponible.
	*/
	registroNotificacionProp = async () => {

		var { pais, estado, ciudad } = this.state;
		var userId = sessionStorage.getItem("userId");

		const user = this.context;
		axios.post('/newsletter/add', {
			params: {
				pais, estado, ciudad, userId
			}
		}).then(response => {
			console.log(response)
			if (response.data.success) {
				message.success("Se creó registro correctamente.")
			}
			else {

				message.error(response.data.error)
			}

		}).catch(error => {
			message.error("Error, no se pudo crear registro.")
			console.log(error)
		}).finally((x) => {
		})
	}



	/**
	* @memberof Mapa
	* 
	* @method getPropInit
	* @description Se consulta la propiedad inicial al cargar vista
	*/

	getPropInit = async () => {

		var idPropiedadInit = this.state.idPropiedadInit;
		var userId = sessionStorage.getItem("userId");

		const user = this.context;

		axios.get('/propiedad/get', {
			params: {
				id: idPropiedadInit
			}
		}).then(response => {
			console.log(response);

			if (response.data.success && response.data.data.localizacion !== undefined) {
				var data = response.data.data;
				this.setState({
					zoom: 10,
					address: data.localizacion
				});
			}


		}).catch(error => {

			console.log(error);
		}).finally((x) => {
		})
	}



	render() {

		const { loading, propiedades, first_load, mapa } = this.state;

		return (
			<Content className="container">
				<Form
					ref={ref => this.filterForms = ref}
					onValuesChange={(changedValues, allValues) => this.getPropiedades({ filters: allValues })}>
					<Row gutter={[12, 12]} className="row-filtros">
						<Col className="gutter-row" xs={24} lg={8} xl={5} xxl={8}>
							<Busqueda
								address={this.state.address}
								placeholder="Calle, colonia, código postal"
								enterButton={true}
								size=""
								className="w-100"
								inputClassName="input-search"
								onSelect={(address) => this.updateAddress(address)}

							/>
						</Col>

						<Col className="gutter-row" xs={12} lg={4} xl={3} xxl={2}>

							<Form.Item noStyle name="tipo">
								<Venta />
							</Form.Item>
						</Col>
						<Col className="gutter-row" xs={12} lg={6} xl={2}>
							<Form.Item noStyle name="tipo_propiedad">
								<Tipos />
							</Form.Item>
						</Col>
						<Col className="gutter-row" xs={12} lg={6} xl={3}>
							<Form.Item noStyle name="precio">
								<Precio />
							</Form.Item>
						</Col>
						<Col className="gutter-row" xs={12} lg={6} xl={4} xxl={3}>
							<Form.Item noStyle name="habitaciones">
								<CantidadDefinida
									nombre="Habitaciones"
									textoRango='O seleccione un rango de Habitaciones'
									textoExacta='O seleccione una cantidad de Habitaciones'
								// abb="Hab"
								/>
							</Form.Item>
						</Col>
						<Col className="gutter-row" xs={12} lg={6} xl={3} xxl={2}>
							<Form.Item noStyle name="banos">
								<CantidadDefinida
									nombre="Baños"
									textoRango='O seleccione un rango de baños'
									textoExacta='O seleccione una cantidad de baños'
								/>
							</Form.Item>
						</Col>


						<Col className="gutter-row center" xs={24} lg={12} xl={4} xxl={4}>
							<Form.Item noStyle name="extra">
								<Extras />
							</Form.Item>

							<Button className="btn-primary" htmlType="submit">
								Buscar
							</Button>
						</Col>
					</Row>
				</Form>
				<Row style={{ minHeight: '100vh' }}>

					<Col xs={24} md={24} xl={11} style={{ maxHeight: 'calc(100vh - 129px)', minHeight: '40vh' }}>

						{/* =======
					<Col xs={24} md={24} xl={11} style={{ maxHeight: 'calc(100vh - 129px)', minHeight: '100vh' }}>
>>>>>>> 7ddbf7ac0596b187e5300262f966c2136cf50c4f */}
						<MapaViewer
							ref={ref => this.mapViewer = ref}
							address={this.state.address}
							propiedades={mapa.data}
							onChange={({ bounds, zoom }) => {
								console.log("bounds fsdfsd", bounds);
								this.state.bounds = bounds;
								this.state.zoom = zoom;
								this.updateView()
							}}

							tipo={this.state.tipo}



						/>
					</Col>

					<Col xs={24} md={24} xl={13} className="p-1 back-white" style={{ maxHeight: 'calc(100vh - 129px)', overflow: 'auto' }}  >
						<Spin spinning={loading == true && first_load == false} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
							<Row>
								<Col span={24} className="col-header">
									<div>
										<Title level={3}> Lista de Inmuebles </Title>
										<Text type="secondary"> Resultados </Text>
									</div>
									<div>
										<Text type="secondary"> Ordenar Por: </Text>
										<Select onSelect={(sort)=>{
											this.setState({sort},()=>{this.getPropiedades()})
										}} className='select-med' placeholder="Opcion" defaultValue="createdAt">
											<Option value="createdAt">---</Option>
											<Option value="costo_propiedad">Precio</Option>
											<Option value="tamano_lote">Tamaño Lote</Option>
											<Option value="tamano_casa">Tamaño Casa</Option>
										</Select>
									</div>
								</Col>
							</Row>
							<Row gutter={[16, 16]}>
								{this.renderPropiedades()}
							</Row>
						</Spin>
					</Col>

				</Row>
				<ModalInmueble
					visible={this.state.ModalInmuebleVisible}
					onCancel={() => { this.setState({ ModalInmuebleVisible: false, propiedad_id: undefined }) }}
					propiedad_id={this.state.propiedad_id}
					favs={this.state.favs}
					setFavs={(favs) => { this.setState({ favs: favs }) }}
					openLogin={() => { this.setState({ ModalLoginVisible: true }) }}
				/>

				<ModalLogin
					visible={this.state.ModalLoginVisible}
					onCancel={() => { this.setState({ ModalLoginVisible: false }) }}
					onForgot={() => { this.setState({ ModalForgotVisible: true, ModalLoginVisible: false }) }}
					setFavs={() => { this.getFavs() }}
				/>

				<ModalForgotPassword
					visible={this.state.ModalForgotVisible}
					onCancel={() => { this.setState({ ModalForgotVisible: false }) }}
				/>
			</Content>
		)
	}
}