import React, { Component } from "react";
import { Row, Col, Button, Input, Select, Typography, DatePicker, Form, message } from 'antd'
import { CaretDownFilled } from '@ant-design/icons';
import axios from 'axios'

import { User, SetUser } from '../../../Context/User'

//css
import '../../../styles/Inmueble.css'
import '../../../styles/Modals.css'

const { Option } = Select;
const { Title, Text, Paragraph } = Typography;
const moment = require('moment')


/**
 *
 *
 * @export
 * @class FormCita
 * @extends {Component}
 */
export default class FormCita extends Component {

    static contextType = User


    static defaultProps = {
        agencia: {
            _id: '',
            nombre: '',
            horarios: [],
            tipo: 0
        }
    }


    citaForm = React.createRef()

    constructor(props) {
        super(props)
        this.state = {
            // citas form state
            time_disabled: {},
            hours_disabled: [],
            horas: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
            fecha_hoy: moment().format('YYYY-MM-DD'),
            intervalo: 1,
            fecha_seleccionada: '',
        }
    }


    /**
     *
     *
     * @memberof ModalInmueble
     * @description CAmbia el state cita_tipo a la opcion seleccionada
     * @values Integer => 1-0
     */
    onChangeTipoCita = (value) => {
        //console.log('cita tipo=>', value);
        this.setState({ cita_tipo: value });
    }


    /**
     *
     *
     * @memberof ModalInmueble
     * @description Al cambia la fecha de la cita, carga los horarios disponibles
     * cada horario entre cita y cita es de 1 hora -> 60 minutos
     * @param fecha Object Moment
     */
    onChangeDateCita = async (fecha) => {


        //console.log('fecha', fecha.format('YYYY-MM-DD'));
        axios.post('/agencias/disponibilidad', {
            agencia_id: this.props.propiedad.agencia_id?._id,
            fecha: fecha.format('YYYY-MM-DD'),
            dia_indice: fecha.day(),
        })
            .then(res => {
                console.log('HORAS NO DISPONIBLES', res)
                const data = res.data;
                this.setState({
                    time_disabled: data.data,
                    hours_disabled: data.horas,
                    intervalo: 60,
                    fecha_seleccionada: fecha.format('YYYY-MM-DD')
                })

                this.citaForm.current.resetFields([
                    'horario'
                ])

            })
            .catch(error => {

                message.error('No se han podido cargar los horarios');
            })



    }
    /**
     *
     *
     * @memberof ModalInmueble
     * @description Manda los datos de la cita al ws
     * @param values Form names values
     */
    FormCita = (values) => {

        //Asignamos la hora de finalizacion automaticamente mediante el intervalo de duracion de la cita.
        let hora_seleccionada = moment(
            {
                hour: parseInt(values.horario.split(':')[0]),
                minute: parseInt(values.horario.split(':')[1])
            }
        )
        let hora_fin = hora_seleccionada.clone().add(this.state.intervalo, 'm');


        axios.post('/citas/add', {
            tipo: this.state.cita_tipo,
            propiedad_id: this.props.propiedad?._id,
            agencia_id: this.props.propiedad?.agencia_id,
            fecha: moment(values.fecha_cita).format('YYYY-MM-DD'),
            mensaje: values.mensaje,
            cita_inicio: {
                hora: hora_seleccionada.hours(),
                minutos: hora_seleccionada.minutes()
            },
            cita_fin: {
                hora: hora_fin.hours(),
                minutos: hora_fin.minutes()
            },
        })
            .then(success => {
                this.citaForm.current.resetFields([
                    'fecha_cita',
                    'horario',
                    'mensaje'
                ])
                this.setState({ modalvisible: true, nombre: values.nombre })
                message.success({ content: '¡Cita Enviada!', className: 'message-success' })
            })
            .catch(error => {
                console.log('error', error)
                message.error('Ocurrio un error al reservar la cita. Intente de nuevo.')
            })

    }





    render() {
        return (
            <Form onFinish={this.FormCita} ref={this.citaForm} layout="vertical" requiredMark={false}>
                <Row gutter={20}>
                    <Col className="flex-column" span={24}>
                        <Paragraph className="text-description">Seleccione el tipo de cita</Paragraph>
                    </Col>
                    <Col className="gutter-row" xs={24} md={12}>
                        <Button className={(this.state.cita_tipo == 1) ? "btn-yellow" : "btn-black"} onClick={() => this.onChangeTipoCita(1)}>En linea</Button>
                    </Col>
                    <Col className="gutter-row" xs={24} md={12}>
                        <Button className={(this.state.cita_tipo == 0) ? "btn-yellow" : "btn-black"} onClick={() => this.onChangeTipoCita(0)}>En Persona</Button>
                    </Col>
                </Row>

                <Row gutter={20} className="mt-2" justify="space-around">
                    <Col className="gutter-row" span={12}>
                        <Form.Item label="Selecciona Día" name="fecha_cita" rules={[{ required: true, message: "Indique la fecha de su cita" }]}>
                            <DatePicker
                                onChange={this.onChangeDateCita}
                                disabledDate={(current) => {

                                    // Can not select days before today and today
                                    return current && current < moment().startOf('day');


                                    //  console.log('current <', this.props.agencia);
                                    //
                                    //  if (current.isBefore(moment())){
                                    //      return true
                                    //  }
                                    //  
                                    //  else if (typeof this.props.agencia === "object" && this.props.agencia.horarios.findIndex(({ dia }) => dia == current.toDate().getDay()) == -1){
                                    //      return true
                                    //  }
                                    //  
                                    //  return false 
                                }}
                                placeholder="Selecciona el Dia" style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item label="Seleccione Hora" name="horario" rules={[{ required: true, message: "Indique la hora de su cita" }]}>
                            <Select suffixIcon={<CaretDownFilled className="icon-down-pink" />} className="form-select-cita" >
                                {

                                    this.state.horas.map((hora, index) => {
                                        //El ternario valida si es el dia de hoy para validar con la hora de logueo.
                                        if (this.state.time_disabled[hora] == undefined && this.state.intervalo != 1 && ((this.state.fecha_hoy == this.state.fecha_seleccionada) ? hora >= moment().hours() : true)) { //Si no existe, tenemos disponibilidad completa
                                            //Se crean los saltos por hora
                                            let limite = Array.from({ length: (60 / this.state.intervalo) }, (x, i) => i)
                                            //SI es el dia de hoy, se debe validar la fecha de 
                                            if (hora == moment().hours()) //Si es la misma hora se validan los minutos mayores a los de la hora actual
                                                return limite.map((v, index) => {
                                                    console.log('v', v, limite.length)
                                                    if ((v * this.state.intervalo) >= moment().minutes()) { //Solo se agregan los intervalos mayores a los minutos de la hora actual
                                                        let m1 = moment({ hour: hora, minute: ((v) * this.state.intervalo) })
                                                        let m2 = m1.clone().add(this.state.intervalo, 'm')
                                                        return <Option value={m1.format('HH:mm')}>{`${m1.format('HH:mm')} - ${m2.format('HH:mm')}`}</Option>
                                                    }
                                                })
                                            else
                                                return limite.map((v, index) => {
                                                    console.log('v', v, limite.length)
                                                    let m1 = moment({ hour: hora, minute: ((v) * this.state.intervalo) })
                                                    let m2 = m1.clone().add(this.state.intervalo, 'm')
                                                    return <Option value={m1.format('HH:mm')}>{`${m1.format('HH:mm')} - ${m2.format('HH:mm')}`}</Option>

                                                })

                                        } else if (this.state.time_disabled[hora] != undefined && this.state.intervalo != 1 && ((this.state.fecha_hoy == this.state.fecha_seleccionada) ? hora >= moment().hours() : true)) {
                                            //Si ya existe la hora, verificamos si hay citas previas
                                            if (this.state.time_disabled[hora].length != 0 && this.state.time_disabled[hora].length != 60) {
                                                //Se crean los saltos por hora
                                                let saltos = Array.from({ length: (60 / this.state.intervalo) }, (x, i) => i * this.state.intervalo)

                                                if (hora == moment().hours())
                                                    return saltos.map((s, index) => {
                                                        let ind = this.state.time_disabled[hora].findIndex((e) => e == s)
                                                        if (ind == -1) {
                                                            //No existe , no hay cita, es disponible
                                                            let m1 = moment({ hour: hora, minute: s })
                                                            let m2 = m1.clone().add(this.state.intervalo, 'm')
                                                            return <Option value={m1.format('HH:mm')}>{`${m1.format('HH:mm')} - ${m2.format('HH:mm')}`}</Option>

                                                        }
                                                    })
                                                else
                                                    return saltos.map((s, index) => {

                                                        if (s >= moment().minutes()) {
                                                            let ind = this.state.time_disabled[hora].findIndex((e) => e == s)

                                                            if (ind == -1) {
                                                                //No existe , no hay cita, es disponible
                                                                let m1 = moment({ hour: hora, minute: s })
                                                                let m2 = m1.clone().add(this.state.intervalo, 'm')
                                                                return <Option value={m1.format('HH:mm')}>{`${m1.format('HH:mm')} - ${m2.format('HH:mm')}`}</Option>

                                                            }
                                                        }

                                                    })


                                            }


                                        }


                                    })

                                }


                            </Select>

                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={24}>
                        <Form.Item label="Mensaje" name="mensaje" >
                            <Input.TextArea placeholder="Deseo visitar esta propiedad" />
                        </Form.Item>
                    </Col>
                    <Col className="mt-1" span={24}>
                        <Form.Item>
                            <Button htmlType="submit" className="btn-yellow">Apartar la fecha.</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form >

        )
    }
}