import React, { Component, } from 'react'
import { Layout, Row, Col, PageHeader, Tag, Calendar, Badge, Button, message } from 'antd'
import axios from 'axios'
import DetalleCita from './Detalle';
const { Content } = Layout;
const moment = require('moment');

/**
 *
 *
 * @class CalendarioCitas
 * @extends {Component}
 */
export default class CalendarioCitas extends Component {


    /**
     *Creates an instance of CalendarioCitas.
     * @param {*} props
     * @memberof CalendarioCitas
     */
    constructor(props) {
        super(props)
        this.state = {
            citas: [],
            cita_selected: null,
            loading: false,
            month: (moment().month()) + 1,
            year: moment().year()
        }
    }



    /**
     *
     *
     * @memberof CalendarioCitas
     * @description Manda a llamar al metodo getCitas para cargar las citas del calendario
     */
    componentDidMount() {
        this.getCitas();
    }


    /**
     * @memberof CalendarioCitas
     * @method getCitas
     *  @description Obtiene las citas del ws del mes y año seleccionado
     */
    getCitas = () => {
        this.setState({ loading: true })
        axios.get('/citas/agencia', {
            params: {
                month: this.state.month,
                year: this.state.year,
            }
        }).then(res => {
            this.setState({citas: this.getDatesFormat(res.data.mensuales)})
        })
        .catch(error => {
            message.error('Error al cargar las citas')
        })
        .finally(() => {
            this.setState({ loading: false })
        })
    }

     /**
     * @memberof Dashboard_Agente
     * @method   getDatesFormat
     * @description Establecemos el formato de fecha para que el metodo de dateCellRender lo pueda leer,
     *  retorna un objeto con campos fechas, y dentro de estos un array con los eventos que hay es esa fecha
     * @param fechas [{*}] : Array de Objetos con la informacion de cada Evento del mes
     *
     * @return fechasCalendar { 
     *      fecha_1: [{evento_1},...],
     *      fecha_2: [{evento_n}]  
     *  }
     */
    getDatesFormat = (fechas) => {
        let fechasCalendar = {};
        if(fechas){
            for (const fecha of fechas){
                let fechaIndex =  moment(fecha.fecha).add(1,'days').format('YYYY-MM-DD');
                if(!fechasCalendar[fechaIndex]){
                    fechasCalendar[fechaIndex] = []
                }
                fechasCalendar[fechaIndex] = fecha
            }
        }
        return fechasCalendar;
    }

     /**
     * @memberof Calendario
     * @method getDatesFormat
     * @description Renderiza los badges dentro del las celdas del calendario
     * 
     */
    dateCellRender = (value) => {
        let cellDayValue = value.format('YYYY-MM-DD');
        if(this.state.citas[cellDayValue]){
            return (
                <ul className="citas">
                    {this.state.citas[cellDayValue].citas.map((cita) => (
                        <Badge
                            className="hover-badge"
                            onClick={()=>{
                                this.setState({
                                    cita_selected: cita,
                                })
                            }} 
                            text={`${cita.cita_inicio.hora}:00 - ${cita.cita_fin.hora}:00`} 
                            color='#3333cc'
                            style={{ width: '100%' }} />
                    ))}
                </ul>
            )
        }
    }


    /**
   * @memberof Calendario
   * @method onChange
   *  @description Manda a llamar al metodo getCitas para cargar las citas del mes 
   */
    onChange = (value) => {
        this.setState({
            month: (moment(value).month()) + 1,
            year: moment(value).year()
        },()=>{this.getCitas()})
    }



    render() {

        return (
            <Layout className="bg-gray pd-1">
                <PageHeader title="Calendario" className="bg-white mt-1 mr-2 ml-2 " />
                <Content className="mt-1" style={{ padding: '0px 2rem' }}>
                    <Row wrap gutter={[16, 16]}>
                        <Col xs={{ span: 24, oder: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 19, order: 1 }} lg={{ span: 19, order: 1 }} xl={{ span: 19, order: 1 }} xxl={{ span: 19, order: 1 }}  >
                            <Calendar
                                className="calendario p-1"
                                dateCellRender={this.dateCellRender}
                                onChange={this.onChange}
                            />
                        </Col>
                        <Col xs={{ span: 24, oder: 2 }} sm={{ span: 24, order: 2 }} md={{ span: 5, order: 2 }} lg={{ span: 5, order: 2 }} xl={{ span: 5, order: 2 }} xxl={{ span: 5, order: 2 }} >
                           <DetalleCita cita={this.state.cita_selected}/>
                        </Col>
                    </Row>

                </Content>
            </Layout>
        )
    }

}
