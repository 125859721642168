import React, { Component, useState, useEffect } from 'react'
import { Dropdown, Checkbox, Menu, Button, Collapse, Radio } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons';
//css
import './filtros.css'
import { type } from 'jquery';

const { Panel } = Collapse;

const plainOptions = ['agente', 'proximamente', 'inmobiliaria', 'construccion', 'subasta', 'existentes',
	'hipotecarios', 'comunidad', 'pre-juicios', 'reducido', 'tours', 'virtuales'];



/**
 *
 *
 * @export
 * @param {*} { value = {}, onChange }
 * @return {*} 
 * 
 * @description Componente de "Tipo de Venta" con diferentes opciones de venta
/**
 *
 *
 * @export
 * @param {*} { value = {}, onChange }
 * @return {*} 
 */
export default function ({ value = {}, onChange }) {

	/** @type {*} Si el DROPDOWN está visible o no */
	const [visible, setVisible] = useState(null);

	/** @type {*} Es el valor actual del tipo de pago, según sea venta renta o vendida */
	const [tipo_pago, setTipoPago] = useState((value?.tipo_pago !== undefined) ? value?.tipo_pago : 2);

	/** @type {*} Son los valores seleccionados */
	const [seleccionado, setSeleccionado] = useState(null);


	/** @type {*} Cuando "Todo"  ha sido seleccionado */
	const [todo, setTodo] = useState([]);

	/** @type {*} Cuando se actualizan los valores de la venta */
	const [venta, setVenta] = useState([]);

	/** @type {*} Cuando se actualizan los valores de de los juicios */
	const [juicios, setJuicios] = useState([]);
	const [tours, setTours] = useState([]);


	/** @type {*} Cuando se actualizan los valores de las rentas */
	const [renta, setRenta] = useState([]);

	/** @type {*} Si se declara vendida (indicar los valores) */
	const [vendida, setVendida] = useState([]);


	/**
	 * componentDidMount
	 *
	 * @export
	 * @param {*} { value = {}, onChange }
	 * @return {*} 
	 * 
	 * Si se actualiza el tipo de pago, tambien lo actualizamos acá.
	 */
	useEffect(() => {
		if (value?.tipo_pago !== tipo_pago)
			setTipoPago(value?.tipo_pago)
	});


	/**
	 * componentDidMount
	 *
	 * @export
	 * @param {*} { value = {}, onChange }
	 * @return {*} 
	 * 
	 * Si actualizamos algunos de los valores, lo actualizamos 
	 */
	const triggerChange = (changedValue) => {
		onChange({
			tipo_pago,
			seleccionado,
			...value,
			...changedValue
		})
	};

	/**
	 * @method onChangeTipo
	 *
	 * @export
	 * @param {*} { value = {}, onChange }
	 * @return {*} 
	 * 
	 * Si se actualiza el tipo, actualizamos tambien en el value
	 */
	const onChangeTipo = (e) => {
		let nuevo_tipo_pago = parseInt(e.target.value);

		if (nuevo_tipo_pago === tipo_pago)
			nuevo_tipo_pago = undefined


		if (Number.isNaN(nuevo_tipo_pago)) {
			return;
		}
		setTipoPago(nuevo_tipo_pago)
		triggerChange({ tipo_pago: nuevo_tipo_pago });
	}

	/**
	 * @method onChangeSubTipos
	 *
	 * @export
	 * @param {*} { value = {}, onChange }
	 * @return {*} 
	 * 
	 * Si actualizamos los subtitpos, tambien actualizamos todos los valores dependiendo de cual es el valor seleccionado
	 */
	const onChangeSubTipos = ({ todo_ = todo, venta_ = venta, juicios_ = juicios, tours_ = tours, renta_ = renta, vendida_ = vendida }) => {

		let seleccionado = []
		switch (tipo_pago) {
			case 2:
				seleccionado = [...venta_, ...juicios_, ...tours_]
				break;
			case 1:
				seleccionado = renta_
				break;
			case 0:
				seleccionado = vendida_
				break;
		}

		setSeleccionado(seleccionado)
		triggerChange({ seleccionado })
	}




	/**
	 *
	 *
	 * @return {*} 
	 * 
	 * @description Renderizamos el tipo seleccionado
	 */
	const renderCurrentVentaType = () => {
		switch (tipo_pago) {

			case 0:

				return <Radio className="simple-radio-yellow" value={2} checked={true}>Vendidas</Radio>;
			case 1:

				return <Radio className="simple-radio-purple" value={2} checked={true}>En Renta</Radio>;

			case 2:
				return <Radio className="simple-radio-green" value={2} checked={true}>En Venta</Radio>;

			default:
				return "Venta";
		}
	}




	/**
	 * @objetc Opciones de los checkbox
	 *
	 * @export
	 * @param {*} { value = {}, onChange }
	 * @return {*} 
	 * 
	 * Si actualizamos los subtitpos, tambien actualizamos todos los valores dependiendo de cual es el valor seleccionado
	 */
	const option_venta = [
		{ label: 'Por Agente', value: 'agente' },
		{ label: 'Por Inmobiliaria', value: 'inmobiliaria' },
		{ label: 'Subasta', value: 'subasta' },
		{ label: 'Casas Existentes', value: 'existentes' },
	]
	const option_renta = [
		{ label: 'Proximamente', value: 'proximamente' },
		{ label: 'Nueva Construcción', value: 'construccion' },
	]
	const option_juicios = [
		{ label: 'Jucios Hipotecarios', value: 'hipotecarios' },
		{ label: 'Comunidad 3era Edad', value: 'comunidad' },
		{ label: 'Pre - Jucios Hipotecarios', value: 'pre-juicios' },
	]
	const option_tours = [
		{ label: 'Precio Reducida', value: 'reducido' },
		{ label: '3D Tours', value: 'tours' },
		{ label: 'Tours Virtuales', value: 'virtuales' },
	]

	return (
		<Dropdown
			overlay={<Menu className="dropdown-collapse">
				<Radio.Group
					name="radiogroup"
					defaultValue={1}
					style={{ width: '100%' }}
					onChange={onChangeTipo}
					value={tipo_pago}
				>
					<Collapse
						accordion
						expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
						className=""
					>
						<Panel header={<Radio className="radio-green" value={2}>En venta</Radio>} key="1" showArrow={true}>

							<Checkbox.Group
								options={[
									{ label: 'Todo', value: 1 }
								]}
								value={todo}
								onChange={todo_ => {
									setTodo(todo_)
									onChangeSubTipos({ todo_ })
								}}
							/>

							<hr className="hr" />

							<Checkbox.Group
								options={[...option_venta, ...option_renta]}
								value={venta}
								// onChange={setVenta}
								onChange={venta_ => {
									setVenta(venta_)
									onChangeSubTipos({ venta_ })
								}}

							/>

							<hr className="hr" />

							<Checkbox.Group
								onChange={juicios_ => {
									setVenta(juicios_)
									onChangeSubTipos({ juicios_ })
								}}
								options={option_juicios}
								value={juicios} />

							<hr className="hr" />

							<Checkbox.Group
								// onChange={tours => this.updateArrays({ tours })}
								onChange={tours_ => {
									setVenta(tours_)
									onChangeSubTipos({ tours_ })
								}}
								options={option_tours}
								value={tours} />

						</Panel>
						<Panel header={<Radio className="radio-purple" value={1}>En Renta</Radio>} key="2" showArrow={true}>
							<Checkbox.Group
								// onChange={setRenta}
								onChange={renta_ => {
									setRenta(renta_)
									onChangeSubTipos({ renta_ })
								}}
								options={option_renta}
								value={renta} />

						</Panel>
						<Panel showArrow={false} disabled={true} header={<Radio style={{ marginLeft: '27px' }} className="radio-yellow disabled-tabpane" value={0}>Vendida</Radio>} key="3">

							<Checkbox.Group
								// onChange={setVendida}
								onChange={vendida_ => {
									setVendida(vendida_)
									onChangeSubTipos({ vendida_ })
								}}
								options={option_renta}
								value={vendida} />
						</Panel>
					</Collapse>
				</Radio.Group>
			</Menu>}
			onVisibleChange={setVisible}
			visible={visible}
			trigger={['click']}
		>
			<Button style={{ width: '100%' }}> {renderCurrentVentaType()} </Button>
		</Dropdown>
	)
}