import React, { Component, Suspense } from 'react'
import { Layout, Row, Col, Typography, PageHeader, Space, Tag, Calendar, Card, Input, Button, message, Select, Comment, Modal, List, Empty, Badge } from 'antd'
import { ExclamationCircleOutlined, FileTextOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { IconAgent } from '../Widgets/Iconos'
import { FaComment, FaCalendarAlt } from 'react-icons/fa'
import { InmuebleCardPro, InmuebleCardSkeleton } from '../Widgets/cards/Cards'
import axios from 'axios'
import { User } from '../../Context/User'
import Dashboard from '../Admin/Dashboard';
import CustomAvatar from '../Widgets/Avatar/Avatar';
import 'moment/locale/es'

const { Content } = Layout;
const { Text, Title } = Typography;
const { Search } = Input
const moment = require('moment');
moment.locale('es')

/**
 *
 *
 * @export
 * @class Dashboard_Agente
 * @extends {Component}
 * @description Dashboard del usuario tipo agente
 */
export default class Dashboard_Agente extends Component {

    static contextType = User
    /**
     *Creates an instance of Dashboard_Agente.
     * @param {*} props
     * @memberof Dashboard_Agente
     * 
     * @citas Listado de citas del agente
     * @messages Listado de mensajes del agente
     * @loading Cargando datos de la vista
     */
    constructor(props) {
        super(props)
        this.state = {
            citas: [],
            searchCita: '',
            propiedades: [],
            prospectos: [],

            loading: false,
            loadingProperties: true,
            loadingCitas: true,
            fecha: moment().format('YYYY-MM-DD'),
            month: (moment().month()) + 1,
            year: moment().year(),
            mensuales: {},
        }
    }


    /**
     *
     *
     * @memberof Dashboard_Agente
     * @description Carga los datos de la vista
     */
    componentDidMount() {
        this.getCitas();
        this.getPropiedades()
        this.getProspectos()
    }

    /**
     * @memberof Dashboard
     * @method   renderHeaderCal
     * @description Renderizado del header del calendario para cambiar el CheckboxGroup a un Select para responsividad
     *
     **/
    renderHeaderCal = ({ value, type, onChange, onTypeChange }) => {
        const start = 0;
        const end = 12;
        const monthOptions = [];

        const current = value.clone();
        const localeData = value.localeData();
        const months = [];
        for (let i = 0; i < 12; i++) {
            current.month(i);
            months.push(localeData.monthsShort(current));
        }

        for (let index = start; index < end; index++) {
            monthOptions.push(
                <Select.Option className="month-item" key={`${index}`}>
                    {months[index]}
                </Select.Option>,
            );
        }
        const month = value.month();

        const year = value.year();
        const options = [];
        for (let i = year - 10; i < year + 10; i += 1) {
            options.push(
                <Select.Option key={i} value={i} className="year-item">
                    {i}
                </Select.Option>,
            );
        }
        return (
            <div className="calendar-header" >
                <Row justify="space-between" className="p-1">
                    <Col span={12} xxl={{ span: 6 }} xl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }} className="calendar-group">
                        <Select className="select-date" onChange={value => onTypeChange(value)} value={type} >
                            <Select.Option value="month">Mes</Select.Option>
                            <Select.Option value="year">Año</Select.Option>
                        </Select>
                    </Col>
                    <Col span={12} xxl={{ span: 6 }} xl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 12 }} className="calendar-select ">
                        <Select
                            className="select-date"
                            dropdownMatchSelectWidth={false}
                            value={String(month)}
                            onChange={selectedMonth => {
                                const newValue = value.clone();
                                newValue.month(parseInt(selectedMonth, 10));
                                onChange(newValue);
                            }}
                        >
                            {monthOptions}
                        </Select>
                    </Col>
                    <Col span={12} xxl={{ span: 6 }} xl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 12 }} className="calendar-select">
                        <Select
                            className="select-date"
                            dropdownMatchSelectWidth={false}
                            onChange={newYear => {
                                const now = value.clone().year(newYear);
                                onChange(now);
                            }}
                            value={String(year)}

                        >
                            {options}
                        </Select>
                    </Col>
                </Row>

            </div>
        )
    }

    /**
     *
     *
     * @memberof Dashboard_Agente
     * @description Lista las ultimas citas del Agencia
     */
    getCitas = (search = this.state.searchCita) => {
        let user = this.context;
        this.setState({ loadingCitas: true })
        axios.get('/citas/agencia', {
            params: {
                limit: 10,
                //fecha: this.state.fecha,
                agencia_id: user?.agencia_id,
                search: search,
                month: this.state.month,
                year: this.state.year,
            }
        })
            .then(res => {
                console.log('citas',res.data)
                let citas = res.data.data.data;
                this.setState({
                    search: search,
                    citas: citas,
                    mensuales: this.getDatesFormat(res.data.mensuales)
                });
            })
            .catch(error => {
                message.error({ content: 'Ha ocurrido un error al traer las citas', className: "message-error" })
            })
            .finally(f => {
                this.setState({ loadingCitas: false })
            })
    }

    /**
     *
     *
     * @memberof Dashboard_Agente
     * @description Lista de las propiedades de la Agencia
     */
    getPropiedades() {
        let user = this.context;
        this.setState({ loadingProperties: true });
        axios.get('/propiedades', {
            params: {
                limit: 3,
                page: 1,
                agencia_id: user?.agencia_id,
            }
        })
            .then(res => {
                let propiedades = res.data.data.propiedades.data;
                this.setState({ propiedades: propiedades })
            })
            .catch(error => {
                console.log('error al traer propiedades', error.res);
            })
            .finally(f => {
                this.setState({ loadingProperties: false })
            })

    }

    /**
     *
     *
     * @memberof Dashboard_Agente
     * @description Lista los utlimos prospectos
     */
    getProspectos = () => {
        axios.get('/prospectos/list',{
            params:{
                limit: 5
            }
        }).then(response => {
            console.log(response.data.data)
            this.setState({prospectos: response.data.data.data})

        }).catch(error => {
            console.log(error)
        })
     }


    /**
     *
     *
     * @memberof Dashboard_Agente
     * @description Cambia el mes a buscar citas
     */
    onChangeDates = (value) => {
        this.setState({
            month: (moment(value).month()) + 1,
            year: moment(value).year()
        },()=>{this.getCitas()})
    }

    /**
     *
     *
     * @memberof Dashboard_Agente
     * @description Elimina una propiedad
     */
    deletePropiedad = (id) => {

        Modal.confirm({
            title: 'Do you Want to delete these items?',
            icon: <ExclamationCircleOutlined />,
            content: 'Some descriptions',
            onOk: () => {
                axios.delete('/propiedad/delete', {
                    params: {
                        id
                    }
                })
                    .then(x => {

                        message.success("Eliminada correctamente.")
                        this.getPropiedades();

                    })
                    .catch(x => {
                        message.error("No fue posible eliminar.")
                    })
            },
            onCancel: () => {
                console.log('Cancel');
            },
        })
    }


     /**
     * @memberof Dashboard_Agente
     * @method   getDatesFormat
     * @description Establecemos el formato de fecha para que el metodo de dateCellRender lo pueda leer,
     *  retorna un objeto con campos fechas, y dentro de estos un array con los eventos que hay es esa fecha
     * @param fechas [{*}] : Array de Objetos con la informacion de cada Evento del mes
     *
     * @return fechasCalendar { 
     *      fecha_1: [{evento_1},...],
     *      fecha_2: [{evento_n}]  
     *  }
     */
    getDatesFormat = (fechas) => {
        let fechasCalendar = {};
        if(fechas){
            for (const fecha of fechas){
                let fechaIndex =  moment(fecha.fecha).add(1,'days').format('YYYY-MM-DD');
                if(!fechasCalendar[fechaIndex]){
                    fechasCalendar[fechaIndex] = []
                }
                fechasCalendar[fechaIndex].push(fecha)
            }
        }
        return fechasCalendar;

    }

    /**
     * @memberof Dashboard_Agente
     * @method dateCellRenderSmall
     * @description Renderiza los badges dentro del las celdas del calendario, 
     * este metodo se llama automaticamente por cada dia del mes por el componente de antd 
     * 
     */
    dateCellRenderSmall = (value) => {

        let cellDayValue = value.format('YYYY-MM-DD');
        if(this.state.mensuales[cellDayValue]){
            return <Badge className="badge-calendario" color='#3333cc' />
        }
    }


    render() {

        const { propiedades, citas } = this.state;
        return (
            <Layout className="bg-gray pd-1">
                {/*<User.Consumer>
                    {value => {
                        if (value !== null && this.preventCycle == false) {
                            this.preventCycle = true
                            this.getCitas();
                            this.getPropiedades()
                        }
                    }}
                </User.Consumer>*/}
                <PageHeader title="Dashboard" className="bg-white mt-1 mr-2 ml-2 " />
                <Content className="mt-1" style={{ padding: '0px 2rem' }}>
                    <Row wrap gutter={[16, 16]} justify="space-between" align="top">
                        <Col xs={{ span: 24, oder: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 12, order: 1 }} lg={{ span: 6, order: 1 }} >
                            <Card className="card-title card-calendar" title="Calendario" bordered={false} >
                                <Calendar
                                    className="calendario-small"
                                    fullscreen={false} 
                                    headerRender={this.renderHeaderCal}
                                    dateCellRender={this.dateCellRenderSmall}
                                    onChange={this.onChangeDates}
                                />
                                <Space className="flex-end">
                                    <Link to={"/professionals/calendario/citas"}>
                                        <Button className="btn-blue" type="primary" size="small" shape="circle" icon={<RightOutlined />} />
                                        <Text strong className="text-blue">Ver Calendario</Text>
                                    </Link>
                                </Space>
                            </Card>
                        </Col>
                        <Col xs={{ span: 24, oder: 3 }} sm={{ span: 24, order: 3 }} md={{ span: 24, order: 3 }} lg={{ span: 12, order: 2 }} >
                            <Card className="card-title" headStyle={{ background: ' rgba(51, 51, 204, 0.1)' }} title="Citas" bordered={false} >
                                <Row gutter={[0, 8]} justify="space-between">
                                    <Col span={24}>
                                        <Text className="w-100">Buscar Citas</Text>
                                    </Col>
                                    <Col sm={{ span: 20 }} md={{ span: 20 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <Search className="input-search-addon-blue" suffix={<SearchOutlined />} placeholder="Buscar..." enterButton onSearch={value => this.getCitas(value)} />
                                    </Col>
                                    {/* <Col sm={{ span: 4 }} lg={{ span: 4 }} lg={{ span: 2, offset: 10 }} xl={{ span: 2, offset: 10 }}>
                                        <Button className="btn-blue float-right" type="primary" icon={<PlusOutlined />}></Button>
                                    </Col> */}
                                </Row>
                                <List
                                    className="pt-05 list-messages"
                                    itemLayout="horizontal"
                                    dataSource={citas}
                                    locale={{ emptyText: 'No tienes Citas programadas para hoy' }}
                                    loading={this.state.loadingCitas}
                                    renderItem={item => {

                                        
                                        let fecha = moment(item.fecha).add(1,'days')
                                        
                                        return <List.Item className="ant-card-bordered mb-1 shadow-sm">
                                            <List.Item.Meta
                                                avatar={<CustomAvatar image={citas?.usuario_id?.foto_perfil} name="C" style={{ marginLeft: '0.5rem' }} />}
                                                title={
                                                    <>
                                                        <div className="btn-property-group">
                                                            <Button className="btn-property-action align-center " icon={<FaComment color="#3333CC" />}>&nbsp;1</Button>
                                                            <Button className="btn-property-action btn-yellow" icon={<IconAgent color="#000" />}>&nbsp;{(item.tipo == 0) ? 'Tour' : 'Visita'}</Button>
                                                            <Button className="btn-property-action" icon={<FaCalendarAlt color="#3333CC" />}>&nbsp; {fecha.format('LL')} de {item.cita_inicio.hora}:00 a {item.cita_fin.hora}:00</Button>
                                                        </div>
                                                        <Row justify="start" align="top"  >
                                                            <Col span={24}>
                                                                <Text className="list-item-property-name">{item?.propiedad_id?.nombre} <br /></Text>
                                                                <Text strong className="list-item-property-prospecto">{item?.usuario_id?.nombres} {item?.usuario_id?.apellidos}</Text>
                                                            </Col>
                                                        </Row>

                                                    </>
                                                }
                                                description={(item.mensaje) ? item.mensaje : ''}
                                            />

                                        </List.Item>
                                    }}
                                />
                                <Space className="flex-end">
                                    <Link to={"/professionals/citas"}>
                                        <Button className="btn-blue" type="primary" size="small" shape="circle" icon={<RightOutlined />} />
                                        <Text strong className="text-blue">Ver todas las citas</Text>
                                    </Link>
                                </Space>
                            </Card>
                        </Col>

                        <Col xs={{ span: 24, oder: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 2 }} lg={{ span: 6, order: 3 }} >
                            <Card className="card-title" title="Nuevos Prospectos" bordered={false} >
                                <Row gutter={[8, 8]} justify="space-between">
                                    <Text>Buscar Mensajes</Text>
                                    <Col span={22}>
                                        <Search className="input-search-addon-blue" suffix={<SearchOutlined />} placeholder="Buscar..." enterButton />
                                    </Col>
                                    {/* <Col span={2}><Button className="btn-blue" type="primary" icon={<PlusOutlined />}>
                                    </Button>
                                    </Col> */}
                                </Row>
                                <Row justify="space-between">
                                    <List
                                        className="pt-05 w-100 list-messages"
                                        itemLayout="horizontal"
                                        dataSource={this.state.prospectos}
                                        locale={{ emptyText: 'Sin Mensajes' }}
                                        renderItem={item => (
                                            <Comment
                                                prefixCls="property-actions ant-comment"
                                                // actions={[
                                                //     <Tag className="btn-property-files" icon={<FileTextOutlined />} >File (x2)</Tag>,
                                                //     <Button className="btn-property-action-medium  align-center " icon={<FaComment color="#3333CC" />}>1</Button>,
                                                //     <Button className="btn-property-action-medium  align-center" icon={<FaCalendarAlt color="#3333CC" />}>1</Button>
                                                // ]}
                                                author={<Text className="list-item-property-name">{item.nombres} {item.apellidos}</Text>}
                                                avatar={<CustomAvatar image={item?.usuario_id?.foto_perfil} name="P" style={{ marginLeft: '0.5rem' }} />}
                                                content={<p>{item.mensaje}</p>}
                                                datetime={<Text className="">{moment(item.createdAt).format('DD-MM-YYYY')}</Text>}
                                            />
                                        )}
                                    />
                                    <Space className="flex-end">
                                        <Link to="/professionals/prospectos">
                                            <Button className="btn-blue" type="primary" size="small" shape="circle" icon={<RightOutlined />} />
                                            <Text strong className="text-blue">Ver todos los prospectos</Text>
                                        </Link>
                                    </Space>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Row gutter={[20, 20]} className=" mt-2  mb-2">
                        <Col span={24}><Title level={3} className="regular-subtitle">Mis Propiedades</Title></Col>

                        {(this.state.loadingProperties == true) ? <Col xs={24} sm={12} lg={8} ><InmuebleCardSkeleton

                        /></Col> :
                            (propiedades.length > 0) ?
                                propiedades.map(propiedad => {
                                    return <Col xs={24} sm={12} lg={8}> <InmuebleCardPro

                                        onEdit={() => this.props.history.push('/professionals/propiedad/' + propiedad._id)}
                                        onDelete={() => this.deletePropiedad(propiedad._id)} {...propiedad}
                                        {...propiedad} /></Col>
                                })
                                : <Col span={12} offset={6} ><Empty description={'Sin Propiedades'} /></Col>}
                    </Row>
                    <Space className="flex-end p-1">
                        <Link to={'/professionals/propiedades'}>
                            <Button className="btn-blue" type="primary" size="small" shape="circle" icon={<RightOutlined />} />
                            <Text strong className="text-blue">Ver Propiedades</Text>
                        </Link>
                    </Space>
                </Content>
            </Layout >
        )
    }
}

Dashboard.contexType = User;