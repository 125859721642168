import React, { Component, useState, useEffect } from 'react'
import { AutoComplete, Input, Button } from 'antd'
import MapboxGL from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import PropTypes from 'prop-types'
import { IconSearchLanding } from '../../Widgets/Iconos'
import Cookies from 'universal-cookie';
import axios from 'axios'
import { User, SetUser } from '../../../Context/User'
import { EnvironmentOutlined } from '@ant-design/icons';
MapboxGL.accessToken = process.env.REACT_APP_MAPBOX_API




export default function Busqueda(props) {


    const { onChange = () => {},  } = props

    const user = React.useContext(User)

    let [address, setAddress] = useState(null)
    let [initial, setInitial] = useState(true)
    let [direcciones, setDirecciones] = useState([])
    let [searchText, setSearchText] = useState(null)
    let [busquedas, setBusquedas] = useState(undefined)
    let [busquedasDB, setBusquedasDB] = useState(undefined)

    useEffect(() => didUpdate());
    useEffect(() => didUpdate(), []);


    const didUpdate = () => {
        if (typeof address == "object" && (props?.address?.id !== null && props?.address?.id !== undefined)) {
            if (
                   (typeof props.address === "object" && address == null ) ||
                   (
                        (Array.isArray(props.address?.center) && Array.isArray(address?.center)) &&
                        (props.address?.center.length > 1 && address?.center.length > 1 ) && 
                        (props.address?.center[0] !== address?.center[0] || props.address?.center[1] !== address?.center[1])
                   )
                ) {
                setAddress(props.address)
                setSearchText(props.address.place_name)
            }
        } else if (typeof address == "string") {
            if (searchText !== address)
                setSearchText(address)
        }
    } 




    /**
     *
     * @memberof Precio
     * @description Obtenemos la lista de divisas y si no está vacia declaramos el primer elemento por defecto. 
     * 
     * @method triggerChange
     * @description AL hacer algún cambio de valor, actuqlaizamos para el formulario de ANTD
     */
    const triggerChange = (changedValue) => {
        if (typeof onChange === "function")
            onChange({ 
                ...address,
                ...changedValue
            });
    }


    /**
     * 
     * @method busquedaDireccion
     * @param {*} search_text texto a buscar
     * 
     * @description Obtenemos las posibles direcciones.
     */
    const busquedaDireccion = (search_text) => {
        setSearchText(search_text)
        fetch(`${MapboxGL.config.API_URL}/geocoding/v5/mapbox.places/${search_text}.json?access_token=${MapboxGL.accessToken}&language=es`)
            .then(response => response.json().then(data => {
                setDirecciones(data.features)
            }))
    }




    /**
     * 
     * @method saveCookies
     * @param {*} objeto localizacion dque genera el mapbox
     * 
     * @description guarda en las cookies las ultimas 5 busqueas realizadas
     */
    const saveCookies = (option) => {
        const cookies = new Cookies();
        option = option.object
        let busquedas = cookies.get('search')
        if (Array.isArray(busquedas)) {
            //buscamos si ya se habia buscado anteriormente
            let index = busquedas.findIndex(local => option.place_name === local.place_name)
            if (index !== -1) {
                busquedas.splice(index, 1);
            }
            if (busquedas.length >= 3) {
                busquedas.shift()
            }
            busquedas.push(option)
        } else {
            busquedas = []
            busquedas.push(option)
        }
        cookies.set('search', busquedas, { path: '/', maxAge: 60 * 60 * 24 });
    }

    /**
    * 
    * @method saveSearch
    * @param {*} objeto localizacion dque genera el mapbox
    * 
    * @description guarda en la bd las busquedas realizadas
    */
    const saveSearch = (option) => {
        // const user = this.context;
        if (user) {
            option = option.object
            axios.post('usuario/busquedas/add', {
                user_id: user._id,
                localizacion: option,
            }).then(response => { })
                .catch(error => console.log(error))
        }
    }



     /**
    * 
    * @method onSelect
    * 
    * @description Se ejecuta al momento de seleccionar una ubicacion
    */
    const onSelect = (search_text, option) => {

        if(option.value === 'actual'){

            //Buscamos obtenemos la latitud y longitud actual
            navigator.geolocation?.getCurrentPosition( position => {

            let lat = position.coords.latitude;
            let lng = position.coords.longitude;
            //Buscamos la informacion de la ubicación actual
            fetch(`${MapboxGL.config.API_URL}/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${MapboxGL.accessToken}&language=es`)
            .then(response => {
                response.json().then(data => {
                    if(Array.isArray(data.features) && data.features.length > 0){
                        let local = data.features[0]
                        let local_obj = {
                            value: local.place_name,
                            object: local
                        }

                        saveCookies(local_obj)
                        saveSearch(local_obj)
                        setAddress(local)
                        setSearchText(local.place_name)

                        if (typeof props.onSelect == "function")
                            props.onSelect(local)

                        triggerChange(local)


                    }
                })
            })
            .catch(error => {
                console.log(error);
            })
        })

        }else{
            saveCookies(option)
            saveSearch(option)
            setAddress(option.object)
            setSearchText(option.object.place_name)

            if (typeof props.onSelect == "function")
                props.onSelect(option.object)

            triggerChange(option.object)
        }

    }

    /**
     *
     *
     * @param {*} [{ direcciones, search_text }=this.state]
     * @memberof Busqueda
     * 
     * @description Renderizamos las direcciones coincidentes con las busquedas
     */
    const renderDirecciones = () => {

        const cookies = new Cookies()
        let historial = cookies.get('search')
        if (Array.isArray(direcciones) && direcciones.length > 0) {
            return direcciones.map((direccion, index) => {
                let place_name = ""
                if (searchText !== null) {
                    //El regex, obtenemos las palabras claves de busqueda sin simbolos y sin importar las mayusculas
                    let regex = new RegExp(`(${searchText.toLowerCase().split(/\W|_/).filter(x => x).join('|')})`, 'gi')
                    //Buscamos todas aquellas coincidencias y sustituimos aquellas que coincida con la busqueda mediante un strong
                    place_name = direccion.place_name.split(regex).map(text => text.match(regex) ? <strong>{text}</strong> : text)
                } else
                    place_name = direccion.place_name


                return {
                    object: direccion,
                    value: direccion.place_name,
                    label: <div key={index} value={index}>{place_name}</div>
                }
            })
        } else if (historial !== undefined && historial !== null && historial.length > 0) {
            
            if (Array.isArray(historial)) {
                let busquedas = historial.reverse()

                let opciones = busquedas.map((direccion, index) => {
                    let place_name = direccion.place_name
                    return {
                        object: direccion,
                        value: direccion.place_name,
                        label: <div key={index} value={index}>{place_name}</div>
                    }
                })

                return [ {
                        object: {},
                        value: 'actual',
                        label: <div key={'actual'} value={'actual'}> <EnvironmentOutlined style={{color: '#3f30cc' }}/> Ubicación Actual</div>
                    }, ...opciones ]

            } else {
                return []
            }

        } else {
            return [{
                object: {},
                value: 'actual',
                label: <div key={'actual'} value={'actual'}> <EnvironmentOutlined style={{color: '#3f30cc' }}/> Ubicación Actual</div>
            }]
            
        } 
    }
    
    const { placeholder, className, inputClassName, size } = props

    return ([
        <AutoComplete
            options={renderDirecciones()}
            onSelect={onSelect}
            onSearch={busquedaDireccion}
            className={className}
            value={searchText}
            enterButton={null}
        >
            <Input.Search
                size={size}
                placeholder={placeholder}
                enterButton={<Button style={{paddingTop: '5px'}}>
                    <IconSearchLanding />
                </Button>}
                className={inputClassName}
            />

        </AutoComplete>,
    ])

}
