 import React, { useEffect, useState } from 'react'
 import { List, Modal, Form, TimePicker, Radio, Button, message } from 'antd'
 import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
 import moment from 'moment'


// function HorarioFormulario({ onFinish = () => { }, item = null } = {}) {


//     let formRef 
//     let [currentItem, setCurrentItem] = useState(null)

/**
 *
 *
 * @method HorariosFormularios es el formulario de los horarios
 * @param {*} [{ onFinish = () => { }, item = null }={}] 
 * @return {*}  
 */
function HorarioFormulario({ onFinish = () => { }, item = null } = {}) {


    let formRef 
    let [currentItem, setCurrentItem] = useState(null)

    useEffect(() => {
        // console.log('form', formRef);
        if (item !== null && (currentItem === null || currentItem.index !== item.index) && formRef !== undefined) {
            formRef.setFieldsValue(item)
            setCurrentItem(item)
        }
    })
    



    return <Form

        ref={ref => formRef = ref}
        layout="vertical"
        onFinish={onFinish}
    >
        <Form.Item name="index" noStyle>
            <input type="hidden" />
        </Form.Item>
        <Form.Item
            style={{ marginBottom: '2em' }}
            label="Dias"
            name="dia"
            rules={[{
                required: true,
                message: "Debe seleccionar un dia."
            }]}>
            <Radio.Group >
                <Radio value={1}>Lunes</Radio>
                <Radio value={2}>Martes</Radio>
                <Radio value={3}>Miercoles</Radio>
                <Radio value={4}>Jueves</Radio>
                <Radio value={5}>Viernes</Radio>
                <Radio value={6}>Sabado</Radio>
                <Radio value={0}>Domingo</Radio>
            </Radio.Group>
        </Form.Item>
        <Form.Item
            style={{ marginBottom: '2em' }}
            label="Inicio"
            name="inicio"
            rules={[
                {
                    required: true,
                    message: 'Debe ingresar la hora máxima.'
                }
            ]}
        >
            <TimePicker format="HH:mm a" picker="time" className="w-100" />
        </Form.Item>
        <Form.Item
            style={{ marginBottom: '2em' }}
            label="Fin"
            name="fin"
            rules={[
                {
                    required: true,
                    message: 'Debe ingresar la hora máxima.'
                }
            ]}

        >
            <TimePicker format="HH:mm a" picker="time" className="w-100" />
        </Form.Item>
        <Form.Item>
            <Button type="primary" htmlType="submit">Agregar</Button>
        </Form.Item>
    </Form>
}




export default function Horarios({ value, onChange = () => { } }) {

    /** @type {*} declaramos los horarios */    
    let [horarios, setHorarios] = useState([])

    /** @type {*} modal Para ocultar el modal */
    let [modal, setModal] = useState(false)

    /** @type {*} item Declaramos el item en el state */
    let [item, setItem] = useState(null)

    /**
     *
     *
     * @method useEffect (componentDidMount)
     * @description Hacemos el trigger cuando cambie.
     */
    useEffect(() => triggerHorarios(horarios), [])

    /**
     *
     *
     * @method useEffect (componentDidUpdate)
     * @description Cuando cambie el value, lo actualizamos
     */
    useEffect(() => {

        if (Array.isArray(value) && value.length !== horarios.length)
            setHorarios(value?.map(e => ({
                dia: e.dia,
                inicio: moment(e.inicio.hora.toString().padStart(2,'0') + ":" + e.inicio.minuto.toString().padStart(2,'0'), "HH:mm"),
                fin: moment(e.fin.hora.toString().padStart(2,'0') + ":" + e.fin.minuto.toString().padStart(2,'0'), "HH:mm")
            })))
    })

 
    /**
     *
     *
     * @method triggerHorarios
     * @description Actualizamos el horario cuando cambie mediante el onchange en le formulario
     * @param {*} newHorarios Nuevo arreglo de horarios
     */

    const triggerHorarios = (newHorarios) => {

        // console.log('newHorarios', newHorarios);
        if (typeof onChange === "function")
            onChange(newHorarios)

        setHorarios(newHorarios)
    }

 
    /**
     *
     *
     * @method deleteHorarios
     * @description Eliminamos el horario seleccionado
     * @param {*} index
     */

    const deleteHorarios = index => {
        horarios.splice(index, 1)
        triggerHorarios([...horarios])
    }

    return (
        <div>
            <Button onClick={() => setModal(true)} disabled={horarios.length >= 7 ? true : false} type="link" style={{ margin: '0 auto', display: "block" }}><PlusOutlined /> Agregar Regla de Horario </Button>
            <List
                itemLayout="horizontal"
                dataSource={horarios}
                renderItem={(item, index) => (
                    <List.Item
                        // EditOutlined, DeleteOutlined
                        actions={[

                            <a key="editar-horario" onClick={() => {
                                    setItem({ ...item, index })
                                    setModal(true)
 
                                
                            }}>Editar &nbsp; <EditOutlined /></a>,

                            <a href="#"
                                onClick={() => Modal.confirm({
                                    title: "¿Deseas eliminar el horario?",
                                    okText: 'Si',
                                    cancelText: 'No',
                                    onOk: () => deleteHorarios(index)
                                })}
                                key="eliminar-horario">Eliminar &nbsp; <DeleteOutlined /></a>
                        ]}
                    >
                        <List.Item.Meta
                            description={<p> {`${((number_dia, index) => {
                                let dia
                                switch (number_dia) {
                                    case 1:

                                        dia = "Lunes"
                                        break
                                    case 2:

                                        dia = "Martes"
                                        break
                                    case 3:

                                        dia = "Miercoles"
                                        break
                                    case 4:

                                        dia = "Jueves"
                                        break
                                    case 5:

                                        dia = "Viernes"
                                        break
                                    case 6:

                                        dia = "Sabado"
                                        break
                                    case 0:

                                        dia = "Domingo"
                                        break
                                }
                                return dia


                            })(item.dia)}`} <strong>{moment(item.inicio).format("HH:mm a")} a {moment(item.fin).format("HH:mm a")}</strong></p>}
                        />
                    </List.Item>
                )}
            />
            <Modal
                title={"Horario"}
                footer={null}
                destroyOnClose={true}
                visible={modal}
                footer={null}
                destroyOnClose={true}
                
                onCancel={() => {
                    setModal(false)
                }}>
                <HorarioFormulario
                    item={item}
                    onFinish={values => {
                        if (typeof values.index === 'number') {

                            let index_h = horarios.findIndex(h=>h.dia === values.dia)

                            if(index_h !== -1){
                                message.error('Ya hay un horario para este dia')
                            }else{
                                horarios[parseInt(values.index)] = values
                                setHorarios([...horarios])

                            }



                        } else {
                            horarios.push(values)
                            triggerHorarios(horarios)
                        }
                        setItem(null)
                        // setModal(false)
                        setModal(false)
                    }}
                />
            </Modal>
        </div>
    )


}


