import React, { Component } from 'react'
import { Layout, Row, Col, Select, List, Button, Typography, Input, Pagination, Spin } from 'antd';
import { IconUbicacion } from './../../Widgets/Iconos'
import { AgenteCard } from "../../Widgets/cards/Cards"
import MenuListas from "./MenuListas"
import axios from 'axios';
import './../../../styles/listas.css'


const { Content } = Layout;
const { Text, Title } = Typography;
const { Search } = Input;
const { Option } = Select;


export default class ListaInspectores extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,

            /* Paginado */
            page: 1,
            itemCount: 0,
            total: 0,

            search: "",


            inspectores: [],
            recomendados: []
        }
    }


    /**
   * @static
   * @memberof ListaInspectores
   * @method   componentDidMount
   * @description  Se llaman consultas iniciales de inspectores
   * */
    componentDidMount = async () => {
        this.getInspectores();
    }


    /**
    * @static
    * @memberof ListaInspectores
    * @method   getInspectores
    * @description   Obtiene la paginación de inspectores
    * */
    getInspectores = async (page = this.state.page, search = this.state.search) => {
        this.setState({ loading: true, });

        axios.get("/agencias", {
            params: {
                page,
                search,
                tipo: 6   // 6 Inspectores
            },
        })
            .then(({ data }) => {
                console.log(data)

                let itemsList = data.data.itemsList,
                    paginator = data.data.paginator;
                this.setState({
                    page: paginator.currentPage,
                    itemCount: paginator.itemCount,
                    inspectores: itemsList,
                    recomendados: data.recomendados
                })
            })
            .catch((e) => {
                console.log('er', e)
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    };

      /**
     *
     *
     * @memberof ListaAgentes
     * @method searchContratista
     * @description Asigna al state.search el valor a buscar y se manda a llamar al metodo getContratistas
     */
       searchInspector = (value) => {
        this.setState({ search: value }, () => {
            this.getInspectores();
        })
    }


    /**
    * @static
    * @memberof ListaInspectores
    * @method   renderRowFiltros
    * @description  se crea row filtros
    * */
    renderRowFiltros = (tipo) => {
        return <Row className="row-filtros-x">
            <Col span={5} className="pdr-10px">
                <Search className="input-search search-ubicacion" placeholder="Localización" />
                <IconUbicacion className="IconUbicacion" />
            </Col>
            <Col span={5} className="pdr-10px">
                <Search className="input-search " placeholder="Busqueda"  onSearch={value => this.searchInspector(value) }/>
            </Col>
            {/* <Col span={5} className="pdr-10px">
                <Select placeholder="Servicios" className="wd-100prs  ">
                    <Option value="a">A</Option>
                    <Option value="b">B</Option>
                    <Option value="c">C</Option>
                </Select>
            </Col>
            <Col span={5} className="pdr-10px">
                <Select placeholder="Tipos de casa" className="wd-100prs ">
                    <Option value="a">A</Option>
                    <Option value="b">B</Option>
                    <Option value="c">C</Option>
                </Select>
            </Col>
            <Col span={4} className="pdr-10px"> 
                <Button type="ghost" className="btn-gh-blue">Más filtros</Button>
            </Col>*/}
        </Row>

    }


    render() {
        let { inspectores, recomendados, loading } = this.state;
        return (
            <Layout className="landing-layout">

                <Content className="list-content" >
                    <Row className="row-bg-image" style={{ backgroundImage: "url('/images/img-lista.png')" }} >
                        <Title level={2} className="text-white mg-auto" >Inspectores</Title>
                    </Row>
                    <Row className="bg-white">
                        <Row className="cnt-center">
                            <Col span={24} >
                                <Title level={4} className="ml-50px">Inspectores Recomendados</Title>
                            </Col>

                            <Col span={24} >

                                <Spin spinning={this.state.loading}>

                                    <Row className="mxw-1240px mg-auto"  >
                                        {recomendados.map((inspector, index) => (
                                            <Col xs={20} md={10} xl={8} span={5} className="center col-landing-card">
                                                <AgenteCard
                                                    _id={inspector._id}
                                                    foto={inspector.logo}
                                                    nombre={inspector.nombre}
                                                    telefono={inspector.telefono}
                                                    calificacion={inspector.calificacion}
                                                    reviews={inspector.reviews}
                                                    descripcion={inspector.descripcion}
                                                    tipo={inspector.tipo}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                </Spin>
                            </Col>

                            <Col xs={22} className="left  col-agentes-tabs ml-50px">
                                <MenuListas />
                            </Col>

                            <Col xs={22} span={5} className="left col-agentes-tabs ml-50px tabs-agentes" >
                                {this.renderRowFiltros(7)}
                            </Col>

                            <Col xs={22} className="left col-agentes-list  ml-50px">
                                <List
                                    itemLayout="horizontal"
                                    dataSource={inspectores}
                                    className="list-agentes"
                                    loading={this.state.loading}
                                    locale={{ emptyText: 'Sin Inspectores' }}
                                    header={<List.Item className="component-list-item wd-100prs">
                                        <Row className="wd-100prs pdl-10px">
                                            <Col xs={22} xl={13} >
                                                <Text>Inspector</Text>
                                            </Col>

                                            <Col xs={22} xl={5}>
                                                <Text>Testimonios</Text>
                                            </Col>
                                        </Row>
                                    </List.Item>

                                    }
                                    renderItem={(inspector, index) => (
                                        <List.Item  >
                                            <Row className="wd-100prs pd-0px">
                                                <Col xs={24} className="left col-landing-card">
                                                    <AgenteCard
                                                        _id={inspector._id}
                                                        foto={inspector.logo}
                                                        nombre={inspector.nombre}
                                                        telefono={inspector.telefono}
                                                        calificacion={inspector.calificacion}
                                                        reviews={inspector.reviews}
                                                        descripcion={inspector.descripcion}
                                                        insignia={inspector.insignia}
                                                        tipo={inspector.tipo}
                                                    />
                                                </Col>
                                            </Row>
                                        </List.Item>
                                    )}
                                />
                            </Col>

                            <Col xs={23} className="left col-paginado">
                                <Pagination
                                    className="paginado right"
                                    defaultCurrent={1}
                                    showSizeChanger={false}
                                    defaultPageSize={this.state.limit}
                                    current={this.state.page}
                                    total={this.state.itemCount}

                                    onChange={(page) => {
                                        this.getInspectores(page)
                                    }}

                                />
                            </Col>
                        </Row>
                    </Row>
                </Content>
            </Layout>
        )
    }
}