import React, { Component } from 'react'
import { Route, Switch } from "react-router";
import { Layout } from 'antd';


// componentes
import Dashboard from "../components/Admin/Dashboard";
import '../styles/dashboard.css'

import { AdminHeader } from "../components/Header/Header";
import { _Footer, _FooterBlue } from "../components/Footer/Footer";
const { Content } = Layout

export default class AdminRoutes extends Component {
	render() {
		return (
			<Layout className="layout">
				<Content>
					<AdminHeader />
					<Switch>
						<Route exact path="/professional" render={(props) => <Dashboard  {...props} />} />
					</Switch>
					<_Footer />
				</Content>
				<_FooterBlue />
			</Layout>
		)
	}
}