import React, { Component } from 'react'
import { Layout, Row, Col, Typography, PageHeader, Space, Carousel, Card, Tabs, Steps, Image, Modal } from 'antd'
import { LeftOutlined } from '@ant-design/icons';
import { IconHousePercent, IconCredit, Gps, Bed, Bath, Room, Area, IconHouse, } from '../../../Widgets/Iconos'
import Tipo from './Tipo'
import Informacion from './Informacion'
import Localizacion from './Localizacion'
import Caracteristicas from './Caracteristicas'
import Imagenes from './Imagenes'


import Preview from './Preview'
import axios from 'axios'







import '../../../../styles/Inmueble.css'
import '../../../../styles/Modals.css'
const { Step } = Steps;
const { TabPane } = Tabs;
const { Content } = Layout;

const { Text, Paragraph } = Typography;



/**
 *
 *
 * @export
 * @class Steps_Container
 * @extends {Component}
 * @description Contenedor de los steps con la informacion de la propiedad y el preview
 */
export default class Editar extends Component {

    /**
     * Creates an instance of Steps_Container.
     * @param {*} props
     * @memberof Steps_Container
     * 
     */
    constructor(props) {
        super(props)
        this.state = {
            current: 0,
            loading: false,
            propiedad: {
                checked_costos: true,
            }
        }
        axios.defaults.headers.common["Authorization"] = sessionStorage.getItem('token')
    }

    /**
     *
     * @method componentDidMount
     * @memberof Steps_Container
     */
    componentDidMount() {
        this.getPropiedad()
    }



    /**
     *
     *
     * @memberof Editar
     * @descripcion Obtiene la propiedad a la cual se desea editar
     */
    getPropiedad = (id = this.props.match.params.id) => {
        axios.get('/propiedad/get', {
            params: { id }
        })
            .then(({ data}) => {
                console.log('data',data);
                this.setPropiedad(data.data)
            })
    }

    /**
     *
     *
     * @memberof Steps_Container
     * @descripcion Asignar al state los datos de la propiedad
     */
    setPropiedad = (propiedad) => this.setState(state => {
        state.propiedad = { ...state.propiedad, ...propiedad }
        return state
    })


    updateCorrect = () => Modal.success({
        title: "Guardado Correctamente"
    })


    irApropiedad = () => this.props.history.push('/professionals/dashboard')

    render() {

        return (
            <Layout className="bg-gray no-overflow-x" style={{ paddingLeft: '3em', paddingRight: '3em' }}>
                <PageHeader title={this.state.propiedad.nombre} subTitle="Editar Propiedad" onBack={() => window.history.back()} backIcon={<LeftOutlined />} style={{ padding: '1rem 2rem' }}
                />
                <Content className="bg-gray">
                    <Row gutter={[16, 40]} justify="center" className="">
                        <Col span={24}>
                            <div className="steps-content p-2 bg-white">
                                <Tabs defaultActiveKey="1" >
                                    <TabPane tab="Información" key="1">
                                        <Informacion
                                            propiedad={this.state.propiedad}
                                            setPropiedad={this.setPropiedad}

                                            editable={true}

                                            titleVisible={false}
                                            next={this.updateCorrect}
                                            />
                                    </TabPane>
                                    <TabPane tab="Localización" key="2">
                                        <Localizacion
                                            propiedad={this.state.propiedad}
                                            setPropiedad={this.setPropiedad}
                                            
                                            editable={true}

                                            titleVisible={false}
                                            next={this.updateCorrect}
                                            />
                                    </TabPane>
                                    <TabPane tab="Características" key="3">
                                        <Caracteristicas
                                            propiedad={this.state.propiedad}
                                            setPropiedad={this.setPropiedad}
                                            
                                            editable={true}

                                            titleVisible={false}
                                            next={this.updateCorrect}
                                            />,
                                    </TabPane>
                                    <TabPane tab="Multimedia" key="4">
                                        <Imagenes
                                            propiedad={this.state.propiedad}
                                            setPropiedad={this.setPropiedad}
                                            {...this.props}
                                            titleVisible={false}
                                            save={false}
                                            
                                            editable={true}

                                            next={this.updateCorrect}
                                            />
                                    </TabPane>

                                    <TabPane tab="Prospectos" key="5" disabled={true}>
                                    </TabPane>
                                    <TabPane tab="Mensajes" key="6"  disabled={true}>
                                    </TabPane>
                                    <TabPane tab="Ayuda" key="7"  disabled={true}>
                                    </TabPane>
                                </Tabs>
                            </div>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        );
    }
}