import React, { Component } from 'react'
import { Layout, Row, Col, Typography, PageHeader, Space, Carousel, Card, Steps, Image, Button } from 'antd'
import { LeftOutlined, ArrowLeftOutlined} from '@ant-design/icons';
import { IconHousePercent, IconCredit, Gps, Bed, Bath, Room, Area, IconHouse, } from '../../../Widgets/Iconos'
import Tipo from './Tipo'
import Informacion from './Informacion'
import Localizacion from './Localizacion'
import Caracteristicas from './Caracteristicas'
import Imagenes from './Imagenes'


import Preview from './Preview'
import axios from 'axios'



import '../../../../styles/Inmueble.css'
import '../../../../styles/Modals.css'
const { Step } = Steps;
const { Content } = Layout;

const { Text, Paragraph } = Typography;



/**
 *
 *
 * @export
 * @class Steps_Container
 * @extends {Component}
 * @description Contenedor de los steps con la informacion de la propiedad y el preview
 */
export default class Steps_Container extends Component {

    /**
     * Creates an instance of Steps_Container.
     * @param {*} props
     * @memberof Steps_Container
     * 
     */
    constructor(props) {
        super(props)

        axios.defaults.headers.common["Authorization"] = sessionStorage.getItem('token')
        this.state = {
            current: 0,
            loading: false,
            propiedad: {
                checked_costos: true,
                // _id: '60d4cd32f74371387414d056'
            },
            first: true,
        }
    }

    /**
     *
     * @method componentDidMount
     * @memberof Steps_Container
     */
    componentDidMount() {
        if (this.state.propiedad._id !== undefined) {
            this.next()
        }

        if (this.props.match.params.id)
            this.getPropiedad()
        // this.props.match.params.id
    }


    /**
     *
     *
     * @memberof Editar
     * @descripcion Obtiene la propiedad a la cual se desea editar
     */
    getPropiedad = (id = this.props.match.params.id) => {
        axios.get('/propiedad/get', {
            params: { id }
        })
            .then(({ data }) => {
                this.setPropiedad(data.data)
            })
    }

    /**
     *
     *
     * @memberof Steps_Container
     * @description Avanzar al siguiente step
     */
    next = () => {
        let current = this.state.current;
        this.setState({ current: current + 1 });
    }

    /**
     *
     *
     * @memberof Steps_Container
     * @description Regresar al step anterior
     */
    prev = () => {
        let current = this.state.current;
        this.setState({ current: current - 1 });
    }

    /**
     *
     *
     * @memberof Steps_Container
     * @descripcion Asignar al state los datos de la propiedad
     */
    setPropiedad = (propiedad, set_edit) => this.setState(state => {
        state.propiedad = { ...state.propiedad, ...propiedad }

        if (state.first === true){

            state.first = false
            
            if(set_edit !== false){
                switch (propiedad.status) {
                    // 1) Información general
                    // 2) Localización
                    // 3) Caracteristicas.
                    // 4) Imagenes.
                    // 5) Completo.
                    case 1:
                        state.current = 1
                        break;
                    case 2:
                        state.current = 2
                        break;
                    case 3:
                        state.current = 3
                        break;
                    case 4:
                        state.current = 4
                        break;
                }
            }
        }
        return state
    })



    irApropiedad = () => this.props.history.push('/professionals/dashboard')

     /**
     *
     *
     * @memberof back
     * @descripcion Vuelve al paso anterior
     */
    back = () => {
        this.setState({current: this.state.current - 1 < 1 ? 1 : this.state.current - 1 })
    }


    render() {

        let { current } = this.state

        const steps = [
            {
                title: 'Tipo de Propiedad',
                content: <Tipo next={this.next} prev={this.prev} propiedad={this.state.propiedad} setPropiedad={this.setPropiedad} />,
            },
            {
                title: 'Información',
                content: <Informacion next={this.next} prev={this.prev} propiedad={this.state.propiedad} setPropiedad={this.setPropiedad} />,
            },
            {
                title: 'Localización',
                content: <Localizacion next={this.next} prev={this.prev} propiedad={this.state.propiedad} setPropiedad={this.setPropiedad} />,
            },
            {
                title: 'Características',
                content: <Caracteristicas next={this.next} prev={this.prev} propiedad={this.state.propiedad} setPropiedad={this.setPropiedad} />,
            },
            {
                title: 'Imágenes',
                content: <Imagenes prev={this.prev} propiedad={this.state.propiedad} setPropiedad={this.setPropiedad} {...this.props} />,
            },
        ]

        return (
            <Layout className="bg-gray no-overflow-x" style={{ overflowX: "hidden" }}>
                <PageHeader title="Nueva Propiedad" onBack={() => {
                    
                   this.props.history.goBack()
                }} backIcon={<LeftOutlined />} style={{ padding: '1rem 2rem' }} >
                    <div className="flex">
                        <Button 
                            onClick={()=>this.back()}
                            type="ghost"
                            className="center"
                            style={{border: "none", color: '#3333cc', marginRight: '1rem', fontSize: '18px', boxShadow: 'none'}}
                        >
                           <ArrowLeftOutlined style={{fontSize: "24px"}}/> Volver
                        </Button>
                        <Steps 
                            current={current}
                        >
                            {steps.map(item => (
                                <Step key={item.title} title={item.title} />
                            ))}
                        </Steps>
                    </div>
                </PageHeader>
                <Content className="bg-gray">
                    <Row gutter={[16, 16]} justify="center" className="pd-1">
                        <Col sm={{ span: 24, order: 2 }} md={{ span: 24, order: 2 }} lg={{ span: 6, order: 1 }} xl={{ span: 6, order: 1 }} xxl={{ span: 6, order: 1 }}>
                            <Preview propiedad={this.state.propiedad} />
                        </Col>
                        <Col sm={{ span: 24, order: 1 }} md={{ span: 24, order: 1 }} lg={{ span: 17, order: 2 }} xl={{ span: 17, order: 2 }} xxl={{ span: 17, order: 2 }}>
                            <div className="steps-content p-2 bg-white">
                                {steps[current].content}
                            </div>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        );
    }
};

