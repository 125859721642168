import React, { Component } from 'react'
import { Form, Col, Row, Input, Spin, Upload, Button, Rate, Checkbox, message } from 'antd'
import { InboxOutlined } from '@ant-design/icons';
import InputPhone from '../Widgets/Inputs/InputPhone/InputPhone';
import Busqueda from '../Widgets/Filtros/Busqueda'

import Horarios from '../Widgets/Horarios/Horarios'

import { User } from '../../Context/User';
import qs from 'qs'
const axios = require('axios')
// responsividad del formulario
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 12 },
        lg: { span: 8, push: 1 },
        xl: { span: 8, push: 1 },
        xxl: { span: 8, push: 1 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 },
        lg: { span: 12 },
        xl: { span: 12 },
        xxl: { span: 12 }
    },
};


/**
 *
 *
 * @export
 * @class AjustesAgencia
 * @extends {Component}
 */
export default class AjustesAgencia extends Component {



    constructor(props) {
        super(props)

        let address = qs.parse('')
        this.state = {
            loading: false,
            form: {},
            idiomas: [],
            caracteristicas: [],
            agencia: null,
            address,
        }
    }

    preventCycle = false
    static contextType = User
    form = React.createRef();


    componentDidMount() {
        axios.defaults.headers.common["Authorization"] = sessionStorage.getItem('token')
        this.getAgencia();
        this.getIdiomas();
        this.getCaracteristicasAgencia();
    }

    /**
     *
     *
     * @memberof AjustesAgencia
     * @description Trae la lista de idiomas del ws
     */
    getIdiomas() {
        axios.get('/idiomas')
            .then(res => this.setState({ idiomas: res.data.data }))
            .catch(error => console.log('no se pudieron cargar los idiomas'))
    }

    /**
     *
     *
     * @memberof AjustesAgencia
     * @description Trae las caracteristicas de agencias en general
     */
    getCaracteristicasAgencia() {
        axios.get('/agencias/caracteristicas')
            .then(res => this.setState({ caracteristicas: res.data.data }))
            .catch(error => console.log('no se pudieron cargar las caracteristicas'))

    }


    /**
     *
     *
     * @memberof AjustesAgencia
     * @description Trae los datos de la agencia
     */
    getAgencia() {
        this.setState({ loading: true })
        let user = this.context;
        axios.get('/agencias/get', {
            params: {
                id: user?.agencia_id
            }
        })
            .then(res => {
                this.setState({ agencia: res.data.data });

                const agencia = res.data.data

                this.form.current.setFieldsValue({
                    nombre: agencia.nombre,
                    url: agencia.identificador_url,
                    idiomas: agencia.idiomas,
                    caracteristicas: agencia.caracteristicas,
                    localizacion: agencia.localizacion?.place_name,
                    telefono: { phonecode: agencia.lada, phone: agencia.telefono },
                    rating: agencia.rating,
                    facebook: agencia.facebook,
                    instagram: agencia.instagram,
                    horarios: agencia.horarios

                })

                if (typeof agencia.localizacion === "object" && Array.isArray(agencia.localizacion.center))
                    this.setState({ address: agencia.localizacion })

            })
            .catch(error => {
                console.log('error al traer la agencia', error);
            })
            .finally(f => {
                this.setState({ loading: false })
            })
    }


    /**
     *
     *
     * @memberof AjustesAgencia
     * @description manda los datos del formulario al ws para ser procesados
     */
    onSubmit = (values) => {

        console.log(values)
        try{
        axios.post('/agencias/update', {
            nombre: values.nombre,
            identificador_url: values.url,
            localizacion: this.state.address,
            idiomas: values.idiomas,
            telefono: values.telefono?.phone,
            lada: values.telefono?.phonecode,
            facebook: values.facebook,
            instagram: values.instagram,
            caracteristicas: values.caracteristicas,
            horarios: values.horarios.map(horario =>{
                console.log(horario)
                return { dia: horario.dia,
                inicio: {
                    hora: horario.inicio.hora,
                    minuto: horario.inicio.minuto
                },
                fin: {
                    hora: horario.fin.hora,
                    minuto: horario.fin.minuto
                },
            }}),
        })
            .then(res => {
                message.success({ content: 'Agencia Actualizada!', className: "message-sucess" })
            })
            .catch(error => {
                console.log('error al guardar agencia', error.response);
                message.error({ content: 'Oooops , ocurrido un error al guardar', className: "message-error" })
            })
        }catch(e){
            console.log(e)
        }
    }


    render() {
        return (
            <Spin tip="Cargando" spinning={this.state.loading} >
                <User.Consumer>
                    {value => {
                        if (value !== null && this.preventCycle == false) {
                            this.preventCycle = true
                            this.getAgencia();
                        }
                    }}
                </User.Consumer>
                <Form  {...formItemLayout}
                    layout="horizontal"
                    onFinish={this.onSubmit}
                    requiredMark={false} ref={this.form} className="bg-white mt-1 w-100 p-1 mr-2 form-ajustes">
                    <Form.Item name="nombre" labelAlign={"left"} label="Nombre de la Empresa" >
                        <Input />
                    </Form.Item>
                    <Form.Item name="url" labelAlign={"left"} label="Usuario URL" >
                        <Input addonBefore="https://bauens.com/" placeholder="fraccionamiento" />
                    </Form.Item>
                    <Form.Item name="localizacion" labelAlign={"left"} label="Dirección de la Empresa" >
                        <Busqueda
                            address={this.state.address}
                            placeholder="Ingrese una dirección."
                            enterButton={true}
                            onSelect={(address) => {

                                console.log(address)
                                this.setState({ address })}} />
                    </Form.Item>
                    <Form.Item name="telefono" labelAlign={"left"} label="Teléfono" >
                        <InputPhone />
                    </Form.Item>
                    <Form.Item name="idiomas" labelAlign={"left"} label="Idiomas" >
                        <Checkbox.Group >
                            <Row gutter={[8, 8]} justify="start" align="top">
                                {this.state.idiomas.map(idioma => {
                                    return <Col span={12}>
                                        <Checkbox value={idioma._id}>{idioma.nombre}</Checkbox>
                                    </Col>
                                })}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                    <Form.Item name="caracteristicas" labelAlign={"left"} label="Características" >
                        <Checkbox.Group >
                            <Row gutter={[8, 8]} justify="start" align="top">
                                {this.state.caracteristicas.map(caracteristica => {
                                    return <Col span={12}>
                                        <Checkbox value={caracteristica._id}>{caracteristica.nombre}</Checkbox>
                                    </Col>
                                })}

                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                    <Form.Item name="facebook" labelAlign={"left"} label="Facebook" >
                        <Input />
                    </Form.Item>
                    <Form.Item name="instagram" labelAlign={"left"} label="Instagram" >
                        <Input />
                    </Form.Item>

                    <Form.Item name="horarios" labelAlign={"left"} label="Horarios" >
                        <Horarios />
                        
                    </Form.Item>

                    <Form.Item wrapperCol={{ sm: { span: 12, offset: 12 } }} className="mt-1">
                        <Button type="primary" shape="round" className="btn-yellow" htmlType="submit">
                            Guardar
                        </Button>
                    </Form.Item>

                </Form>
            </Spin>

        )
    }

}