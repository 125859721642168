import React, { Component } from "react";
import { Button, Row, Col, Typography, Form, Input, InputNumber, Radio, Select, DatePicker, message, Divider, Checkbox, AutoComplete } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { Bed, Bath, Room, Area, IconCalendar, IconStairs, IconHouseSize } from '../../../Widgets/Iconos'


import '../../../../styles/steps/caracteristicas.css'
import { CategoriasSeleccionadas } from '../../../Widgets/Filtros/Extras'
import axios from 'axios';
const moment = require('moment');
const { Title, Text } = Typography;
const { Option } = Select;

// responsividad del formulario
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 12 },
        lg: { span: 12 },
        xl: { span: 10 },
        xxl: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 },
        lg: { span: 12 },
        xl: { span: 14 },
        xxl: { span: 14 }
    },
};

const options = [
    { label: 'Cualquiera', value: 'any' },
    { label: '+1', value: '+1' },
    { label: '+2', value: '+2' },
    { label: '+3', value: '+3' },
    { label: '+4', value: '+4' },
    { label: '+5', value: '+5' },
    { label: '+6', value: '+6' },
    { label: '+7', value: '+7' },
];

/**
 *
 *
 * @export
 * @class Caracteristicas
 * @extends {Component}
 * @description Caracteristicas de la propiedad a crear/editar
 */
export default class Caracteristicas extends Component {

    static defaultProps = {
        titleVisible: true
    }


    
    /**
     *Creates an instance of Caracteristicas.
     * @param {*} props
     * @memberof Caracteristicas
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            preview: {},

        }
        axios.defaults.headers.common["Authorization"] = sessionStorage.getItem('token')
    }


    /**
     *
     *
     * @memberof Caracteristicas
     */
    componentDidMount() {
        this.actualizarFormulario()
    }


    /**
     *
     *
     * @memberof Informacion
     */
    componentDidUpdate() {
        if (this.state.propiedad_id !== this.props.propiedad._id) {
            this.state.propiedad_id = this.props.propiedad._id;
            this.actualizarFormulario()
        }
    }



    /**
     *
     *
     * @memberof Caracteristicas
     */
    actualizarFormulario = () => {
        if (this.refForm) {

            console.log('this.props.propiedad',this.props.propiedad);
            const { tamano_lote, tamano_casa, antiguedad, antiguedad_ano, banos, recamaras, habitaciones, pisos, categorias_caracteristicas } = this.props.propiedad
            this.refForm.setFieldsValue({
                tamano_lote, tamano_casa, antiguedad, antiguedad_ano, banos, recamaras, habitaciones, pisos, categorias: categorias_caracteristicas
            })

        }
    }

    /**
     *
     *
     * @memberof Caracteristicas
     * @description Trae las categorias y caracteristicas existentes
     */
    componentDidMount() {
        this.getCaracteristicas();
    }



    /**
     *
     *
     * @memberof Caracteristicas
     * @description Trae las caracteristicas existentes en el sistema 
     */
    getCaracteristicas = async () => {
        this.setState({ loadingCaracteristicas: true })
        await axios.get('/propiedad/categorias')
            .then(res => {
                // console.log('res categorias', res)
                this.setState({ categorias: res.data.data });
            })
            .catch(error => {
                // console.log('error', error);
                message.error({ content: 'Ha ocurrido un error, por favor recargue la pagina', className: "messages-error" });
            })
    }



    /**
     *
     *
     * @memberof Caracteristicas
     * @description Guarda los datos del step y avanza al siguiente
     * @param values {*} formulario  
     */
    onFinish = (values) => {
        
        console.log('values',values);
        axios.post('/propiedad/add/caracteristicas', {
            id: this.props.propiedad._id,
            tamano_lote: values.tamano_lote,
            tamano_casa: values.tamano_casa,
            antiguedad: values.antiguedad,
            antiguedad_ano: values.antiguedad_ano,
            banos: values.banos,
            recamaras: values.recamaras,
            habitaciones: values.habitaciones,
            categorias: values.categorias,
            pisos: values.pisos,

            status: (this.props.editable === true)? undefined : 4
        })
            .then(res => {
                console.log('res caracteristicas step', res);
                if (res.status == 200) {
                    //message.success({ content: 'Características guardadas', className: 'message-sucess' })
                    this.props.setPropiedad(res.data.data);
                    this.props.next();
                }
                else {
                    message.error({ content: 'Ha ocurrido un error, por favor intente de nuevo', className: 'messages-error' });
                }
            })
            .catch(error => {
                console.log('error', error.response);
            })
            .finally(f => {
                this.state.loadingSave = true;
            })

    }

    /**
     *
     *
     * @memberof Caracteristicas
     * @description Renderiza las caracteristicas seleccionadas del lado derecho
     */
     renderPreview = (preview = this.state.preview) => {

        let render = []

        if (preview.recamaras)
            render.push(
                <Col span={24}>
                    <Text className="text-info flex-start"><Bed /> {preview.recamaras} Recamaras</Text>
                </Col>
            )

        if (preview.habitaciones)
            render.push(
                <Col span={24}>
                    <Text className="text-info flex-start"><Room /> {preview.habitaciones} habitaciones</Text>
                </Col>
            )

        if (preview.banos)
            render.push(
                <Col span={24}>
                    <Text className="text-info flex-start"><Bath /> {preview.banos} baños</Text>
                </Col>
            )

        if (preview.tamano_lote)
            render.push(
                <Col span={24}>
                    <Text className="text-info flex-start"><Area /> {preview.tamano_lote} m<sup>2</sup></Text>
                </Col>
            )
            
        if (preview.piso)
            render.push(
                <Col span={24}>
                    <Text className="text-info flex-start"><IconStairs width={15} color="#3333CC" /> {preview.piso} piso </Text>
                </Col>
            )

        if (preview.pisos)
            render.push(
                <Col span={24}>
                    <Text className="text-info flex-start"><IconStairs width={15} color="#3333CC" /> {preview.pisos} pisos</Text>
                </Col>
            )

        if (preview.tamano_casa)
            render.push(
                <Col span={24}>
                    <Text className="text-info flex-start"><IconHouseSize width={15} color="#3333CC" /> {preview.tamano_casa}  m<sup>2</sup> </Text>
                </Col>
            )

        for (const categoria in preview.categorias) if (preview.categorias.hasOwnProperty(categoria) && Object.keys(preview.categorias[categoria].caracteristicas).length > 0) {
            render.push(<Divider plain orientation="left">{preview.categorias[categoria].nombre}</Divider>)
            for (const caracteristica in preview.categorias[categoria].caracteristicas) if (typeof preview.categorias[categoria].caracteristicas[caracteristica] === "object") {
                const { value, nombre, icon } = preview.categorias[categoria].caracteristicas[caracteristica]
                render.push(
                    <Col span={24}>
                        <Text className="text-info flex-start">
                            <img src={axios.defaults.baseURL + '/caracteristicas/' + icon} alt={nombre} height={20} />
                            &nbsp;
                            {nombre}
                            &nbsp;
                            {value ? <strong>({value})</strong> : null}
                        </Text>
                    </Col>
                )


            }
        }
        
        return (render.length > 0) ?
            <Row className="caracteristicas-container">
                <Text>Resumen de Caracteristicas</Text>
                <Row gutter={[8, 8]} className="mb-1 border p-1 w-100" justify="start" align="middle"> {render} </Row>
            </Row> : null
    }



    render() {

        const options = [
            { value: 0 },
            { value: 1 },
            { value: 2 },
            { value: 3 },
            { value: 4 },
            { value: 5 },
            { value: 6 },
            { value: 7 },
            { value: 8 },
        ]

        return (
            <>
                {
                    (this.props.titleVisible == true) ? <Row justify="start" align="middle">
                        <Col span={24} className="flex-start"> <Button type="text" shape="round" icon={<LeftOutlined />} className="btn-onback" onClick={() => this.props.prev()}></Button> <Title level={4}>Características de la Propiedad</Title></Col>
                    </Row> : null
                }
                <Row>
                    <Col sm={{ span: 24, order: 2 }} md={{ span: 16, order: 1 }} lg={{ span: 14 }} xl={{ span: 14 }} xxl={{ span: 16 }}>
                        <Form  {...formItemLayout}
                            layout="horizontal"
                            onFinish={this.onFinish}
                            onValuesChange={(changedValues, allValues) => {
                                this.setState(state => {
                                    state.preview = {
                                        ...state.preview,
                                        ...allValues
                                    }
                                    return state;
                                })
                                this.props.setPropiedad(allValues)
                            }}
                            requiredMark={false} 
                            ref={ref => this.refForm = ref}
                            scrollToFirstError={true}
                        >

                            <Divider className="divider-element" orientation="left">Estatus </Divider>
                            <Form.Item name="antiguedad" labelAlign={"left"} name="radio-group" label="Antiguedad" rules={[{ required: true, message: "Antiguedad casa es requerido" }]}>
                                <Radio.Group  value={this.state.preview.antiguedad}>
                                    <Radio.Button value="0">A estrenar</Radio.Button>
                                    <Radio.Button value="1">Con Antiguedad</Radio.Button>
                                    <Radio.Button value="2">En Construcción</Radio.Button>
                                </Radio.Group>
                            </Form.Item>

                            {(this.state.preview.antiguedad === 1 || this.state.preview.antiguedad == '1') ?
                                <Form.Item name="antiguedad_ano" labelAlign={"left"} label="Año de Construcción" rules={[{ required: true, message: "El año de antiguedad es requerida" }]}>
                                    <DatePicker picker="year" style={{ width: '100%' }} />
                                </Form.Item> : null}


                            <Divider className="divider-element" orientation="left">Tamaños </Divider>
                            <Form.Item name="tamano_lote" labelAlign={"left"} label="Tamaño del lote" rules={[{ required: true, message: "Tamaño de lote es requerido" }]}>
                                <InputNumber
                                    className="w-100"
                                    placeholder="150 m2"
                                    formatter={value => (!isNaN(parseFloat(value))) ? value + ' m²' : ''}
                                    parser={value => value.replace(/m|²| /g, '')}
                                />
                            </Form.Item>
                            <Form.Item name="tamano_casa" labelAlign={"left"} label="Tamaño de la casa" rules={[{ required: true, message: "Tamaño de casa es requerido" }]}>
                                <InputNumber
                                    className="w-100"
                                    placeholder="150 m2"
                                    formatter={value => (!isNaN(parseFloat(value))) ? value + ' m²' : ''}
                                    parser={value => value.replace(/m|²| /g, '')}
                                />
                            </Form.Item>
                            <Divider className="divider-element" orientation="left">Caracteristicas </Divider>

                            <Form.Item name="recamaras" labelAlign={"left"} label="Cantidad de Recámaras" rules={[{ required: true, message: "La cantidad de recámaras es requerida" }]}>
                                <AutoComplete
                                    options={options}
                                    placeholder="3 recamaras"
                                />
                            </Form.Item>
                            <Form.Item name="habitaciones" labelAlign={"left"} label="Cantidad de Habitaciones" rules={[{ required: true, message: "La cantidad de habitaciones es requerida" }]}>

                                <AutoComplete
                                    options={options}
                                    placeholder="2 habitaciones"
                                />

                            </Form.Item>
                            <Form.Item name="banos" labelAlign={"left"} label="Cantidad de Baños" rules={[{ required: true, message: "La cantidad de baños es requerido" }]}>
                                <AutoComplete
                                    options={options}
                                    placeholder="3 baños"
                                />
                            </Form.Item>
                            <Form.Item name="pisos" labelAlign={"left"} label="Número de Pisos" rules={[{ required: true, message: "La cantidad de pisos es requerido" }]}>
                                <AutoComplete
                                    options={options}
                                    placeholder="2 pisos"
                                />
                            </Form.Item>

                            <Form.Item name="categorias" labelAlign={"left"} label="Características de la Propiedad" >
                                <CategoriasSeleccionadas />
                            </Form.Item>

                            {/* Caracteristicas */}

                            <Form.Item wrapperCol={{ sm: { span: 12, offset: 12 } }} >
                                <Button type="primary" shape="round" className="btn-yellow" htmlType="submit">
                                    Continuar
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                    {/* preview */}
                    <Col sm={{ span: 24, order: 1 }} md={{ span: 6, order: 2 }} lg={{ span: 9, order: 2, push: 1 }} xl={{ span: 8, order: 2 }} xxl={{ span: 6, order: 2, push: 2 }}>
                        {this.renderPreview()}
                    </Col>
                </Row>

            </>
        )
    }
}