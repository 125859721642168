import React from 'react'


/**
 *
 * @description Es el usuario loggeado. Cuando está vacio significa que no hay sesión iniciada. 
 * */

let User = React.createContext({});
let SetUser = React.createContext(() => {});

export {User, SetUser};
export default User