import React, { Component, useState } from 'react'
import { Row, Col, Dropdown, Radio, Checkbox, Slider, Typography, Menu, Button, AutoComplete,  } from 'antd'

//css
import './filtros.css'

const { Text } = Typography;




export default function Precio({
	value = {},
	onChange,
	nombre,
	textoExacta,
	textoRango,
	slider = true,
	range = true, 
	exactQuantity = true, 
	medium = true

}) {
	
	
	/** @type {*} SI está visible o no el dropdown */
	let [visible, setVisible] = useState(null)

	/** @type {*} Valor minimo a buscar */
	let [min, setMin] = useState(0)

	/** @type {*} Valor máximo  a buscar */
	let [max, setMax] = useState(null)

	/** @type {*} Valor máximo  a buscar */
	let [exact, setExact] = useState(false)


	let [limiteSlider, setLimiteSlider] = useState(12)

	
	const triggerChange = (changedValue) => {
		onChange({
			max,
			min,
			exact,
			...value,
			...changedValue
		})
	};



	
	/*
	* methodOf Baños
	* description metodo que manda el fitrado de baños
	*
	*/
	const onChangeGroupCheckbox = ({target}) => {
		setMin(target.value)
		setMax(null)
		triggerChange({ min: target.value, max: null })
	}
	

	/*
	* methodOf Baños
	* description metodo que actualiza el valor minimo, cuando se ingresa en el input
	*
	*/
	const onChangeMin = min => {

		if (max > min || max == null) {
			setMin(min)
			triggerChange({ min, max, exact })
		}
	}

	/*
	* methodOf Baños
	* description metodo que actualiza el valor maximo, cuando se ingresa en el input
	*
	*/
	const onChangeMax = max => {
		if (min < max|| max == null) {
			setMax(max)
			triggerChange({ min, max, exact })
		}
	}

	/**
	 *
	 * @memberof Precio
	 * 
	 * @method onChangeSlider
	 * @description Al cambiar el valor del slider, 
	 */
	 const onChangeSlider = value => {
		if (value[0] < value[1]) {
			setMin(value[0])
			setMax(value[1])
		}
	}

	const onChangeCheckbox = (e) => {
		setExact(e.target.checked)
		triggerChange({ max, min, exact: e.target.checked })
	}

	/**
	 *
	 * @memberof Precio
	 * 
	 * @method onAfterChange
	 * @description se manda a lamar cuando el usuario suelta el click del slider, con esto evitamos ejecutar el metodo del filtrar
	 * varias veces antes del que el usuario termine de usar el slider
	 */
	const onAfterChange = value => {
    	triggerChange({ max, min, exact})
  	};


	const renderCantidad = () => {


		if (exact == true){
			return `${min} ${nombre} `
		}else{


			if ((min == null || min == 0) && max == null)
				return `${nombre}`


			if (!isNaN(parseInt(min)) && !isNaN(parseInt(max)) )
				return `De ${min} a ${max}  ${nombre} `

			if (!isNaN(parseInt(min)))
				return `Más de ${min} ${nombre} `


			// if ((min == null || min == 0) && !isNaN(max))

			// else 
			// 	return 



		}



		// nombre
		// value={}
		// value={exact?"":((max == null)?"Cualquiera":max)}

	}


	const options = [
		0, 1, 2, 3, 4, 6, 7, 8
	];


	const options_min = [
		{ value: 1 },
		{ value: 2 },
		{ value: 3 },
		{ value: 4 },

	]

	const options_max = [
		{ value: 5 },
		{ value: 6 },
		{ value: 7 },
		{ value: 8 },
	]

	return (
		<Dropdown
			overlay={<Menu className="dropdown-menu">
				<div className="flex-column">
					<Text className="dropdown-title">{nombre}</Text>
					<small className="text-small">Indique la cantidad de {nombre}</small>
					<Radio.Group
						className="dropdown-radio"
						options={options.map(value => ({ label: ((exact == true)?"":"+") + value.toString(), value }))}
						value={min}
						optionType="button"
						onChange={onChangeGroupCheckbox}
					/>
					{exactQuantity? <Checkbox className="dropdown-check" onChange={onChangeCheckbox}>Cantidad Exacta</Checkbox>: null}
					<div className="hr" />
					{
						range?[
						<small className="text-small">{(exact)?textoExacta:textoRango}</small>,
						<Row gutter={20}>
							<Col span={12}>
								<AutoComplete
									style={{ width: '100%' }}
									options={options_min}
									value={((min == null || min == 0)?"Cualquiera":min)}
									onChange={onChangeMin}
									onFocus={(event) => event.target.select()}
									addonBefore="5"
									/>
							</Col>
							<Col span={12}>
								<AutoComplete
									style={{ width: '100%' }}
									options={options_max}
									value={exact?"":((max == null)?"Cualquiera":max)}
									onChange={onChangeMax}
									disabled={exact}
									onFocus={(event) => event.target.select()}
								/>
							</Col>
						</Row>]:null
					}
					
					{slider?<Slider
						min={1}
						max={limiteSlider}
						onChange={onChangeSlider}
						range={true}
						value={[min, ((max == null)?12:max)]}
						onAfterChange={onAfterChange}
					/>:null}

				</div>

			</Menu>}
			onVisibleChange={setVisible}
			visible={visible}
			trigger={['click']}
		>
			<Button style={{ width: '100%', padding: '0', textAlign: 'center' }}> {renderCantidad()} </Button>
		</Dropdown>
	)
}



