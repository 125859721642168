import React, { Component } from "react";
import { Button, Row, Col, Typography, Form, message, Input, Checkbox, Select, Divider } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import axios from "axios";

const { Title, Text } = Typography;
const { Option } = Select;


//Responsividad del formulario
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 12 },
        lg: { span: 8, offset: 1 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 8 },
        lg: { span: 8 }
    },
};

/**
 *
 *
 * @export
 * @class Informacion
 * @extends {Component}
 * @description Seccion Informacion de la creacion de la propiedad
 */
export default class Informacion extends Component {



    static defaultProps = {
        titleVisible: true
    }




    /**
     *Creates an instance of Informacion.
     * @param {*} props
     * @memberof Informacion
     */
    constructor(props) {
        super(props)
        this.state = {

            loading: false,
            tipo_selected: 2,
            divisas: [],
            divisa: null,
            loadingSave: false,


            checked_pago_credito: false,
            checked_costos: true,
            checked_deposito: false,
            checked_deposito_renta: false,
        }

        axios.defaults.headers.common["Authorization"] = sessionStorage.getItem('token')
    }

    componentDidMount(){
        this.getDivisas();
    }


    /**
     *
     *
     * @memberof Informacion
     * @description actualiza el formulario cada vez que se cargue una propiedad diferente
     */
    componentDidUpdate() {
        if (this.state.propiedad_id !== this.props.propiedad._id) {
            this.state.propiedad_id = this.props.propiedad._id;
            this.actualizarFormulario()
        }
    }


    /**
     *
     *
     * @memberof Informacion
     * @descripcion Llena el formulario con la informacion de la propiedad
     */
    actualizarFormulario = () => {
        if (this.formRef) {
            const { nombre, descripcion, tipo_pago, costo_propiedad, pago_credito, pago_mensual_credito, pago_mensual, deposito_inicial, deposito_renta, costo_mantenimiento } = this.props.propiedad
            this.setState({ tipo_selected: tipo_pago })
                                // {/* case venta */}
                                // {( == 2) ?
            this.formRef.setFieldsValue({
                nombre,
                descripcion,
                tipo_operacion: tipo_pago,
                costo_propiedad,
                pago_credito,
                pago_mensual_credito,
                pago_mensual,
                deposito_inicial,
                deposito_renta,
                costo_mantenimiento
            })
        }
    }

    /**
    *
    *
    * @memberof Informacion
    * @description Actualiza la informacion del formulario
    */
    updateValuesPreview = (values = this.formRef.getFieldsValue()) => {
        this.setState({
            
            
            checked_pago_credito: (values.pago_credito > 0),
            checked_deposito: values.pago_deposito,
            checked_deposito_renta: (values.deposito_renta > 0),
            checked_costos: values.mostrar_costos,

        })

        this.props.setPropiedad({
            nombre: values.nombre,
            descripcion: values.descripcion,
            costo_mantenimiento: values.costo_mantenimiento,
            mostrar_costos: values.mostrar_costos,
            tipo_pago: values.tipo_operacion,
            tipo_operacion: values.tipo_operacion,

            // ---- RENTA ----
            deposito_inicial: values.deposito_inicial,
            deposito_renta: values.deposito_renta,
            pago_deposito: values.pago_deposito,
            pago_mensual: values.pago_mensual,

            //Venta
            costo_propiedad: values.costo_propiedad,
            pago_credito: values.pago_credito,
            pago_mensual_credito: values.pago_mensual_credito,

        })
    }


    /**
     *
     *
     * @memberof Informacion
     * @description Obtiene las divisas registradas del sistema
     */
    getDivisas() { axios.get('/divisas').then(res => this.setState({ divisas: res.data.data, divisa: res.data.data[0].code })) }


    /**
     *
     *
     * @memberof Informacion
     * @description Cambia los inputs de renta/venta segun la opcion seleccionada
     */
    onChangeTipo = (value) => this.setState({ tipo_selected: value })

    /**
    *
    *
    * @memberof Informacion
    * @description cambia el valor del checked de deposito
    */
    onChangeCheckedDeposito = e => this.setState({ checked_deposito: e.target.checked, })

    /**
     *
     *
     * @memberof Informacion
     * @description Cambia la divisa escogida
     */
    onChangeDivisa = (value) => this.setState({ divisa: value })

    /**
     *
     *
     * @memberof Informacion
     * @description Guarda los datos del step y avanza al siguiente
     * @param values {*} formulario 
     */
    onFinish = (values) => {

        let propiedad = this.props.propiedad;
        this.state.loadingSave = false;
        axios.post('/propiedad/add/informacion', {
            id: propiedad._id,
            nombre: values.nombre,
            descripcion: values.descripcion,
            tipo_pago: values.tipo_operacion,

            //venta
            costo_propiedad: values.costo_propiedad,
            pago_credito: values.pago_credito,
            pago_mensual_credito: values.pago_mensual_credito,


            //renta
            pago_mensual: values.pago_mensual,
            deposito_inicial: values.deposito_inicial,
            deposito_renta: values.deposito_renta,


            mostrar_costos: this.state.checked_costos,
            costo_mantenimiento: values.costo_mantenimiento,
 
            status: (this.props.editable === true)? undefined : 2
        })
            .then(res => {
                this.props.setPropiedad(res.data.data);
                this.props.next();
            })
            .catch(error => {
                console.log('error', error.response);
            })
            .finally(f => {
                this.state.loadingSave = true;
            })

    }



    render() {
        const { tipo_selected, divisas } = this.state;
        const selectAfterMoney = (
            <Select defaultActiveFirstOption={true} defaultValue={divisas[0]?.code} value={this.state.divisa} className="select-after" onChange={this.onChangeDivisa} >
                {divisas.map((divisa, index) => {
                    return <Option value={divisa._id}>{divisa.code}</Option>
                })}
            </Select>
        )

        return (
            <>
                {
                    (this.props.titleVisible == true) ?
                        <Row justify="start" align="middle">
                            <Col span={24} className="flex-start"> <Button type="text" shape="round" icon={<LeftOutlined />} className="btn-onback" onClick={() => this.props.prev()}></Button> <Title level={4}>Información de la Propiedad</Title></Col>
                        </Row>
                        : null
                }

                <Form
                    {...formItemLayout}
                    layout="horizontal"

                    initialValues={{
                        mostrar_costos: this.state.checked_costos
                    }}

                    onFinish={this.onFinish}
                    onValuesChange={(n, values) => this.updateValuesPreview(values)}

                    requiredMark={false}
                    ref={formRef => this.formRef = formRef}

                >

                    <Divider orientation="left" className="divider-title">Información </Divider>
                    {/* 
                    <Form.Item name="nombre" labelAlign={"left"} label="Nombre " rules={[{ required: true, message: "El nombre es requerido." }]}>
                        <Input />
                    </Form.Item> 
                    */}
                    <Form.Item name="descripcion" labelAlign={"left"} label="Descripción ">
                        <Input.TextArea />
                    </Form.Item>
                    <Divider orientation="left" className="divider-title">Costos </Divider>
                    <Form.Item name="tipo_operacion" labelAlign={"left"} label="Tipo de Operación" rules={[{ required: true, message: "El Tipo de operación es requerido." }]}>
                        <Select placeholder="Seleccionar" onChange={this.onChangeTipo} value={tipo_selected}>
                            <Option value={1}>Renta</Option>
                            <Option value={2}>Venta</Option>
                        </Select>
                    </Form.Item>

                    {/* case venta */}
                    {(tipo_selected == 2) ?
                        <>
                            <Form.Item name="costo_propiedad" labelAlign={"left"} label="Costo" rules={[{ required: true, message: "El costo es requerido." }]}
                                //extra={
                                    // <Form.Item name="pago_credito" labelAlign={"left"} valuePropName="checked">
                                    //     <Checkbox valuePropName="checked">Incluir el pago estimado mensual de un crédito</Checkbox>
                                    // </Form.Item>
                              // }
                                style={{ marginBottom: '12px' }}
                            >
                                <Input type="number" prefix={"$"} placeholder="200.00" addonAfter={selectAfterMoney} />
                            </Form.Item>
                            {(this.state.checked_pago_credito == true) ?
                                <Form.Item name="pago_mensual_credito" labelAlign={"left"} label="Pago Mensual Estimado" >
                                    <Input type="number" prefix={"$"} placeholder="200.00" />
                                </Form.Item> : null
                            }
                        </>
                        : null}

                    {/* case renta*/}

                    {(tipo_selected == 1) ?
                        <>
                            <Form.Item name="pago_mensual" labelAlign={"left"} label="Costo Renta Mensual" rules={[{ required: true, message: "La renta es requerida." }]}
                                // checked={this.state.checked_deposito} 
                                extra={
                                    <Form.Item name="pago_deposito" labelAlign="left" valuePropName="checked">
                                        <Checkbox
                                        >¿Incluye depósito inicial?</Checkbox>
                                    </Form.Item>
                                }
                                style={{ marginBottom: '12px' }}
                            >
                                <Input prefix={"$"} placeholder="200.00" addonAfter={selectAfterMoney} />
                            </Form.Item >


                            {(this.state.checked_deposito == true) ?
                                <>
                                    <Form.Item name="deposito_inicial" labelAlign={"left"} label="Costo del depósito inicial"
                                        extra={
                                            <Form.Item name="deposito_renta" labelAlign="left" valuePropName="checked">
                                                <Checkbox
                                                >¿Es el mismo que el depósito de la renta mensual?</Checkbox>
                                            </Form.Item>
                                        } >
                                        <Input prefix={"$"} placeholder="200.00" />
                                    </Form.Item>

                                </>
                                : null}
                        </> : null}


                    <Form.Item name="costo_mantenimiento" labelAlign={"left"} label="Costo Mantenimiento Mensual"
                        // extra={
                        //     <Form.Item  style={{ marginTop: '2em' }} name="mostrar_costos" labelAlign="left" valuePropName="checked"  >
                        //         <Checkbox > ¿Mostrar Costos?</Checkbox>
                        //     </Form.Item>
                        // }
                        >
                        <Input type="number" prefix={"$"} placeholder="200.00" />
                    </Form.Item>


                    <Form.Item wrapperCol={{ sm: { span: 12, offset: 12 } }}>
                        <Button type="primary" shape="round" className="btn-yellow" htmlType="submit" disabled={this.state.loadingSave}>
                            Continuar
                        </Button>
                    </Form.Item>
                </Form>

            </>
        )
    }
}