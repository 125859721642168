import React, { Component } from 'react'
import { Layout, Row, Col, Select,  List, Button, Typography, Input, Pagination, Spin } from 'antd';
import { IconUbicacion, } from './../../Widgets/Iconos'
import { AgenteCard,AgenteLargeCard} from "../../Widgets/cards/Cards"
import MenuListas from "./MenuListas"
import axios from 'axios';

import './../../../styles/listas.css'


const { Content } = Layout;
const { Text, Title } = Typography;
const { Search } = Input;
const { Option } = Select;


/**
 *
 *
 * @export
 * @class ListaAgentes
 * @extends {Component}
 * @description Lista de Agentes
 */
export default class ListaAgentes extends Component {


    /**
     *Creates an instance of ListaAgentes.
     * @param {*} props
     * @memberof ListaAgentes
     */
    constructor(props) {
        super(props);
        this.state = {

            loading: true,

            /* Paginado */
            page: 1,
            itemCount: 0,
            total: 0,

            search: "",
            agentes: [],
            recomendados: [],
        }
    }


    /**
    * @static
    * @memberof ListaAgentes
    * @method   componentDidMount
    * @description  Se llaman consultas iniciales de agentes
    * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getAgentes();
    }



    /**
    * @static
    * @memberof ListaAgentes
    * @method   getAgentes
    * @description  Obtiene la paginación de agentes
    */
    getAgentes = async (page = this.state.page, search = this.state.search) => {

        this.setState({ loading: true });
        axios.get("/usuarios", {
            params: {
                page,
                search,
                agencia: true,
                localizacion: this.state.localizacion
            },
        })
            .then(({ data }) => {
                console.log(data)
                let itemsList = data.data.itemsList
                this.setState({
                    page: data.data.currentPage,
                    itemCount: data.data.itemCount,
                    agentes: itemsList,
                    recomendados: data.recomendados
                })
            })
            .catch((e) => {
                console.log('er', e)
                this.setState({
                    page: 1,
                    itemCount: 0,
                })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    };



    /**
     *
     *
     * @memberof ListaAgentes
     * @method searchAgente
     * @description Asigna al state.search el valor a buscar y se manda a llamar al metodo getAgentes
     */
    searchAgente = (value) => {
        this.setState({ search: value }, () => {
            this.getAgentes();
        })
    }

     /**
     *
     *
     * @memberof ListaAgentes
     * @method searchLocalizacion
     * @description Asigna al state.search el valor a buscar y se manda a llamar al metodo getAgentes
     */
    searchLocalizacion = (value) => {
        this.setState({ localizacion: value }, () => {
            this.getAgentes();
        })
    }


    /**
    * @static
    * @memberof ListaAgentes
    * @method   renderRowFiltros
    * @description  Se crea row de filtros
    */
    renderRowFiltros = (tipo) => {
        return <Row className="row-filtros-x">
            <Col span={5} className="pdr-10px">
                <Search className="input-search search-ubicacion" placeholder="Localización" onSearch={value => this.searchLocalizacion(value)} />
                <IconUbicacion className="IconUbicacion" />
            </Col>
            <Col span={5} className="pdr-10px">
                <Search className="input-search " placeholder="Busqueda" onSearch={value => this.searchAgente(value)} />
            </Col>
            {/* <Col span={5} className="pdr-10px">
                <Select placeholder="Servicios" className="wd-100prs  ">
                    <Option value="a">A</Option>
                    <Option value="b">B</Option>
                    <Option value="c">C</Option>
                </Select>
            </Col>
            <Col span={5} className="pdr-10px">
                <Select placeholder="Tipos de casa" className="wd-100prs ">
                    <Option value="a">A</Option>
                    <Option value="b">B</Option>
                    <Option value="c">C</Option>
                </Select>
            </Col> */}
            {/* <Col span={4} className="pdr-10px">
                <Button type="ghost" className="btn-gh-blue">Más filtros</Button>
            </Col> */}
        </Row>

    }



    render() {

        let { loading, agentes } = this.state;

        return (
            <Layout className="landing-layout">
                <Content className="list-content" >
                    <Row className="row-bg-image" style={{ backgroundImage: "url('/images/img-lista.png')" }} >
                        <Title level={2} className="text-white mg-auto" >Las mejores propiedades </Title>
                    </Row>
                    <Row className="bg-white">
                        <Row className="cnt-center">
                            <Col span={24} >
                                <Title level={4} className="ml-50px">Agentes Recomendados</Title>
                            </Col>

                            <Col span={24} >
                                <Spin spinning={this.state.loading}>
                                    <Row className="mxw-1240px mg-auto" style={{width:"100%"}}  >
                                        {this.state.recomendados.map((agente, index) => (
                                            <Col xs={20} md={10} xl={8} span={5} className="center col-landing-card">
                                                <AgenteCard
                                                    _id={agente._id}
                                                    createdAt={agente.createdAt}
                                                    foto={agente.foto}
                                                    nombre={agente.nombre}
                                                    telefono={agente.lada + " " + agente.telefono}
                                                    calificacion={agente.rating}
                                                    insignia={false}
                                                    reviews={0}
                                                    descripcion={agente.descripcion}
                                                    tipo={agente.agencia_id.tipo}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                </Spin>
                            </Col>

                            <Col xs={22} className="left  col-agentes-tabs ml-50px">
                                <MenuListas />
                            </Col>

                            <Col xs={22} span={5} className="left col-agentes-tabs ml-50px tabs-agentes" >
                                {this.renderRowFiltros(7)}
                            </Col>

                            <Col xs={22} className="left col-agentes-list  ml-50px">
                                <List
                                    itemLayout="horizontal"
                                    dataSource={agentes}
                                    className="list-agentes"
                                    loading={loading}
                                    locale={{ emptyText: 'Sin Agentes' }}
                                    header={<List.Item className="component-list-item wd-100prs">
                                        <Row className="wd-100prs pdl-10px">
                                            <Col xs={22} md={13} xl={11}>
                                                <Text>Agente</Text>
                                            </Col>
                                            <Col xs={22} md={10} xl={5}>
                                                <Text>Localización</Text>
                                            </Col>
                                            <Col xs={22} xl={6}>
                                                <Text>Testimonios</Text>
                                            </Col>
                                        </Row>
                                    </List.Item>

                                    }
                                    renderItem={(agente, index) => (
                                    
                                        <List.Item>
                                            {console.log(agente)}
                                            <Row className="wd-100prs pd-0px">
                                                <Col xs={24} className="left col-landing-card">
                                                    <AgenteLargeCard
                                                        _id={agente._id}
                                                        createdAt={agente.createdAt}
                                                        url={agente.url}    
                                                        nombre={agente.nombres + " " + agente.apellidos}
                                                        telefono={agente.lada + " " + agente.telefono}
                                                        calificacion={agente.rating}
                                                        localizacion={agente.agencia_id.localizacion}
                                                        reviews={agente.rating}
                                                        insignia={false}
                                                        foto={agente.foto_perfil}
                                                        descripcion={"Soy un agente experimentado, llamame!, encontremos lo mejor para ti. "}
                                                        tipo={agente.agencia_id.tipo}
                                                    />
                                                </Col>
                                            </Row>
                                        </List.Item>
                                    )}
                                />
                            </Col>

                            <Col xs={23} className="left col-paginado">

                                <Pagination
                                    className="paginado right"
                                    defaultCurrent={1}
                                    showSizeChanger={false}
                                    defaultPageSize={this.state.limit}
                                    current={this.state.page}
                                    total={this.state.itemCount}

                                    onChange={(page) => {
                                        this.getAgentes(page)
                                    }}

                                />
                            </Col>

                        </Row>
                    </Row>
                </Content>
            </Layout>
        )
    }
}