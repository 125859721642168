import React, { Component } from 'react'
import { Layout, Row, Col, Typography, PageHeader, Space, Carousel, Card, Steps, Image } from 'antd'
import { LeftOutlined } from '@ant-design/icons';
import { IconHousePercent, IconCredit, Gps, Bed, Bath, Room, Area, IconHouse, } from '../../../Widgets/Iconos'

import axios from 'axios'


import '../../../../styles/Inmueble.css'
import '../../../../styles/steps/preview.css'
import '../../../../styles/Modals.css'
const { Step } = Steps;
const { Content } = Layout;

const { Title, Text, Paragraph } = Typography;


/**
 *
 *
 * @export
 * @class Steps_Container
 * @extends {Component}
 * @description Contenedor de los steps con la informacion de la propiedad y el preview
 */
export default class Preview extends Component {

    static getDerivedStateFromProps(props, state) {
        return {
            propiedad: props.propiedad
        }
    }


    constructor(props) {
        super(props)
        this.state = {
            current: 3,
            loading: false,
        }
        axios.defaults.headers.common["Authorization"] = sessionStorage.getItem('token')
    }
    
    /**
     *
     *
     * @param {*} propiedad
     * @memberof Preview
     * 
     * @description Renderiza la forma de pago que tendrá la propiedad en el preview
     */
    renderCostos = (propiedad) => {
        if (propiedad.tipo_pago == 2) {
            return isNaN(parseFloat(propiedad?.costo_propiedad)) ? (0).toMoney() : parseFloat(propiedad?.costo_propiedad).toMoney()
        } else {
            return [
                isNaN(parseFloat(propiedad?.pago_mensual)) ? (0).toMoney() : parseFloat(propiedad?.pago_mensual).toMoney(),
                <span style={{ fontWeight: "lighter", fontSize: 12 }}>  /mensual</span>
            ]
        }
    }



    /**
     *
     *
     * @param {*} propiedad
     * @memberof Steps_Container
     * 
     * @description Renderiza la forma de pago que tendrá la propiedad en el preview
     */
    renderPagoMensual = (propiedad) => {
        if (propiedad.tipo_pago == 2) {
            return [
                // isNaN(parseFloat(propiedad?.pago_mensual)) ? (0).toMoney() : parseFloat(propiedad?.pago_mensual).toMoney(), 
                // <span style={{ fontWeight: "lighter", fontSize: 12 }}>  /mensual</span>
                <Col xs={24} sm={24} style={{ marginTop: '1em',display: (propiedad.mostrar_costos == true || propiedad.mostrar_costos == undefined) ? undefined : "none" }}>
                    <Text className="text-info left"><IconHousePercent style={{ width: 20, height: 20 }} /> Pago Mensual estimado: </Text>
                </Col>,
                <Col xs={24} sm={12} style={{display: (propiedad.mostrar_costos == true || propiedad.mostrar_costos == undefined) ? undefined : "none"}}>
                    <Text className="text-info  left"> <b> {
                        isNaN(parseFloat(propiedad?.pago_mensual_credito)) ? (0).toMoney() : parseFloat(propiedad?.pago_mensual_credito).toMoney()    
                    } </b>/ <small>mensuales</small></Text>
                </Col>,
            ]
        } else {
            return [
                <Col xs={24} sm={24} style={{ marginTop: '1em',display: (propiedad.mostrar_costos == true || propiedad.mostrar_costos == undefined) ? undefined : "none" }}>
                    <Text className="text-info left"><IconHousePercent style={{ width: 20, height: 20 }} /> Deposito Inicial: </Text>
                </Col>,
                <Col xs={24} sm={12} style={{display: (propiedad.mostrar_costos == true || propiedad.mostrar_costos == undefined) ? undefined : "none"}}>
                    <Text className="text-info  left"> <b> {
                        isNaN(parseFloat(propiedad?.deposito_inicial)) ? (0).toMoney() : parseFloat(propiedad?.deposito_inicial).toMoney()    
                    } </b></Text>
                </Col>,
            ]
        }
    }


    renderCantidades = (propiedad) => {
        
        return <Row gutter={[8, 8]}>
            {propiedad.recamaras ? <Col xs={12} sm={6}>
                <Text className="text-info flex-start"><Bed /> {propiedad.recamaras} Rec</Text>
            </Col> : null}
            
            {propiedad.habitaciones ? <Col xs={12} sm={6}>
                <Text className="text-info flex-start"><Room /> {propiedad.habitaciones} hab</Text>
            </Col> : null}

            {propiedad.banos ? <Col xs={12} sm={6}>
                <Text className="text-info flex-start"><Bath /> {propiedad.banos} Bñ</Text>
            </Col> : null}


            {propiedad.tamano_lote ? <Col xs={12} sm={6}>
                <Text className="text-info flex-start"><Area /> {propiedad.tamano_lote} m<sup>2</sup></Text>
            </Col> : null}
        </Row>
    }





        // tamano_lote
    // tamano_casa
    // antiguedad,
    // antiguedad_ano
    // recamaras
    // habitaciones
    // banos
    // pisos



    render() {

        let { propiedad } = this.props;
        return (
            <Card hoverable bodyStyle={{ padding: 0 }} className="col-inmueble" >
                <Row gutter={[8, 8]} align="middle" justify="space-between">
                    <Col className="inmueble-info pb-1" span={24} style={{ marginTop: '0.5em' }}>
                        <Text style={{ display: (propiedad.mostrar_costos == true || propiedad.mostrar_costos == undefined) ? undefined : "none" }} className="text-price float-left" >{this.renderCostos(propiedad)}</Text>
                        <Text className=" align-middle float-right icon-description" style={{ display: 'block' }}>
                            {(typeof propiedad.tipo_propiedad_id == "object") ? [
                                propiedad.tipo_propiedad_id.nombre,
                                <img src={axios.defaults.baseURL + "/tipos/" + propiedad.tipo_propiedad_id.icon} style={{ width: 40, marginLeft: 5 }} />
                            ] : null}
                            {/* Casa <IconHouse width={30} height={30} color={"#3333CC"} /> */}

                        </Text>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Title level={4} >{(propiedad?.nombre == null || propiedad?.nombre == undefined || propiedad?.nombre == "") ? " - " : propiedad?.nombre}</Title>
                </Row>
                {this.renderCantidades(propiedad)}

                {(typeof propiedad.localizacion === "object") ? <Row gutter={[8, 8]}>
                    <Col span={12} >
                        <Text className="text-direction"><Gps /> {propiedad.localizacion.place_name}</Text>
                    </Col>
                </Row> : null}

                <Row gutter={[8, 8]} className="mb-1">
                    {this.renderPagoMensual(propiedad)}
                    <Col span={24}>
                        <Paragraph className="text-description mt-1">{(propiedad?.descripcion == null || propiedad?.descripcion == undefined || propiedad?.descripcion == "") ? " - " : propiedad?.descripcion}</Paragraph>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    {propiedad.imagenes?.map(imagen => {
                        return <Col span={(imagen.tamano.w == 1)?12:24} style={{ height: imagen.tamano.h * 150, background: `url(${axios.defaults.baseURL + '/upload/' + imagen.filename_server}) center center/auto no-repeat`  }}>
                        </Col>
                    })}
                </Row>
            </Card>
        );
    }
};

