import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Layout, Row, Col, Button, Input, Select, Typography, Card, message, Pagination, Spin, Progress, Comment, Avatar, Tooltip, Upload, Image } from 'antd'
import { UnorderedListOutlined, AppstoreFilled, PlusOutlined, } from '@ant-design/icons';

import { FaFilePdf, FaImage, FaFileExcel, FaFilePowerpoint, FaFileAlt, FaTimes } from 'react-icons/fa'


import { BsCheck, BsCheckAll } from "react-icons/bs";

//compoenentes
import { Habitaciones, Baños, Precio, Extras, Venta, Tipos } from './../../Widgets/Filtros/Filtros'
import { InmuebleCardPro, InmuebleCardProBig } from './../../Widgets/cards/Cards'

import { IconSearchLanding, IconAddPlus, IconComment, IconCalendarSM, IconFiles, IconMenuChat, IconMensajeEntregado, IconMensajeVisto } from './../../Widgets/Iconos'

import { User, SetUser } from '../../../Context/User'

import { array } from 'prop-types';

import SocketIOFileClient from 'socketio-file-upload';
import ifIsImage from 'if-is-image';


import { IconAttach, IconImage, IconSend } from '../../Widgets/Iconos'
import Logged from '../../../Hooks/Logged';
import { SocketContext } from '../../../Hooks/Socket';

import axios from 'axios';

import './../../../styles/mensajes.css'



const moment = require("moment");
const { TextArea } = Input;
const { Title, Text } = Typography;

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;


function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
}


let rem_id = "", rem_casa = "";


export default class Mensajes extends Component {


    static contextType = User

    constructor(props) {
        super(props);
        this.state = {

            dataConversaciones: [],
            dataMensajes: [],
            detalleDestinatario: {},
            loading: true,

            view: 'bloqs',
            propiedades: [],
            initial: true,


            data: [],
            page: 1,
            limit: 100,
            search: null,

            usuarios: [],
            conectado: false,

            userLogged: "",
            user_selected: null,
            filesArray: [],
            filesDictionary: {},
            update: false,

            user_conversacion: [],

            user: {
                id: '',
                tipo: 1
            }

        }
    }

    static contextType = SocketContext;

    loading = false

    scrollToBottomOption = true

  /***
     *
     * @methodOf Mensajes
     * @function componentDidMount
     * 
     * @description Se monta componente y se inician procesos socket
     */
    componentDidMount() {

        const userID = sessionStorage.getItem("userId");
        if (userID !== undefined) {
            this.setState({ userLogged: userID });
        }


        document.getElementById("messages-body").addEventListener('scroll', this.handleScroll, true);


        this.context.emit("start", sessionStorage.getItem("token"))
        this.context.removeListener('successful');
        this.context.on('successful', this.IO_start)

        // this.uploader = new SocketIOFileClient(this.context);

        this.uploader = new SocketIOFileClient(this.context);
        this.uploader.addEventListener("start", this.startUpload);

        this.uploader.addEventListener("progress", this.progressUpload);

        this.uploader.addEventListener("complete", this.completeUpload);


        setTimeout(() => {
            this.setState({ loading: false })
        }, 1500);

    }


    /***
     *
     * @methodOf Mensajes
     * @function progressUpload
     * 
     * @description Progreso de subida de archivos
     */
    progressUpload = (event) => {
        let index = this.state.filesDictionary[event.file.name];
        this.state.filesArray[index - 1].progress = (parseInt(event.bytesLoaded) * 100) / parseInt(event.file.size);
        this.state.filesArray[index - 1].status = "progress";
        this.setState({ update: !this.state.update })
    }

     /***
     *
     * @methodOf Mensajes
     * @function completeUpload
     * 
     * @description Se completo subida de archivos
     */
    completeUpload = (event) => {

        let index = this.state.filesDictionary[event.file.name];
        this.state.filesArray[index - 1].progress = 100;
        this.state.filesArray[index - 1].status = "complete";
        this.state.filesArray[index - 1].fileSend = event.file;
        this.setState({ update: !this.state.update })
    }

      /***
     *
     * @methodOf Mensajes
     * @function completeUpload
     * 
     * @description Se inicia subida de archivos
     */
    startUpload = (event) => {

        let name = "";
        let fileSplit = event.file.name.split("_");
        for (let index = 0; index < (fileSplit.length - 1); index++) {
            name += ((index == 0) ? "" : '_') + fileSplit[index];
        }
        let extension = fileSplit[fileSplit.length - 1].split('.')[1];


        let index = this.state.filesArray.push({
            file: event.file,
            progress: 0,
            status: 'start',
            name,
            extension
        });

        this.state.filesDictionary[event.file.name] = index;
        this.setState({ update: !this.state.update })
    }

      /***
     *
     * @methodOf Mensajes
     * @function completeUpload
     * 
     * @description Se actualiza  archivo en upload
     */
    uploadFile = ({ file }) => {
        let fileSplit = file.name.split(".");
        let rename = "";
        for (let index = 0; index < (fileSplit.length - 1); index++)
            rename += fileSplit[index]
        let fileName = rename + "_" + Math.random().toString(36).substring(2, 10) + "." + fileSplit[fileSplit.length - 1];
        this.uploader.submitFiles([renameFile(file, fileName)])
    }


    /***
     *
     * @methodOf Mensajes
     * @function IO_start
     * 
     * @description Inicio del socket, obtebemos los mensajes y si es admin obtebemos los usuarios
     */
    IO_start = () => {
        const { page, search, limit } = this.state;

        //Eliminamos estos metodos para volverlos a declarar
        this.context.removeListener('get_messages');
        this.context.removeListener('mensaje_nuevo');

        this.context.on('get_messages', this.IO_get_messages)
        this.context.on('mensaje_nuevo', this.IO_mensaje_nuevo)


        //Si es administrador, obtenemos los usuarios, caso contrario es un colono por lo que obteemos los mensajes normales
        if (this.state.user?.tipo == 1) {
            this.context.removeListener('get_users');

            this.context.on('get_users', this.IO_get_users)
            this.context.emit('get_users')
        }

        else {
            this.loading = true;
            this.context.emit('get_messages', { page, search, limit })
        }
    }

    /***
     *
     * @methodOf Mensajes
     * @function IO_get_users
     * 
     * @description Obtenemos los usuarios para ponerlos en las tarjetas. Solo funciona si eres Admin 
     */
    IO_get_users = (usuarios) => {
        const page = 1, search = "", limit = 1000;

        //  let m = usuarios[0]?._id;
        //  this.setState({ user_selected: m })
        //  this.context.emit('get_messages', { page, search, limit, user_selected: m })

        this.setState({
            usuarios: usuarios.map(e => {
                e.count = 0;
                return e;
            })
        })
    }


    /**
     *
     * @methodOf Mensajes
     * @function IO_mensaje_nuevo
     * 
     * @description Cuando llegue un mensaje nuevo, se agrega a la lista de mensajes
     */
    IO_mensaje_nuevo = ({ mensaje, user_id }) => {

        let data = this.state.data;

        //Obtenemos el ultimo grupo de mensajes. Esto es importante porque dependiendo del usuario que envió el mensaje
        //Es si el nuevo mensaje se agregará al grupo o se creará un grupo nuevo para ese mensaje.
        let latestGroupOfMessagesIndex = data.length - 1;
        let latestGroupOfMessages = data[latestGroupOfMessagesIndex]

        // SI es usuario normal, y el usuario seleccionado es el mismo de lque envió el mensaje, 
        if (this.state.user.tipo == 2 || this.state.user_selected == user_id) {

            //Intenamos agregar el mensaje al grupo de mensajes correspondiente.
            if (latestGroupOfMessages) {

                //Obtebemos el index del último mensaje0
                let latestMessageIndex = latestGroupOfMessages.length - 1

                ///Y obtenemos el utlimo mensaje
                let latestMessage = latestGroupOfMessages[latestMessageIndex]

                // Si hay un ultimo mensaje y si el tipo del ultimo mensaje se agrega, caso contrario, se crea un nuevo grupo
                if (latestMessage) {
                    if (latestMessage.owner == mensaje.owner)
                        data[latestGroupOfMessagesIndex].push(mensaje)
                    else
                        data.push([mensaje])
                }
            } else //Data mensaje, 
                data = [[mensaje]]
            this.setState({ data: [...data] })

            setTimeout(() => {
                this.scrollToBottom()
            }, 500);
        }


        ///Si es usuario admin y el mensaje que llegó no es del selecccionado, se agrega al contador del usuario correspondiente.
        if (this.state.user.tipo == 1 && this.state.user_selected != user_id) {
            let usuarios = this.state.usuarios;
            let findUser = usuarios.findIndex(e => user_id?.toString() == user_id)
            if (findUser !== -1) {
                usuarios[findUser].count = parseInt(usuarios[findUser].count) + 1
                this.setState({ usuarios })
            }
        }
    }



    /**
     *
     * @methodOf Mensajes
     * @function IO_get_messages
     * 
     * @description Obtenemos los mensajes completos 
     */
    IO_get_messages = ({ mensajes }) => this.setState(state => {
        let messagesList = []
        let content = []
        let tempOwner = null;

        const userID = sessionStorage.getItem("userId");

        for (let index = 0; index < mensajes.list.length; index++) {
            let mensaje = mensajes.list[index];

            let owner = (mensaje.remitente_id === userID);


            if (tempOwner == null) {
                tempOwner = owner
                mensaje.primero = true
            }

            if (tempOwner != owner) {
                tempOwner = owner
                messagesList.push([...content.reverse()])
                content = [mensaje]
            } else {
                content.push(mensaje)
            }


            if ((mensajes.list.length - 1) == index && tempOwner == owner) {
                messagesList.push([...content.reverse()])
            }
        }

        state.data = [...messagesList.reverse()]

        state.page = mensajes.page;
        state.limit = mensajes.limit;

        this.loading = false

        //Si es scrooll top, scrolleamos hacia arriba.
        if (this.scrollToBottomOption === true) {
            setTimeout(this.scrollToBottom, 1000)
        }
        // 


        return state;
    })



    /**
     *
     * @methodOf Mensajes
     * @function onKeyDownPress
     * 
     * @description Se ejecuta al dar enter, enviamos el mensaje y limpiamos el input
     * 
     * */
    onKeyDownPress = (event) => {

        if (event.key === 'Enter' && ((event.target.value && event.target.value != null && event.target.value != "") || this.state.filesArray.length > 0)) {
            if (this.state.user_selected !== "" && this.state.user_selected !== undefined) {

                this.context.emit('new_message', {
                    message: event.target.value,
                    user_id: this.state.user_selected,
                    files: this.state.filesArray.map(event => event.fileSend.name)
                })

                this.inputRef.setState({ value: '' })
                this.setState({ filesArray: [], filesDictionary: {} })


                this.context.emit('get_users')

                setTimeout(() => {
                    this.setUserConversation(this.state.user_selected)
                }, 500);
            }
            else {
                message.warning("Selecciona una conversación para poder enviar un mensaje.")
            }
        }

    }




    /**
     *
     * @methodOf Mensajes
     * @function onKeyDownPress
     * 
     * @description Se ejecuta al dar enter, enviamos el mensaje y limpiamos el input
     * 
     * */
    onPress = (event) => {
        if (this.state.user_selected !== "" && this.state.user_selected !== undefined) {
            this.context.emit('new_message', {
                message: event.target.value,
                user_id: this.state.user_selected,
                files: this.state.filesArray.map(event => event.fileSend.name)

            })

            this.setState({ filesArray: [], filesDictionary: {} })
            this.inputRef.setState({ value: '' })

            this.context.emit('get_users')

            setTimeout(() => {
                this.setUserConversation(this.state.user_selected)
            }, 500);

        }
        else {
            message.warning("Selecciona una conversación para poder enviar un mensaje.")
        }
    }


    /**
     *
     * @methodOf Mensajes
     * @function setUserConversation
     * 
     * @description Ponemos el usuario en el chat para hablar con el 
     * 
     * */
    setUserConversation = async (user_id) => {
        this.setState({ loading: true });

        const { page, search, limit } = this.state;

        let usuarios = this.state.usuarios;
        let usuarioSeleccionado = {};

        for (let index = 0; index < usuarios.length; index++) {
            const user = usuarios[index];

            if (user_id === user._id) {

                usuarioSeleccionado = user;
                break;
            }
        }

        this.setState({ user_selected: user_id, detalleDestinatario: usuarioSeleccionado, loading: false });

        this.context.emit('get_messages', { page, search, limit, user_selected: user_id })

    }


    /**
     *
     * @methodOf Mensajes
     * @function scrollToBottom
     * 
     * @description Scrolleamos el chat al final
     * 
     * */
    scrollToBottom = () => {

        this.scrollToBottomOption = true;
        const messages_body = document.getElementById("messages-body");

        if (messages_body !== null && messages_body !== undefined && messages_body !== null)
            messages_body.scrollTop = messages_body.scrollHeight;
    }


    /**
     *
     * @methodOf Mensajes
     * @function handleScroll
     * 
     * @description Se ejecuta cuando se scrollea el chat, cargamos mas mensajes antiguos.
     * 
     * */
    handleScroll = (event) => {

        //  let { scrollTop } = document.getElementById("messages-body");
        //  let { page, limit, search } = this.state;
        //  if (scrollTop == 0 && this.loading == false) {

        //      this.loading = true;
        //      this.scrollToBottomOption = false;
        //      page = page + 1
        //      this.context.emit('get_messages', { page, search, limit, user_selected: this.state.user_selected })
        //  }
    }


    /**
     *
     * @methodOf Mensajes
     * @function deleteFile
     * 
     * @description Se elimina archivo de upload
     * 
     * */

    deleteFile = (file) => {
        let index = this.state.filesDictionary[file];

        this.state.filesArray.splice(index, 1);
        this.context.emit("delete_file", file)
        this.setState({ update: !this.state.update })
    }



    
   /**
     *
     * @methodOf Mensajes
     * @function renderRowDetalle
     * 
     * @description Render de detalle de usuario de conversación seleccionada
     * 
     * */
    renderRowDetalle = () => {

        var { foto, nombre, telefono, email, empresa } = this.state.detalleDestinatario;
        var descripcion = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor";

        if (foto === undefined || foto === "") { foto = "/images/img-default.png" } else { foto = (axios.defaults.baseURL + '/upload/' + foto) }

        return <Row className=" row-detalle">
            <Col xs={24} className="col-img-agente center">
                <img src={foto} width={70} alt="imgCircle" className="imgCircle" />
            </Col>

            <Col span={24}>
                <Title level={3} className="txt-det-nombre">{nombre}</Title>
            </Col>

            <Col span={24}>
                <Title level={3} className="txt-bold">Teléfono</Title>
                <Text>{telefono}</Text>
            </Col>

            <Col span={24}>
                <Title level={3} className="txt-bold" >Email</Title>
                <Link >
                    <Text className="txt-link">{email}</Text>
                </Link>
            </Col>

            <Col span={24}>
                <Title level={3} className="txt-bold" >Descripción</Title>
                <Text>{descripcion}</Text>
            </Col>
        </Row>
    }

    
    messagesContentReference = React.createRef();


    render() {
        var { foto, nombre, telefono, calificacion, reviews, descripcion } = this.props;
        if (foto === undefined || foto === "") { foto = "/images/img-default.png" } else { foto = (axios.defaults.baseURL + '/upload/' + foto) }

        const { view, userLogged } = this.state;



        let user = {
            _id: "",
            tipo: 1
        };

        return (
            <Content className="content-mensajes">
                <Row className="bg-white row-titulo-vista">
                    <Title level={3} className="tituloVista" >Mensajes </Title>
                </Row>

                <Spin spinning={this.state.loading} >


                    <Row className="wd-100prs row-contenedores">

                        <Col xs={24} md={8} xl={6} xxl={6} className="contenedor-conversaciones">

                            <Row className="wd-100prs">
                                <Col span={24}>
                                    <Text>Buscar Mensajes</Text>
                                </Col>
                                <Col span={24}>
                                    <Input.Search
                                        placeholder="Piscina, Sotano, Estudio"
                                        enterButton={<Button>
                                            <IconSearchLanding />
                                        </Button>}
                                        className="input-search"
                                    />

                                    <Button className="btn-addPlusMorado">
                                        <IconAddPlus />
                                    </Button>
                                </Col>

                                <Col span={24} className="row-conversaciones">
                                    {
                                        this.state.usuarios.map((conversacion, index) => {
                                            var { _id, foto, nombre, nombres, apellidos, empresa, ultimaConexion, ultimoMensaje, archivos, imagenes, mensajes, citas, mensaje } = conversacion;
                                            let _ultimaConexion = ""; //moment(ultimaConexion).format('HH:mm DD-MM-YYYY');

                                            const date1 = new Date(ultimaConexion);
                                            const date2 = new Date();

                                            var diff = (date2 - date1) / 1000;
                                            diff = Math.abs(Math.floor(diff));


                                            var days = Math.floor(diff / (24 * 60 * 60));
                                            var leftSec = diff - days * 24 * 60 * 60;


                                            var hrs = Math.floor(leftSec / (60 * 60));
                                            var leftSec = leftSec - hrs * 60 * 60;


                                            var min = Math.floor(leftSec / (60));
                                            var leftSec = leftSec - min * 60;

                                            if (days === 0) {
                                                if (hrs === 0) {
                                                    if (min === 0) {
                                                        _ultimaConexion = " Ahora."
                                                    }
                                                    else {

                                                        _ultimaConexion = "Hace " + min + " minutos."
                                                    }
                                                }
                                                else {
                                                    _ultimaConexion = "Hace " + hrs + " horas."
                                                }
                                            }
                                            else {
                                                _ultimaConexion = "Hace " + days + " dias."
                                            }

                                            if (apellidos == undefined) { apellidos = "" }


                                            if (foto === undefined || foto === "") { foto = "/images/img-default.png" } else { foto = (axios.defaults.baseURL + '/upload/' + foto) }

                                            if (mensajes > 0) {
                                                return <div
                                                    className={"cnt -card-mensaje " + ((this.state.user_selected == _id) ? " selected" : null)}
                                                    onClick={e => this.setUserConversation(_id)}>
                                                    <Card
                                                        hoverable
                                                        className="card-row-conversacion"
                                                        key={"cnv-" + index}
                                                        style={{ width: '100%' }}
                                                    >
                                                        <Row className="wd-100prs">
                                                            <Col xs={5} md={7} xl={6} xxl={5} className="col-img-agente">
                                                                <img src={foto} width={55} alt="imgCircle" className="imgCircle" />
                                                            </Col>

                                                            <Col xs={19} md={24} xl={18} xxl={19} className="col-info-agente">
                                                                <Col span={24}>
                                                                    <Text className="txt-ultCnx" title="Ultima conexión"> {_ultimaConexion}</Text>
                                                                    <Title level={4} title="Empresa" className="txt-empresa">{empresa}</Title>
                                                                    <Title level={3} title="Usuario" className="txt-nombre">{nombre}</Title>
                                                                </Col>

                                                                <Col span={24}>
                                                                    <Text className="txt-ultMsj" title="Ultimo mensaje"> {ultimoMensaje}</Text>
                                                                </Col>

                                                                <Col span={24}>
                                                                    <Row className="wd-100prs">
                                                                        <Col xs={12} md={24} xxl={10} span={24}>
                                                                            <Button className=" btn-card-files" title="Total Archivos"><IconFiles /> Files (x{(archivos + imagenes)})</Button>
                                                                        </Col>
                                                                        <Col xs={12} md={24} xxl={14} span={24}>
                                                                            <Button className=" btn-msj " title="Total mensajes"><IconComment />{mensajes}</Button>
                                                                            <Button className=" btn-cita " title="Total citas"><IconCalendarSM />{citas}</Button>
                                                                        </Col>
                                                                    </Row>

                                                                </Col>
                                                            </Col>
                                                        </Row>
                                                    </Card>

                                                </div>
                                            }
                                        })
                                    }

                                </Col>

                            </Row>
                        </Col>


                        <Col xs={24} md={10} xl={13} xxl={14} className="contenedor-conversacion">
                            <Row className="wd-100prs bg-white">
                                <Col span={24} className="cnt-mensajes" id="messages-body" >

                                    <div className="cnt-Mensajes-body " ref={messageContentReference => { this.messageContentReference = messageContentReference; }} >
                                        {this.state.data.map(e => {
                                            return <Row className="card-msj">
                                                <Card className="cnt-msj ">
                                                    <Row>
                                                        {e.map(msj => {

                                                            const { archivos, createdAt, destinatario_id, fecha, imagenes, mensaje, primero, remitente_id, updatedAt, visto, _id } = msj;
                                                            let owner = (remitente_id === userLogged);
                                                            var destinatario = this.state.detalleDestinatario;
                                                            var dst_foto = destinatario?.foto;
                                                            if (dst_foto === undefined || dst_foto === "") { dst_foto = "/images/img-default.png" } else { dst_foto = (axios.defaults.baseURL + '/upload/' + dst_foto) }

                                                            return <div style={{ width: '100%' }}>
                                                                <Comment
                                                                    style={{ width: '100%' }}
                                                                    // id={((primero == true) ? "primero-elemento" : undefined)}
                                                                    className={(owner == true) ? "owner" : null}

                                                                    avatar={
                                                                        owner ? null :
                                                                            <Avatar
                                                                                className="avatarGlobal"
                                                                                style={{ paddingTop: "5px" }}
                                                                                size="large"
                                                                                src={dst_foto}
                                                                            >
                                                                                {(remitente_id != undefined) ? remitente_id.nombre : "User"}
                                                                            </Avatar>
                                                                    }
                                                                    content={
                                                                        <Tooltip className="dateMsj" title={moment(fecha).format('YYYY-MM-DD HH:mm:ss')}>
                                                                            <span>{moment(fecha).fromNow()}</span>

                                                                        </Tooltip>
                                                                    }

                                                                    datetime={<div>
                                                                        <div className="divCntMsj">
                                                                            {mensaje} <br></br>

                                                                            {archivos.map(filename => {

                                                                                let name = "";
                                                                                let fileSplit = filename.split("_");
                                                                                let file = filename.split(".")[0];
                                                                                for (let index = 0; index < (fileSplit.length - 1); index++) {
                                                                                    name += ((index == 0) ? "" : '_') + fileSplit[index];
                                                                                }
                                                                                let extension = fileSplit[fileSplit.length - 1].split('.')[1];
                                                                                let extensionImage = null;

                                                                                if (ifIsImage(filename)) {
                                                                                    extensionImage = <FaImage style={{ marginRight: '0.5em' }} className="chatFile" />
                                                                                }

                                                                                else if (extension == "pdf" || extension == "PDF") {
                                                                                    extensionImage = <FaFilePdf style={{ marginRight: '0.5em' }} className="chatFile" />
                                                                                }

                                                                                else {
                                                                                    extensionImage = <FaFileAlt style={{ marginRight: '0.5em' }} className="chatFile" />
                                                                                }

                                                                                if (ifIsImage(`${name}.${extension}`))
                                                                                    return <a >
                                                                                        <Image src={axios.defaults.baseURL + '/upload/' + filename} style={{ maxWidth: '200px', margin: '1em' }} />
                                                                                    </a>
                                                                                else
                                                                                    return <>
                                                                                        <br />
                                                                                        <a target="_blank" download={true} href={axios.defaults.baseURL + '/upload/' + filename}  >
                                                                                            {extensionImage}{`${file}.${extension}`}
                                                                                        </a>
                                                                                    </>
                                                                            })}

                                                                            {imagenes.map(filename => {

                                                                                let name = "";
                                                                                let fileSplit = filename.split("_");
                                                                                for (let index = 0; index < (fileSplit.length - 1); index++) {
                                                                                    name += ((index == 0) ? "" : '_') + fileSplit[index];
                                                                                }
                                                                                let extension = fileSplit[fileSplit.length - 1].split('.')[1];
                                                                                let extensionImage = null;

                                                                                if (ifIsImage(filename)) {
                                                                                    extensionImage = <FaImage style={{ marginRight: '0.5em' }} className="chatFile" />
                                                                                }

                                                                                else if (extension == "pdf" || extension == "PDF") {
                                                                                    extensionImage = <FaFilePdf style={{ marginRight: '0.5em' }} className="chatFile" />
                                                                                }

                                                                                else {
                                                                                    extensionImage = <FaFileAlt style={{ marginRight: '0.5em' }} className="chatFile" />
                                                                                }

                                                                                if (ifIsImage(`${name}.${extension}`))
                                                                                    return <a  >
                                                                                        <Image src={axios.defaults.baseURL + '/upload/' + filename} style={{ maxWidth: '200px', margin: '1em' }} />
                                                                                    </a>
                                                                                else
                                                                                    return <>
                                                                                        <br />
                                                                                        <a target="_blank" download={true} href={axios.defaults.baseURL + '/upload/' + filename} >
                                                                                            {extensionImage}{`${name}.${extension}`}
                                                                                        </a>
                                                                                    </>
                                                                            })}
                                                                        </div>
                                                                        <Button title="Opciones" className="btnMenuChat" icon={<IconMenuChat />} />
                                                                        <span className="msjStatus">
                                                                            {(owner) ? ((visto) ? <BsCheckAll /> : <BsCheck />) : ""}
                                                                        </span>
                                                                    </div>
                                                                    }
                                                                />
                                                            </div>
                                                        })}
                                                    </Row>
                                                </Card>
                                            </Row>
                                        })}
                                    </div>

                                    <div className={this.state.filesArray.length > 0 ? "filesList" : null}>

                                        {this.state.filesArray.map(file => {

                                            let extensionImage = null;
                                            if (ifIsImage(file.file.name)) {
                                                extensionImage = <FaImage />
                                            }
                                            else if (file.extension == "pdf" || file.extension == "PDF") {
                                                extensionImage = <FaFilePdf />
                                            }
                                            else {
                                                extensionImage = <FaFileAlt />
                                            }

                                            let name = (file.name.length > 10) ? (file.name.slice(0, 10) + '... ') : file.name;
                                            return <div className="file-container">
                                                <div className="file">
                                                    {(file.status != "complete") ? <Progress percent={parseInt(file.progress)} size="small" style={{
                                                        position: 'absolute',
                                                        top: '36%',
                                                        left: '5px',
                                                        width: 'calc(100% - 10px)',
                                                    }} /> : extensionImage}
                                                    <Button onClick={() => this.deleteFile(file.file.name)} className="buttom-close"><FaTimes /></Button>
                                                </div>
                                                <span style={{
                                                    display: 'block',
                                                    margin: '0 auto',
                                                    textAlign: ' center',
                                                    left: 0,
                                                    color: '#7b7373',
                                                    fontSize: 12

                                                }}>{name + '.' + file.extension}</span>
                                            </div>
                                        })}
                                    </div>


                                </Col>
                                <Col span={24} className="cnt-opciones">
                                    <div className="cnt-Mensajes-opciones">

                                        <Input
                                            ref={ref => this.inputRef = ref}
                                            onKeyDown={this.onKeyDownPress}
                                            placeholder="Escribe tu mensaje aqui..." />


                                        <div className="cnt-msj-opciones">
                                            <Button title="Adjuntar archivo"
                                                className="btnAtrach">
                                                <Upload
                                                    multiple={true}
                                                    showUploadList={false}
                                                    customRequest={this.uploadFile}

                                                >
                                                    <IconAttach />
                                                </Upload>
                                            </Button>

                                            <Button title="Enviar"
                                                className="btnSend"
                                                onClick={() => this.onPress({ target: { value: this.inputRef.state.value } })}>
                                                <IconSend />
                                            </Button>
                                        </div>
                                    </div>

                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} md={6} xl={5} xxl={4} className="contenedor-detalle">
                            <Row className="wd-100prs bg-white">
                                {this.renderRowDetalle()}
                            </Row>
                        </Col>
                    </Row>

                </Spin>

            </Content>
        )
    }
}