import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Switch, Route } from 'react-router';
import { Layout } from 'antd';

import Cookies from 'universal-cookie';
//Routes
import PublicRoutes from "./Routes/PublicRoutes";
import AdminRoutes from "./Routes/AdminRoutes";
import AgenteRoutes from "./Routes/AgenteRoutes";
import PasswordRecovery from "./components/Auth/PasswordRecovery";
import { User, SetUser } from "./Context/User";

//css
import './styles/Global.css';
import 'antd/dist/antd.css'


import axios from 'axios';


export default class App extends React.Component {
	
	constructor(props) {
		super(props)
		this.state = {
			user: null,
		}
		this.getUser()
	}


	/**
	 * 
	 * @method getUser
	 * 
	 * @description Si hay una sesión guardada previamente, la validamos con el servidor y declaramos el usuario en el provider.
	 * 
	 */
	getUser = () => {
		const cookie = new Cookies();

		let token = null

		if (sessionStorage.getItem('token') !== null && sessionStorage.getItem('token') !== undefined)
			token = sessionStorage.getItem('token')

		if (token == null && cookie.get('token') !== null && cookie.get('token') !== undefined)
			token = cookie.get('token')

		if (token !== null){
			
			axios.defaults.headers.common["Authorization"] = token
			sessionStorage.setItem('token', token);

			axios.get('/usuario/logged')
			.then(({ data }) => {
				this.setState({user: data.data })
			})
			.catch(({ response }) => {
				console.log({ response });
			})
		}
	}



	/**
	 * 
	 * @method setUser
	 * 
	 * @description Declaramos los usuarios en el sistema. 
	 * 
	 * @param {*} user 
	 * @returns 
	 */
	setUser = user => this.setState({ user })
	

	render(){

		const { setUser, } = this;
		const { user,  } = this.state;

		return (
			<User.Provider value={user}>
				<SetUser.Provider value={setUser}>
					<BrowserRouter>
						<Layout className="main-layout">
							<Switch>
								<Route exact path="/password/recovery" render={(props)=> <PasswordRecovery  {...props} />}/>
								<Route  path="/professional" component={AdminRoutes} />
								<Route  path="/professionals" component={AgenteRoutes} />
								<Route path="/" component={PublicRoutes} />
							</Switch>							
						</Layout>
					</BrowserRouter>
				</SetUser.Provider>
			</User.Provider>
		)
	}
}