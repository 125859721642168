const English = {
    email:{
        name: "Correo Electronico",
        ValidationRules: {
            isRequired: "Se requiere el correo electronico para continua"
        },
        placeholder: "usuario@dominio.com"
    }
};
export {
    English
};
