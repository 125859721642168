import React, { Component } from 'react'
import { Form, Input, Select, Upload, Button, Spin, message } from 'antd'
import { InboxOutlined } from '@ant-design/icons';
import ModalContraseña from './ModalContraseña'
import axios from 'axios';
import { User } from '../../Context/User';
const { Option } = Select;

// responsividad del formulario
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 12 },
        lg: { span: 8, push: 1 },
        xl: { span: 8, push: 1 },
        xxl: { span: 8, push: 1 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 },
        lg: { span: 12 },
        xl: { span: 12 },
        xxl: { span: 12 }
    },
};

export default class AjustesPerfil extends Component {


    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            usuario: null
        }
    }

    form = React.createRef();

    onSubmit = () => {
        this.setState({modalContraseña: true})
    }

    render() {
        return (
            <Spin tip="Cargando" spinning={this.state.loading} >
                <Form {...formItemLayout} layout="horizontal" onFinish={this.onSubmit} requiredMark={false} ref={this.form} 
                className="bg-white mt-1 w-100 pl-1 pt-5 mr-2 form-ajustes">
                    
                    <Form.Item name="password" labelAlign={"left"} label="Contraseña" >
                        <Input visibilityToggle={false} defaultValue="*********" disabled={true}/>
                    </Form.Item>

                    <Form.Item wrapperCol={{ sm: { span: 12, offset: 12 } }} className="mt-3">
                        <Button type="primary" shape="round" className="btn-yellow" htmlType="submit">
                            Cambiar Contraseña
                        </Button>
                    </Form.Item>

                </Form>
                <ModalContraseña
                    visible={this.state.modalContraseña}
                    onCancel={()=>this.setState({modalContraseña: false})}
                />
            </Spin>
        )
    }
}