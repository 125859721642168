import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';


import Cookies from 'universal-cookie';
import './index.css'
const n = new Cookies()
n.remove('XSRF-TOKEN')

const axios = require("axios").default;

// axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.REACT_APP_WEB_SERVICE


//Funcion para obtener cookies del server
function getCookie(name) {
  return document?.cookie
    ?.split('; ')
    ?.find(row => row.startsWith(name + '='))
    ?.split('=')[1]
}



//Vamos a obtener la cookie de XSRF-TOKEN para evitar las solicitudes externas. 
//Así nos vamos asegunra
axios.interceptors.response.use((response) => {
  axios.defaults.headers.common['XSRF-TOKEN'] = getCookie('XSRF-TOKEN')  
  return response;
},error => {
  axios.defaults.headers.common['XSRF-TOKEN'] = getCookie('XSRF-TOKEN')
  return Promise.reject(error)
});

Number.prototype.toMoney = function (fractionDigits = 2) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: fractionDigits
  })

  return formatter.format(this)
}

window.getCookie = getCookie;


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
