import { MapController } from 'react-map-gl';

export default class MyMapController extends MapController {

    constructor() {
        super();
        this.callbacks = {}
    }
    
    handleEvent(event) {
        if (event.type === 'panend') 
            this.callbackPanend()
        return super.handleEvent(event);
    }
}