import React from 'react';
import { Card, Typography, Tag, Row, Col, Rate, Carousel, Button, Collapse, Image, Skeleton } from 'antd';
import logo from './casa.jpg'
import GaleriaImagenes from './galeria-de-imagenes.svg'
import { Gps, Area, IconWatsAgente, IconCircleBlueArrowDown, IconTrash, IconComment, IconAddUser, IconView, IconCalendarSM } from '../Iconos'
import { EditOutlined, } from '@ant-design/icons';
import { Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { FaPencilAlt } from 'react-icons/fa'
//css
import './cards.css'


const moment = require('moment');
moment.locale('es')


const { Text, Title, Paragraph } = Typography;
const { Panel } = Collapse;


/*
* @function InmuebleCard
* @desrcition Card
*/
function InmuebleCard(props) {

	let tipos = {
		'venta': '#87d068',
		'renta': '#7878dd',
		'vandida': '#dfff19',
	}

	let { createdAt, onClick, onAddressClick, imagenes, nombre, descripcion, localizacion, categorias_caracteristicas, costo_propiedad, pago_mensual, divisa_id } = props;

	let cat = []

	if (Array.isArray(categorias_caracteristicas) && categorias_caracteristicas.length >= 3)
		cat = categorias_caracteristicas.slice(0, 3)


	if (typeof localizacion == "object")
		localizacion.onChange = false

	return (
		<Card className="card-inmueble" >
			{imagenes.length > 0 ? <Carousel autoplay dotPosition={'top'}>
				{imagenes.map(({ filename_server }) => {
					return <div>
						<div
							className="card-image"
							style={{ backgroundImage: `url(${axios.defaults.baseURL + '/upload/' + filename_server})` }} />
					</div>
				})}
			</Carousel> : <div
				className="card-image"
				style={{
					backgroundImage: `url(${GaleriaImagenes})`
				}}
			/>}
			<Tag className="card-tag" color={`${props.tipo ? tipos[props.tipo] : tipos['venta']}`}>{(costo_propiedad !== undefined) ? costo_propiedad.toMoney() : pago_mensual.toMoney()} {divisa_id?.code}</Tag>
			<div className="card-body">
				<div>
					<Title level={3} className="card-title" >
						<a onClick={onClick}>{localizacion?.place_name}</a>
						{/* <a onClick={onClick}>{nombre}</a> */}
					</Title>
					<p className="card-text">{descripcion}</p>
					<Text style={{ color: "#AAA" }}> {moment(createdAt).fromNow()}</Text>
				</div>
				<div className="card-footer">
					<Text className="card-direction">
						<a onClick={() => onAddressClick(localizacion)}><Gps /> {localizacion?.place_name}</a>
					</Text>
					<hr />
					<div className="card-info">
						<div className="1"> <Area /> {props.tamano_lote} m<sup>2</sup></div>
						{cat.map(({ icon, cantidad, nombre }, index) => <div className={index + " bl"}>
							<img style={{ maxHeight: '20px' }} src={axios.defaults.baseURL + '/caracteristicas/' + icon} />
							{cantidad} {nombre.substring(0, 3)}
						</div>)}
					</div>
				</div>
			</div>
		</Card>
	);
}


/*
* @function InmuebleCard
* @desrcition Card
*/
function InmuebleCardSkeleton(props) {
	return (
		<Card className="card-inmueble ">
			<div className="card-image" style={{
				backgroundImage: `url(${GaleriaImagenes})`
			}}>
			</div>
			<div className="card-body">
				<Skeleton active />
				<div className="card-footer">
					<Text className="card-direction"><Skeleton.Input active size="small" /></Text>
					<hr />
					<div className="card-info" style={{ width: '100%' }}>
						<Skeleton.Input active size="small" style={{ width: '100%' }} />
					</div>
				</div>
			</div>
		</Card>
	);
}

/*
* @function InmuebleCardSmall
* @desrcition Card
*/
function InmuebleCardSmall(props) {
	const { nombre, localizacion, imagenes, descripcion, costo_propiedad, pago_mensual, divisa_id, banos, habitaciones, tamano_lote } = props

	let imagen = GaleriaImagenes;
	if (Array.isArray(imagenes) && imagenes.length > 0) {
		imagen = `${axios.defaults.baseURL}/upload/${imagenes[0].filename_server}`
	}
	return (
		<Card className="card-inmueble-small">
			<Row>
				<Col span={9}>
					<div className="card-image" style={{
						backgroundImage: `url(${imagen})`
					}}>
					</div>
				</Col>
				<Col span={15}>
					<div className="card-body">
						<Title level={3} className="card-title"> {(costo_propiedad !== undefined) ? costo_propiedad.toMoney() : (pago_mensual !== undefined) ? pago_mensual.toMoney() : 'Sin definir'} <smail>{divisa_id?.code}</smail></Title>
						<p className="card-text">{descripcion}</p>
						<div className="card-footer">
							<Text className="card-direction"> <Gps /> {localizacion.place_name}</Text>
							<hr />
							<div className="card-info">
								<div> 4 Rec</div>
								<div className="bl"> {banos} Baños</div>
								<div className="bl"> {habitaciones} Habitac</div>
								<div className="bl">  {tamano_lote} m<sup>2</sup></div>
							</div>
						</div>
					</div>
				</Col>
			</Row>
		</Card>
	);
}

/*
* @function InmuebleCardPro
* @desrcition Card
*/
function InmuebleCardPro(props) {

	const { nombre, localizacion, vistos, count_citas, imagenes, onDelete = () => { }, onEdit = () => { } } = props

	let imagen = GaleriaImagenes;

	if (Array.isArray(imagenes) && imagenes.length > 0)
		imagen = `${axios.defaults.baseURL}/upload/${imagenes[0].filename_server}`


	return (
		<Card className="card-inmueble-pro card-hoverable" >
			<div className="card-image" style={{
				backgroundImage: `url(${imagen})`
			}}>
				<div className="position-down">
					{/* <Text className="card-title">{nombre}</Text> */}
					<Text className="card-direction"> <Gps /> {localizacion?.place_name} </Text>
				</div>
			</div>
			<div className="card-body">
				<Row>
					<Col span={18} className="flex-start">
						<Button className="btn-card"><IconComment />0</Button>
						<Button className="btn-card"><IconAddUser />0</Button>
						<Button className="btn-card"><IconView />{vistos}</Button>
						<Button className="btn-card"><IconCalendarSM />{count_citas}</Button>
					</Col>
					<Col span={6} className="flex-end">
						<Button onClick={onEdit} className="btn-edit"><FaPencilAlt /></Button>
						<Button onClick={onDelete} className="btn-trash"> <IconTrash /> </Button>
					</Col>
				</Row>
			</div>
		</Card>
	);
}

/*
* @function InmuebleCardPro
* @desrcition Card
*/
function InmuebleCardProBig(props) {
	const { nombre, localizacion, vistos, count_citas, imagenes, descripcion, costo_propiedad, pago_mensual, divisa_id, onDelete = () => { }, onEdit = () => { } } = props

	let imagen = GaleriaImagenes;
	if (imagenes.length > 0) {
		imagen = `${axios.defaults.baseURL}/upload/${imagenes[0].filename_server}`
	}

	return (
		<Card className="card-inmueble-pro-big card-hoverable">
			<Row>
				<Col xs={24} md={7}>
					<div className="card-image" style={{
						backgroundImage: `url(${imagen})`
					}} />
				</Col>
				<Col xs={24} md={17} className="pt-1 pr-1 pb-1 pl-2">
					<Row>
						<Col span={24} className="flex-end">
							<Button onClick={onDelete} className="btn-trash"><IconTrash /></Button>
							<Button onClick={onEdit} className="btn-edit"><FaPencilAlt /></Button>
						</Col>
						<Col xs={24} lg={12} className="flex-column">
							{/* <Text className="card-title">{nombre}</Text> */}
							<Text className="card-direction"> <Gps /> {localizacion?.place_name} </Text>
						</Col>
						<Col xs={24} lg={10} className="flex-end-top">
							<Button className="btn-card"><IconComment />0</Button>
							<Button className="btn-card"><IconAddUser />0</Button>
							<Button className="btn-card"><IconView />{vistos}</Button>
							<Button className="btn-card"><IconCalendarSM />{count_citas}</Button>
						</Col>
						<Col span={22}>
							<Paragraph className="card-text">{descripcion}</Paragraph>
						</Col>
						<Col span={22}>
							<Title level={3} className="card-price"> {(costo_propiedad !== undefined) ? costo_propiedad.toMoney() : (pago_mensual !== undefined) ? pago_mensual.toMoney() : 'Sin definir'} <small>{divisa_id?.code}</small></Title>
						</Col>
					</Row>
				</Col>
			</Row>
		</Card>
	);
}


/**
 *
 *
 * @param {*} props
 * @returns Component
 */
function CardTipoPropiedadSkeleton() {
	return <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
		<Card hoverable className="shadow-card card-property-type">
			<Row justify="center">
				<Col span={24} className="center" >
					<Skeleton.Image active shape="round" />
				</Col>
				<Col span={24} className="center p-1" >
					<Skeleton.Button active size={'small'} />
				</Col>
				<Col span={24} className="center p-1" >'
					<Skeleton.Button active size={'small'} />
				</Col>
			</Row>
		</Card>
	</Col>
}


/**
 *
 *
 * @param {*} props
 * @returns Component
 */
function CardTipoPropiedad(props) {

	let { tipo, onClick } = props;
	return <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
		<Card hoverable className="shadow-card card-property-type">
			<Row justify="center">
				<Col span={24} className="center" >
					<Image src={axios.defaults.baseURL + '/tipos/' + props.tipo.icon} preview={false} className="center card-property-type-image" />
				</Col>
				<Col span={24} className="center p-1" >
					<Text className="card-tipo-text">{props.tipo.nombre}</Text>
				</Col>
				<Col span={24} className="center p-1" >
					<Button block className="btn-yellow" shape="round" onClick={() => onClick(tipo._id)}>Elegir</Button>
				</Col>
			</Row>
		</Card>
	</Col>
}
/**
 *
 *
 * @param {*} props
 * @returns Component
 */
function CardApp(props) {

	let { logo, nombre } = props;
	return <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
		<Card hoverable className="shadow-card card-property-type">
			<Row justify="center">
				<Col span={24} className="center" >
					<Image src={axios.defaults.baseURL + '/tipos/' + props.logo} preview={false} className="center card-property-type-image" />
				</Col>
				<Col span={24} className="center p-1" >
					<Text className="card-tipo-text">{props.nombre}</Text>
				</Col>
				<Col span={24} className="center p-1" >
					<Button block className="btn-yellow" shape="round" >Conectar</Button>
				</Col>
			</Row>
		</Card>
	</Col>
}


/**
 *
 *
 * @param {*} props
 * @returns
 */
function AgenteCard(props) {
	
	let { _id, createdAt, foto, nombre, telefono, calificacion, reviews, descripcion, tipo } = props;
	if (foto === undefined || foto === "") { foto = "/images/img-default.png" }
	return <Card hoverable className="list-card-agente  shadow-sm">
		<Row>
			<img src={"/images/icon-img-insignia.png"} alt="iconInsignia" className="iconImgInsignia" />
			<Col xs={24} md={10} xl={8} className="col-img-agente"
			>
				<img src={foto} width={100} alt="imgCircle" className="imgCircle" />


				<img src={"/images/icon-img-circle.png"} width={35} alt="iconImgCircle" className="iconImgCircle" />
			</Col>
			<Col xs={24} md={14} xl={16} className="col-info-agente">
				<Col span={23}>
					<Link to={"agente/detalle/" + _id} >
						<Title level={3} className="text-blue" title="Ver">{nombre}</Title>
					</Link>
				</Col>
				<Col span={23}>
					<IconWatsAgente /> <Text>{telefono}</Text>

				</Col>
				<Col span={23}>
					<Rate defaultValue={calificacion} disabled /> {reviews} Reviews
				</Col>

				<Col span={23}>
					<Text style={{ color: "#AAA" }}> {moment(createdAt).fromNow()}</Text>
				</Col>
				<Col span={22} className="col-card-descripcion">
					<Text>{descripcion} </Text>
				</Col>
			</Col>
		</Row>
	</Card>

}


/**
 *
 *
 * @param {*} props
 */
function AgenteLargeCard(props) {
	console.log(props);

	let { _id, createdAt, foto, nombre, telefono, calificacion, reviews, descripcion, tipo, localizacion, insignia } = props;
	if (foto === undefined || foto === "") { foto = "/images/img-default.png" }
	
	else if(foto.length > 0){
		foto =  `${axios.defaults.baseURL}/upload/${foto}`
		
	}

	return <Card hoverable={false} className="list-card-agente row-card-agente shadow-sm"  >
		{(insignia == !undefined && insignia) ? <img src={"/images/icon-img-insignia.png"} alt="iconInsignia" className="iconImgInsignia row-iconimgInsignia" /> : null}
		<Row className="wd-100prs center pd-10" >
			<Col xs={22} xl={10} span={10}>
				<Row>
					<Col xs={24} md={8} xl={6} className="col-img-agente">
						<img src={foto} width={80} alt="fotoPerfil" className="imgCircle imgPerfilAgente" />
						<img src={"/images/icon-img-circle.png"} width={25} alt="agencia_imagen" className="iconImgCircle" />
					</Col>
					<Col xs={24} md={16} xl={18} className="col-info-agente">

						<Col span={23}>
							<Link to={`agente/detalle/${props.url ? props.url : _id}`} >
								<Title level={3} className="text-blue" title="Ver">{nombre}</Title>
							</Link>
						</Col>

						<Col span={23}>
							<Text>{telefono}</Text>
						</Col>

						<Col span={23}>
							<Rate defaultValue={calificacion} disabled /> {reviews} Reviews
						</Col>


						<Col span={23}>
							<Text style={{ color: "#AAA" }}> {moment(createdAt).fromNow()}</Text>
						</Col>

						<Col span={22}>
							<Text>{descripcion} </Text>
						</Col>
					</Col>
				</Row>

			</Col>
			<Col xs={22} md={8} xl={4} span={4}>
				<Text>{localizacion?.place_name} </Text>
				{/*	<img src={'/images/mapa.png'} alt="imgMapa" className="imgMapa" /> */}
			</Col>
			<Col xs={22} md={16} xl={10} span={8} className="pd-10">
				<Carousel autoplay className="carusel-testimonios">
					<Row className="row-testimonio">
						<Col className="col-tst-fecha right">
							<Text>2021-05-01</Text>
						</Col>
						<Col className="col-tst-testimonio">
							<Title level={5}> “Lorem Ipsum is simply dummy text of the printing and typesetting industry.”</Title>
						</Col>
						<Col className="col-tst-autor">
							<Text>Luis Rivera</Text>
						</Col>
					</Row>

					<Row className="row-testimonio">
						<Col className="col-tst-fecha right">
							<Text>2021-05-01</Text>
						</Col>
						<Col className="col-tst-testimonio">
							<Title level={5}> “Lorem Ipsum is simply dummy text of the printing and typesetting industry.”</Title>
						</Col>
						<Col className="col-tst-autor">
							<Text>Luis Rivera</Text>
						</Col>
					</Row>

					<Row className="row-testimonio">
						<Col className="col-tst-fecha right">
							<Text>2021-05-01</Text>
						</Col>
						<Col className="col-tst-testimonio">
							<Title level={5}> “Lorem Ipsum is simply dummy text of the printing and typesetting industry.”</Title>
						</Col>
						<Col className="col-tst-autor">
							<Text>Luis Rivera</Text>
						</Col>
					</Row>
				</Carousel>
			</Col>
		</Row>
	</Card>
}


function AgenciasCard(props) {
	let { _id, foto, nombre, telefono, calificacion, reviews, descripcion, } = props;
	if (foto === undefined || foto === "") { foto = "/images/img-default.png" }
	return <Card hoverable={false} className="list-card-agente  shadow-sm">
		<Row>
			<img src={"/images/icon-img-insignia.png"} alt="iconInsignia" className="iconImgInsignia" />
			<Col xs={24} md={10} xl={8} className="col-img-agente">
				<img src={foto} width={100} alt="perfil_image" className="imgCircle" />
			</Col>
			<Col xs={24} md={14} xl={16} className="col-info-agente">
				<Col span={23}>
					<Title level={3} className="text-blue">{nombre}</Title>
				</Col>
				<Col span={23}>
					<IconWatsAgente /> <Text>{telefono}</Text>
				</Col>
				<Col span={23}>
					<Rate defaultValue={parseInt(calificacion)} disabled key={"rt" + nombre} /> {reviews} Reviews
				</Col>
				<Col span={22} className="col-card-descripcion">
					<Text>{descripcion} </Text>
				</Col>
			</Col>
		</Row>
	</Card>
}

function AgenciasLargeCard(props) {
	let { _id, foto, nombre, telefono, calificacion, reviews, descripcion, insignia } = props;
	if (foto === undefined || foto === "") { foto = "/images/img-default.png" }
	return <Card
		hoverable={false}
		className="list-card-agente row-card-agente ">
		{(insignia == !undefined && insignia) ? <img src={"/images/icon-img-insignia.png"} alt="iconInsignia" className="iconImgInsignia row-iconimgInsignia" /> : null}
		<Row className="wd-100prs center pd-10">
			<Col xs={22} xl={10} span={10}>
				<Row>
					<Col xs={24} md={8} xl={6} className="col-img-agente">
						<img src={foto} width={95} alt="imgCircle" className="imgCircle" />
					</Col>
					<Col xs={24} md={16} xl={18} className="col-info-agente">
						<Col span={23}>
							<Link to={"agente/detalle/" + _id} >
								<Title level={3} className="text-blue" title="Ver" >{nombre}</Title>
							</Link>
						</Col>

						<Col span={23}>
							<Text>{telefono}</Text>
						</Col>

						<Col span={23}>
							<Rate defaultValue={calificacion} disabled /> {reviews} Reviews
						</Col>

						<Col span={22}>
							<Text>{descripcion} </Text>
						</Col>
					</Col>
				</Row>
			</Col>

			<Col xs={22} md={16} xl={10} span={8} className="pd-10">
				<Carousel autoplay className="carusel-testimonios">
					<Row className="row-testimonio">
						<Col className="col-tst-fecha right">
							<Text>2021-05-01</Text>
						</Col>
						<Col className="col-tst-testimonio">
							<Title level={5}> “Lorem Ipsum is simply dummy text of the printing and typesetting industry.”</Title>
						</Col>
						<Col className="col-tst-autor">
							<Text>Luis Rivera</Text>
						</Col>
					</Row>

					<Row className="row-testimonio">
						<Col className="col-tst-fecha right">
							<Text>2021-05-01</Text>
						</Col>
						<Col className="col-tst-testimonio">
							<Title level={5}> “Lorem Ipsum is simply dummy text of the printing and typesetting industry.”</Title>
						</Col>
						<Col className="col-tst-autor">
							<Text>Luis Rivera</Text>
						</Col>
					</Row>

					<Row className="row-testimonio">
						<Col className="col-tst-fecha right">
							<Text>2021-05-01</Text>
						</Col>
						<Col className="col-tst-testimonio">
							<Title level={5}> “Lorem Ipsum is simply dummy text of the printing and typesetting industry.”</Title>
						</Col>
						<Col className="col-tst-autor">
							<Text>Luis Rivera</Text>
						</Col>
					</Row>
				</Carousel>
			</Col>
		</Row>
	</Card>
}

function ContructoraCardLarge(props) {

	let { _id, foto, nombre, telefono, calificacion, reviews, descripcion, insignia, cntCasas, cntComunidades, tipo } = props;
	let dataComunidades = props.comunidades;
	if (dataComunidades === undefined) { dataComunidades = [] }
	if (foto === undefined || foto === "") { foto = "/images/img-default.png" }
	if (cntCasas === undefined) { cntCasas = 0 }
	if (cntComunidades === undefined) { cntComunidades = 0 }
	if (descripcion === undefined) { descripcion = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's" }

	return <Card hoverable={false} className="list-card-agente row-card-agente">
		{(insignia == !undefined && insignia) ? <img src={"/images/icon-img-insignia.png"} alt="iconInsignia" className="iconImgInsignia row-iconimgInsignia" /> : null}
		<Row className="wd-100prs left pd-10">
			<Col xs={24} >
				<Row className="left">

					<Col xs={24} md={4} xl={3} xxl={2} className="col-img-agente">
						<img src={foto} width={95} alt="imgCircle" className="imgCircle" />
					</Col>

					<Col xs={24} md={20} xl={21} xxl={22} className="col-info-agente">
						<Col span={23}>
							<Link to={"agente/detalle/" + _id} >
								<Title level={3} className="text-blue" title="Ver" >{nombre}</Title>
							</Link>
						</Col>

						<Col span={23}>
							<Rate defaultValue={calificacion} disabled className="mr-20px" style={{ marginRight: "10px" }} />
							<Tag color="#87D068"> {cntComunidades} Comunidades </Tag>
							<Tag color="#108EE9"> {cntCasas} Casas </Tag>
						</Col>

						<Col span={23}>
							<Text>{descripcion} </Text>
						</Col>

						{(dataComunidades !== undefined && dataComunidades.length > 0) ?
							<Row className="wd-100prs">

								<Col xs={24}>
									<Collapse
										bordered={false}
										defaultActiveKey={['0']}
										className="site-collapse-custom-collapse"
										expandIcon={({ isActive }) => <div className={isActive ? "panelColapsed" : "panelNoColapsed"}><IconCircleBlueArrowDown /> {isActive ? <Text className="text-link-blue">Ver menos </Text> : <Text className="text-link-blue">Ver más </Text>}  </div>}
									>
										<Panel key={'0'} className="site-collapse-custom-panel">
											<Row className="wd-100prs left pd-10">
												{dataComunidades.map((comunidad, index) => {
													return ComunidadCard(comunidad)
												})}
											</Row>
										</Panel>
									</Collapse>
								</Col>


								<Col xs={24}>
									<Collapse
										bordered={false}
										className="site-collapse-custom-collapse"
										expandIcon={({ isActive }) => <div className={isActive ? "panelColapsed" : "panelNoColapsed"}><IconCircleBlueArrowDown /> {isActive ? <Text className="text-link-blue">Ver menos </Text> : <Text className="text-link-blue">Ver todas las comunidades </Text>}  </div>}
									>
										<Panel key={0} className="site-collapse-custom-panel">

										</Panel>
									</Collapse>
								</Col>
							</Row> : null}

					</Col>
				</Row>
			</Col>

		</Row>
	</Card>
}
/*Row Constructora */
function ComunidadCard(props) {

	var { id, casa, logo, nombre, comunidad, estado } = props;
	if (logo === undefined || logo === "") { logo = "/images/img-default.png" }
	if (casa === undefined || casa === "") { casa = "/images/img-default.png" }


	return <Col xs={24} md={12} xl={8} className="col-comunidad">
		<Row className="wd-100prs row-comunidad">
			<Col xs={24} className="col-img-comunidad">
				<img src={casa} width={100} alt="imgCasa" className="imgCasa" />
				<img src={logo} width={35} alt="iconImgCircle" className="iconLogoCircle" />
			</Col>

			<Col xs={24} className="col-info-comunidad">
				<Col span={23}>
					<Title level={5} className="text-cmn-nombre">{nombre}</Title>
				</Col>
				<Col span={23}>
					<Text className="text-cmn-comunidad">{comunidad}</Text>
				</Col>
				<Col span={23}>
					<Text className="text-cmn-estado">{estado}</Text>
				</Col>
			</Col>

		</Row>
	</Col>

}

function CardCalendarioSkeleton(props) {
	return <Card hoverable className="shadow-card card-property-type">
		<Row justify="center">
			<Col span={24} className="center" >
				{/*<Skeleton.Image active shape="round" />*/}
				<Text>No hay ninguna cita seleeccionada</Text>
			</Col>
			<Col span={24} className="center p-1" >
				<Skeleton.Button size={'small'} />
			</Col>
			<Col span={24} className="center p-1" >
				<Skeleton.Button size={'small'} />
			</Col>
		</Row>
	</Card>
}


export {
	InmuebleCardSkeleton,
	CardTipoPropiedadSkeleton,
	CardTipoPropiedad,
	InmuebleCard,
	InmuebleCardSmall,
	InmuebleCardPro,
	InmuebleCardProBig,

	ComunidadCard,
	CardApp,
	CardCalendarioSkeleton,

	AgenteCard,
	AgenteLargeCard,

	AgenciasCard,
	AgenciasLargeCard,


	ContructoraCardLarge

}
