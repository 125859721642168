import React, { Component } from 'react';
import { Row, Popover, Button, Typography, Spin, Col } from 'antd'

import { FaMapMarkerAlt } from 'react-icons/fa'

import MapboxGL from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import MapGL, { _useMapControl as useMapControl, MapContext, Marker, LinearInterpolator, GeolocateControl } from 'react-map-gl';

import CustomClassController from './CustomClassController'

import { IconMapMarker } from '../Iconos'
 
import axios from 'axios';

import './Mapa.css'; // eslint-disable-line import/no-webpack-loader-syntax

MapboxGL.accessToken = process.env.REACT_APP_MAPBOX_API



const { Title, Paragraph, Text } = Typography;

function PropertyMarker(props) {
    const context = React.useContext(MapContext);

    const { longitude, latitude, propiedad, onClick } = props;

    const [x, y] = context.viewport.project([longitude, latitude]);


    //console.log(props);

    const markerStyle = {
        position: 'absolute',
        //background: '#fff',
        left: x,
        top: y,
    };

    const tipos = {
        'venta': 'color-venta',
        'renta': 'color-renta',
        'vendida': 'color-vendida'
    }


    let imagen =  "/images/img-default.png" ;

    if (Array.isArray(propiedad.imagenes) && propiedad.imagenes.length > 0) {
        imagen = `${axios.defaults.baseURL}/upload/${propiedad.imagenes[0].filename_server}`
     }

    
    const titulo = ""
    // <Row style={{ maxWidth: '250px' }}>
    //     <Title level={5}>{propiedad?.localizacion.place_name_es}</Title>
    // </Row>

    const content = (
        <Row style={{ maxWidth: '250px' }}>
            
            <Col span={10}>
                <img src={imagen} width={90} alt="img-location" className="img-location" />
            </Col>
            <Col span={14}  className="info-location">
                <Title level={5} className="card-title"> {(propiedad.costo_propiedad !== undefined) ? propiedad.costo_propiedad.toMoney() : (propiedad.pago_mensual !== undefined) ? propiedad.pago_mensual.toMoney() : 'Sin definir'} <smail>{propiedad.divisa_id?.code}</smail></Title>
                
                <Paragraph > 
                {(propiedad.habitaciones !== undefined) ? ("Hab: "+ propiedad.habitaciones) : ""}
                {(propiedad.habitaciones !== undefined && propiedad.banos !== undefined) ? " / "  : ""}
                {(propiedad.banos !== undefined) ? ("Bañ: "+ propiedad.banos) : ""}
                </Paragraph>
                <Paragraph >Lote: {propiedad.tamano_lote} m²</Paragraph>
            </Col>
        </Row>
    )


    return (
        // InmuebleCardSmall
        // <Popover content={
        //     <Row style={{ maxWidth: '220px' }}>
        //         <Title level={5}>{propiedad.nombre}</Title>
        //         <Paragraph >{propiedad.descripcion}</Paragraph>
        //     </Row>
        // } title={null}>
        //     <a style={markerStyle} className={`dot-map ${props.tipo ? tipos[props.tipo] : 'color-venta'}`} />
        // </Popover>


        <Popover
            className="dot-location"
            placement="bottom"
            content={content}
            title={titulo}>

            <a style={markerStyle} className={`dot-map ${props.tipo ? tipos[props.tipo] : 'color-venta'}`} />

        </Popover>

    );
}


export default class Mapa extends Component {

    /**
     *
     *
     * @static
     * @memberof Mapa
     * 
     * @prop propiedades Propiedades del sistema.
     */
    static defaulProps = {
        propiedades: [],
        propiedad: {},



        selectLocation: false
    }



    constructor(props) {
        super(props)
        this.state = {
            actualizado: false,
            address: {},
            viewport: {
                latitude: 19.41944,
                longitude: -99.14556,
                zoom: 10
            },

            markers: {},
            transitionDuration: 0,
            loading: false
        }


        this.controller = new CustomClassController();
        this.controller.callbackPanend = () => {
            const map = this.mapRef.getMap()

            if (typeof this.props.onChange === "function")
                this.props.onChange({
                    bounds: map.getBounds(),
                    zoom: map.getZoom()
                })
        }
    }

    componentDidUpdate = async (x, y) => {
        if (
            (
                (Array.isArray(this.props.address?.center) && Array.isArray(this.state.address?.center)) &&
                (this.props.address?.center.length > 1 && this.state.address?.center.length > 1) &&
                (this.props.address?.center[0] !== this.state.address?.center[0] || this.props.address?.center[1] !== this.state.address?.center[1])
            )
            ||
            (
                this.props.address?.id !== this.state.address?.id
            )
        ) {
            this.state.address = this.props.address
            this.updateLocationByProps()
        }

        if (this.state.updateLocation === true) {
            this.state.updateLocation = false
            const map = this.mapRef.getMap()

            if (typeof this.props.onChange === "function")
                this.props.onChange({
                    bounds: map.getBounds(),
                    zoom: map.getZoom(),
                    address: this.state.address
                })
        }

    }


    updateFrom = "props"
    /**
     *
    *
    * @memberof Mapa
    * @method updateLocationByProps
    * 
    * @description Cuando se actualiza el address, se actualiza el address y se cambia el mapa a esa dirección
    */
    updateLocationByProps = () => {
        this.setState(state => {
            state.viewport = {
                ...state.viewport,
                ...this.getViewPortByAddress(this.state.address)
            }
            if (state.address?.onChange !== false)
                state.updateLocation = true
            return state;
        })

    }


    getViewPortByAddress = (address = this.props.address) => {


        if (Array.isArray(address?.center))
            return ({
                latitude: parseFloat(address?.center[1]),
                longitude: parseFloat(address?.center[0]),
                zoom: parseInt(this.getZoom(address?.place_type))
            })
    }



    /**
     *
     *
     * @memberof Mapa
     * @method getZoom
     * 
     * @description Obtenemos el ZOOM del mapa con base al tipo de zoom
     */
    getZoom = (places_type_array) => {
        if (Array.isArray(places_type_array) && places_type_array.length > 0) {
            switch (places_type_array[0]) {
                case 'country':
                    return 4;

                case 'region':
                    return 11;

                case 'postcode':
                    return 14;

                case 'district':
                    return 8;

                case 'place':
                    return 11;

                case 'locality':
                    return 13;

                case 'neighborhood':
                    return 13;

                case 'address':
                    return 13;

                case 'poi':
                    return 15;

                default:
                    return 15;
            }
        }
        return 11
    }



    getAddressByCords = async (longitud, latitud) => {
        this.updateFrom = "state"
        fetch(`${MapboxGL.config.API_URL}/geocoding/v5/mapbox.places/${longitud},${latitud}.json?access_token=${MapboxGL.accessToken}&language=es`)
            .then(response => response.json().then(this.fetchAndUpdateAddress))
    }


    fetchAndUpdateAddress = data => {
        if (Array.isArray(data.features) && data.features.length > 0 && data.features[0].center.length > 1) {

            this.setState({ address: data.features[0] })
            if (typeof this.props.onChange === "function")
                this.props.onChange({
                    address: data.features[0]
                })
        }
    }


    render() {
        const { viewport } = this.state
        return (
            <MapGL
                {...viewport}
                controller={this.controller}

                ref={ref => this.mapRef = ref}

                onClick={(this.props.selectLocation == true) ? pointer => {
                    if (Array.isArray(pointer.lngLat) && pointer.lngLat.length > 1)
                        this.setState(state => {

                            state.address.center = []
                            state.address.center[0] = pointer.lngLat[0]
                            state.address.center[1] = pointer.lngLat[1]

                            this.getAddressByCords(pointer.lngLat[0], pointer.lngLat[1])
                            return state;
                        })
                } : undefined}

                showZoom={true}
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API}
                mapStyle='mapbox://styles/mapbox/streets-v11'
                width="100%"
                height="100%"
                dragRotate={false}
                transitionDuration={this.state.transitionDuration}
                transitionInterpolator={new LinearInterpolator()}
                onTransitionEnd={() => {
                    if (this.state.address.onChange !== null)
                        this.setState({ updateLocation: true })
                }}

                onViewportChange={(newViewport) => this.setState(state => {
                    if (parseInt(newViewport.zoom) !== parseInt(state.viewport.zoom))
                        state.updateLocation = true

                    state.viewport = newViewport
                    return state;
                })}
            >

                {(this.props.selectLocation == true && Array.isArray(this.state.address.center) && this.state.address.center.length === 2) ? <Marker longitude={this.state.address.center[0]} latitude={this.state.address.center[1]} >
                    <IconMapMarker className="marker" style={{ position: 'relative', left: -25, top: -45 }} />


                </Marker> : undefined}

                {(this.props?.propiedad && this.props?.propiedad?.localizacion !== null && this.props.propiedad.localizacion !== undefined) ? <PropertyMarker longitude={this.props.propiedad?.localizacion?.center[0]} latitude={this.props.propiedad?.localizacion?.center[1]} propiedad={this.props.propiedad} /> : null}

                {this.props?.propiedades?.map(propiedad => (propiedad.localizacion.center !== undefined && propiedad.localizacion !== null) ? <PropertyMarker tipo={this.props.tipo} longitude={propiedad.localizacion.center[0]} latitude={propiedad.localizacion.center[1]} propiedad={propiedad} /> : null)}




                <GeolocateControl
                    style={{
                        right: 10,
                        top: 10
                    }}
                    positionOptions={{ enableHighAccuracy: true }}
                    trackUserLocation={true}

                />

            </MapGL>

        )
    }
}