import { Component } from "react";
import { List, Row, Col, Typography, Card, Button, Empty } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { FaAngleRight } from "react-icons/fa";
const { Text } = Typography;
const moment = require('moment');

/**
 *
 *
 * @export
 * @class AjustesPagos
 * @extends {Component}
 */
export default class AjustesPagos extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            data: [
                //     {
                //     _id:1,
                //     propiedad_id:{
                //         nombre:'Propiedad'
                //     },
                //     cantidad:0,
                //     fecha: new Date(),
                //     servicio:'Servicio'
                // },
                // {
                //     _id:1,
                //     propiedad_id:{
                //         nombre:'Propiedad'
                //     },
                //     cantidad:0,
                //     fecha: new Date(),
                //     servicio:'Servicio'
                // }
            ],

            currentPage: 1,
            itemCount: 0
        }
    }


    getPagos = (page) => {
        console.log('page', page)
    }

    render() {
        return (
            <List
                loading={this.state.loading}
                size="small"
                className="bg-white mt-1 w-100 p-1 mr-2 form-ajustes component-list"
                bordered={false}
                locale={{ emptyText: 'Sin Pagos Registrados' }}
                dataSource={this.state.data}
                pagination={{
                    onChange: page => {
                        this.getPagos(page)
                    },
                    pageSize: 10,
                    current: this.state.currentPage,
                    total: this.state.itemCount
                }}

                renderItem={item => <List.Item className="component-list-item">
                    <Card className="card-list shadow-sm">
                        <Row align="middle" className="w-100">
                            <Col xxl={{ span: 6, order: 1 }} xl={{ span: 6, order: 1 }} lg={{ span: 6, order: 1 }} md={{ span: 6, order: 1 }} sm={{ span: 6, order: 1 }} xs={{ span: 22, order: 1 }}>
                                <Text>{item.propiedad_id.nombre}</Text>
                            </Col>
                            <Col xxl={{ span: 5, order: 2 }} xl={{ span: 5, order: 2 }} lg={{ span: 5, order: 2 }} md={{ span: 5, order: 2 }} sm={{ span: 5, order: 2 }} xs={{ span: 11, order: 2 }}>
                                <Text>{item.cantidad}</Text>
                            </Col>
                            <Col xxl={{ span: 5, order: 4 }} xl={{ span: 5, order: 4 }} lg={{ span: 5, order: 4 }} md={{ span: 5, order: 4 }} sm={{ span: 5, order: 4 }} xs={{ span: 11, order: 6 }}>
                                <Text>{moment(item.fecha).utc().format('DD/MM/YYYY')}</Text>
                            </Col>
                            <Col xxl={{ span: 6, order: 5 }} xl={{ span: 6, order: 5 }} lg={{ span: 6, order: 5 }} md={{ span: 6, order: 5 }} sm={{ span: 6, order: 5 }} xs={{ span: 11, order: 5 }}>
                                <Text>{item.servicio}</Text>
                            </Col>
                            <Col xxl={{ span: 2, order: 6 }} xl={{ span: 2, order: 6 }} lg={{ span: 2, order: 6 }} md={{ span: 2, order: 6 }} sm={{ span: 2, order: 6 }} xs={{ span: 2, order: 4 }}>
                                <Button type="link" icon={<FaAngleRight />} className="card-list-btn"></Button>
                            </Col>
                        </Row>
                    </Card>
                </List.Item>}
            />
        )
    }
}