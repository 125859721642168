import React, { Component } from "react";
import { Col, Typography, Row, Button, Form, message } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import Busqueda from "../../../Widgets/Filtros/Busqueda";
import Mapa from "../../../Widgets/Filtros/MapaSelect";
import qs from 'qs'

import axios from 'axios'
import { FaRegHospital } from "react-icons/fa";

const { Title } = Typography;
//Responsividad del formulario
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 12 },
        lg: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 8 },
        lg: { span: 12 }
    },
};
/**
 *
 *
 * @export
 * @class Localizacion
 * @extends {Component}
 * @description Localizacion de la propiedad a crear/editar
 */
export default class Localizacion extends Component {

    static defaultProps = {
        titleVisible: true
    }

    constructor(props) {
        super(props)
        let address = qs.parse('')
        this.state = {
            loadingSave: false,
            address,
            search: ''
        }
        axios.defaults.headers.common["Authorization"] = sessionStorage.getItem('token')
    }


    componentDidMount() {
        

        console.log('this.props', this.props.propiedad.localizacion);
        if (typeof this.props.propiedad === "object" && typeof this.props.propiedad.localizacion === "object" && Array.isArray(this.props.propiedad.localizacion.center)){
            this.setState({ address: this.props.propiedad.localizacion })
        }

    }

    updateAddress = (address) => {
        this.setState({ address })

        console.log('address', address);
    }
    
    /**
     *
     *
     * @memberof Localizacion
     * @description Metodo que se ejecuta al hacer submit, manda la peticion al webService para guardar el step
     * @param values Object Localizacion
     */
    onFinish = () => {



        console.log('this.props.propiedad', this.props.propiedad);
        let address = this.state.address
        this.state.loadingSave = true;
        axios.post('/propiedad/add/localizacion', {
            id: this.props.propiedad,
 
            status: (this.props.editable === true)? undefined : 3,
            localizacion: address
        })
            .then(res => {
                console.log('res informacion step', res);
                if (res.status == 200) {
                    //message.success({ content: 'Información guardada', className: 'message-sucess' })
                    this.props.setPropiedad(res.data.data);
                    this.props.next();
                }
                else {
                    message.error({ content: 'Ha ocurrido un error, por favor intente de nuevo', className: 'messages-error' });
                }
            })
            .catch(error => {
                console.log('error', error.response);
            })
            .finally(f => {
                this.state.loadingSave = false;
            })


    }

    render() {
        return (
            <>

                {
                    (this.props.titleVisible == true) ?
                        <Row justify="start" align="middle">
                            <Col span={24} className="flex-start"> <Button type="text" shape="round" icon={<LeftOutlined />} className="btn-onback" onClick={() => this.props.prev()}></Button> <Title level={4}>Localización de la Propiedad</Title></Col>
                        </Row>

                        : null
                }



                <Form  {...formItemLayout} layout="horizontal" onFinish={this.onFinish}>
                    <Form.Item
                        labelAlign={"left"}
                        label="Ubicación de la Propiedad"
                        rules={[{ required: true }]}

                    >
                        <Busqueda
                            placeholder="Ingrese una dirección, vecindario, ciudad o código postal."
                            enterButton={true}
                            address={this.state.address}
                            onSelect={address => this.updateAddress(address)}
                        />
                    </Form.Item>
                    <Row>
                        <Col xs={24} md={24} xl={24} style={{ width: 500, height: 500 }}>
                            <Mapa
                                style={{ height: 500, minHeight: '100%' }}
                                
                                address={this.state.address}
                                // selectLocation={true}
                                onChange={({address}) => {
                                    console.log('onChangeAddress', address);
                                    this.setState({ address })
                                }}
                                />
                        </Col>
                    </Row>

                    <Form.Item wrapperCol={{ sm: { span: 12, offset: 12 } }} className="mt-1">
                        <Button type="primary" shape="round" className="btn-yellow" htmlType="submit" disabled={this.state.loadingSave}>
                            Continuar
                        </Button>
                    </Form.Item>
                </Form>


            </>
        )
    }
}