import React, { Component, useEffect, useState } from 'react'
import { Row, Col, Dropdown, Typography, Menu, Button, Avatar, Divider } from 'antd'

import axios from 'axios'

//css
import './filtros.css'
import {
	IconCheck, IconCheckNot, IconAll, IconHouse, IconCondo, IconDepa,
	IconHouseFam, IconResidencial, IconLote, IconAlmacen, IconFarm
} from '../Iconos'

import { logDOM } from '@testing-library/dom'

const { Text } = Typography;



export default function Tipos({ value, onChange }) {


	/** @type {*} SI está visible o no el dropdown */
	let [visible, setVisible] = useState(null)

	/** @type {*} SI está visible o no el dropdown */
	let [update, setUpdate] = useState(false)


	/** @type {*} Divisa actual seleccionada*/
	let [tipos, setTipos] = useState([])

	/** @type {*} Tipos Seleccionados */
	let [selectedTipos, setSelectedTipos] = useState([])

	/** @type {*} Tipos Seleccionados (para iconos e información general) */
	let [selectedTiposObject, setSelectedTiposObject] = useState({})

	/** @type {*} Divisa actual seleccionada*/
	//0 Ninguno
	//1 Todos
	//2 Intermedio
	let [todos, setTodos] = useState(false)




	/**
	 *
	 * @memberof Precio
	 *
	 * @param {*} { value = {}, onChange }
	 * @return {*}
	 * 
	 * @description "componentDidMount", obtenemos las divisas 
	 */
	useEffect(() => {
		getTipos();
	}, []);

	/**
	 *
	 * @memberof Precio
	 * @description Obtenemos la lista de divisas y si no está vacia declaramos el primer elemento por defecto. 
	 * 
	 * @method triggerChange
	 * @description AL hacer algún cambio de valor, actuqlaizamos para el formulario de ANTD
	 */
	const triggerChange = (changedValue) => {
		if (typeof onChange === "function")
			onChange({ selectedTipos, ...value, ...changedValue });
	}


	/**
	 *
	 * @memberof Precio
	 *
	 * @description Obtenemos la lista de divisas y si no está vacia declaramos el primer elemento por defecto. 
	 */
	const getTipos = () => {
		axios.get('/tipo-propiedades')
			.then(({ data }) => {

				setTipos(data.data)
				let selectAll = {}
				const array = data.data.map(tipo => {
					selectAll[tipo._id] = tipo
					return tipo._id
				})
				setSelectedTipos(array)
				setSelectedTiposObject(selectAll)
			})
	}





	/**
	 *
	 * Agregamos un TIPO al arreglo de tipos
	 * @param {*} id
	 */
	const setTipo = (id, tipo) => {


		let index = selectedTipos.findIndex(selected => id === selected)

		console.log('index', index);

		// let tempTipos = selectedTipos
		if (index !== -1) {

			selectedTipos.splice(index, 1)
			delete selectedTiposObject[id]
		} else {
			selectedTipos.push(id)
			selectedTiposObject[id] = tipo
		}

		setSelectedTipos(selectedTipos)
		setSelectedTiposObject(selectedTiposObject)

		console.log({
			selectedTipos,
			selectedTiposObject
		});
		setUpdate(!update)
		triggerChange({ selectedTipos })

	}



	/**
	 *
	 * @description Agregamos al arreglo de seleccionados todos los IDs
	 *
	 */
	const setTodo = () => {

		if (selectedTipos.length == tipos.length) {

			selectedTipos = []
			selectedTiposObject = {}

		} else {

			let selectAll = {}
			selectedTipos = tipos.map(tipo => {
				selectAll[tipo._id] = tipo
				return tipo._id
			})
			setSelectedTiposObject(selectAll)
		}

		console.log({
			selectedTipos,
			selectedTiposObject
		});
		setSelectedTipos(selectedTipos)
		setUpdate(!update)
		triggerChange({ selectedTipos })
	}


	const renderTipos = () => {
		return "Tipos"

	}


	return (
		<Dropdown
		
			overlay={<Menu className="dropdown-tipo">
				<Row gutter={[12, 12]}>
					<Col span={24} className="mb-1">
						<Text className="dropdown-title" style={{fontWeight:"700", paddingLeft:"25px"}}>Tipos de Propiedades</Text>
					</Col>

					<Col span={24} onClick={e => setTodo()}>
						<a className="btn-tipo" >
							<div className={`svg-div`}>
								<IconCheck className={`svg-check ${(selectedTipos.length == tipos.length) ? 'active' : ''}`} />

								<IconCheckNot className={`svg-check ${(selectedTipos.length != tipos.length) ? 'active' : ''}`} />
							</div>

							<div className="div-text">
								<Text> {(selectedTipos.length == tipos.length) ? "Borrar selección" : "Seleccionar todos"  }</Text>
							</div>
						</a>
					</Col>
					<Col span={24}>
						<Divider />
					</Col>
					{/* selectedTipos, setSelectedTipos */}

					{tipos.map(tipo => <Col 
						span={24} onClick={e => setTipo(tipo._id, tipo)}
					>
						<a className="btn-tipo" >
						  <div className={`svg-div `}>
								{/* <img src={axios.defaults.baseURL + '/tipos/' + tipo.icon} alt={tipo.nombre} style={{ maxHeight: 80 }} /> */}
								<IconCheck className={`svg-check ${(selectedTipos.includes(tipo._id)) ? 'active' : ''}`} />
								<IconCheckNot className={`svg-check ${ !(selectedTipos.includes(tipo._id)) ? 'active' : ''}`} />
							</div>  

							<div className="div-text">
								<Text>{tipo.nombre}</Text>
							</div>
						</a>
					</Col>
					)}

				</Row>
			</Menu>}
			onVisibleChange={setVisible}
			visible={visible}
			placement="bottomRight"
			trigger={['click']}
		>
			<Button style={{ width: '100%' }}> {renderTipos()} </Button>
		</Dropdown>
	)


}
