import React, { Component } from 'react'
import { Layout, Row, Col, Button, Input, Select, Typography, Card, message, Pagination, Spin } from 'antd'
import { UnorderedListOutlined, AppstoreFilled } from '@ant-design/icons';
import axios from 'axios';
//compoenentes
import { CantidadDefinida, Precio, Extras, Venta, Tipos } from './../../Widgets/Filtros/Filtros'
import { InmuebleCard, InmuebleCardProBig } from './../../Widgets/cards/Cards'
import { User, SetUser } from '../../../Context/User'
import ModalInmueble from '../../Public/Modals/ModalInmueble'

const { Content } = Layout;
const { Title } = Typography;
const { Search } = Input;
const { Option } = Select;


/**
 *
 *
 * @export
 * @class PropiedadesGuardadas
 * @extends {Component}
 * @description Componente donde se listan las propiedades que el usuario da "like"
 */
export default class PropiedadesGuardadas extends Component {

	static contextType = User


	/**
	 *Creates an instance of PropiedadesGuardadas.
	 * @param {*} props
	 * @memberof PropiedadesGuardadas
	 */
	constructor(props) {
		super(props);
		this.state = {
			view: 'bloqs',
			propiedades: [],
			initial: true,
			loading: false,
			modalInmuebleVisible: false,
			favs: undefined,
		}
	}

	componentDidMount(){
        this.getPropiedadesGuardadas(1)
    }

    /**
     * @memberof PropiedadesGurdadas
     * 
     * @method getPropiedadesGuardadas
     * @description retorna las propiedades en el que el usuario LOGEADO haya dado en favoritos
     */
    getPropiedadesGuardadas = (page) => {
    	this.setState({loading:true})
        axios.get('/usuario/guardadas',{
            params:{
                page: page,
                limit: 10,
                precio: this.state.precio,
                habitaciones: this.state.habitaciones,
                baños: this.state.baños,
                tipo_propiedad: this.state.tipo_propiedad,
                extras: this.state.extras,
                tipo_pago: this.state.tipo_pago,
                sort: this.state.sort
            }
        }).then(response => {
        	console.log(response.data)
           this.setState({
           		favs: response.data.favs, //array con ids de propiedades guardadas
           		propiedades: response.data.data.itemsList,
           		initial: false,
           		currentPage: response.data.data.page,
           		itemCount: response.data.data.total,
           		pageCount: response.data.data.pageCount,
           	})
        }).catch(error => {
            console.log(error)
            message.error('Error al registrar')
        }).finally(()=>{
        	this.setState({loading: false, initial:false})
        })
    }


     /**
     * @memberof PropiedadesGurdadas
     * 
     * @method deleteFav
     * @description elimina una propiedad guardada del usuario LOGEADO haya dado en favoritos
     */
    deleteFav = (propiedad_id) => {
    	this.setState({loading:true})
        axios.post('/usuario/guardadas/delete',{
            propiedad_id
        }).then(response => {
        	console.log(response.data)
           this.getPropiedadesGuardadas(1)
        }).catch(error => {
            console.log(error)
            message.error('Error al eliminar de guardados')
        }).finally(()=>{
        	this.setState({loading: false})
        })
    }

    /**
     * @memberof PropiedadesGurdadas
     * 
     * @method onRegister
     * @description Se ejecuta cuando da click en las propiedaes, para abrir el modal
     */
    onClick = (propiedad) => {
    	this.setState({
    		modalInmuebleVisible: true,
    		propiedad_id: propiedad._id,

		})
	}

	/**
	 * @memberof PropiedadesGuardadas
	 * 
	 * @method renderView
	 * @description renderiza el tipo de vista
	 */
	renderView = (view, propiedades) => {
		if (view === 'bloqs') {
			return <Row gutter={[20, 20]}>
				{
					propiedades.map(propiedad => {
						return <Col xs={24} sm={12} lg={8} >
							<InmuebleCard {...propiedad} onClick={() => { this.onClick(propiedad) }} />
						</Col>
					})
				}
			</Row>
		} else {
			return <Row gutter={[20, 20]}>
				{
					propiedades.map(propiedad => {
						return <Col xs={24} className="">
							<InmuebleCardProBig {...propiedad} onDelete={()=>{this.deleteFav(propiedad._id)}}/>
						</Col>
					})
				}
			</Row>
		}
	}

	/**
     * @memberof Propiedades
     * 
     * @method onChangePrice
     * @description filtra al al cambiar el precio
     * @param precio {min, max, divisa_id}
     */
	onChangePrice = (precio) => this.setState({precio},()=>{this.getPropiedadesGuardadas(1)})

	/**
     * @memberof Propiedades
     * 
     * @method onChangeHabitaciones
     * @description filtra al cambiar el numero de habitaciones seleccionadas
     * @param habitaciones: puede ser un objeto o un numero para hacer le filtrado de habitaciones
     */
	onChangeHabitaciones = (habitaciones) => this.setState({habitaciones},()=>{ this.getPropiedadesGuardadas(1)})

	/**
     * @memberof Propiedades
     * 
     * @method onChangeBaños
     * @description filtra al cambiar el numero de baños seleccionados
     * @param habitaciones: puede ser un objeto o un numero para hacer le filtrado de baños
     */
	onChangeBaños = (baños) => this.setState({baños},()=>{this.getPropiedadesGuardadas(1)})

	/**
     * @memberof Propiedades
     * 
     * @method onChangeTipoVenta
     * @description filtra al cambiar el tipo_pago y sus caracteristcas
     */
	onChangeTipoVenta = (tipo_pago) => this.setState({tipo_pago},()=>{this.getPropiedadesGuardadas(1)})

	/**
     * @memberof Propiedades
     * 
     * @method onChangeTipoVivienda
     * @description filtra al cambiar el tipo de vivienda seleccionada
     */
	onChangeTipoVivienda = (tipo_propiedad) => this.setState({tipo_propiedad},()=>{ this.getPropiedadesGuardadas(1)})

	/**
     * @memberof Propiedades
     * 
     * @method onChangeTipoVivienda
     * @description filtra al cambiar los valores de el filtro de extras
     */
	onChangeExtras = (extras) => this.setState({extras},()=>{this.getPropiedadesGuardadas(1)})



	render() {

		const { view } = this.state;

		return (
			<Content className="container-pro">
				<Card>
					<Row>
						<Col span={24}>
							<Title level={3} style={{ fontWeight: '500', fontSize: '25px' }}>Propiedades Guardadas</Title>
						</Col>
					</Row>
					<Row gutter={[12, 12]}>
						<Col className="gutter-row" xl={5} xxl={8}>
							<Search className="input-search" />
						</Col>
						<Col className="gutter-row" xl={2} xxl={2}>
							<Venta onChange={this.onChangeTipoVenta} />
						</Col>
						<Col className="gutter-row" xl={2} xxl={2}>
							<Precio onChange={this.onChangePrice} />
						</Col>
						<Col className="gutter-row" xl={4} xxl={3}>
							<CantidadDefinida onChange={this.onChangeHabitaciones} nombre="Habitaciones"/>
						</Col>
						<Col className="gutter-row" xl={2} xxl={2}>
							<CantidadDefinida onChange={this.onChangeBaños} nombre="Baños"/>
						</Col>
						<Col className="gutter-row" xl={2} xxl={2}>
							<Tipos onChange={this.onChangeTipoVivienda} />
						</Col>
						<Col className="gutter-row center" xl={7} xxl={5}>
							<Extras onChange={this.onChangeExtras} />
							<Button className="btn-primary">
								Buscar
							</Button>
						</Col>
					</Row>
				</Card>
				<Row>
					<Col span={24} className="pt-2 pb-2 flex-end">
						<Select defaultValue="1" className="mr-1" onChange={(value)=>{this.setState({sort: value},()=>this.getPropiedadesGuardadas(1))}}>
							<Option value="-1">Mas Recientes</Option>
							<Option value="1">Mas Antiguos</Option>
						</Select>
						<Button onClick={() => { this.setState({ view: 'bloqs' }) }}>
							<AppstoreFilled
								style={{ color: view === 'bloqs' ? '#3333cc' : '#000' }}
							/>
						</Button>
						<Button onClick={() => { this.setState({ view: 'lista' }) }} >
							<UnorderedListOutlined
								style={{ color: view === 'lista' ? '#3333cc' : '#000' }}
							/>
						</Button>
					</Col>
				</Row>
				<Spin spinning={this.state.loading}>
					<Row align="center">
						<Col span={22}>
							{this.renderView(view, this.state.propiedades)}
						</Col>

					</Row>
					<Row>
						<Pagination className="mt-3"
							total={this.state.itemCount}
							current={this.state.currentPage}
							onChange={(page) => {
								this.getPropiedadesGuardadas(page)
							}} />
					</Row>


				</Spin>



				<ModalInmueble
					visible={this.state.modalInmuebleVisible}
					onCancel={() => {
						this.setState({ modalInmuebleVisible: false, propiedad_id: undefined })
						this.getPropiedadesGuardadas(1)
					}}
					propiedad_id={this.state.propiedad_id}
					favs={this.state.favs}
				/>
			</Content>
		)
	}
}