import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { Button, Row, Col, Typography, Form, Input, Upload, message, Image, Space, } from 'antd';

import { LeftOutlined, InboxOutlined, EllipsisOutlined, DeleteOutlined, RadiusBottomrightOutlined } from '@ant-design/icons';


import { } from 'react-icons';

import { Redirect } from "react-router-dom";

import axios from 'axios';
import Muuri from 'muuri';

import ModalAccion from './Imagenes/ModalAccion';

import { IconDotsDrag, } from '../../../Widgets/Iconos'


import "../../../../styles/steps/imagenes.css";

// const ResponsiveGridLayout = WidthProvider(Responsive);
const { Title, Text } = Typography;


let grid

/**
 *
 *
 * @export
 * @class Imagenes
 * @extends {Component}
 * @description Imagenes de la propiedad a crear/editar
 */
export default class ImagenesContainer extends Component {




    static defaultProps = {
        titleVisible: true,
        save: true
    }



    grid = React.createRef()

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            redirect: false,
            imagenes: [],

            visible: false,
            
            imagen: null
        }

        axios.defaults.headers.common["Authorization"] = sessionStorage.getItem('token')
    }


    componentDidMount() {
        this.init()
        this.getImages();
    }


    /**
     *
     * @memberof ImagenesContainer
     * 
     * @member init
     * @description Iniciamos el GRID
     */
    init = () => {
        grid = new Muuri('.grid', {
            dragEnabled: true,
            dragContainer: this.ref,
            dragSort: function (e, f) {

                console.log({ e, f });
                return [grid]
            },

            dragHandle: '.item-dragabble-handle',
            layoutEnd: (e, f) => { console.log({ e, f }) }
        })
    }

    /**
     *
     *
     * @param {*} values
     * @memberof ImagenesContainer
     * 
     * @method onFinish
     */
    onFinish = (values) => {
        message.success('¡Propiedad Creada!');
        // this.setState({ redirect: true })
        
        if(this.props.save)
            this.props.history.push('/professionals/dashboard')
    }

    /**
     *
     *
     * @memberof ImagenesContainer
     */
    redirect = () => {
        if (this.state.redirect) {
            return <Redirect to="/pro/propiedades" />
        }
    }
    /**
     * 
     * @method getImages
     * @description  Obtenemos las imagenes y actualizamos el preview.
     */
    getImages = () => {
        let { source } = this;
        if (source) {
            console.log('cancelado');
            source.cancel();
        }
        const CancelToken = axios.CancelToken;
        source = CancelToken.source()
        this.source = source
        this.setState({ loading: true })

        axios.get('/propiedad/get', {
            params: {
                id: this.props.propiedad._id
            },
            cancelToken: source.token,
            onCancel: () => this.setState({ loading: false })
        })
            .then(({ data }) => {


                this.props.setPropiedad(data.data);

                const items = grid.getItems();

                this.imagen_representantiva = data.data.imagen_representantiva

                if (Array.isArray(data.data.imagenes)) {
                    for (const imagen of data.data.imagenes) {
                        console.log('item._element.dataset.mongoId', imagen._id);

                        let element = items.findIndex(item => {
                            console.log('item', item._element.dataset.mongoId);
                            return item._element.dataset.mongoId == imagen._id
                        })

                        console.log('item._element.dataset.mongoId', element);
                        if (element == -1) {
                            this.addImagen(imagen)
                        }
                    }
                }

            })
            .catch(y => {
                console.log('YY', y);
            })

    }

    observers = {}
    imagenes = {}
    //Imagen Representantiva

    /**
     * 
     * @param {*} id 
     * @description Eliminamos una imagen
     * 
     * @param id
     */
    deleteImage = (item, imagen) => {


        console.log('deleteImage');
        axios.post('/propiedad/add/imagenes/delete', {
            id: this.props.propiedad._id,
            imagen_id: imagen._id,

            status: (this.props.editable === true)? undefined : 5
        })
            .then(({ data }) => {

                console.log('data',data);
                item?.parentNode.removeChild(item);
                grid.refreshItems()
                grid.layout()
                this.getImages()
                setTimeout(e => this.updateOrder = true, 600)
            })
            .catch(y => {
                console.log('YY', y);
            })


    }


    /**
     *
     *
     * @memberof ImagenesContainer
     * 
     * @description Actualizamos las imagenes conforme al orden, y los tamaños
     */
    updateImages = async () => {
        console.log('updateImages');
        let { sourceImages } = this;

        if (sourceImages)
            sourceImages.cancel()

        const CancelToken = axios.CancelToken;
        sourceImages = CancelToken.source();

        this.sourceImages = sourceImages;

        this.setState({ loading: true })

        axios.post('/propiedad/add/imagenes/list', {
            id: this.props.propiedad?._id,
            imagenes: grid.getItems().map(item => {
                return this.imagenes[item._element.dataset.mongoId]
            }),
            imagen_representantiva: this.imagen_representantiva,
            status: (this.props.editable === true)? undefined : 5
        }, {
            cancelToken: sourceImages.token,
            onCancel: () => this.setState({ loading: false })
        })
            .then(({ data }) => {
                console.log('data',);
                this.props.setPropiedad(data.propiedad)
            })
            .catch(y => {

            })
    }

    /**
     *
     *
     * @param {*} imagen
     * @memberof ImagenesContainer
     * 
     * @method setObserver
     * @description Declaramos el observer para analizar los cambios de tamano. 
     */
    setObserver = (imagen, gridItem) => {

        //Seleccionamos la clase mutacion
        const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

        //Obtenemos los elementos a draggear
        const list = document.querySelector("#item-dragabble-" + imagen._id);

        //Configuramos
        const config = {
            attributes: true,
            childList: true,
            characterData: true,
            subtree: true,
        };

        // Declaramos los observadores-
        if (this.observers[imagen._id]) {
            this.observers[imagen._id].disconnect();
        }

        //Para poder utilizar la variable
        const comp = this;

        //Almacenará el metodo para que ajuste las imagenes.
        let resizerTimer;

        //Llenamos el objeto de observadores
        this.observers[imagen._id] = new MutationObserver((mutations) => {

            //Iteramos aquellos cambios, y buscamos el donde hayan cambiado los atributos.
            mutations.forEach(function (mutation) {
                if (mutation.type === "attributes") {
                    let {
                        width,
                        height,
                    } = list.style;
                    let style = {
                        width,
                        height
                    }

                    //Refrescamos los grids, para ajustar el orden de imagenes
                    grid.refreshItems(gridItem, true)
                    grid.layout()

                    //Si hay un metodo, lo borramos para dar lugar al nuevo timer, si hay otro nuevo, se cancelará y así sucesivamente hasta que uno se ejecute completamente y no sea cancelado.
                    if (resizerTimer)
                        clearTimeout(resizerTimer);


                    resizerTimer = setTimeout(function () {


                        //Si el updateOrder esta activo, omitiremos la actualización hasta que sea otra cosa

                        console.log('comp.updateOrder',comp.updateOrder);
                        if (comp.updateOrder == true) return

                        //Si no existe la imagen, la agregamos al objeto
                        if (!comp.imagenes[imagen._id])
                            comp.imagenes[imagen._id] = imagen


                        //Actualizamows el witdh
                        if (mutation.target.offsetWidth > ((comp.ref.offsetWidth - 10) / 2)) {
                            mutation.target.style.width = `calc(100% - 10px)`
                            comp.imagenes[imagen._id].tamano.w = 2
                        } else {
                            mutation.target.style.width = `calc(50% - 10px)`
                            comp.imagenes[imagen._id].tamano.w = 1
                        }


                        //Actualizamos el HEIGHT del ITEM,
                        const mutationTargetOffsetHeight = mutation.target.offsetHeight
                        const currentHeight = mutationTargetOffsetHeight / 250
                        const intCurrentHeight = parseInt(currentHeight)
                        const residuo = (currentHeight - intCurrentHeight)
                        const height = ((intCurrentHeight * 250) + ((residuo > 0.5) ? 250 : 0))

                        //Actualizamos las mutaciones
                        mutation.target.style.height = ((height < 250) ? 250 : height) + 'px'
                        comp.imagenes[imagen._id].tamano.h = intCurrentHeight + ((residuo > 0.5) ? 1 : 0)

                        //Actualizamos el GRID.
                        grid.refreshItems(gridItem, true)
                        grid.layout()

                        //Actualizamos las imagenes.
                        comp.updateImages()
                    }, 400);
                }
            });
        });
        //Agregamos los observadores a la imagen.
        this.observers[imagen._id].observe(list, config);
    }


    /**
     *
     *
     * @param {*} imagen
     * @memberof ImagenesContainer
     * 
     * @method addImagen
     * @description Agregamos una imagen al item draggable.
     */
    addImagen = (imagen) => {
        const id = "item-dragabble-" + imagen._id;

        const item = document.createElement('div')
        item.className = "item"
        item.dataset.mongoId = imagen._id
        item.dataset.filenameServer = imagen.filename_server;
        item.dataset.filename = imagen.filename;
        item.dataset.tamanoW = imagen.tamano.w
        item.dataset.tamanoH = imagen.tamano.h
        item.style.width = `calc(${((imagen.tamano.w == 2) ? 100 : 50)}% - 10px)`
        item.style.height = 250 * imagen.tamano.h + 'px'
        item.id = id
        this.imagenes[imagen._id] = imagen
        // item.onresize = this.updateImagen
        this.renderButtons(item, imagen)
        const gridItem = grid.add(item)
        this.setObserver(imagen, gridItem)
    }


    renderButtons = (item, imagen) => {


        ReactDOM.render([
            <div className="background-card" style={{ background: `#fff url(${axios.defaults.baseURL + '/upload/' + imagen.filename_server}) center center/auto no-repeat` }}></div>,
            <Row className="buttons-main-container" style={{ width: '100%', height: '100%' }}>
                <Col span={4}>
                    <Space style={{
                        paddingLeft: 15,
                        float: 'right',
                        position: 'relative',
                        top: -18,
                        width: '100%'
                    }}>
                        <IconDotsDrag className="item-dragabble-handle" color='#8180C8' style={{ width: 25, height: 25, transform: 'rotate(-90deg)' }} />
                    </Space>
                </Col>
                <Col span={20} className="button-content">
                    <Button className="button button-delete" size="large" type="link" danger icon={<DeleteOutlined onClick={() => this.deleteImage(item, imagen)} />} />
                   
                   {/* Boton deshabilitado por falta de funcionalidad integrada */}
                    {/* <Button className="button button-options" size="large" type="link" icon={<EllipsisOutlined />} onClick={() => {
                        this.setState({ visible: true, imagen: item })
                    }} /> */}
                </Col>
            </Row>,
            <Row>

            </Row>
        ],
            item
        )

    }


    /**
     *
     *
     * @param {*} e
     * @memberof ImagenesContainer
     * @method normFile
     * 
     * @description Actualizamos las imagenes si el drawer se actualiza.
     */
    normFile = (e) => {
        const { file, fileList } = e;

        let actualizazImagenes = true

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].status !== "done") {
                // Si alguna imagen no ha terminado
                actualizazImagenes = false;
                break;
            }
        }

        if (actualizazImagenes == true)
            this.getImages()

        if (Array.isArray(e)) {
            return e;
        }

        return e && e.fileList;
    }


    /**
     * @memberof ImagenesContainer
     * @var formImagenes
     * 
     * @description Referencia del formulario
     */
    formImagenes = React.createRef()

    /**
     * @memberof ImagenesContainer
     * @var ref
     * 
     * @description Referenciaa al GRID
     */
    ref = null

    render() {

        return [


            // (
            //     (this.props.titleVisible == true) ?
            //         <Row className="header-imagenes" justify="start" align="middle">
            //             <Col span={24} className="flex-start"> <Button type="text" shape="round" icon={<LeftOutlined />} className="btn-onback" onClick={() => this.props.prev()}></Button> <Title level={4}>Imágenes de la Propiedad</Title></Col>
            //         </Row>
            //         : null
            // ),
            <Form className="form-imagenes" layout="vertical" onFinish={this.onFinish} ref={this.formImagenes}>
                <Row gutter={[0, 0]}  >
                    <div id="grid" class="grid" ref={ref => this.ref = ref} />
                </Row>
                <Form.Item
                    name="dragger"
                    valuePropName="fileList"
                    getValueFromEvent={this.normFile}
                    className="content-uploader"
                >
                    <Upload.Dragger
                        multiple={true}
                        name="files"
                        action={axios.defaults.baseURL + "/propiedad/add/imagenes?id=" + this.props.propiedad?._id}
                        className="avatar-uploader"
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Haga click o arrastre las imagenes de la propiedad.</p>
                        <p className="ant-upload-hint">Puede acomodar las imagenes.</p>
                    </Upload.Dragger>
                </Form.Item>
                <Form.Item labelAlign={"left"} label="URL de Tour 3D">
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ sm: { span: 12, offset: 12 } }}>
                    <Button type="primary" shape="round" className="btn-yellow" htmlType="submit">   Finalizar  </Button>
                </Form.Item>

                <ModalAccion
                    visible={this.state.visible}
                    onCancel={() => this.setState({ visible: false, imagen: null })}
                    onSuccess={() => this.setState({ visible: false, imagen: null  })}
                />
            </Form>
        ]
    }
}