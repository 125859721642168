import React, { Component } from 'react';
import { Row, Col, Radio, Button, Divider, Typography, Modal, Tabs, List, Steps, Popover, message, Input, Result, Form } from 'antd'
import InputPhone from '../../Widgets/Inputs/InputPhone/InputPhone';
import Paypear from '../../Widgets/paypear/Paypear';

import axios from 'axios'

//componentes
import {
    IconCloseModal,
    IconAgenteMdlCnt, IconHouseMdlCnt, IconOtherMdlCnt,
    IconLupaMdlcnt,
    IconCasaMdlcnt,
    IconUserMdlcnt,
    IconHelpMdlcnt,
    IconArrowLeftMdlcnt,
    IconArrowRightMdlcnt,
} from '../../Widgets/Iconos'

//css
import '../../../styles/Modals.css'

const { Title, Text } = Typography;
const { Step } = Steps;
const { TabPane } = Tabs;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 12, offset: 6 },
        md: { span: 12, offset: 6 },
        lg: { span: 12, offset: 6 },
        xl: { span: 12, offset: 6 },
        xxl: { span: 12, offset: 6 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12, offset: 6 },
        md: { span: 12, offset: 6 },
        lg: { span: 12, offset: 6 },
        xl: { span: 12, offset: 6 },
        xxl: { span: 12, offset: 6 }
    },
};


/**
 *
 *
 * @export
 * @class ModalContacto
 * @extends {Component}
 */
export default class ModalContacto extends Component {


    /**
     *Creates an instance of ModalContacto.
     * @param {*} props
     * @memberof ModalContacto
     */
    constructor(props) {
        super(props)

        this.state = {
            paso: 0,
            pasoInicial: 0,
            loading: true,
            paquetes: [
                {
                    _id: 1,
                    nombre: "PAQUETE 1",
                    precio: 0,
                    descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor iveniam"
                },
                {
                    _id: 2,
                    nombre: "PAQUETE 2",
                    precio: 0,
                    descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor iveniam"
                },
                {
                    _id: 3,
                    nombre: "PAQUETE 3",
                    precio: 0,
                    descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor iveniam"
                }
            ],

            nombres: "",
            apellidos: "",
            correo: "",
            telefono: "",
            paquete: "",

        }
    }

    formOpcionInicial = React.createRef();

    formPaso1 = React.createRef();
    formPaso2 = React.createRef();
    formPaso3 = React.createRef();
    formPaso4 = React.createRef();
    paypear = React.createRef();




    /**
     *
     *
     * @memberof ModalContacto
     */
    componentDidUpdate = async () => {

        let visible = this.props.visible;
        let dataUser = this.props.dataUser;
        let tipo = "";

        if (visible && this.state.pasoInicial === 0 && dataUser != null) {

            dataUser.agencia_id = null;
            //Nuevo registro
            if (dataUser.agencia_id === undefined || dataUser.agencia_id === null) {
                setTimeout(() => {
                    document.getElementsByClassName("ant-tabs-tab")[3].click();
                }, 100);
            }

            else {
                tipo = dataUser.agencia_id.tipo;

                // Tipo 1 = agencia
                if (tipo === 1) {
                    setTimeout(() => {
                        document.getElementsByClassName("ant-tabs-tab")[2].click();
                    }, 100);
                }
                // Tipo otro
                else {
                    setTimeout(() => {
                        document.getElementsByClassName("ant-tabs-tab")[1].click();
                    }, 100);
                }
            }
        }
    }

    /**
     *
     *
     * @memberof ModalContacto
     * @description Avanza al siguiente paso del step
     */
    next = () => {
        let paso = this.state.paso;
        this.setState({
            paso: (paso + 1)
        })

    }

    /**
     *
     *
     * @memberof ModalContacto
     * @description Regresa al paso anterior del step
     */
    prev = () => {
        let paso = this.state.paso;
        this.setState({
            paso: (paso - 1)
        })
    };

    /**
     *
     *
     * @memberof ModalContacto
     * @description Renderiza el tab correspondiente, segun la opcion escogida
     * @param val String
     */
    SeleccionarOpcion = (val) => {
        switch (val.radio_opcion) {
            case "agente":
                document.getElementsByClassName("ant-tabs-tab")[3].click()
                break;

            case "comprador":
                document.getElementsByClassName("ant-tabs-tab")[2].click()
                break;

            case "otra":
                document.getElementsByClassName("ant-tabs-tab")[1].click()
                break;

            default:
                break;
        }
    }

    /**
     *
     *
     * @memberof ModalContacto
     * @description Guarda la informacion del agente con la agencia
     */
    guardarAgencia = async () => {

        let { nombre, telefono, usuario_id } = this.state;
        axios.post('/agencias/add', {
            params: {
                nombre,
                telefono,
                usuario_id,
                tipo: 1,
                rating: 0,
            }
        })
            .then(response => {

                if (response.data.success) {
                    message.success(response.data.message);
                    let paso = this.state.paso;
                    this.setState({ paso: paso + 1 });

                    setTimeout(() => {
                        this.setState({ paso: 0 });
                        this.props.onCancel()
                    }, 500);

                }
                else {
                    message.error({ content: "Error.", className: 'message-error' })
                }

            }).catch(error => {
                console.log('error al guardar agencia', error)
                message.error({ content: 'Error al guardar agencia, el campo nombre es único e irrepetible.', className: 'message-error' })
            })



    }


    saveStep1 = (values) => {
        this.formPaso1.current.setFieldsValue({
            nombres: values.nombres,
            apellidos: values.apellidos
        })
        this.next();
    }

    saveStep2 = (values) => {
        this.formPaso2.current.setFieldsValue({
            correo: values.correo
        })
        this.next();
    }

    saveStep3 = (values) => {
        this.formPaso3.current.setFieldsValue({
            telefono: values.phone?.phone,
            lada: values.phone?.phonecode,
        })
        this.next();
    }

    saveStep4 = (values) => {
        this.formPaso4.current.setFieldsValue({
            paquete: values.paquete,
        })
        this.next();
    }

    

    render() {

        const footer = (
            <Row className="row-info center w-100" justify="center">
                <Col span={24}>
                    <Divider className="txt-gray">ó</Divider>
                </Col>
                <Col span={24}>
                    <Text>¡Comunícate con nosotros al <span className="text-blue" > +52 664-874-1212! </span> </Text>
                </Col>
                <Col span={24} className="pd-10px">
                    <Text> Tu información es segura con nosotros. <br /> Visita nuestro  <span className="text-blue"> aviso de privacidad. </span></Text>
                </Col>
            </Row>
        )

        const customDot = (dot, { status, index }) => (
            <Popover style={{ display: "none" }}  >
                {dot}
            </Popover>
        );

        let current = this.state.paso;

        const steps = [
            {
                title: '¿Cuál es tu nombre?',
                content: <Col span={24}>
                    <Form layout="vertical" {...formItemLayout} requiredMark={false} className="frm-contacto" onFinish={this.saveStep1} ref={this.formPaso1} >
                        <Form.Item label="Nombre" name="nombres"
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese nombre"
                            }]}
                        >
                            <Input className="input_form" placeholder="Nombre" />
                        </Form.Item>
                        <Form.Item label="Apellidos" name="apellidos"
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese apellidos"
                            }]}
                        >
                            <Input className="input_form" placeholder="Apellidos" />
                        </Form.Item>
                        <Form.Item className="center pd-30px">
                            <Button type="primary" shape="round" htmlType="submit" className="btn-limon br-20px"> Siguiente </Button>
                        </Form.Item>
                    </Form>
                </Col>,
                footer: 'Este nombre lo usaremos como tu nombre de agente o  como representante de tu agencia.'
            },
            {
                title: '¿Cuál es tu correo electrónico?',
                content: <Col span={24}>
                    <Button className="btnArrowCircle left" title="Atras" onClick={() => this.prev()}>
                        <IconArrowLeftMdlcnt />
                    </Button>
                    <Form layout="vertical" {...formItemLayout} requiredMark={false} className="frm-contacto" onFinish={this.saveStep2} ref={this.formPaso2} >
                        <Form.Item label="Correo Electrónico" name="correo"
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese correo electrónico"
                            }]}
                        >
                            <Input className="input_form" placeholder="Correo@gmail.com" />
                        </Form.Item>
                        <Form.Item className="center pd-30px">
                            <Button type="primary" shape="round" htmlType="submit" className="btn-limon br-20px"> Siguiente </Button>
                        </Form.Item>
                    </Form>
                </Col>,
                footer: 'Este correo lo usaremos como tu correo de agente o como representante de tu agencia.'
            },
            {
                title: '¿Cuál es tu número telefónico?',
                content: <Col span={24}>
                    <Button className="btnArrowCircle left" title="Atras" onClick={() => this.prev()}>
                        <IconArrowLeftMdlcnt />
                    </Button>
                    <Form layout="vertical" {...formItemLayout} requiredMark={false} className="frm-contacto" onFinish={this.next} ref={this.formPaso3} >
                        <Form.Item label="Número de telefono" name="telefono"
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese correo electróninco"
                            }]}
                        >
                            <InputPhone className="input_form" />
                        </Form.Item>
                        <Form.Item className="center pd-30px">
                            <Button type="primary" shape="round" htmlType="submit" className="btn-limon br-20px"> Siguiente </Button>
                        </Form.Item>
                    </Form>
                </Col >,
                footer: 'Este número telefónico lo usaremos como tu número de agente o como representante de tu agencia.'
            },
            {
                title: 'Elige tu Paquete',
                content: <Col span={24}>
                    <Button className="btnArrowCircle left" title="Atras" onClick={() => this.prev()}>
                        <IconArrowLeftMdlcnt />
                    </Button>
                    <Form layout="horizontal" requiredMark={false} className="frm-contacto" onFinish={this.saveStep4} ref={this.formPaso4} >
                        <Form.Item name="paquete" className="w-100">
                            <Radio.Group buttonStyle="solid" className="grupoRadio-mdlCnt-paquetes">
                                <Row>
                                    <Col span={24}>
                                        {this.state.paquetes.map(paquete => (
                                            <Radio.Button className="radioButton rdbtn-paquete" value={paquete._id}>
                                                <Row className="w-100">
                                                    <Col span={24} className="col-nombre">
                                                        {paquete.nombre}
                                                    </Col>
                                                    <Col span={24} className="col-precio">
                                                        <Title> {paquete.precio} </Title>
                                                        <Text>MXN</Text>
                                                    </Col>
                                                    <Col span={24} className="col-descripcion">
                                                        <Text>{paquete.descripcion} </Text>
                                                    </Col>

                                                    <Col span={24} className="col-boton">
                                                        <Button className="btn-morado">Adquirir</Button>
                                                    </Col>

                                                </Row>
                                            </Radio.Button>
                                        ))}
                                    </Col>
                                </Row>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item className="center pd-30px">
                            <Button type="primary" shape="round" htmlType="submit" className="btn-limon br-20px"> Siguiente </Button>
                        </Form.Item>
                    </Form>
                </Col>,
                footer: ''
            },
            {
                title: 'Pago',
                content: <Col span={24}>
                    <Button className="btnArrowCircle left" title="Atras" onClick={() => this.prev()}>
                        <IconArrowLeftMdlcnt />
                    </Button>

                    <Paypear
                        ref={this.paypear}
                        width="90%"
                        total={0}
                        type="stripe"
                        user={null}
                        additionalCharges={[
                            {
                                type: 'percentage',
                                operation: '+',
                                quantity: 16,
                                description: 'Impuesto IVA'
                            },
                        ]}
                        paymentHandledSubmit={(token) => {
                            console.log('token', token)
                        }}
                    />
                </Col>
            },
            {
                title: '¡Felicidades, has finalizado el registro!',
                content: <Col span={24}>
                    <Row className="w-100 center">
                        <Col>
                            <Result
                                className="resultado-success"
                                status="success"
                                title="Ahora puedes empezar a ofrecer tus propiedades"
                                subTitle="!Gracias por su confianza¡"
                                extra={[
                                    <Button className="btn-mdl-finalizar"
                                        id="btn-mdl-finalizar"
                                        onClick={() => {
                                            message.success('¡Completado!');
                                            setTimeout(() => {
                                                this.setState({ paso: 0 });
                                                this.props.onCancel()
                                            }, 500);
                                        }
                                        }
                                        key="finalizar">Finalizar</Button>,
                                ]}
                            />
                        </Col>
                    </Row>
                </Col>
            },
        ];

        return (
            <Modal
                className="modal-contacto"
                visible={this.props.visible}
                onCancel={() => {
                    current = 0;
                    this.setState({ paso: 0 });
                    this.props.onCancel();
                }}
                closeIcon={<Button className="btn-closeModal" onClick={() => { this.props.onCancel() }} icon={<IconCloseModal />}> </Button>}
                footer={null}
                destroyOnClose={true}
            >
                <Tabs defaultActiveKey="1" >
                    <TabPane tab="Tab 1" id="tab_1" key="1">
                        <Row className="w-100 contenido-modal">
                            <Row className="w-100">
                                <Col span={24} >
                                    <Title level={4} className="tituloModal">Cuéntanos un poco de ti,</Title>
                                    <Text strong className=" tituloModal center">¿A qué te dedicas?</Text>
                                </Col>
                            </Row>

                            <Form layout="vertical" className="w-100 frm-contacto " onFinish={this.SeleccionarOpcion} ref={this.formOpcionInicial} >
                                <Row className="w-100">
                                    <Form.Item name="radio_opcion"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, seleccione opción para continuar"
                                        }]}
                                    >
                                        <Radio.Group buttonStyle="solid" className="grupoRadio-mdlCnt">
                                            <Radio.Button className="radioButton" value="agente">
                                                <Row>
                                                    <Col span={24} className="col-img">
                                                        <IconAgenteMdlCnt />
                                                    </Col>

                                                    <Col span={24} className="col-txt">
                                                        <Text>Soy un Agente o Agencia de Bienes Raíces</Text>
                                                    </Col>

                                                </Row>
                                            </Radio.Button>
                                            <Radio.Button className="radioButton" value="comprador">
                                                <Row>
                                                    <Col span={24} className="col-img">
                                                        <IconHouseMdlCnt />
                                                    </Col>

                                                    <Col span={24} className="col-txt">
                                                        <Text>Soy un Comprador / Vendedor de Casas</Text>
                                                    </Col>

                                                </Row>
                                            </Radio.Button>
                                            <Radio.Button className="radioButton" value="otra">
                                                <Row>
                                                    <Col span={24} className="col-img">
                                                        <IconOtherMdlCnt />
                                                    </Col>

                                                    <Col span={24} className="col-txt">
                                                        <Text>Otra Profesión</Text>
                                                    </Col>
                                                </Row>
                                            </Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                </Row>

                                <Form.Item className="center pd-30px">
                                    <Button type="primary" shape="round" htmlType="submit" className="btn-limon br-20px">
                                        Continuar</Button>
                                </Form.Item>
                            </Form>
                            {footer}
                        </Row>

                    </TabPane>

                    <TabPane tab="Tab 2" id="tab_2" key="2">
                        <Row className="contenido-modal" style={{ marginTop: "-26px" }}>
                            <Row className="center">
                                <Col span={24} >
                                    <Title level={4} className="tituloModal">Otras profesiones</Title>
                                    <Text className="tituloModal">Seleccione cualquiera de las siguientes opciones</Text>
                                </Col>
                            </Row>
                            <Row className="w-100">
                                <List itemLayout="horizontal" className="list-profesiones" >
                                    <List.Item >
                                        <Row className="w-100">
                                            <Col span={1}>
                                                <IconLupaMdlcnt />
                                            </Col>

                                            <Col span={22}>
                                                <Text>Soy prestamista u oficial de prestamos.</Text>
                                            </Col>

                                            <Col span={1}>
                                                <Button className="btnArrowCircleList right" >
                                                    <IconArrowRightMdlcnt />
                                                </Button>
                                            </Col>

                                        </Row>
                                    </List.Item>
                                    <List.Item >
                                        <Row className="w-100">
                                            <Col span={1}>
                                                <IconCasaMdlcnt />
                                            </Col>

                                            <Col span={22}>
                                                <Text>Soy un Administrador de propiedades</Text>

                                            </Col>

                                            <Col span={1}>
                                                <Button className="btnArrowCircleList right" >
                                                    <IconArrowRightMdlcnt />
                                                </Button>
                                            </Col>

                                        </Row>
                                    </List.Item>
                                    <List.Item >
                                        <Row className="w-100">
                                            <Col span={1}>
                                                <IconUserMdlcnt />
                                            </Col>

                                            <Col span={22}>
                                                <Text>Soy un anunciante </Text>
                                            </Col>

                                            <Col span={1}>
                                                <Button className="btnArrowCircleList right" >
                                                    <IconArrowRightMdlcnt />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                    <List.Item >
                                        <Row className="w-100">
                                            <Col span={1}>
                                                <IconHelpMdlcnt />
                                            </Col>

                                            <Col span={22}>
                                                <Text>Ayuda</Text>
                                            </Col>

                                            <Col span={1}>
                                                <Button className="btnArrowCircleList right" >
                                                    <IconArrowRightMdlcnt />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </List.Item>

                                </List>

                                {footer}
                            </Row>

                        </Row>

                    </TabPane>

                    <TabPane tab="Tab 3" id="tab_3" key="3">
                        <Row className="contenido-modal" style={{ marginTop: "-26px" }}>
                            <Row className="center">
                                <Col span={24} >
                                    <Title level={4} className="tituloModal">Comprar o Vender Propiedades</Title>
                                    <Text className="tituloModal">Seleccione cualquiera de las siguientes opciones</Text>
                                </Col>

                            </Row>

                            <Row className="w-100">
                                <List itemLayout="horizontal" className="list-profesiones" >
                                    <List.Item className="">
                                        <Row className="w-100">
                                            <Col span={1}>
                                                <IconLupaMdlcnt />
                                            </Col>

                                            <Col span={22}>
                                                <Text>Buscar una Casa</Text>

                                            </Col>

                                            <Col span={1}>
                                                <Button className="btnArrowCircleList right" >
                                                    <IconArrowRightMdlcnt />
                                                </Button>
                                            </Col>

                                        </Row>
                                    </List.Item>

                                    {/* <List.Item className="">
                                        <Row className="w-100">
                                            <Col span={1}>
                                                <IconCasaMdlcnt />
                                            </Col>

                                            <Col span={22}>
                                                <Text>Publicar una Casa</Text>

                                            </Col>

                                            <Col span={1}>
                                                <Button className="btnArrowCircleList right" >
                                                    <IconArrowRightMdlcnt />
                                                </Button>
                                            </Col>

                                        </Row>
                                    </List.Item> */}

                                    <List.Item className="">
                                        <Row className="w-100">
                                            <Col span={1}>
                                                <IconUserMdlcnt />
                                            </Col>

                                            <Col span={22}>
                                                <Text>Buscar un agente de bienes raices</Text>
                                            </Col>

                                            <Col span={1}>
                                                <Button className="btnArrowCircleList right" >
                                                    <IconArrowRightMdlcnt />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </List.Item>


                                    <List.Item className="">
                                        <Row className="w-100">
                                            <Col span={1}>
                                                <IconHelpMdlcnt />
                                            </Col>

                                            <Col span={22}>
                                                <Text>Ayuda</Text>
                                            </Col>

                                            <Col span={1}>
                                                <Button className="btnArrowCircleList right" >
                                                    <IconArrowRightMdlcnt />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </List.Item>

                                </List>
                            </Row>
                            {footer}
                        </Row>
                    </TabPane>

                    <TabPane tab="Tab 4" id="tab_4" key="4">
                        <Row className="contenido-modal" justify="center" align="middle">
                            <Steps current={this.state.paso} progressDot={customDot} className="steps-contacto" >
                                {steps.map(item => (<Step />))}
                            </Steps>
                            <Col span={24} className="pd-0px"> <Title level={4} className="tituloModal">{steps[this.state.paso].title}</Title></Col>
                            {steps[this.state.paso].content}
                        </Row>
                        <Row className="pd-0px" justify="center">
                            <Col span={24} >
                                <Text className="tituloModal center"> {steps[this.state.paso].footer}</Text>
                            </Col>
                        </Row>
                        {current < 3 && (footer)}
                    </TabPane>
                </Tabs>
            </Modal>
        )
    }
}