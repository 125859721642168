import React, { Component } from 'react';
import { Row, Col, Button, Input, Select, Typography, DatePicker, Image, Rate, Tag, Form, Modal, Menu, Carousel, message } from 'antd'
import { LeftOutlined, RightOutlined, CloseOutlined } from '@ant-design/icons';
import Scroll from 'react-scroll';
import axios from 'axios'
//componentes
import { User } from '../../../Context/User'
import { IconHeart, IconShare, IconHousePercent, IconCredit, Gps, Bed, Bath, Room, Area, IconHouse } from '../../Widgets/Iconos'
import FormCita from '../Formularios/FormCita';
import FormContacto from '../Formularios/FormContacto'
import MapaViewer from '../../Widgets/Filtros/MapaSelect'
// import  from '../../Widgets/Mapa/Mapa'
import { InmuebleCardSmall } from '../../Widgets/cards/Cards';
//css
import '../../../styles/Inmueble.css'
import '../../../styles/Modals.css'




var Link = Scroll.Link;
var Element = Scroll.Element;
const { Title, Text, Paragraph } = Typography;
const moment = require('moment')



/**
 *
 *
 * @export
 * @class ModalInmueble
 * @extends {Component}
 */
class ModalInmueble extends Component {

	static contextType = User

	constructor(props) {
		super(props)
		this.carousel = React.createRef();
		this.state = {
			info: {
				imagenes: [],
				categorias_caracteristicas: [],
				agencia_id: null,
				localizacion: null
			},
			agencia: {
				_id: '',
				nombre: '',
				horarios: [],
				tipo: 0
			},
			address: null,
			cercanas: []
		}
	}

	componentDidMount(){
		if (this.props.propiedad_id !== undefined) {
			this.getPropiedad(this.props.propiedad_id)
		}
	}

	componentDidUpdate(prevPros) {
	}

	/**
	 * @memberof ModalInmueble
	 * 
	 * @method getPropiedad
	 * @description Carga la propiedad seleccionada
	 */
	getPropiedad = (id) => {
		axios.get('/propiedad/get', {
			params: {
				id,
				visita: true,
			}
		})
			.then(res => {
				this.setState({
					info: res.data.data,
					agencia: res.data.data.agencia_id,
					cercanas: res.data.propiedades_cercanas,
					address: res.data.data.localizacion //.localizacion,
				});
			}).catch(error => {
				console.log(error)
				message.error('Error al cargar la Propiedad')
			})
	}

	/**
	 * @memberof ModalInmueble
	 * 
	 * @method next
	 * @description Pasa a la siguiente imagen en el carrousel
	 */
	next = () => {
		this.carousel.next();
	}

	/**
	 * @memberof ModalInmueble
	 * 
	 * @method previous
	 * @description Pasa a la imagen anterior en el carrousel
	 */
	previous = () => {
		this.carousel.prev();
	}



	/**
	 * @memberof ModalInmueble
	 * 
	 * @method clickFav
	 * @description 
	 */
	clickFav = () => {
		const user = this.context;
		if (this.isLogged(true)) {
			if (this.isInFav()) {
				axios.post('/usuario/guardadas/delete', {
					propiedad_id: this.props.propiedad_id,
					user: user._id
				}).then((success) => {
					if (this.props.setFavs) { this.props.setFavs(success.data.favs) }
					message.success('Eliminado de la lista de guardados')
					//this.props.onCancel()
				}).catch((error) => {
					console.log(error)
					message.error('Error al eliminar')
				})
			} else {
				axios.post('/usuario/guardadas/add', {
					propiedad_id: this.props.propiedad_id,
					user: user._id
				}).then((success) => {
					if (this.props.setFavs) { this.props.setFavs(success.data.favs) }
					message.success('Agregado a la lista de guardados')
				}).catch((error) => {
					console.log(error)
					message.error('Error al guardar')
				})
			}
		}
	}

	/**
	 * @memberof ModalInmueble
	 * 
	 * @method isInFav
	 * @description veririfca si la propiedad esta en la lista de favoritos del usuario, retorna true si esta y false si no
	 */
	isInFav = () => {

		if (this.isLogged()) {
			const user = this.context;
			if (this.props.favs) {
				let index = this.props.favs.indexOf(this.props.propiedad_id?.toString())
				if (index !== -1) {
					return true
				}
				else {
					return false
				}
			}
		}

	}

	/**
	 * @memberof ModalInmueble
	 * 
	 * @method isLogged
	 * @description veririfca si el usuario esta logedo, atravez del context, sino esta abre el modal para iniciar sesion,
	 * @param open bool flag que indica si abrir el modal en caso de que el usuario no este logeado
	 */
	isLogged = (open) => {
		const user = this.context;
		if (user) {
			return true
		} else {
			if (open) {
				sessionStorage.setItem('add_fav', this.props.propiedad_id);
				this.props.openLogin();
			}
			return false;
		}
	}


	/**
	 *
	 *
	 * @memberof ModalInmueble
	 * @description Formatea el horario a moment
	 */
	horarioFormat = (dias) => {
		const dias_semana = {
			0: 'Domingo',
			1: 'Lunes',
			2: 'Martes',
			3: 'Miércoles',
			4: 'Jueves',
			5: 'Viernes',
			6: 'Sábado'
		}
		let horario = []

		for (const index in dias) {
			let dia = dias[index];
			let hora_inicio = new Date(0, 0, 0, dia.inicio.hora, dia.inicio.minuto).getTime();
			let hora_fin = new Date(0, 0, 0, dia.fin.hora, dia.fin.minuto).getTime();;
			horario.push(<Col className="flex-column mb-2" xs={24} md={12}>
				<Text className="text-strong">{dias_semana[dia.dia]}</Text>
				<Text className="text-strong">{moment(hora_inicio).format('hh:mm A')} {moment(hora_fin).format('hh:mm A')}</Text>
			</Col>)
		}

		return horario;


	}


	/**
	 * @memberof ModalInmueble
	 * 
	 * @method renderImagenes
	 * @description Pasa a la imagen anterior en el carrousel
	 */
	renderImagenes = (imagenes) => {
		if (imagenes.length > 0) {
			return imagenes.map(img => <Col span={(img.tamano.w === 2) ? 24 : 12}>
				<div className="card-image-med" style={{
					minHeight: (img.tamano.h * 200) + "px",
					backgroundImage: `url(${axios.defaults.baseURL + '/upload/' + img.filename_server})`,
				}} />
			</Col>
			)
		}
	}

	render() {
		const { info, agencia, address } = this.state;
		const props = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1
		}


		return (

			<Row>
				<Col className='col-imagen' style={{ position: 'relative' }} xs={24} lg={0}>
					<Button className="left-circle" onClick={this.previous}><LeftOutlined /></Button>
					<Carousel autoplay ref={node => (this.carousel = node)} {...props}>
						<div>
							<div className="card-image-big" style={{ backgroundImage: `url('images/indice.jpg')` }}></div>
						</div>
						<div>
							<div className="card-image-big" style={{ backgroundImage: `url('images/cocina.jpg')` }}></div>
						</div>
						<div>
							<div className="card-image-big" style={{ backgroundImage: `url('images/fuera.jpg')` }}></div>
						</div>
					</Carousel>
					<Button className="right-circle" onClick={this.next} ><RightOutlined /></Button>
				</Col>
				<Col xs={0} lg={14} className='col-imagen' >
					<Row gutter={12}>
						{this.renderImagenes(info.imagenes)}
					</Row>

					<Row gutter={20} className="pl-1">
						<Col span={24}>
							<Title level={2}>Propiedades Similares</Title>
						</Col>
						<Col span={24}>
							<div className="hr-strong" />
						</Col>

						{(this.state.cercanas?.length > 0) ?
							this.state.cercanas.map(propiedad => {
								return <Col md={24} xl={12}>
									<InmuebleCardSmall {...propiedad} />
								</Col>
							})
							:
							<Col md={24} xl={23}>
								<Title level={3} className="txt-gray">No hay propiedades similares a esta propiedad.</Title>
							</Col>
						}
					</Row>
				</Col>
				<Col xs={24} lg={10} className="col-inmueble">
					<Row>
						<Col span={24} className="col-btns-top">
							<Button className="btn-secondary" onClick={this.clickFav}> {this.isInFav() ? 'No me Gusta' : 'Me gusta'} <IconHeart /></Button>
							<Button danger style={{ color: "red" }} onClick={this.props.onCancel}> Cerrar <CloseOutlined /></Button>
						</Col>
					</Row>
					<Row className="mt-1 mb-1">
						<Col className="flex-start" span={12}>
							<Text className="text-price"> {
								(info?.costo_propiedad !== undefined) ? info?.costo_propiedad.toMoney() :
									(info?.pago_mensual !== undefined) ? info?.pago_mensual.toMoney() :
										'Sin definir'}
								<small>{info.divisa_id?.code}</small></Text>
						</Col>
						<Col className="flex-end" span={12}>
							<Text className="text-svg">
								<Image src={axios.defaults.baseURL + '/tipos/' + info?.tipo_propiedad_id?.icon} preview={false} width="50px" />
								&nbsp;{info?.tipo_propiedad_id?.nombre}
							</Text>
						</Col>
					</Row>
					<Row>
						<Col xs={12} sm={6}>
							<Text className="text-info"><Bed /> {info.recamaras} Rec</Text>
						</Col>
						<Col xs={12} sm={6}>
							<Text className="text-info"><Bath /> {info.banos} Baños</Text>
						</Col>
						<Col xs={12} sm={6}>
							<Text className="text-info"><Room /> {info.habitaciones} Habitac</Text>
						</Col>
						<Col xs={12} sm={6}>
							<Text className="text-info"><Area /> {info.tamano_lote} m<sup>2</sup></Text>
						</Col>
					</Row>
					<Row>
						<Col span={24} >
							<Text className="text-direction"><Gps /> {info.localizacion?.place_name}</Text>
						</Col>
					</Row>
					{info.pago_mensual ? <Row className="mb-1">
						<Col xs={24} sm={12}>
							<Text className="text-info left"><IconHousePercent style={{ width: 25, height: 25 }} /> Pago Mensual estimado: </Text>
						</Col>
						<Col xs={24} sm={12}>
							<Text className="text-info left"> <b> $ {info.pago_mensual} </b>/ <small>mensuales</small></Text>
						</Col>
						{/* Falta informacion de esta parte */}
						{/* <Col span={24}>
								<Text className="text-info left"><IconCredit /> <small> Aplicar solicitud de Crédito </small></Text>
							</Col> */}
					</Row> : null }
					<Row gutter={20}>
						<Col className="gutter-row" xs={24} md={12}>
							<Button className="btn-black"><Link
								activeClass="active"
								to="contacto-s"
								spy={true}
								smooth={true}
								duration={1000}
								containerId="containerElement">
								Contactar a la Agencia
							</Link></Button>
						</Col>
						<Col className="gutter-row" xs={24} md={12}>
							<Button className="btn-yellow">
								<Link
									activeClass="active"
									to="cita-s"
									spy={true}
									smooth={true}
									duration={1000}
									containerId="containerElement">
									Solicitar Tour
								</Link>
							</Button>
						</Col>

					</Row>
					<Row>
						<Col span={24}>
							<Menu className="menu-modal" onClick={this.handleClick} mode="horizontal">
								<Menu.Item key="map">
									<Link
										activeClass="active"
										to="description"
										spy={true}
										smooth={true}
										duration={1000}
										containerId="containerElement">
										Descripción
									</Link>
								</Menu.Item>
								<Menu.Item key="carac">
									<Link
										activeClass="active"
										to="caracteristicas"
										spy={true}
										smooth={true}
										duration={1000}
										containerId="containerElement">
										Características
									</Link>
								</Menu.Item>
								{/* <Menu.Item key="prop"> Valor </Menu.Item>
									<Menu.Item key="rate"> Precio </Menu.Item> */}

							</Menu>

						</Col>
					</Row>

					<Element id="containerElement" className="element content-modal-inmueble">
						<Row>
							<Col className="center mt-2" span={24} style={{ width: '100px', height: '300px', minWidth: '100px', maxWidth: '100%', minHeight: '100%', maxHeight: '400px' }}>
								<MapaViewer address={address} />
							</Col>
						</Row>

						<Element name="description">
							<Row>
								<Col className="mt-2 mb-3" span={24}>
									<Paragraph className="text-description">{(info.descripcion) ? info.descripcion : 'Esta propiedad no tiene una descripción'}</Paragraph>
								</Col>
							</Row>
						</Element>

						<Row>
							<Col className="flex-column" span={24}>
								<Text className="text-strong">{agencia?.nombre} </Text>
								<Text className="text-strong-blue">{(agencia?.localizacion) ? agencia.localizacion.place_name : 'Esta agencia no tiene una localización definida'}</Text>
							</Col>
							<Col className="flex-column mt-2 mb-2" span={24}>
								<Text className="text-strong">Horario </Text>
							</Col>

							{(agencia?.horarios?.length > 0) ?
								this.horarioFormat(agencia?.horarios)
								: <Col className="flex-column mb-2" xs={24} md={12}>
									<Text className="text-strong">Esta Agencia no tiene horarios disponibles </Text>
								</Col>

							}
							<Col span={24}>
								<hr className="hr" />
							</Col>
							<Col className="flex-column mt-2 mb-2" span={24}>
								<Paragraph className="text-description"> </Paragraph>
							</Col>
						</Row>

						<Element name="cita-s">
							<FormCita
								propiedad={this.state.info}
								agencia={this.state.agencia}
							/>
						</Element>
						<Element name="caracteristicas">
							<Row gutter={20} className="mt-3">
								<Col className="flex-column" span={24}>
									<Text className="text-strong">Características</Text>
								</Col>
								<Col span={24}>
									<hr className="hr" />
								</Col>
							</Row>
						</Element>
						<Row gutter={20} className="mt-1 pl-2">
							{
								info.categorias_caracteristicas.map(cate => {
									return <>
										<Col className="flex-column mt-1" span={12}>
											<Text className="text-sub">{cate.nombre}</Text>
											<div>
												{cate.caracteristicas.map(carc =>
													<div className="text-cat car-list-element">
														<img src={axios.defaults.baseURL + '/caracteristicas/' + carc.icon} alt={carc.nombre} height={20} />
														&nbsp;{carc.nombre}
													</div>)}
											</div>
										</Col>
									</>
								})
							}
						</Row>

						{/* agencia info */}
						<Row gutter={20} className="mt-2 mb-1">
							<Col offset={3} span={21} className="mb-1">
								<Text className="text-rate">{agencia?.nombre}</Text>
								<div>
									<Rate disabled defaultValue={(agencia?.rating) ? agencia.rating : 0} className="mr-1" />
									{/* <Tag color="#fadb14">tag 1</Tag>
										<Tag color="#8cd26e">tag 2</Tag>
										<Tag color="#1a93ea">tag 3</Tag> */}
								</div>
							</Col>
							<Col span={18}>
								<Paragraph className="text-description">{(agencia?.descripcion) ? agencia.descripcion : 'Esta Agencia no tiene una descripción'}</Paragraph>
							</Col>
						</Row>


						{/* Prospecto */}
						<Element name="contacto-s">
							<Text strong className="text-strong ">¿Necesitas mas información? ¡Contáctanos!</Text>
							<FormContacto propiedad={this.state.info} />
						</Element>

					</Element>

				</Col>
			</Row>

		)
	}
}

export default function (props) {


	return <Modal
		className="modal-inmueble"
		visible={props.visible}
		onCancel={props.onCancel}
		footer={null}
		destroyOnClose={true}
		closable={false}
	>
		<ModalInmueble {...props} />
	</Modal >
}