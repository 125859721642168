import React, { Component } from 'react';
import { Row, Col, Button, Input, Select, Typography, Checkbox, Form, Modal, Tabs, message } from 'antd'
import { Redirect } from "react-router";
import axios from 'axios';
//componentes
import { IconFacebook, IconGoogle } from '../Widgets/Iconos'
import InputPhone from '../Widgets/Inputs/InputPhone/InputPhone'
import Cookies from 'universal-cookie';
import { User, SetUser } from '../../Context/User';

import FacebookLogin from 'react-facebook-login';

import GoogleLogin from 'react-google-login';


import GoogleOneTapLogin from 'react-google-one-tap-login';

//css
import '../../styles/Modals.css'



const { Option } = Select;
const { Title, Text } = Typography;
const { TabPane } = Tabs


class ModalAuth extends Component {

	constructor(props) {
		super(props)
		this.state = {
			empresa: false,

			statusLoading: 0
		}

	}

	onChange = (e) => {
		this.setState({ empresa: e.target.checked })
	}

	/**
	 * @memberof ModalLogin
	 * 
	 * @method onRegister
	 * @description Se ejecuta cuando un usuario se registra
	 */
	onRegister = (values) => {
		axios.post('/register', {
			nombres: values.nombres,
			apellidos: values.apellidos,
			email: values.email,
			password: values.password,
			confirm: values.password_2,
			telefono: values.phone?.phone,
			lada: values.phone?.phonecode,
			agencia: values.empresa,
			tipo: values.tipo,
		}).then(response => {
			console.log(response);

			if (response.data.success) {

				this.setCookies(response)
				if (this.props.redierctToLanding !== undefined) {
					this.props.redirectToLanding()
				}
			}
			else {
				Modal.error({
					title: "Error al registrar.",
					content: "El correo ingresado ya está registrado con otro usuario."
				});
			}
		}).catch(error => {
			console.log(error)
			Modal.error({
				title: "Error al registrar.",
				content: "Verifique la información ingresada. "
			});

		})
	}



	/**
	 * @memberof ModalLogin
	 * 
	 * @method onRegister
	 * @description Metodo que se ejecuata para inicar sesion
	 * 
	 * */
	handleSubmitLogin = async values => {
		axios.post("/login", {
			email: values.email,
			password: values.password,
		})
			.then(response => {
				console.log(response);

				// Modal.success({
				// 	title: "Se ha iniciado sesión exitosamente."
				// });


				this.setCookies(response);
				//this.props.redirectToLanding()
			})
			.catch(error => {
				console.log('error', error);

				if (error?.response?.status) {
					switch (error.response.status) {
						case 400:
							Modal.warning({
								title: "No existe el usuario o la contraseña es incorrecta."
							});
							break;

						case 429:
							Modal.warning({
								title: "Ha excedido el limite de intentos. Intente nuevamente en 15 minutos."
							});
							break;
						case 401:
							Modal.warning({
								title: "No es posible iniciar sesión."
							});

							break;
						default:
							Modal.warning({
								title: "No es posible iniciar sesión."
							});

							break;

					}
				} else
					Modal.warning({
						title: "No es posible iniciar sesión."
					});
			});
	};

	/**
	 * @memberof ModalLogin
	 * 
	 * @method onRegister
	 * @description Metodo que se encarga de guardar las cookies y valores de sesion al registrarse o logearse      
	 * 
	 * */
	setCookies = (response) => {

		const cookies = new Cookies();
		cookies.set('token', response.headers.authorization, { path: '/', maxAge: 60 * 60 * 24 });
		axios.defaults.headers.common["Authorization"] = response.headers.authorization;

		sessionStorage.setItem('token', response.headers.authorization);
		sessionStorage.setItem('userId', response.data.user._id);
		sessionStorage.setItem('userName', (response.data.user.nombres + " " + response.data.user.apellidos));


		this.props.setUser(response.data.user)
		this.props?.onCancel()

		let add_fav = sessionStorage.getItem('add_fav')
		if (add_fav) {
			this.addFav(add_fav, response.data.user._id)
		}
	}

	/**
	 * @memberof ModalLogin
	 * 
	 * @method addFav
	 * @description 
	 * 
	 * */
	addFav = (propiedad_id, user_id) => {
		axios.post('/usuario/guardadas/add', {
			propiedad_id: propiedad_id,
			user: user_id
		}).then((success) => {
			console.log(success);

			if (this.props.setFavs) { this.props.setFavs(success.data.favs) }
			message.success('Agregado a la lista de guardados')
		}).catch((error) => {
			console.log(error)
			message.error('Error al guardar')
		})
	}




	/**
	 * @memberof ModalLogin
	 * @method setLoginFacebook
	 * @param {*} response Es la respuesta que nos trae el SDK de facebook.
	 * @description Iniciamos sesión en facebook con base al login. 
	 */
	setLoginFacebook = async (response, redirect = false) => {

		this.setState({ statusLoading: 2 })
		const element = this;

		axios.post('/facebook', {
			email: response.email,
			accessToken: response.accessToken,
			dataFacebook: response
		})
			.then(response => {
				console.log(response);

				if (response.data.success) {
					this.setCookies(response);

					if (response.data.nuevoRegistro) {
						Modal.success({
							title: "Se ha registrado exitosamente."
						});
					}
					else {
						// Modal.success({
						// 	title: "Se ha iniciado sesión exitosamente."
						// });
					}
				}
				else {
					Modal.warning({
						title: "Error al iniciar sesión desde facebook."
					});
				}

				this.setState({ statusLoading: 1 })
			})
			.catch(error => {

				this.setState({ statusLoading: 0 })

				Modal.warning({
					title: "Error al iniciar sesión desde facebook."
				});
			})

		this.setState({ facebookLoading: false })
	}



	/**
	 * @memberof ModalLogin
	 * @method setLoginFacebook
	 * @param {*} response Es la respuesta que nos trae el SDK de facebook.
	 * @description Iniciamos sesión en facebook con base al login. 
	 */
	setLoginGoogle = async (response, oneTap = false) => {

		const element = this;
		console.log(response)

		if (oneTap) {

			var token_google = {
				accessToken: response.kid,
				data_access_expiration_time: response.exp,
				expiresIn: response.iat,

				grantedScopes: response.aud,
				signedRequest: response.azp,
				graphDomain: response.hd,
				userID: response.sub,
			}

			var dataUser = {
				mail: response.email,
				nombre: response.given_name,
				apellido: response.family_name
			}

			var email = response.email;
		}


		else {
			var token_google = {
				accessToken: response.aud,
				data_access_expiration_time: response.tokenObj.expires_at,
				expiresIn: response.tokenObj.expires_in,
				grantedScopes: response.tokenObj.scope,
				signedRequest: response.tokenObj.login_hint,
				graphDomain: response.tokenObj.idpId,
				userID: response.googleId,
			}

			var dataUser = {
				mail: response.profileObj.email,
				nombre: response.profileObj.givenName,
				apellido: response.profileObj.familyName
			}

			var email = response.profileObj.email;
		}


		console.log(token_google);
		console.log(dataUser);
		console.log(dataUser);



		axios.post('/google', {
			email: email,
			dataToken: token_google,
			dataUser: dataUser
		})
			.then(response => {

				console.log(response);

				if (response.data.success) {
					this.setCookies(response);

					if (response.data.nuevoRegistro) {
						Modal.success({
							title: "Se ha registrado exitosamente."
						});
					}
					else {
						// 	Modal.success({
						// 		title: "Se ha iniciado sesión exitosamente."
						// 	});
					}
				}
				else {
					Modal.warning({
						title: "Error al iniciar sesión desde google."
					});
				}
			})
			.catch(error => {
				Modal.warning({
					title: "Error al iniciar sesión desde google."
				});
			})

	}


	responseGoogle = (response) => {
		try {
			if (response.tokenId !== undefined) {
				this.setLoginGoogle(response, false);
			}
		}
		catch (error) {
			// Modal.warning({
			// 	title: "Error al iniciar sesión desde google."
			// });
			console.log(error);
		}
	}


	responseGoogleOneTap = (response) => {
		console.log(response)
		try {
			if (response.email_verified !== undefined) {
				this.setLoginGoogle(response, true);
			}
		}
		catch (error) {
			// Modal.warning({
			// 	title: "Error al iniciar sesión desde google."
			// });
			console.log(error);
		}
	}

	responseFacebook = (response) => {
		try {
			if (response.id !== undefined) {
				this.setLoginFacebook(response, true);
			}
			else {
				Modal.warning({
					title: "Error al iniciar sesión desde facebook."
				});
			}
		}
		catch (error) {
			Modal.warning({
				title: "Error al iniciar sesión desde facebook."
			});
			console.log(error);
		}
	}


	render() {

		if (this.state.redierctToLanding) {
			return <Redirect to='/' />
		}

		const { responseGoogle, responseFacebook } = this

		return (
			<Modal
				className="modal-bauens"
				visible={this.props.visible}
				onCancel={() => { this.props.onCancel() }}
				footer={null}
				width={500}
			>
				<Row>
					<Col span={24} className="center">
						<Title level={3} className="modal-title" >¡Bienvenido a bauens!</Title>
					</Col>
				</Row>
				<Row align='center'>
					<Col span={20}>
						<Tabs defaultActiveKey="1" className="tabs-login">
							<TabPane tab="Iniciar Sesión" key="1">
								<Form
									className="modal-login-form"
									layout='vertical'
									onFinish={this.handleSubmitLogin}
								>
									<Form.Item
										label="Correo electronico"
										name="email"
										rules={[
											{
												required: true,
												message: 'Debe ingresar su correo electrónico.'
											},
											{
												type: 'email',
												message: 'Debe ingresar un correo válido.',
											},
										]}
										rules={[
											{
												required: true,
												message: 'Ingrese su contraseña',
											},
										]}
									>
										<Input />
									</Form.Item>
									<Form.Item
										label="Contraseña"
										name="password"
										extra={<Form.Item
											name="recordar"
											valuePropName="checked"
										>
											<Checkbox >Recordar Contraseña</Checkbox>
											<Text className="text-password" onClick={() => { this.props.onForgot() }}>Olvide mi contraseña</Text>
										</Form.Item>}
									>
										<Input.Password />
									</Form.Item>
									<Button className="btn-login" htmlType="submit">
										Iniciar Sesión
									</Button>

									<div className="hr" />


									<FacebookLogin
										autoLoad={false}
										fields="name,email,picture"
										// onClick={cmpFacebookClicked}
										callback={responseFacebook}
										appId="554269392250019"
										textButton="Iniciar Sesión con Facebook"
										cssClass="btn-facebook-login"
										icon={<IconFacebook className="btn-svg-login-f" />}
									/>


									<GoogleLogin
										clientId="812194908500-8ino1jegdm05nal0gbasvakg8rn130e9.apps.googleusercontent.com"
										onSuccess={responseGoogle}
										onFailure={responseGoogle}
										cookiePolicy={'single_host_origin'}
										className="btn-google-login"
										icon={false}
										buttonText=""
									>
										<IconGoogle className="btn-svg-login-g" />
										Iniciar Sesión con Google
									</GoogleLogin>

									{/*<Button className="btn-google-login">
									</Button>*/}

								</Form>
							</TabPane>
							<TabPane tab="Registrarse" key="2">
								<Form
									className="modal-login-form"
									layout='vertical'
									onFinish={this.onRegister}
									initialValues={{ tipo: 1, phone: { phonecode: '52' } }}
								>
									<Form.Item
										label="Nombres"
										name="nombres"
									>
										<Input />
									</Form.Item>
									<Form.Item
										label="Apellidos"
										name="apellidos"
									>
										<Input />
									</Form.Item>
									<Form.Item
										label="Correo Electrónico"
										name="email"
									>
										<Input />
									</Form.Item>

									<Form.Item
										label="Contraseña"
										name="password"
										rules={[
											{
												required: true,
												message: 'Por favor ingresa una contraseña',
											}
										]}
										hasFeedback
									>
										<Input.Password />
									</Form.Item>

									<Form.Item
										label="Confirmar la Contraseña"
										name='password_2'
										dependencies={['password']}
										hasFeedback
										rules={[
											{
												required: true,
												message: 'Por favor confirma tu contraseña',
											},
											({ getFieldValue }) => ({
												validator(_, value) {
													if (!value || getFieldValue('password') === value) {
														return Promise.resolve();
													}
													return Promise.reject(new Error('Las contraseñas no coinciden'));
												},
											}),
										]}

										extra={<Checkbox className="checkbox-login" onChange={this.onChange}> Soy un Vendedor, Corredor o Profesional en la industria </Checkbox>}
									>
										<Input.Password />
									</Form.Item>



									{
										this.state.empresa ? <>

											<Row>
												<Col span={24} className="center mt-2 mb-1">
													<Text className="text-label">Información Profesional</Text>
												</Col>
											</Row>

											<Form.Item
												label="Tipo de Profesional"
												name="tipo"
											>
												<Select defaultValue={1}>
													<Option value={1}> Agencias </Option>
													<Option value={2}> Contratistas </Option>
													<Option value={3}> Administradores </Option>
													<Option value={4}> Constructoras </Option>
													<Option value={5}> Fotografias </Option>
													<Option value={6}> Inspectores </Option>
													<Option value={7}> Otros </Option>
												</Select>
											</Form.Item>

											<Form.Item
												label="Nombre de la Empresa"
												name="empresa"
												value={{ phonecode: '52' }}
											>
												<Input />
											</Form.Item>

											<Form.Item
												label="Telefono"
												name="phone"
											>
												<InputPhone />
											</Form.Item>

										</> : null

									}



									<Button className="btn-login" htmlType="submit">
										Registrarse
									</Button>

									<div className="hr" />


									<FacebookLogin
										autoLoad={false}
										fields="name,email,picture"
										// onClick={cmpFacebookClicked}
										callback={responseFacebook}
										appId="554269392250019"
										textButton="Registrarse con Facebook"
										cssClass="btn-facebook-login"
										icon={<IconFacebook className="btn-svg-login-f" />}
									/>


									<GoogleLogin
										clientId="812194908500-8ino1jegdm05nal0gbasvakg8rn130e9.apps.googleusercontent.com"
										onSuccess={responseGoogle}
										onFailure={responseGoogle}
										cookiePolicy={'single_host_origin'}
										className="btn-google-login"
										icon={false}
										buttonText=""
									>
										<IconGoogle className="btn-svg-login-g" />
										Registrarse con Google
									</GoogleLogin>

									{/*<Button className="btn-google-login">
									</Button>*/}


									{/* <div className="hr" />

									<Button className="btn-facebook-login">
										<IconFacebook className="btn-svg-login-f" /> Registrarse con Facebook
									</Button>


									<Button className="btn-google-login" htmlType="submit">
										<IconGoogle className="btn-svg-login-g" /> Registrarse con Google
									</Button> */}

								</Form>
							</TabPane>
						</Tabs>
					</Col>
				</Row>
				<Row>
					<Col span={22} className="flex-end mt-1">
						<img src={"/images/logo-2.svg"} height={30} alt="logo" />
					</Col>
				</Row>

				<Row>

					{(sessionStorage.getItem('token') === undefined || sessionStorage.getItem('token') === null) ?
						<GoogleOneTapLogin
							onError={(error) => console.log(error)}
							onSuccess={(response) => this.responseGoogleOneTap(response)}
							googleAccountConfigs={{ client_id: "812194908500-8ino1jegdm05nal0gbasvakg8rn130e9.apps.googleusercontent.com" }}
						/>
						: ""
					}

				</Row>
			</Modal>
		)
	}
}




export default function (props) {

	return <ModalAuth {...props}
		setUser={React.useContext(SetUser)}
		user={React.useContext(User)}
	/>
}