import { Component } from "react";
import { Layout, PageHeader, List, Row, Col, Typography, Card, Button, message } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { FaAngleRight } from "react-icons/fa";
import axios from 'axios'
import { User } from '../../../Context/User'

const { Content } = Layout;
const { Text, Title } = Typography;
const moment = require('moment');
/**
 *
 *
 * @export
 * @class AjustesPagos
 * @extends {Component}
 */
export default class Citas extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            currentPage: 1,
            itemCount: 0,
            citas: [],
        }
    }

    componentDidMount(){
        this.getCitas();
    }

    /**
     *
     *
     * @memberof Dashboard_Agente
     * @description Lista las ultimas citas del Agencia
     */
    getCitas = (page) => {
        this.setState({ loading: true })
        axios.get('/citas/agencia', {
            params: {
                limit: 10,
                page,
            }
        })
        .then(res => {
            console.log('citas',res.data)
            this.setState({
            	citas: res.data.data.data,
            	total: res.data.data.total,
            	page: res.data.data.page,
            })
        })
        .catch(error => {
            message.error({ content: 'Ha ocurrido un error al traer las citas', className: "message-error" })
        })
        .finally(f => {
            this.setState({ loading: false })
        })
    }

    render() {
        return (
            <Layout className="bg-gray no-overflow-x">
                <PageHeader title="Citas" className="bg-white mt-1 mr-2 ml-2 " />
                <Content className="bg-gray mr-2 ml-2 mt-1 ">
                    <List
                        loading={this.state.loading}
                        size="small"
                        className="bg-white component-list form-ajustes"
                        bordered={false}
                        locale={{emptyText:'Sin Prospectos'}}
                        dataSource={this.state.citas}
                        pagination={{
                            onChange: page => {
                                this.getCitas(page)
                            },
                            pageSize: 10,
                            current: this.state.page,
                            total: this.state.total
                        }}

                        header={

                            <Row align="middle row-titleProspectos" >
                                <Col className="center" xxl={{ span: 6 }} xl={{ span: 6 }} sm={{ span: 6}} xs={{ span: 22 }}>
                                    <Title>Propiedad</Title>
                                </Col>
                                <Col className="center" xxl={{ span: 4 }} xl={{ span: 4 }} sm={{ span: 4}} xs={{ span: 11 }}>
                                    <Title>Usuario</Title>
                                </Col>
                                <Col className="center" xxl={{ span: 4 }} xl={{ span: 4 }} sm={{ span: 4}} xs={{ span: 11 }}>
                                    <Title>Teléfono</Title>
                                </Col>
                                <Col className="center" xxl={{ span: 4 }} xl={{ span: 4 }} sm={{ span: 4}} xs={{ span: 11 }}>
                                    <Title>Email</Title>
                                </Col>
                                <Col className="center" xxl={{ span: 3 }} xl={{ span: 3 }} sm={{ span: 4}} xs={{ span: 11 }}>
                                    <Title>Fecha y Hora</Title>
                                </Col>
                                <Col className="center" xxl={{ span: 3 }} xl={{ span: 3 }} sm={{ span: 2}} xs={{ span: 2  }}>
                                    <Title>Acciones</Title>
                                </Col>
                            </Row>
                        }

                        renderItem={item => <List.Item className="component-list-item">
                            <Card className="card-list shadow-sm">
                                <Row align="middle" >
                                    <Col className="center"  className="center" xxl={{ span: 6 }} xl={{ span: 6 }} sm={{ span: 6}} xs={{ span: 22 }} >
                                        <Text>{item.propiedad_id?.nombre}</Text>
                                    </Col>
                                    <Col  className="center" xxl={{ span: 4 }} xl={{ span: 4 }} sm={{ span: 4}} xs={{ span: 11 }} >
                                        <Text>{item.usuario_id?.nombres} {item.usuario_id?.apellidos}</Text>
                                    </Col>
                                    <Col  className="center" xxl={{ span: 4 }} xl={{ span: 4 }} sm={{ span: 4}} xs={{ span: 11 }} >
                                        <Text>{item.usuario_id?.lada} {item.usuario_id?.telefono}</Text>
                                    </Col>
                                    <Col  className="center" xxl={{ span: 4 }} xl={{ span: 4 }} sm={{ span: 4}} xs={{ span: 11 }} >
                                        <Text>{item.usuario_id?.email}</Text>
                                    </Col>
                                    <Col  className="center" xxl={{ span: 3 }} xl={{ span: 3 }} sm={{ span: 4}} xs={{ span: 11 }} >
                                        <Text>{moment(item.fecha).utc().format('DD/MM/YYYY')} {item.cita_inicio.hora}:00 - {item.cita_fin.hora}:00</Text>
                                    </Col>
                                    <Col  className="center" xxl={{ span: 3 }} xl={{ span: 3 }} sm={{ span: 2}} xs={{ span: 2  }} >
                                        <Button type="link"  className="ant-btn btn-yellow">Enviar mensaje</Button>
                                        {/* <Button type="link" icon={<FaAngleRight />} className="card-list-btn"></Button> */}
                                    </Col>
                                </Row>
                            </Card>
                        </List.Item>}
                    />
                </Content>
            </Layout>
        )
    }
}