import React, { Component } from 'react'

import { Layout, Row, Col, Tabs, Button, Typography, Input, Carousel } from 'antd';

import { Link } from "react-router-dom";

import { IconGotoLanding, } from './../Widgets/Iconos'

import 'antd/dist/antd.css';

import './../../styles/landing.css'
import './../../styles/dashboard.css'

const { Content } = Layout;
const { Text, Title } = Typography;
const { Search } = Input;


export default class Dashboard extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			AbrirModalContacto: false
		}
	}

	
	abrirModal = () => {
		setTimeout(() => {
			var btnModalContacto = document.getElementById("btnAbrirModalContacto");
			btnModalContacto.click()
		}, 250);
	}
	

	render() {
		return (
			<Layout className="landing-layout">
				<Content className="landing-content" >
					<Row id="sec-1" className="lnd-sec-1 db-sec-1" style={{ backgroundImage: "url('/images/bg-dashboard.png')" }} >
						<Row className="row-db-sec-1">
							<Col span={24} className="" >
								<Title level={4} className="text-white" >SOLUCIONES EN BIENES RAICES</Title>
							</Col>

							<Col span={24} className="" >
								<Title level={2} className="text-white" >¡Publicita tus propiedades en <br /> BAUENS! </Title>
							</Col>

							<Col span={24} className="" >
								<Title level={4} className="text-white" >Obtenga acceso a una de las plataformas en ventas de <br /> completa.</Title>
							</Col>

							<Col span={24} className="">
								<Button className="btn-limon br-20px" onClick={this.abrirModal}>Iniciar Ahora</Button>
							</Col>

							<Col span={24} className="mg-auto center btn-db-down" >
								<a href="#sec-2" >
									<img src={"/images/icon-db-down.svg"} className="db-imgDown" alt="imgDown" />
								</a>
							</Col>
						</Row>
					</Row>

					<Row id="sec-2" className="lnd-sec-2  bg-white pb-30px">
						<Row id="sec-2" className=" db-sec-2 ">
							<Col span={24} xl={12} className="pb-30px" >
								<Row>
									<Col span={18} className="mg-auto pb-30px">
										<Title level={2} className="" >Conectar con tus clientes a un click de distancia</Title>
										<Text>There are many variations of passages of Lorem Ipsum available but the this in majority have suffered alteration in some.</Text>
									</Col>

									<Col span={24} className="center col-compradores">
										<Col span={8}>
											<Title level={5}>Comprador <br /> Interesado</Title>
											<img src={"/images/img-comprador.svg"} className="db-imgComprador" alt="imgComprador-1" />
										</Col>
										<Col span={8}>
											<Title level={5}>Comprador <br /> Interesado</Title>
											<img src={"/images/img-comprador.svg"} className="db-imgComprador" alt="imgComprador-2" />
										</Col>
										<Col span={8}>
											<Title level={5}>Comprador <br /> Interesado</Title>
											<img src={"/images/img-comprador.svg"} className="db-imgComprador" alt="imgComprador-3" />
										</Col>
									</Col>
								</Row>
							</Col>

							<Col xs={24} xl={12} span={5} className="center">
								<img src={"/images/img-dashboard-1.jpg"} className="db-imgClientes" alt="imgClientes" />
							</Col>
						</Row>
					</Row>


					<Row id="sec-3" className="lnd-sec-3 db-sec-3" align="center " style={{ backgroundImage: "url('/images/bg-dashboard-2.png')" }} >
						<Row className="row-db-sec-3">
							<Col span={24} className="" >
								<Title level={2} className="" >Mantente conectado con tus <br />
								clientes con  <span className="text-blue">bauens</span>  <span className="text-red"> professionals</span></Title>
							</Col>

							<Col span={24} className="" >
								<Title level={4} className="" >Get in touch or create account. We are 24/7 available.</Title>
							</Col>

							<Col span={24} className="mg-auto" >
								<Link to="#" >
									<img src={"/images/logo-key.png"} className="db-imgLogoKey" alt="imgLogoKey" />
								</Link>
							</Col>
						</Row>
					</Row>



					<Row id="sec-4" className="lnd-sec-4 db-sec-4" align="center "  >
						<Row className="row-db-sec-4">
							<Col span={24} className="center" >
								<Title level={3} className="" > What Say Our Clients </Title>
							</Col>

							<Col span={24} className="center" >
								<Title level={4} className="" >There are many variations of passages of Lorem Ipsum  available but <br /> the this in majority have suffered alteration in some.</Title>
							</Col>

							<Col span={24} className="col-carusel">
								<Carousel autoplay className="carusel-clientes">

									<Row className="row-cliente mg-auto">

										<Col xs={24} xl={4} className="col-img center">
											<div className="col-img-cliente" style={{ backgroundImage: "url('/images/foto-circle-4.jpg')" }} >
												<img src={"/images/icon-img-circle-01.png"} width={35} alt="iconImgCircle" className="iconLogoCircle" />
											</div>
										</Col>
										<Col xs={24} xl={20} className="col-info">
											<Col className="col-txt-comentario">
												<Text>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration There are many variations of is a passages of lorem Ipsum.</Text>
											</Col>
											<Col className="col-txt-cliente">
												<Title level={5}>Courtney Henry</Title>
											</Col>
											<Col className="col-txt-puesto">
												<Text>Product Designer</Text>
											</Col>
										</Col>
									</Row>

									<Row className="row-cliente mg-auto">

										<Col xs={24} xl={4} className="col-img center">
											<div className="col-img-cliente" style={{ backgroundImage: "url('/images/foto-circle-4.jpg')" }} >
												<img src={"/images/icon-img-circle-01.png"} width={35} alt="iconImgCircle" className="iconLogoCircle" />
											</div>
										</Col>
										<Col xs={24} xl={20} className="col-info">
											<Col className="col-txt-comentario">
												<Text>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration There are many variations of is a passages of lorem Ipsum.</Text>
											</Col>
											<Col className="col-txt-cliente">
												<Title level={5}>Courtney Henry</Title>
											</Col>
											<Col className="col-txt-puesto">
												<Text>Product Designer</Text>
											</Col>
										</Col>
									</Row>

									<Row className="row-cliente mg-auto">

										<Col xs={24} xl={4} className="col-img center">
											<div className="col-img-cliente" style={{ backgroundImage: "url('/images/foto-circle-4.jpg')" }} >
												<img src={"/images/icon-img-circle-01.png"} width={35} alt="iconImgCircle" className="iconLogoCircle" />
											</div>
										</Col>
										<Col xs={24} xl={20} className="col-info">
											<Col className="col-txt-comentario">
												<Text>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration There are many variations of is a passages of lorem Ipsum.</Text>
											</Col>
											<Col className="col-txt-cliente">
												<Title level={5}>Courtney Henry</Title>
											</Col>
											<Col className="col-txt-puesto">
												<Text>Product Designer</Text>
											</Col>
										</Col>
									</Row>
								</Carousel>
							</Col>
						</Row>
					</Row>



					<Row id="sec-5" className="center lnd-sec-4" >
						<Col span={24} className="mxh-25prs" >
							<img src={"/images/bg-landing-2.png"} className="landing-header-img-2" />
						</Col>

						<Row className="center row-bar-sub-blue">
							<Col xs={22} xl={14} className="xl-align-left" >
								<Title level={4} className="text-white" >Suscribete a nuestro boletín de noticias <br /> para recibir nuevas promociones. </Title>
							</Col>
							<Col xs={22} xl={10} className="center col-search wd-70prs">
								<Search className="inputSearch" placeholder="Escribe tu correo electrónico." />
								<Button className="btnSearch">
									<IconGotoLanding className="iconGotoLanding" />
								</Button>
							</Col>
						</Row>
					</Row>
				</Content>
			</Layout>
		)
	}
}