import React, { Component } from 'react';
import { Row, Col, Button, Input, Typography, Form, Modal, message } from 'antd'
import axios from 'axios';
//css
import '../../styles/Modals.css'

const { Title, Text } = Typography;

export default class ModalForgotPassword extends Component {

	constructor(props) {
		super(props)
		this.state = {
			
		}
		
	}



	onChange = (e) => {
		this.setState({ empresa: e.target.checked })
	}

	/**
     * @memberof ModalForgotPassword
     * 
     * @method handleSubmit
     * @description Envia una peticion para enviar un correo para restablecer la contraseña
     */
	handleSubmit = (values) => {
		
		axios.put('/password/recovery',{
			email: values.email,
		}).then(response => {
			this.props.onCancel()
			message.success('Verifica tu correo')
		}).catch(error => {
			console.log(error)
			message.error('Error el enviar el correo')
		})
	}


	render() {


		return (
			<Modal
				className="modal-bauens"
				visible={this.props.visible}
				onCancel={() => { this.props.onCancel() }}
				footer={null}
				width={500}
			>
				<Row>
					<Col span={24} className="center mt-3">
						<Title level={3} className="modal-title" >Restablecer contraseña</Title>
					</Col>
				</Row>
				<Row align='center'>
					<Col span={20}>
						<Form
							className="modal-login-form"
							layout='vertical'
							onFinish={this.handleSubmit}
						>
							<Form.Item
								label="Correo electronico"
								name="email"
								rules={[
									{
										required: true,
										message: 'Debe ingresar su correo electrónico.'
									},
									{
										type: 'email',
										message: 'Debe ingresar un correo válido.',
									},
								]}
							>
								<Input/>
							</Form.Item>
							<Button className="btn-login mt-1" htmlType="submit">
								Enviar
							</Button>
						</Form>
					</Col>
				</Row>
				<Row>
					<Col span={22} className="flex-end mt-1">
						<img src={"/images/logo-2.svg"} height={30} alt="logo" />
					</Col>
				</Row>
			</Modal>
		)
	}
}

