import React, { Component } from 'react';
import { Row, Col, Button, Input, Select, Typography, Checkbox, Form, Modal, Tabs, message } from 'antd'
import { Redirect } from "react-router";
import axios from 'axios';
//componentes
import { IconFacebook, IconGoogle } from '../Widgets/Iconos'
import InputPhone from '../Widgets/Inputs/InputPhone/InputPhone'
import Cookies from 'universal-cookie';
import { User, SetUser } from '../../Context/User';

import FacebookLogin from 'react-facebook-login';

import GoogleLogin from 'react-google-login';


import GoogleOneTapLogin from 'react-google-one-tap-login';

//css
import '../../styles/Modals.css'



const { Option } = Select;
const { Title, Text } = Typography;
const { TabPane } = Tabs


class ModalPassword extends Component {

	constructor(props) {
		super(props)
		this.state = {
			empresa: false,

			statusLoading: 0
		}

	}


	/**
	 * @memberof ModalPassword
	 * 
	 * @method onFinish
	 * @description Verifica y actualiza la contraseña
	 */
	onFinish = (values) => {
		axios.get('/password/verify',{
			params: {
				password_old: values.password_old,
				password_new: values.password,
			}
		}).then(response => {
			console.log(response)
			message.success('Contraseña Actualizada')
			this.props.onCancel()
		}).catch(error => {
			message.error('Error al Actualizar')
			console.log(error)
		})
	}



	render() {

		if (this.state.redierctToLanding) {
			return <Redirect to='/' />
		}

		return (
			<Modal
				className="modal-bauens"
				visible={this.props.visible}
				onCancel={() => { this.props.onCancel() }}
				footer={null}
				width={500}
				destroyOnClose={true}
			>
				<Row>
					<Col span={24} className="center">
						<Title level={3} className="modal-title" >Actualizar Contraseña</Title>
					</Col>
				</Row>
				<Row align='center'>
					<Col span={20}>
						<Tabs defaultActiveKey="1">
							<TabPane tab="" key="1">
								<Form
									className="modal-login-form"
									layout='vertical'
									onFinish={this.onFinish}
									initialValues={{
										password: ''
									}}
								>
									<Form.Item
										label="Contraseña actual"
										name="password_old"
										rules={[
								        	{
								    			required: true,
								        		message: 'Ingrese su contraseña actual',
								       		},
								        ]}
									>
										<Input.Password />
									</Form.Item>

									<div className="hr" />

									<Form.Item
								        label="Contraseña nueva"
								        name="password"
								        rules={[
								        	{
								    			required: true,
								        		message: 'Ingrese su nueva contraseña',
								       		},
								        ]}
								        hasFeedback
								    >
								        <Input.Password />
								    </Form.Item>

								    <Form.Item
								    	name="confirm"
								        label="Confirmar Contraseña"
								        dependencies={['password']}
								        hasFeedback
								        rules={[
								          {
								            required: true,
								            message: 'Por favor, confirme su contraseña',
								          },
								          ({ getFieldValue }) => ({
								            validator(_, value) {
								              if (!value || getFieldValue('password') === value) {
								                return Promise.resolve();
								              }
								              return Promise.reject(new Error('Las contraseñas no coinciden'));
								            },
								          }),
								        ]}
								      >
								        <Input.Password />
								      </Form.Item>

									<Button className="btn-login" htmlType="submit">
										Aceptar
									</Button>

								</Form>
							</TabPane>
						</Tabs>
					</Col>
				</Row>
			</Modal>
		)
	}
}




export default function (props) {

	return <ModalPassword {...props}
		setUser={React.useContext(SetUser)}
		user={React.useContext(User)}
	/>
}