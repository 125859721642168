import React, { Component } from 'react';
import { Select, Typography, Modal, Tabs, List, Form, Checkbox } from 'antd'


import { CalendarOutlined, IdcardOutlined, StopOutlined } from '@ant-design/icons';
import { FaUserTie, FaChevronCircleRight } from 'react-icons/fa';


import axios from 'axios'

const { Title } = Typography;



export default class ModalContacto extends Component {

    constructor(props) {
        super(props)
        this.carousel = React.createRef();

        this.state = {

        }
    }

    updateImagen = ({ difuminar, accion}) => {
        axios.post('/propiedad/add/imagenes/update',{
            difuminar,
            accion,            
        })
            .then(x => {
                this.props?.onSuccess()  
            })

    }





    render() {
        return (
            <Modal
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                footer={null}
                destroyOnClose={true}
            >
                <Form >
                    <List
                        className="list-modal-action"
                        itemLayout="horizontal"
                        bordered={false}
                        header={<Title className="list-modal-action-title" level={4}>Seleccione la acción de la imagen</Title>}

                        bordered
                        dataSource={[
                            {
                                content: 'Solicitar un Tour',
                                icon: <CalendarOutlined />,
                                action: e => this.updateImagen({ accion: 1 })
                            },
                            {
                                content: 'Solicitar una cita con un agente',
                                icon: <FaUserTie />,
                                action: e => this.updateImagen({ accion: 2 })
                            },
                            {
                                content: 'Contactar Agente',
                                icon: <IdcardOutlined size="1.3em" style={{ fontSize: "1.2em" }} />,
                                action: e => this.updateImagen({ accion: 3 })
                            },
                            {
                                content: 'No hacer nada',
                                icon: <StopOutlined size="1.3em" style={{ fontSize: "1.2em" }} />,
                                action: e => this.updateImagen({ accion: 0 })
                            },
                        ]}
                        renderItem={item => (
                            <List.Item
                                actions={[<FaChevronCircleRight style={{ color: "#1890FF", height: "1.3em", fontSize: "1.4em" }} />]}
                            >
                                <List.Item.Meta
                                    avatar={item.icon}
                                    title={item.content}
                                />

                            </List.Item>
                        )}

                        footer={<Checkbox onClick={e => this.updateImagen({ difuminar: e.target.value })} className="list-modal-action-checkbox" >Difuminar la imagen para mostrar el contenido</Checkbox>}
                    />
                </Form>

            </Modal>
        )
    }
}