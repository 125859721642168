import React, { useState, useEffect } from 'react';
import { Row, Popover, Typography, Spin } from 'antd'

import MapboxGL from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import MapGL, { _useMapControl as useMapControl, MapContext, Marker, LinearInterpolator, FlyToInterpolator, MapController } from 'react-map-gl';

import CustomClassController from '../Mapa/CustomClassController'

import { IconMapMarker } from '../Iconos'

import '../Mapa/Mapa.css'; // eslint-disable-line import/no-webpack-loader-syntax

MapboxGL.accessToken = process.env.REACT_APP_MAPBOX_API



export default function (props) {

    const { onChange = () => { }, address = {} } = props;

    /** @type {*} SI está visible o no el dropdown */
    let [viewport, setViewport] = useState({
        latitude: ((Array.isArray(address?.center) && address?.center[0]) ? address?.center[0] : 19.41944),
        longitude: ((Array.isArray(address?.center) && address?.center[1]) ? address?.center[1] : -99.14556),
        zoom: 10
    })

    let [currentAddress, setCurrentAddress] = useState({})

    let mapReference = null

    useEffect(() => didUpdate());

    /** 
     * @method controller
     * @description Custom controller
     * @type {*}
     * */
    const controller = new CustomClassController()
    controller.callbackPanend = () => {
        const map = mapReference?.getMap()

        if (map !== undefined)
            onChange({
                bounds: map.getBounds(),
                zoom: map.getZoom(),
                address: currentAddress
            })
    }
    
    /**
     * @description Actualizamos la dirección en caso de que cambie.
     */
    const didUpdate = () => {
        if (
            /* Si hay
                Si el estado está vacio y la dirección no,
                entonces se actualiza */
            (
                // (address !== undefined && address !== null) &&
                currentAddress.id == undefined &&
                address?.id !== undefined
            )
            ||
            (
                //Si los centros son arreglos, (esdecir, hay validacion)
                Array.isArray(address?.center) &&
                Array.isArray(currentAddress?.center) &&
                //Si ya hay una dirección, validar que sean diferentes los centros
                (
                    address?.center[0] !== currentAddress?.center[0]
                    ||
                    address?.center[1] !== currentAddress?.center[1]
                )
            )
        ) {
            setCurrentAddress({ ...currentAddress, ...address })
            setViewport({ ...viewport, latitude: address.center[1], longitude: address.center[0] })
            getZoom(address.places_type)
        }
    }
    
    /**
     *
     *
     * @param {*} longitud
     * @param {*} latitud
     */
    const updateAddressByCords = (longitud, latitud) => {
        fetch(`${MapboxGL.config.API_URL}/geocoding/v5/mapbox.places/${longitud},${latitud}.json?access_token=${MapboxGL.accessToken}&language=es`)
            .then(response => response.json().then(data => {
                if (Array.isArray(data.features) && data.features.length > 0 && data.features[0].center.length > 1) {
                    setCurrentAddress(data.features[0])
                    onChange({ address: data.features[0] })
                }
            }))
    }

    /**
     *
     *
     * @memberof Mapa
     * @method getZoom
     * 
     * @description Obtenemos el ZOOM del mapa con base al tipo de zoom
     */
    const getZoom = (places_type_array) => {
        if (Array.isArray(places_type_array) && places_type_array.length > 0) {
            switch (places_type_array[0]) {
                case 'country':
                    return 4;

                case 'region':
                    return 11;

                case 'postcode':
                    return 14;

                case 'district':
                    return 8;

                case 'place':
                    return 11;

                case 'locality':
                    return 13;

                case 'neighborhood':
                    return 13;

                case 'address':
                    return 13;

                case 'poi':
                    return 15;

                default:
                    return 15;
            }
        }
        return 11
    }

    return <MapGL
        
        {...viewport}
        controller={controller}

        ref={ref => mapReference = ref}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API}
        mapStyle='mapbox://styles/mapbox/streets-v11'
        width="100%"
        height="100%"
        dragRotate={false}
        transitionInterpolator={new LinearInterpolator()}
        onViewportChange={setViewport}
    >
        {(Array.isArray(currentAddress?.center) && currentAddress?.center.length === 2)
            ?
            <Marker 
                draggable 
                longitude={currentAddress.center[0]} 
                latitude={currentAddress.center[1]}
                onDragEnd={pointer => {
                        if (Array.isArray(pointer.lngLat) && pointer.lngLat.length > 1) {
                            let newAddress = {}
                            newAddress.center = []
                            newAddress.center[0] = pointer.lngLat[0]
                            newAddress.center[1] = pointer.lngLat[1]
                            setCurrentAddress(newAddress)
                            updateAddressByCords(pointer.lngLat[0], pointer.lngLat[1])
                        }

                    }}
            >
                <IconMapMarker className="marker" style={{ position: 'relative', left: -25, top: -45 }} />
            </Marker>
            :
            undefined
        }

        {/* {(this.props?.propiedad && this.props?.propiedad?.localizacion !== null) ? <PropertyMarker longitude={this.props.propiedad.localizacion.center[0]} latitude={this.props.propiedad.localizacion.center[1]} propiedad={this.props.propiedad} /> : null}
        {this.props?.propiedades?.map(propiedad => (propiedad.localizacion && propiedad.localizacion !== null) ? <PropertyMarker longitude={propiedad.localizacion.center[0]} latitude={propiedad.localizacion.center[1]} propiedad={propiedad} /> : null)} */}
    </MapGL>



}


