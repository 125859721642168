import React, { Component } from 'react';
import { Row, Col, Empty } from 'antd'
import { CardTipoPropiedadSkeleton, CardApp } from '../Widgets/cards/Cards'
import { AntCloudOutlined } from '@ant-design/icons';
/**
 *
 *
 * @export
 * @class AjustesApps
 * @extends {Component}
 */
export default class AjustesApps extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            data: []
        }
    }

    render() {
        return (

            <Row gutter={[16, 16]} className="bg-white mt-1 w-100 p-1 mr-2 form-ajustes">

                {(this.state.loading == true) ? <CardTipoPropiedadSkeleton />
                    :
                    (this.state.data.length > 0) ?
                        this.state.data.map((app, index) => {
                            return <CardApp  {...app} />
                        })
                        : <Col span={24}><Empty description="Próximamente" image={<AntCloudOutlined />} imageStyle={{fontSize:80}} /></Col>
                }
            </Row>
        )
    }
}