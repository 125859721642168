import React, { Component } from 'react'
import { Form, Input, Select, Upload, Button, Spin, message } from 'antd'
import { InboxOutlined } from '@ant-design/icons';
import InputPhone from '../Widgets/Inputs/InputPhone/InputPhone';
import axios from 'axios';
import { User } from '../../Context/User';
const { Option } = Select;

// responsividad del formulario
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 12 },
        lg: { span: 8, push: 1 },
        xl: { span: 8, push: 1 },
        xxl: { span: 8, push: 1 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 },
        lg: { span: 12 },
        xl: { span: 12 },
        xxl: { span: 12 }
    },
};

export default class AjustesPerfil extends Component {


    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            usuario: null
        }
    }

    static contextType = User;
    preventCycle = false
    //Referencia de form
    form = React.createRef();





    /**
     *
     *
     * @memberof AjustesPerfil
     * @description Cargar los datos del form
     */
    componentDidMount() {

        if (this.context !== null){
            this.preventCycle = true
            this.getAgente()
        }

    }


    /**
     * @memberof AjustesPerfil
     *
     * @description Trae la informacion del usuario logeado
     *
     */
    getAgente = () => {
        this.setState({ loading: true })
        axios.get('/usuario/get', {
            params: {
                //id se obtiene en el server
            }
        })
            .then(res => {
                this.setState({ usuario: res.data.data });
                this.formFill();
            })
            .catch(error => {
                console.log('error', error)
            })
            .finally(f => {
                this.setState({ loading: false })
            })

    }


    /**
     *
     *
     * @memberof AjustesPerfil
     * @description Llena el formulario con la informacion del state
     */
    formFill() {

        let user = this.state.usuario;
        this.form.current.setFieldsValue({
            nombres: user.nombres,
            apellidos: user.apellidos,
            url: user.url,
            categoria: user.agencia_id?.tipo,
            titulo: user.titulo,
            email: user.email,
            telefono:{phonecode:user.lada,phone:user.telefono},
            lada: user.lada,
            telefono: user.telefono,
            facebook: user.facebook,
            instagram: user.instagram
        });

    }


    /**
     *
     *
     * @memberof AjustesPerfil
     * @description Manda la peticion al Ws para actualizar los datos del usuario
     */
    onSubmit = (values) => {
        axios.post('/usuario/update', {
            ...values,
            telefono:{
                phone: this.state.usuario?.telefono,
                phonecode: this.state.usuario?.lada
            }
        })
            .then(res => {
                message.success({ content: '¡Perfil Actualizado!', className: "message-sucess" })
            })
            .catch(error => {
                message.error({ content: 'Oooops , ocurrido un error al guardar', className: "message-error" })
            })
    }

    onChangePhone = (values)  => {
        this.setState({usuario: {...this.state.usuario,telefono: values.phone, lada: values.phonecode}}) 
    }
    

    render() {
        return (
            <Spin tip="Cargando" spinning={this.state.loading} >
                <User.Consumer>
                    {value => {
                        if (this.context !== null && this.preventCycle == false) {
                            this.preventCycle = true
                            this.getAgente();
                        }
                    }}
                </User.Consumer>
                <Form  {...formItemLayout} layout="horizontal" onFinish={this.onSubmit} requiredMark={false} ref={this.form} className="bg-white mt-1 w-100 p-1 mr-2 form-ajustes">
                    <Form.Item name="nombres" labelAlign={"left"} label="Nombre del Profesional" >
                        <Input />
                    </Form.Item>
                    <Form.Item name="apellidos" labelAlign={"left"} label="Apellidos del Profesional" >
                        <Input />
                    </Form.Item>
                    <Form.Item name="url" labelAlign={"left"} label="Usuario URL" >
                        <Input addonBefore="https://bauens.com/" />
                    </Form.Item>
                    {/* <Form.Item label="Foto de Perfil" labelAlign={"left"}
                        name="files"
                        valuePropName="fileList"
                        getValueFromEvent={this.normFile}
                        className="content-uploader"
                    >
                        <Upload.Dragger name="files"
                            {...props}

                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Haz clic o arrastra la imagén a esta área</p>
                        </Upload.Dragger>

                    </Form.Item> */}
                    <Form.Item name="titulo" labelAlign={"left"} label="Titulo Profesional" >
                        <Input />
                    </Form.Item>
                    <Form.Item labelAlign={"left"} label="Telefono" >
                        <InputPhone onChange={this.onChangePhone} value={{ phonecode: this.state.usuario?.lada, phone: this.state.usuario?.telefono }}
                        />
                    </Form.Item>
                    <Form.Item name="email" labelAlign={"left"} label="Email" >
                        <Input />
                    </Form.Item>

                    <Form.Item wrapperCol={{ sm: { span: 12, offset: 12 } }} className="mt-1">
                        <Button type="primary" shape="round" className="btn-yellow" htmlType="submit">
                            Guardar
                        </Button>
                    </Form.Item>

                </Form>
            </Spin>
        )
    }
}