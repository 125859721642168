import React, { Component, useState, useEffect } from 'react'
import { Dropdown, Slider, Typography, Menu, Button, Select, AutoComplete, Row, Col, Statistic } from 'antd'


import axios from 'axios'

//css
import './filtros.css'

var SiPrefix = require('si-prefix');


// scale with hecto and deca prefixes
var scale = new SiPrefix.Scale({minor: true}),
    unit = new SiPrefix.Unit(scale, '');

const { Text } = Typography;
const { Option } = Select;


/**
 *
 *
 * @method getFormatStringByNumber
 * @description Obtener el formato de moneda con base a un número
 * 
 * @param {*} value Formato numerico para transformar a moneda
 * @return {*} value Formato string en moneda
 * 
 */
const getFormatStringByNumber = (value) => '$ ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

/**
 *
 *
 * @method getFormatStringByNumber
 * @description Obtener el formato de moneda con base a un número
 * 
 * @param {*} value value Formato string en moneda
 * @return {*} Valor numerico de la moneda
 * 
 */
const getNumberFromFormatString = (value) => parseFloat(value.replace(/\$\s?|(,*)/g, ''))

/**
 *
 * @description Opciones de valores numericos
 * 
 */
const opciones = [100000, 200000, 300000, 500000, 800000, 1000000, 5000000, 10000000, 50000000, 100000000].map(value => ({ value, label: getFormatStringByNumber(value) }))



const defaultMaximum = 50000000
const defaultMaximumLimitSlider =100000000


/**
 *
 *
 * @export
 * @param {*} { value = {}, onChange }
 * @return {*} 
 */
export default function Precio({ value = {}, onChange }) {


	/** @type {*} Limite del slider */
	let limitSlider = 100000000

	/** @type {*} SI está visible o no el dropdown */
	let [visible, setVisible] = useState(null)

	/** @type {*} Valor minimo a buscar */
	let [min, setMin] = useState(10000)

	/** @type {*} Valor máximo  a buscar */
	let [max, setMax] = useState(100000000)

	/** @type {*} Valor en formato de moneada, de la variable min*/
	let [minStr, setMinStr] = useState(getFormatStringByNumber(min.toString()))

	/** @type {*} Valor del formato de moneda de la variable max*/
	let [maxStr, setMaxStr] = useState(getFormatStringByNumber(max.toString()))

	/** @type {*} Lista de divisas*/
	let [divisas, setDivisas] = useState([])

	/** @type {*} Divisa actual seleccionada*/
	let [divisa, setDivisa] = useState(null)
	
	/**
	 *
	 * @memberof Precio
	 *
	 * @param {*} { value = {}, onChange }
	 * @return {*}
	 * 
	 * @description "componentDidMount", obtenemos las divisas 
	 */	
	useEffect(() => {
		getDivisas();
	}, []);

	/**
	 *
	 * @memberof Precio
	 * @description Obtenemos la lista de divisas y si no está vacia declaramos el primer elemento por defecto. 
	 * 
	 * @method triggerChange
	 * @description AL hacer algún cambio de valor, actuqlaizamos para el formulario de ANTD
	 */	
		 const triggerChange = (changedValue) => {
			if (typeof onChange === "function")
				onChange({ max, min, divisa, ...value, ...changedValue });
		}


	/**
	 *
	 * @memberof Precio
	 *
	 * @description Obtenemos la lista de divisas y si no está vacia declaramos el primer elemento por defecto. 
	 */	
	const getDivisas = () => {
		axios.get('/divisas')
			.then(({data}) => {

				setDivisas(data.data)
				if (data.data.length > 0)
					setDivisa(data.data[0]._id)
			})
	}




	/**
	 *
	 * @memberof Precio
	 * 
	 * @method onChangeSlider
	 * @description Al cambiar el valor del slider, 
	 */
	const onChangeSlider = value => {
		if (value[0] < value[1]) {

			setMinStr(getFormatStringByNumber(value[0]))
			setMin(value[0])

			setMaxStr(getFormatStringByNumber(value[1]))
			setMax(value[1])

			//triggerChange({ max, min, divisa })
		}
	}

	/**
	 *
	 * @memberof Precio
	 * 
	 * @method onChangeMax
	 * @description Cuando se cambia el valor maximo, se actualiza el campo.
	 */
	const onChangeMax = value => {
		const max = (typeof value === "number") ? value : getNumberFromFormatString(value)
		if (max > min) {
			setMaxStr(getFormatStringByNumber(max))
			setMax(max)
			triggerChange({ max, min, divisa })
		}
	}

	/**
	 *
	 * @memberof Precio
	 * 
	 * @method onChangeMin
	 * @description Cuando se cambia el valor minimo, se actualiza el campo.
	 */
	const onChangeMin = value => {
		console.log('value', value);
		const min = (typeof value === "number") ? value : getNumberFromFormatString(value)
		if (max > min) {
			setMinStr(getFormatStringByNumber(min))
			setMin(min)
			triggerChange({ max, min, divisa })
		}
	}

	/**
	 *
	 * @memberof Precio
	 * 
	 * @method onChangeDivisa
	 * @description Actualizamos el valor al actualizar una divisa.
	 */
	const onChangeDivisa = divisa => {
		console.log('divisa',divisa);
		setDivisa(divisa )
		triggerChange({max, min, divisa})
	}




	//Con base al step, establecemos el limite.
	if (max > defaultMaximum && limitSlider !== defaultMaximumLimitSlider) 
		limitSlider = 100000000
	else if (max < defaultMaximum && limitSlider !== defaultMaximum) 
		limitSlider = defaultMaximum


	/**
	 *
	 * @memberof Precio
	 * 
	 * @method onAfterChange
	 * @description se manda a llamar cuando el usuario suelta el click del slider, con esto evitamos ejecutar el metodo del filtrar
	 * varias veces antes del que el usuario termine de usar el slider
	 */
	const onAfterChange = value => {
    	onChange({max: value[1], min: value[0], divisa})
  	};


	const renderPrecio = () => {

		if (!isNaN(min) && !isNaN(max))
			return  [
				<strong> $ </strong>,
				unit.format(min, ''),
				<strong> a </strong>,
				unit.format(max, ''),
			]		
		return "Precio"
	}



	return (
		<Dropdown
			overlay={<Menu className="dropdown-menu">
				<div className="flex-column">
					<Text className="dropdown-title">Rango de Precios</Text>
					<small className="text-small">Indique el rango de precios deseado.</small>
					<Row gutter={20}>
						<Col span={12}>
							<AutoComplete
								style={{ width: '100%' }}
								options={opciones}
								value={minStr}
								onChange={onChangeMin}
								suffixIcon="$"
							/>

						</Col>

						<Col span={12} offeset={2}>
							<AutoComplete
								style={{ width: '100%' }}
								options={opciones}
								value={maxStr}
								onChange={onChangeMax}
								suffixIcon="$"
							/>
						</Col>
					</Row>

					<Slider
						min={100}
						max={limitSlider}
						range={true}
						defaultValue={[min, max]}
						value={[min, max]}
						step={10000}
						tipFormatter={quantity => quantity.toMoney(0)}
						onChange={onChangeSlider}
						className="slider"
						onAfterChange={onAfterChange}
					/>
					<Select
						value={divisa}
						style={{ marginRight: '0px', marginLeft: 'auto', minWidth:'80%' }}

						onChange={onChangeDivisa}
					>
						{divisas.map(divisa => <Option value={divisa._id}>({divisa.code}) {divisa.nombre}</Option>)}

					</Select>
				</div>
			</Menu>}

			onVisibleChange={setVisible}
			visible={visible}
			trigger={['click']}
		>
			<Button style={{ width: '100%', padding: '0', textAlign: 'center' }}> Precio </Button>
		</Dropdown>
	)
}


