import React, { Component, useState } from 'react'
import { Layout, Row, Col, PageHeader, Select, Spin, Button, Menu, Carousel } from 'antd'
import { FaUserCircle, FaShareAlt, FaDollarSign, FaHome, FaLock } from 'react-icons/fa'
import { User } from '../../Context/User';

import { Switch, Link, Route,   useRouteMatch } from 'react-router-dom';

import AjustesPerfil from './Perfil';
import AjustesAgencia from './Agencia';
import AjustesApps from './Apps';
import AjustesPagos from './Pagos';
import AjustesSeguridad from './Seguridad';

import '../../styles/ajustes.css';


const { Content } = Layout;
const axios = require('axios')

/**
 *
 *
 * @export
 * @class Ajustes
 * @extends {Component}
 * @description Contenedor de los steps con la informacion de la propiedad y el preview
 */

class Ajustes extends Component {
        
    static contextType = User

    constructor(props){
        super(props)
        this.state = {
            view: 'Perfil'
        }
    }

    changeView = (view) => {
        this.setState({view: view.key})
    }

    renderView = () => {

        let views = {
            'Perfil': <AjustesPerfil/>,
            'Agencia': <AjustesAgencia/>,
            'Apps': <AjustesApps/>,
            'Pagos': <AjustesPagos/>
        }

        return views[this.state.view]
    }

    render(){

        const user = this.context;

        return (
            <Layout className="bg-gray no-overflow-x">
                <PageHeader title={this.state.view} className="bg-white mt-1 mr-2 ml-2 " />
                <Content className="bg-gray pl-2 pr-2">
                    <Row gutter={[16, 32]} justify="start" >
                        <Col sm={{ span: 24, order: 2 }} md={{ span: 24, order: 2 }} lg={{ span: 4, order: 1 }} xl={{ span: 4, order: 1 }} xxl={{ span: 4, order: 1 }}>
                            <Menu mode="vertical" className="menu-ajustes mt-1 mb-1" onClick={this.changeView}>
                                <Menu.Item key="Perfil">
                                    <FaUserCircle /> &nbsp; Perfil
                                </Menu.Item>
                                {(user?.agencia_id !== undefined && user?.agencia_id !== null) ? <Menu.Item key="Agencia">
                                    <FaHome />&nbsp; Agencia
                                </Menu.Item> : null}
                                <Menu.Item key="Apps">
                                    <FaShareAlt />&nbsp; Apps
                                </Menu.Item>
                                <Menu.Item key="Pagos">
                                    <FaDollarSign />&nbsp; Pagos
                                </Menu.Item>
                            </Menu>
                        </Col>
                        <Col sm={{ span: 24, order: 1 }} md={{ span: 24, order: 1 }} lg={{ span: 20, order: 2 }} xl={{ span: 20, order: 2 }} xxl={{ span: 20, order: 2 }} >
                            {this.renderView()}
                        </Col>
                    </Row>
                </Content>
            </Layout>
        );
    }
    
}

export default class Ajusstes extends Component {

    static contextType = User

    constructor(props){
        super(props)
        this.state = {
            view: 'Perfil'
        }
    }

    preventCycle = false

    render() {
        let user = this.context;

        return (
            <Layout className="bg-gray no-overflow-x">

                <User.Consumer>
                    {value => {
                        if (this.context !== null && this.preventCycle == false) {
                            this.preventCycle = true
                        }
                    }}
                </User.Consumer>
                <PageHeader title={this.state.view} className="bg-white mt-1 mr-2 ml-2 " />
                <Content className="bg-gray pl-2 pr-2">
                    <Row gutter={[16, 32]} justify="start" >
                        <Col sm={{ span: 24, order: 2 }} md={{ span: 24, order: 2 }} lg={{ span: 4, order: 1 }} xl={{ span: 4, order: 1 }} xxl={{ span: 4, order: 1 }}>
                            <Menu mode="vertical" className="menu-ajustes mt-1 mb-1"  >
                                <Menu.Item key="1">
                                    <Link to="/professionals/ajustes"> <FaUserCircle /> &nbsp; Perfil  </Link >
                                </Menu.Item>

                                <Menu.Item key="5">
                                    <Link to="/professionals/ajustes/seguridad"> <FaLock /> &nbsp; Seguridad  </Link >
                                </Menu.Item>

                                {(user?.agencia_id !== undefined && user?.agencia_id !== null) ? <Menu.Item key="2">
                                    <Link to="/professionals/ajustes/agencia"> <FaHome />&nbsp; Agencia  </Link >
                                </Menu.Item> : null}
                                <Menu.Item key="3">
                                    <Link to="/professionals/ajustes/apps"> <FaShareAlt />&nbsp; Apps  </Link >
                                </Menu.Item>
                                <Menu.Item key="4">
                                    <Link to="/professionals/ajustes/pagos"> <FaDollarSign />&nbsp; Pagos  </Link >
                                </Menu.Item>

                            </Menu>
                        </Col>
                        <Col sm={{ span: 24, order: 1 }} md={{ span: 24, order: 1 }} lg={{ span: 20, order: 2 }} xl={{ span: 20, order: 2 }} xxl={{ span: 20, order: 2 }} >
                            <Switch >
                                <Route exact path="/professionals/ajustes/agencia" component={AjustesAgencia} />
                                <Route exact path="/professionals/ajustes/apps" component={AjustesApps} />
                                <Route exact path="/professionals/ajustes/pagos" component={AjustesPagos} />
                                <Route exact path="/professionals/ajustes/seguridad" component={AjustesSeguridad} />
                                <Route exact path="/professionals/ajustes" component={AjustesPerfil} />
                            </Switch>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        );
    }
};

