import React, { Component } from 'react';
import { Row, Col, Button, Input, Form, message } from 'antd'
import InputPhone from "../../Widgets/Inputs/InputPhone/InputPhone"
import axios from 'axios'
import { User, SetUser } from '../../../Context/User'

//css
import '../../../styles/Inmueble.css'
import '../../../styles/Modals.css'


/**
 *
 *
 * @export
 * @class FormContacto
 * @extends {Component}
 */
export default class FormContacto extends Component{
    constructor(props){
        super(props)
    }

    onSubmit=(values)=>{
        axios.post('/prospectos/add',{
            nombres:values.nombres,
            apellidos:values.apellidos,
            email:values.email,
            lada:values.telefono.phonecode,
            telefono:values.telefono.phone,
            mensaje:values.mensaje,
            propiedad_id:this.props.propiedad._id

        })
        .then(res=>{
            message.success( {content:'¡Mensaje Enviado!. El agente se pondrá en contacto lo mas pronto posible',className:'success-message' }) 

        })
        .catch(error=>{
            console.log('error form contacto',error)
            message.error( {content:'!Mensaje No Enviado¡.Por favor intentelo de nuevo, si el error persiste, recargue la pagina por favor',className:'success-message' }) 

        })
        .finally(()=>{
        })
    }

    render(){
        return (
            <Form layout={'vertical'} onFinish={this.onSubmit} requiredMark={false} initialValues={{ telefono :{ phonecode: '52' } }}>
                <Form.Item label="Nombre" name="nombres"  rules={[{ required: true, message: "Indique su nombre por favor." }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Apellidos" name="apellidos"  rules={[{ required: true, message: "Indique su apellido por favor" }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Correo Electronico" name="email"  rules={[{ required: true, message: "Indique su correo electrónico por favor" }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Telefono" name="telefono"  rules={[{ required: true, message: "Indique su telefono por favor" }]}>
                    <InputPhone />
                </Form.Item>
                <Form.Item label="Mensaje" name="mensaje"  rules={[{ required: true, message: "Escribamos su duda para poder atenderlo adecuadamente" }]}>
                    <Input.TextArea />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" className="btn-yellow">Solicitar</Button>
                </Form.Item>

            </Form>
        )
    }
   
}