import React, { Component, useState, useEffect } from 'react'
import { Row, Col, AutoComplete, Dropdown, Typography, Checkbox, Slider, Menu, Button, Input, Divider, Tag, Tooltip } from 'antd'

import { RiHandCoinFill, RiCheckFill, RiHashtagFill } from 'react-icons/ri'
import { NumberOutlined } from '@ant-design/icons';
//css
import './filtros.css'
import moment from 'moment';
import axios from 'axios';

const { Text } = Typography;
const { Search } = Input;
const options_tamano = [
	{ value: 50, label: "50 m2" },
	{ value: 75, label: "75 m2" },
	{ value: 100, label: "100 m2" },
	{ value: 200, label: "200 m2" },
	{ value: 300, label: "300 m2" },
]

const anos = new Array((moment().year() + 1) - 1700).fill(0).map((x, index) => {
	const value = index + 1700;
	return { value, label: value }
})


function SliderFilter({
	value = {},
	onChange = () => { },
	nombre,



	formatter = i => i,
	parser = i => i,


	options_min = [],
	options_max = [],

	minLimit = 0,
	maxLimit = 1500,



	minLimitSlider = 0,
	maxLimitSlider = 1000

}) {



	/** @type {*} Valor minimo a buscar */
	let [min, setMin] = useState(minLimit)

	/** @type {*} Valor máximo  a buscar */
	let [max, setMax] = useState(maxLimit)


	/** @type {*} Valor en formato de moneada, de la variable min*/
	let [minStr, setMinStr] = useState((minLimit == null || minLimit == undefined) ? "Cualquiera" : minLimit)

	/** @type {*} Valor del formato de moneda de la variable max*/
	let [maxStr, setMaxStr] = useState((maxLimit == null || maxLimit == undefined) ? "Cualquiera" : maxLimit)

	const triggerChange = (changedValue) => {
		console.log('envio', changedValue)
		onChange({
			max,
			min,
			...value,
			...changedValue
		})
	};




	/*
	* methodOf Baños
	* description metodo que actualiza el valor minimo, cuando se ingresa en el input
	*
	*/
	const onChangeMin = min => {
		min = (typeof min === "number") ? min : parseInt(parser(min))
		if (max > min) {
			setMinStr(formatter(min.toString()))
			setMin(min)
			triggerChange({ max, min })
		}
	}

	/*
	* methodOf Baños
	* description metodo que actualiza el valor maximo, cuando se ingresa en el input
	*
	*/
	const onChangeMax = max => {
		max = (typeof max === "number") ? max : parseInt(parser(max))
		if (max > min) {
			setMaxStr(formatter(max.toString()))
			setMax(max)
			triggerChange({ max, min })
		}
	}
	/**
	 *
	 * @memberof Precio
	 * 
	 * @method onChangeSlider
	 * @description Al cambiar el valor del slider, 
	 */
	const onChangeSlider = value => {
		if (value[0] < value[1]) {

			setMinStr(formatter(value[0]))
			setMin(value[0])

			setMaxStr(formatter(value[1]))
			setMax(value[1])

			//triggerChange({ max: value[1], min: value[0], })
		}
	}

	/**
	 *
	 * @memberof Precio
	 * 
	 * @method onAfterChange
	 * @description se manda a lamar cuando el usuario suelta el click del slider, con esto evitamos ejecutar el metodo del filtrar
	 * varias veces antes del que el usuario termine de usar el slider
	 */
	const onAfterChange = value => {
    	triggerChange({max: value[1], min: value[0]})
  	};


	return [

		<Row gutter={3}>
			<Col span={10} className="extra-title-section">{nombre}</Col>
			<Col span={7}>
				<AutoComplete
					style={{ width: '100%' }}
					options={options_min}
					value={((min == null || min == 0) ? "Cualquiera" : minStr)}
					onChange={onChangeMin}
					onFocus={(event) => event.target.select()}
					addonBefore="5"
					className="extra-input"
				/>
			</Col>
			<Col span={7}>
				<AutoComplete
					style={{ width: '100%' }}
					options={options_max}
					value={(max == null) ? "Cualquiera" : maxStr}
					onChange={onChangeMax}
					onFocus={(event) => event.target.select()}
					className="extra-input"
				/>
			</Col>
		</Row>,
		<Slider
			min={minLimitSlider}
			max={maxLimitSlider}
			onChange={onChangeSlider}
			range={true}
			value={[min, ((max == null) ? maxLimitSlider : max)]}
			onAfterChange={onAfterChange}
			// tooltipVisible={true}
			className="extra-slider"
			tipFormatter={value => <span className="indie-container">{value}</span>}
		/>
	]
}




function CategoriasSeleccionadas( {value = {}, onChange = () => {}  }) {

	/** @type {*} Lista de divisas*/
	let [categorias, setCategorias] = useState([])

	/** @type {*} Divisa actual seleccionada*/
	let [categoriasCaracteristicasSelected, setCategoriasCaracteristicasSelected] = useState(value)

	/** @type {*} SI está visible o no el dropdown */
	let [update, setUpdate] = useState(false)



	const triggerChange = ({ caracteristica } = {}) => {
		setUpdate(!update)
		onChange(caracteristica)
	};


	/**
	 *
	 * @memberof Precio
	 *
	 * @param {*} { value = {}, onChange }
	 * @return {*}
	 * 
	 * @description "componentDidMount", obtenemos las divisas 
	 */
	useEffect(() => {
		getCategorias();


		loadCategorias()
	}, []);


	useEffect(() => {

		loadCategorias()

	});


	const loadCategorias = () => {
		if ( Array.isArray(value) && Object.keys(categoriasCaracteristicasSelected).length !==  value.length ) {
			let categoriasSelected = {}
			for (const categoria of value) {
				categoriasSelected[categoria._id] = {
					...categoria,
					caracteristicas: {}
				}
				for (const caracteristica of categoria.caracteristicas) {
					categoriasSelected[categoria._id].caracteristicas[caracteristica._id] = {
						...caracteristica,
						value: (caracteristica.cantidad > 0) ? caracteristica.cantidad : undefined 
					};
				}
			}
			setCategoriasCaracteristicasSelected(categoriasSelected)

			triggerChange({ caracteristica: categoriasSelected })
		}
	}



	/**
	 *
	 * @memberof Precio
	 *
	 * @description Obtenemos la lista de divisas y si no está vacia declaramos el primer elemento por defecto. 
	 */
	const getCategorias = () => {
		axios.get('/propiedad/categorias')
			.then(({ data }) => {
				setCategorias(data.data)
			})
	}


	/**
	 * 
	 * @param {*} param0 
	 */
	const updateCaracteristica = ({ value, categoria, caracteristica }) => {
		let categoriaCaracteristica = categoriasCaracteristicasSelected//[categoria._id]




		//Si se tiene que agregar
		if (value) {
			//Se verifica que la categoria exista

			
			if (!(categoriaCaracteristica[categoria._id])) {
				//Se agrega el objeto categoria



				// return ;

				categoriaCaracteristica[categoria._id] = {
					...categoria,
					caracteristicas: {}
				}

				// return 
				// console.log('categoriaCaracteristica categoriaCaracteristica',categoriaCaracteristica );
			}

			//Agregamos la caracteristica al arreglo. 
			categoriaCaracteristica[categoria._id].caracteristicas[caracteristica._id] = caracteristica;
		} else {

			if (categoriaCaracteristica[categoria._id] && categoriaCaracteristica[categoria._id].caracteristicas[caracteristica._id]) {
				delete categoriaCaracteristica[categoria._id].caracteristicas[caracteristica._id]

				if(Object.keys(categoriaCaracteristica[categoria._id].caracteristicas).length === 0){
					delete categoriaCaracteristica[categoria._id]
				}
			}
		}


		console.log(categoriaCaracteristica)
		setCategoriasCaracteristicasSelected(categoriaCaracteristica)
		// return 
		triggerChange({ caracteristica: categoriaCaracteristica })
	}


	const showQuantityInput = ({ categoria, caracteristica, visible, value }) => {
		let categoriaCaracteristica = categoriasCaracteristicasSelected

		categoriaCaracteristica[categoria._id].caracteristicas[caracteristica._id].inputVisible = visible


		

		if (value)
			categoriaCaracteristica[categoria._id].caracteristicas[caracteristica._id].value = value


		setCategoriasCaracteristicasSelected(categoriaCaracteristica)
		triggerChange({ caracteristica: categoriaCaracteristica })
	}

	const options = [
		{ label: 'No importa', value: '' },
		{ label: '+1', value: 1 },
		{ label: '+2', value: 2 },
		{ label: '+3', value: 3 },
		{ label: '+4', value: 4 },
		{ label: '+5', value: 5 },
		{ label: '+6', value: 6 },
		{ label: '+7', value: 7 },
	]

	return categorias.map(categoria => [
		<Divider />,
		<small className="text-small">{categoria.nombre}</small>,
		<Checkbox.Group style={{ width: '100%' }} 
			value={(categoriasCaracteristicasSelected[categoria._id]) ? Object.keys(categoriasCaracteristicasSelected[categoria._id].caracteristicas) : []}
		>
			<Row>
				{categoria.caracteristicas.map(caracteristica => <Col md={12} sm={24}>
					{console.log('categoriasCaracteristicasSelected[categoria._id] && categoriasCaracteristicasSelected[categoria._id].caracteristicas[caracteristica._id]', categoriasCaracteristicasSelected[categoria._id] && categoriasCaracteristicasSelected[categoria._id].caracteristicas[caracteristica._id])}
					<Checkbox
						className="checkbox-extra"
						value={caracteristica._id}
						onChange={value => updateCaracteristica({ 
							value: value.target.checked,
							categoria,
							caracteristica
						})}
					>
						{caracteristica.nombre}
					</Checkbox>
					{(caracteristica.tipo == 0 && categoriasCaracteristicasSelected[categoria._id] && categoriasCaracteristicasSelected[categoria._id].caracteristicas[caracteristica._id]) ?
						[
							<Tooltip title="Indique la cantidad">
								<Tag className="tag-extra" onClick={() => showQuantityInput({ categoria, caracteristica, visible: !categoriasCaracteristicasSelected[categoria._id].caracteristicas[caracteristica._id].inputVisible })}>
									#
									{(categoriasCaracteristicasSelected[categoria._id].caracteristicas[caracteristica._id].value !== undefined) ? (' +' + categoriasCaracteristicasSelected[categoria._id].caracteristicas[caracteristica._id].value) : null}</Tag>
							</Tooltip>,
							(categoriasCaracteristicasSelected[categoria._id].caracteristicas[caracteristica._id].inputVisible == true) ? <AutoComplete
								className="input-extra-option"
								placeholder="Cantidad"
								size="small"
								value={categoriasCaracteristicasSelected[categoria._id].caracteristicas[caracteristica._id].value}
								style={{ width: 'calc(100% - 30px)', marginBottom: '1em' }}
								options={options}
								onSelect={(value) => showQuantityInput({ categoria, caracteristica, visible: false, value })}
							/> : null
						]
						: null}
				</Col>)}
			</Row>

		</Checkbox.Group>
	])


}

function Extras({
	value = {},
	onChange = () => { },
}) {


	/** @type {*} SI está visible o no el dropdown */
	let [visible, setVisible] = useState(null)



	/** @type {*} SI está visible o no el dropdown */
	let [casa, setTamanoCasa] = useState({})

	/** @type {*} SI está visible o no el dropdown */
	let [lote, setTamanoLote] = useState({})


	// setAnoConstruccion
	/** @type {*} SI está visible o no el dropdown */
	let [ano, setAnoConstruccion] = useState({})


	/** @type {*} Divisa actual seleccionada*/
	let [categoriasCaracteristicasSelected, setCategoriasCaracteristicasSelected] = useState({})



	const triggerChange = (changedValue) => {
		console.log('envio',changedValue)
		onChange({
			casa,
			lote,
			ano,
			categorias: categoriasCaracteristicasSelected,
			...value,
			...changedValue
		})
	};





	return (
		<Dropdown
			placement="bottomRight"
			overlay={<Menu className="dropdown-menu extras">
				<div className="flex-column">
					{/* <Text className="dropdown-title">Palabras Clave</Text>
					<Search className="input-search" /> */}
					<SliderFilter
						nombre="Tamaño de la Casa"

						formatter={value => `${value} m²`}
						parser={value => value.replace(/m|²| /gm, '')}

						limit={750}

						minLimit={0}
						maxLimit={null}

						options_min={options_tamano}
						options_max={options_tamano.reverse()}

						onChange={(tamano_casa) => {
							setTamanoCasa(tamano_casa)

							// casa,
							// lote,
							// ano,
							// caracteristica:
							triggerChange({ casa: tamano_casa })
						}}
					/>
					<Divider />
					<SliderFilter
						nombre="Tamaño del lote"

						formatter={value => `${value} m²`}
						parser={value => value.replace(/m|²| /gm, '')}

						limit={750}

						minLimit={0}
						maxLimit={null}

						options_min={options_tamano}
						options_max={options_tamano.reverse()}

						onChange={(tamano_lote) => {
							setTamanoLote(tamano_lote)
							// casa,
							// lote,
							// ano,
							// caracteristica:
							triggerChange({ lote: tamano_lote })
						}}
					/>
					<Divider />
					<SliderFilter
						nombre="Año de Construcción"
						limit={750}
						minLimitSlider={1900}
						maxLimitSlider={2021}
						minLimit={1700}
						maxLimit={null}
						options_min={anos}
						options_max={anos.reverse()}

						onChange={(ano_construccion) => {
							setTamanoLote(ano_construccion)
							// casa,
							// lote,
							// ano,
							// caracteristica:
							triggerChange({ ano: ano_construccion })
						}}

					// onChange={(ano_construccion) => setAnoConstruccion(ano_construccion)}
					/>
					<CategoriasSeleccionadas 
						onChange={categoriasCaracteristicasSelected => {
							triggerChange({ categorias: categoriasCaracteristicasSelected })
							setCategoriasCaracteristicasSelected(categoriasCaracteristicasSelected)
						}}
					/>
				</div>
			</Menu>}

			onVisibleChange={setVisible}
			visible={visible}
			trigger={['click']}
		>
			<Button className="btn-secondary"> Más filtros </Button>
		</Dropdown>
	)

}

export {CategoriasSeleccionadas, SliderFilter};

export default Extras;