import React, { Component } from "react"
import axios from 'axios';
import { Row, Col, Card, Typography, Divider ,message} from 'antd'
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import { CardCalendarioSkeleton, } from "../../Widgets/cards/Cards";
import { timers } from "jquery";
import moment from "moment";
const { Text } = Typography;
export default class DetalleCita extends Component {



    /**
     *Creates an instance of DetalleCita.
     * @param {*} props
     * @memberof DetalleCita
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            update: true,
            cita: {}
        }
    }


    /**
     *
     *
     * @memberof DetalleCita
     * @description Manda a llamar al metodo getCitaInfo cuando el id del props sea diferente al actual
     */
    componentDidUpdate(prevPros) {
        if (this.props.cita !== prevPros.cita && this.props.cita !== undefined ) {
        console.log(this.props.cita)
            this.getCitaInfo(this.props.cita._id)
        }
    }


    /**
    *
    *
    * @memberof DetalleCita
    * @description Obtiene toda la informacion de la cita
    */
    getCitaInfo = (id) => {
        this.state.update = false;
        this.setState({ loading: true })
        console.log('get cita', id);
        axios.post('/citas/get', { id: id })
            .then(res => {
               
                this.setState({ cita: res.data.data });
            })
            .catch(error => {
                message.error('No se ha podido cargar la información de la cita')
                console.log('No se ha podido cargar la informacion de la cita', error)
            })
            .finally(f => {
                this.setState({ loading: false });
                this.state.update = true;
            })
    }


    render() {
        return (
            <>
                {(this.state.loading == true) ? <CardCalendarioSkeleton /> :
                    <Card className="align-center center card-agente-info " bodyStyle={{maxHeight:'770px',overflowY:'auto'}} bordered={false}>
                        {(this.state.cita.usuario_id?._id) ?
                            <>
                                <Row gutter={[16, 16]} align="middle" justify="center">
                                    <Col span={24} className="center">
                                        <CustomAvatar
                                            name={(this.state.cita.usuario_id?.nombre) ? this.state.cita.usuario_id.nombre : 'U'}
                                            image={(this.state.cita.usuario_id?.foto_perfil)}
                                            size={128} className="flexc-center"
                                        />
                                    </Col>
                                    <Divider plain> Contacto </Divider>
                                    <Col span={24} className="center">
                                        <Text strong className="center">{this.state.cita.usuario_id?.nombres}&nbsp;{this.state.cita.usuario_id?.apellidos}</Text>
                                    </Col>
                                </Row>

                                <Row justify="start" align="top" className="agente-row-info">
                                    <Col span={24}><Text strong>Teléfono</Text></Col>
                                    <Col span={24}><Text >{this.state.cita.usuario_id?.lada}&nbsp;{this.state.cita.usuario_id?.telefono}</Text></Col>
                                </Row>
                                <Row justify="start" align="top" className="agente-row-info">
                                    <Col span={24}><Text strong>Email</Text></Col>
                                    <Col span={24}><Text >{this.state.cita.usuario_id?.email}</Text></Col>
                                </Row>

                            </> : null}

                        <Divider plain> Información </Divider>
                        <Row justify="start" align="top" className="agente-row-info">
                            <Col span={24}><Text strong>Mensaje</Text></Col>
                            <Col span={24}><Text >{this.state.cita?.mensaje}</Text></Col>
                        </Row>
                        <Row justify="start" align="top" className="agente-row-info">
                            <Col span={24}><Text strong>Fecha</Text></Col>
                            <Col span={24}><Text >{moment(this.state.cita?.fecha).format('DD-MM-YYYY')}</Text></Col>
                        </Row>
                        <Row justify="start" align="top" className="agente-row-info">
                            <Col span={24}><Text strong>Horario</Text></Col>
                            <Col span={24}><Text >{this.state.cita.cita_inicio?.hora?.toString().padStart(2, "0")}:{this.state.cita.cita_inicio?.minutos?.toString().padStart(2, "0")}</Text></Col>
                        </Row>
                        <Divider plain> Propiedad </Divider>
                        <Row justify="start" align="top" className="agente-row-info">
                            <Col span={24}><Text strong>Nombre</Text></Col>
                            <Col span={24}><Text >{this.state.cita.propiedad_id?.nombre}</Text></Col>
                        </Row>
                        <Row justify="start" align="top" className="agente-row-info">
                            <Col span={24}><Text strong>Dirección</Text></Col>
                            <Col span={24}><Text >{this.state.cita.propiedad_id?.localizacion?.place_name}</Text></Col>
                        </Row>
                        
                    </Card>
                }
            </>
        )

    }
}