import React, { Component } from 'react'
import { Layout, Row, Col, Card, Typography, Form, Input, Button, Modal } from 'antd';
import { Link, Redirect } from "react-router-dom";
import axios from 'axios';

//css
import '../../styles/Modals.css'

const { Title } = Typography;

export default class PasswordRecovery extends Component{

	url = new URL(window.location.href);

	constructor(props){
		super(props)
		this.state = {
			return: false
		}
	}

	/**
   * @memberof PasswordRecovery
   *
   * @method handleSubmit
   * @description  Envia los datos del formulario al Servidor para recuperar la cotraseña
   *
   * @param values (array)
   * Contiene los campos del formulario para registrar al producto
   *
   * @returns response (array)
   **/
    handleSubmit = value => {
        value.email = this.url.searchParams.get('email');
        value.hash = this.url.searchParams.get('hash');
        axios.put('/password/recovery/update', value)
            .then(success => {
                Modal.success({
                    title: 'Actualizado.',
                    content: 'Se ha actualizado la contraseña',
                    onOk: e => { e(); this.setState({ return: true }) }
                });

            })
            .catch(error => {
                console.log(error.response)
                Modal.warning({
                    title: 'Error',
                    content: 'No es posible actualizar.'
                });
            });
    };

	render(){
		return(
			<Layout style={{height: '100vh'}}>
				{this.state.return ? <Redirect to="/" /> : null}
				<Row>
					<Col span={24} className="center bg-white">
						<Link to="/">
                            <img src={"/images/logo.svg"} height={60} alt="logo" />
                        </Link>
					</Col>
				</Row>
				<Row style={{height: '100%'}}>
					<Col span={24} className="center">
						<Card className="card-password modal-bauens">
							<Row>
								<Col span={24} className="center mt-2 mb-3">
									<Title level={3} className="modal-title" >Ingresa la nueva contraseña</Title>
								</Col>
							</Row>
							<Row align="center">
								<Col span={20}>
									<Form
										className="modal-login-form"
										layout='vertical'
										onFinish={this.handleSubmit}
									>
										<Form.Item
											label="Contraseña"
											name="password"
											rules={[
												{
													required: true,
													message: 'Por favor ingresa una contraseña',
												}
											]}
											hasFeedback
										>
											<Input.Password/>
										</Form.Item>
										<Form.Item
											label="Confirmar la Contraseña"
											name='confirm'
											dependencies={['password']}
									        hasFeedback
									        rules={[
									          {
									            required: true,
									            message: 'Por favor confirma tu contraseña',
									          },
									          ({ getFieldValue }) => ({
									            validator(_, value) {
									              if (!value || getFieldValue('password') === value) {
									                return Promise.resolve();
									              }
									              return Promise.reject(new Error('Las contraseñas no coinciden'));
									            },
									          }),
									        ]}
										>
											<Input.Password />
										</Form.Item>
										<Form.Item>
											<Button className="btn-login mt-2" htmlType="submit">
												Cambiar Contraseña
											</Button>
										</Form.Item>
									</Form>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
			</Layout>
		)
	}

}