import React, { Component } from 'react'

import { Layout, Row, Col, Typography } from 'antd';

//import { Link } from "react-router-dom";

import {  IconWatsLanding, IconFaceLanding, IconMailLanding, IconUbiLanding } from './../Widgets/Iconos'


import './../../styles/footer.css'

const { Footer  } = Layout;

const { Text, Title } = Typography;


class _Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }
 
    render() {
        return (
            <div>
                <Row id="sec-5" className="center lnd-sec-5" >
                    <Col span={14} className="" style={{ maxHeight: "25%", float: "left", textAlign: "left", padding: "30px 0px" }}>
                        <Row>
                            <img src={"/images/logo-blue.png"} width={250} alt="logo-blue" />
                        </Row>
                        <Row className="pb-30">
                            <Text>En Bauens perfilamos y corroboramos la veracidad y seguridad de la <br />
								información que manejamos, combatiendo así los fraudes inmobiliarios, <br />
								los perfiles que generan nuestros clientes son seguros y claros.</Text>
                        </Row>
                        <Row>
                            <Col xs={24} xl={10} className="bg-white div-landing-contacto" style={{ padding: "10px" }}>
                                <Row className="mg-10">
                                    <Col className="col-icon">
                                        <IconWatsLanding />
                                    </Col>
                                    <Col >
                                        <Text>¡Comunicate con nosotros!</Text>
                                        <Title level={5}>+5216742525</Title>
                                    </Col>
                                </Row>

                                <Row className="mg-10">
                                    <Col className="col-icon">
                                        <IconFaceLanding />
                                    </Col>
                                    <Col >
                                        <Text>Síguenos en nuestra página</Text>
                                        <Title level={5}>facebook.com</Title>
                                    </Col>
                                </Row>

                                <Row className="mg-10">
                                    <Col className="col-icon">
                                        <IconMailLanding />
                                    </Col>
                                    <Col >
                                        <Text>Escríbenos</Text>
                                        <Title level={5}>correos@bauens.com</Title>
                                    </Col>
                                </Row>

                                <Row className="mg-10">
                                    <Col className="col-icon">
                                        <IconUbiLanding />
                                    </Col>
                                    <Col >
                                        <Text>Nuestra Oficina</Text>
                                        <Title level={5}>CDMX Tijuana 22190</Title>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} xl={14} >
                                <Row className="center list-links">
                                    <Col xs={24} xl={11}>
                                        <Title level={4} className="left"> Acerca de</Title>
                                        <ol className="footer-links">
                                            <li><a href="#">Nuestra Historia</a></li>
                                            <li><a href="#">Investigación</a></li>
                                            <li><a href="#">Ayuda</a></li>
                                            <li><a href="#">Planes y precio</a></li>
                                        </ol>

                                    </Col>
                                    <Col xs={24} xl={11}>
                                        <Title level={4} className="left">   Información General</Title>
                                        <ol className="footer-links">
                                            <li><a href="#">Guía de vivienda justa</a></li>
                                            <li><a href="#">Condiciones de uso</a></li>
                                            <li><a href="#">Portal de privacidad</a></li>
                                            <li><a href="#">Preferencia de cookies</a></li>
                                        </ol>

                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="center bg-white bar-logos-cmp" >
                    <Col xs={24} xl={11} style={{ maxWidth: "700px" }}></Col>
                    <Col xs={24} xl={11} >
                        <Row className="row-logos-cmp">
                            <Col span={5}>
                                <img src={"/images/logo-cmp-1.png"} alt="logo-cmp-1" width="100%" />
                            </Col>
                            <Col span={5}>
                                <img src={"/images/logo-cmp-2.png"} alt="logo-cmp-1" width="100%" />
                            </Col>
                            <Col span={5}>
                                <img src={"/images/logo-cmp-3.png"} alt="logo-cmp-1" width="100%" />
                            </Col>
                            <Col span={5}>
                                <img src={"/images/logo-cmp-4.png"} alt="logo-cmp-1" width="100%" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                
            </div>
        )
    }
}

class _FooterBlue extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }
 
    render() {
        return (
            <Footer className="footer" >Todos los derechos Reservados <a href="https://iseeyoutech.com/" target="_blank">I SEE YOU TECHNOLOGIES SAPI ©</a> </Footer>
        )
    }
}

export {_Footer, _FooterBlue}