import React, { Component } from 'react'


import { Layout, Row, Col, Card, Tabs, Radio, Button, Typography, Input, Menu, Modal, Form } from 'antd';

import { Link } from "react-router-dom";

import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';

import { IconSearchLanding, IconGotoLanding, } from './../Widgets/Iconos'
import MapboxGL from 'mapbox-gl'

import Busqueda from '../Widgets/Filtros/Busqueda'


import axios from 'axios'
import qs from 'qs'

import './../../styles/Global.css'
import './../../styles/landing.css'
const { Content } = Layout
const { Text, Title } = Typography
const { Search } = Input
const { TabPane } = Tabs
const { SubMenu } = Menu;
MapboxGL.accessToken = process.env.REACT_APP_MAPBOX_API


/**
 *
 *
 * @export
 * @class CiudadImagen
 * @extends {Component}
 * 
 * @description Para otener los valores de una ciudad en especifico
 */
class CiudadImagen extends Component {

	/**
	 *
	 *
	 * @static
	 * @memberof CiudadImagen
	 * 
	 * @property xs Para Responsividad de la columna
	 * @property xs Para responsivdia de la columna
	 * 
	 * @property nombre Nombre del lugar
	 * @property src Imagen de la ciudad
	 * @property mabox_context_text TEXTO del contexto, para la busqueda
	 * @property mabox_context_id Tipo del lugar sin contar el id (place, )
	 * @property localization Objeto localizacion para mapbox
	 * 
	*/
	static defaultProps = {
		xs: 24,
		xl: 8,
		nombre: "",
		src: "/images/monterrey.jpg",
		alt: "Imagen Monterrey",
		mabox_context_text: "",
		mabox_context_id: "",


		localization: {}
	}

	state = {
		cantidad: 0,
		
		tipo: ""
	}

	componentDidMount() {
		this.getCantidad()
	}

	componentDidUpdate() {


		console.log('this.props.tipo', this.props.tipo);
		if (this.state.tipo !== this.props.tipo) {
			this.state.tipo = this.props.tipo
			this.getCantidad()
		}

	}


	getCantidad = (
		mabox_context_text = this.props.mabox_context_text,
		mabox_context_id = this.props.mabox_context_id,
		tipo = this.props.tipo
	) => {
		axios.get('/landing-page/groups-by-place', {
			params: {
				mabox_context_text,
				mabox_context_id,
				tipo
			}
		})
			.then(({ data }) => {
				this.setState({ cantidad: data.data })
			}).catch(error => console.log(error))
	}


	render() {

		const { xs, xl, src, alt, nombre } = this.props
		const { cantidad } = this.state

		return (
			<Col xs={xs} xl={xl}>
				<Link to={{
					pathname: '/mapa',
					search: `?${qs.stringify(this.props.localization)}`
				}}>
					<Card
						hoverable
						className="card-ciudad">
						<Row>
							<Col span={24} className="col-ciudad-img">
								<img src={src} alt={alt} width="100%" />
							</Col>
							<Col span={24} className="col-ciudad-info">
								<Title className="text-white" level={3}>{nombre}</Title>
								<Text className="text-white"> <strong>{cantidad}</strong> Propiedades  en Venta.</Text>
							</Col>
						</Row>
					</Card>
				</Link>
			</Col>
		)
	}
}




/**
 *
 *
 * @export
 * @class Landing
 * @extends {Component}
 * 
 * @description Landing Page para compradores
 */
export default class Landing extends Component {
	
	/**
	 * Creates an instance of Landing.
	 * @param {*} props
	 * @memberof Landing
	 */
	constructor(props) {
		super(props)
		this.state = {
			filtro: "",
			tipo: "venta"
		}
	}



	/**
	 *
	 *
	 * @param {*} {email} Cambiamos el filtro
	 * @memberof Landing
	 * 
	 * @method cambioFiltro
	 * @description Enviamos al servidor el boletin
	 */
	cambioFiltro = (filtro) => this.setState({ filtro: filtro.key })



	/**
	 *
	 *
	 * @param {*} {email} Correo Electronico de la persona a subscriberse
	 * @memberof Landing
	 * 
	 * @method addBoletin
	 * @description Enviamos al servidor el boletin
	 */
	addBoletin = ({ email }) => {

		

		console.log(email);

		if (typeof email !== "string")
			return Modal.error({
				title: "Debe ingresar un correo.",
				content:"Para subscribirte al boletin debe de haber un correo."
			})

		if ( !(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.
			test(email)))
			return Modal.error({
				title: "Debe ser un correo valido.",
				content:"Para subscribirte al boletin debe de haber un correo valido."
			})		

		axios.post('/boleting/add', {
			email
		})
			.then(x => Modal.success({ title: "Registrado exitosamente", content: "Hemos registrado tu correo a nuestro boletin." }))
			.catch(x => Modal.error({ title: "El correo ha sido registrado previamente", content: "Hubo un error al registrar tu correo." }))
	}

	/**
	 * @memberof Landing
	 * 
	 * @method renderImagenes
	 * @description Renderizamos la lista de imagenes
	 */
	renderImagenes = () => {
		return <Row>
			<CiudadImagen
				xs={24}
				xl={8}
				src="/images/monterrey.jpg"

				alt=" Ciudad de Monterrey "
				nombre="Monterrey"
				localization={{
					"id": "place.7988670281816950",
					"type": "Feature",
					"place_type": [
						"place"
					],
					"relevance": 1,
					"properties": {
						"wikidata": "Q81033"
					},
					"text_es": "Monterrey",
					"language_es": "es",
					"place_name_es": "Monterrey, Nuevo León, México",
					"text": "Monterrey",
					"language": "es",
					"place_name": "Monterrey, Nuevo León, México",
					"bbox": [
						-100.422003254276,
						25.5001894382886,
						-100.184189025609,
						25.7974005887395
					],
					"center": [
						-100.30861,
						25.67139
					],
					"geometry": {
						"type": "Point",
						"coordinates": [
							-100.30861,
							25.67139
						]
					},
					"context": [
						{
							"id": "region.10665801077659310",
							"wikidata": "Q15282",
							"short_code": "MX-NLE",
							"text_es": "Nuevo León",
							"language_es": "es",
							"text": "Nuevo León",
							"language": "es"
						},
						{
							"id": "country.9933875229773450",
							"wikidata": "Q96",
							"short_code": "mx",
							"text_es": "México",
							"language_es": "es",
							"text": "México",
							"language": "es"
						}
					]
				}}
				mabox_context_text="Monterrey"
				mabox_context_id="place"

				tipo={this.state.filtro}

			/>
			<CiudadImagen
				xs={24}
				xl={16}
				src="/images/cdmx.png"

				alt="Ciudad de México"
				nombre="Ciudad de México"
				localization={{
					"id": "place.12541599803673840",
					"type": "Feature",
					"place_type": [
						"region",
						"place"
					],
					"relevance": 1,
					"properties": {
						"wikidata": "Q1489",
						"short_code": "MX-CMX"
					},
					"text_es": "Ciudad de México",
					"language_es": "es",
					"place_name_es": "Ciudad de México, México",
					"text": "Ciudad de México",
					"language": "es",
					"place_name": "Ciudad de México, México",
					"bbox": [
						-99.3649249984507,
						19.0482360000012,
						-98.9403200040908,
						19.592738999791
					],
					"center": [
						-99.14556,
						19.41944
					],
					"geometry": {
						"type": "Point",
						"coordinates": [
							-99.14556,
							19.41944
						]
					},
					"context": [
						{
							"id": "country.9933875229773450",
							"wikidata": "Q96",
							"short_code": "mx",
							"text_es": "México",
							"language_es": "es",
							"text": "México",
							"language": "es"
						}
					]
				}}

				mabox_context_text="Ciudad de México"
				mabox_context_id="place"

				tipo={this.state.filtro}
			/>
			<CiudadImagen
				xs={24}
				xl={16}
				src="/images/guadalajara.png"

				alt="Ciudad de Guadalajara"
				nombre="Guadalajara"
				localization={{
					"id": "place.2650347488162500",
					"type": "Feature",
					"place_type": [
						"place"
					],
					"relevance": 1,
					"properties": {
						"wikidata": "Q9022"
					},
					"text_es": "Guadalajara",
					"language_es": "es",
					"place_name_es": "Guadalajara, Jalisco, México",
					"text": "Guadalajara",
					"language": "es",
					"place_name": "Guadalajara, Jalisco, México",
					"bbox": [
						-103.407064620656,
						20.60373732598,
						-103.263761705847,
						20.7438464814891
					],
					"center": [
						-103.35194,
						20.66611
					],
					"geometry": {
						"type": "Point",
						"coordinates": [
							-103.35194,
							20.66611
						]
					},
					"context": [
						{
							"id": "region.9731098207993510",
							"wikidata": "Q13160",
							"short_code": "MX-JAL",
							"text_es": "Jalisco",
							"language_es": "es",
							"text": "Jalisco",
							"language": "es"
						},
						{
							"id": "country.9933875229773450",
							"wikidata": "Q96",
							"short_code": "mx",
							"text_es": "México",
							"language_es": "es",
							"text": "México",
							"language": "es"
						}
					]
				}}

				mabox_context_text="Guadalajara"
				mabox_context_id="place"

				tipo={this.state.filtro}

			/>

			<CiudadImagen
				xs={24}
				xl={8}
				src="/images/tijuana.jpg"

				mabox_context_text="Tijuana"
				mabox_context_id="place"

				alt="Ciudad de Tijuana"
				nombre="Tijuana"
				localization={{
					"id": "place.17362539382514190",
					"type": "Feature",
					"place_type": [
						"place"
					],
					"relevance": 1,
					"properties": {
						"wikidata": "Q124739"
					},
					"text_es": "Tijuana",
					"language_es": "es",
					"place_name_es": "Tijuana, Baja California, México",
					"text": "Tijuana",
					"language": "es",
					"place_name": "Tijuana, Baja California, México",
					"bbox": [
						-117.124202396042,
						32.4377820511622,
						-116.821037077583,
						32.5554397427761
					],
					"center": [
						-117.02,
						32.53083
					],
					"geometry": {
						"type": "Point",
						"coordinates": [
							-117.02,
							32.53083
						]
					},
					"context": [
						{
							"id": "region.10080623558092390",
							"wikidata": "Q58731",
							"short_code": "MX-BCN",
							"text_es": "Baja California",
							"language_es": "es",
							"text": "Baja California",
							"language": "es"
						},
						{
							"id": "country.9933875229773450",
							"wikidata": "Q96",
							"short_code": "mx",
							"text_es": "México",
							"language_es": "es",
							"text": "México",
							"language": "es"
						}
					]
				}}

				mabox_context_text="Tijuana"
				mabox_context_id="place"


				tipo={this.state.filtro}

			/>
		</Row>
	}


	render() {

		return (
			<Layout className="landing-layout">
				<Content className="landing-content" >
					<Row id="sec-1" className="lnd-sec-1" style={{ backgroundImage: "url('images/main-landing.jpg')" }} >
						<Col span={24} className="mxh-25prs" >
							{/*<img src={"images/main-landing.jpg"} className="landing-header-img" alt="landing-header-img" />*/}
						</Col>
						<Col span={24} className="center mt-5" >
							<Title level={2} className="text-white" >Las mejores propiedades </Title>
						</Col>
						<Col span={24} className="center mt-5">
							<Radio.Group defaultValue="comprar" value={this.state.tipo} className="itemRadio" onChange={(event) => this.setState({ tipo: event.target.value })}>
								<Radio.Button value="venta">Comprar</Radio.Button>
								<Radio.Button value="renta">Rentar</Radio.Button>
							</Radio.Group>
						</Col>
						<Col span={24} className="center mt-5 col-search">
							<Busqueda
								placeholder="Ingrese una dirección, colonia, ciudad o código postal"
								className="landing-page-search-input"
								onSelect={(address) => this.props.history.push({
									pathname: '/mapa',
									search: `?${qs.stringify({...address, tipo: this.state.tipo})}`
								})}
								onSearch={(address) => this.props.history.push({
									pathname: '/mapa',
									search: `?${qs.stringify({...address, tipo: this.state.tipo })}`
								})}
								parseURL={true}
							/>
						</Col>
					</Row>
					<Row id="sec-2" className="lnd-sec-2" align="center ">
						<Col xs={24} className="center mt-10" >
							<Title level={2} className="pd-3" >Te ayudamos a encontrar<br /> la propiedad de tus sueños </Title>
						</Col>
						<Row  align="center" className="wd-100prs mxw-1300px" >
							<Col xs={20} md={10} xl={7} span={5} className="center col-landing-card">
								<Link to="/mapa?type=venta" style={{height: '100%'}}>
									<Card
										hoverable
										className="center mg-10 mxw-345px pd-10"
										style={{height: '96%'}}>
										<Row>
											<Col span={24} className="mg-5">
												<img src={"/images/img-landing-comprar.png"} width={200} alt="imgComprar" />
											</Col>
											<Col span={24} className="mg-5">
												<Title level={4}>Compra un Inmueble </Title>
												<Text>Encuentra tu próximo hogar, accede a <br /> una gran variedad de opciones y <br /> encuentra alternativas que se <br /> acomoden a tus necesidades.</Text>
											</Col>
											<Col span={24} className="mg-10">
												<Button className="btn-limon">Comprar</Button>
											</Col>
										</Row>
									</Card>
								</Link>
							</Col>
							<Col xs={20} md={10} xl={7} span={5} className="center col-landing-card">
								<Link to="/professional" style={{height: '100%'}}>
									<Card
										hoverable
										className="center mg-10 mxw-345px pd-10"
										style={{height: '96%'}}>
										<Row>
											<Col span={24} className="mg-5">
												<img src={"/images/img-landing-vender.png"} width={200} alt="imgVender" />
											</Col>
											<Col span={24} className="mg-5">
												<Title level={4}>Vende un Inmueble </Title>
												<Text>Publica tus propiedades y encuentra <br /> clientes para una venta exitosa.</Text>
											</Col>
											<Col span={24} className="mg-10">
												<Button style={{marginTop: '35px'}} className="btn-limon">Vender</Button>
											</Col>
										</Row>
									</Card>
								</Link>
							</Col>
							<Col xs={20} md={10} xl={7} span={5} className="center col-landing-card">
								<Link to="/mapa?type=renta" style={{height: '100%'}}>
									<Card
										hoverable
										className="center mg-10 mxw-345px pd-10"
										style={{height: '96%'}}>
										<Row>
											<Col span={24} className="mg-5">
												<img src={"/images/img-landing-rentar.png"} width={200} alt="imgRentar" />
											</Col>
											<Col span={24} className="mg-5">
												<Title level={4}>Renta un Inmueble </Title>
												<Text>Te asesoraremos, elige tu casa o <br /> departamento de manera fácil y rápida. </Text>
											</Col>
											<Col span={24} className="mg-10">
												<Button className="btn-limon">Rentar</Button>
											</Col>
										</Row>
									</Card>
								</Link>
							</Col>
						</Row>
					</Row>
					<Row id="sec-3" className="lnd-sec-3 bg-white center pb-30">
						<Col span={24} className="center mt-5 " >
							<Row>
								<Col span={24}>
									<Title level={2} className="" >Encuentra propiedades <br /> en las siguientes ciudades</Title>
									<Text>Busca propiedades en las principales ciudades.</Text>
								</Col>
							</Row>
						</Col>
						<Row className="center wd-100prs  mxw-1400px" >
							<Col xs={20} md={20} xl={20} span={5} className="col-landing-tabs">
								<Menu onClick={this.cambioFiltro} mode="horizontal" style={{ marginBottom: 20 }}>
									<Menu.Item key={"null"}>
										Bienes Raíces
								</Menu.Item>
									<Menu.Item key="2">
										Ventas
									</Menu.Item>
									<Menu.Item key="1">
										Rentas
								</Menu.Item>
								</Menu>
							</Col>
							<Col xs={20} md={20} xl={20} span={5} className="center col-landing-tabs">
								{this.renderImagenes()}
							</Col>
							<Col xs={20} md={20} xl={20} className="link-todas-cuidades">
								<Link to="/mapa">Ir al Mapa Completo</Link>
							</Col>
						</Row>
					</Row>
					<Row id="sec-4" className="center lnd-sec-4" >
						<Col span={24} className="mxh-25prs" >
							<img src={"images/bg-landing-2.png"} className="landing-header-img-2" />
						</Col>
						<Row className="center row-bar-sub-blue">
							<Col xs={22} xl={14} className="xl-align-left" >
								<Title level={4} className="text-white" >Suscribete a nuestro boletín de noticias <br /> para recibir nuevas promociones. </Title>
							</Col>
							<Col xs={22} xl={10} className="center col-search wd-70prs">

								<Form onFinish={this.addBoletin}>
									<Form.Item name="email" noStyle>
										<Input className="inputSearch" style={{ minWidth: 330 }} placeholder="Escribe tu correo electrónico."
											suffix={
												<Button className="btnSearch" htmlType="submit">
													<IconGotoLanding className="iconGotoLanding" />
												</Button>
											} />
									</Form.Item>
								</Form>
							</Col>
						</Row>
					</Row>
				</Content>
			</Layout>
		)
	}
}