import React, { Component } from "react";
import { Button, Row, Col, Typography, message } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import axios from "axios";
import { CardTipoPropiedad, CardTipoPropiedadSkeleton } from "../../../Widgets/cards/Cards";
import { User, SetUser } from '../../../../Context/User'
const { Title } = Typography;

/**
 *
 *
 * @export
 * @class Tipo
 * @extends {Component}
 * @description Seccion Tipo del step de creacion de propiedad
 */
export default class Tipo extends Component {

    static contextType = User

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            tipo: null,
            data: []
        }
    }



    /**
     *
     *
     * @memberof Tipo
     * @description Carga los datos a la vista
     */
    componentDidMount() {
        axios.defaults.headers.common["Authorization"] = sessionStorage.getItem('token')
        // si la propiedad ya se creo, se salta el paso 1
        if (this.props.propiedad._id != null || this.props.propiedad._id != undefined)
            this.props.next()
        else
            this.getTipos();
    }




    /**
     *
     *
     * @memberof Tipo
     * @description Al hacer click en el tipo de propiedad se manda el elemento al webservice para crear una nueva propiedad de ese tipo
     * @params value ObjectId
     */
    onClickTipo = (value) => {
        this.setState({ tipo: value });
        const user = this.context;
        axios.post('/propiedad/add/tipo', {
            tipo_propiedad: value,
            usuario_id: user._id
        })
            .then(res => {
                if (res.status == 200) {
                    this.props.setPropiedad(res.data.data,false);
                    this.props.next();
                }
                else {
                    message.error({ content: 'Ha ocurrido un error, por favor intente de nuevo', className: 'messages-error' });
                }
            })
            .catch(error => {
                console.log('error al guardar el paso 1', error.response);
                message.error({ content: 'Ha ocurrido un error, por favor intente de nuevo', className: 'messages-error' });
            })

    }


    /** 
     *
     *
     * @memberof Tipo
     * @description Manda a llamar al webService los tipos de propiedades existentes
     */
    getTipos = () => {
        axios.get('/tipo-propiedades')
            .then(res => {
                this.setState({ data: res.data.data });
            })
            .catch(error => {
                message.error('Ha ocurrido un error al traer la informacion')
                console.log('error al traer los tipos de propiedades', error);
            })
            .finally(f => {
                this.setState({ loading: false })
            })
    }


    render() {
        let loading = this.state.loading;
        const { onClickTipo } = this;
        return (
            <>
                <Row justify="start" align="middle">
                    <Col span={24} className="flex-start"> <Button type="text" shape="round" icon={<LeftOutlined />} className="btn-onback"></Button> <Title level={4}>¿Qué tipo de propiedad deseas agregar?</Title></Col>
                </Row>
                <Row gutter={[16, 16]}>
                    {(loading == true) ?
                        <CardTipoPropiedadSkeleton />
                        :
                        this.state.data.map((tipo, index) => {
                            return <CardTipoPropiedad tipo={tipo} onClick={onClickTipo} />
                        })
                    }
                </Row>
            </>
        )
    }
}