import React, { Component } from 'react'

import { Link } from "react-router-dom";
import { Layout, Row, Col, Card, Rate, Carousel, Empty, Form, Button, Typography, Input, message } from 'antd';
import { IconWatsAgente, IconADCorazon, IconADCorazonSeleccionado, IconADFacebook, IconADInstagram, IconADTelefono, IconADCorreo, IconADWats, IconADUbicacion} from './../../Widgets/Iconos'
import InputPhone from "../../Widgets/Inputs/InputPhone/InputPhone"
import { ComunidadCard } from "../../Widgets/cards/Cards"
import axios from 'axios';
import FormContacto from '../Formularios/FormContacto'
import './../../../styles/listas.css'


const { Content } = Layout;
const { Text, Title } = Typography;
const moment = require('moment');


export default class DetalleAgente extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userLogged: "",
            tipoVista: "",
            tituloVista: "Agencia",
            agente_id: "",
            agencia_id: "",

            agente:
            {
                _id: "",
                foto: "",
                nombre: "",
                telefono: "",
                whatsApp: "",
                correo: "",
                ubicacion: "",
                favorito: false,
                insignia: false,

                calificacion: "",
                reviews: "0",
                descripcion: "",
            },

            agencia: {},

            dataTestimonios: [],

            dataTable: [],

            dataAgentes: [],

            dataComunidades: [],

            horarios: []
        }
    }

    /**
    * @static
    * @memberof DetalleAgente
    * @method   componentDidMount
    * @description Se llama consulta inicial de los detalles 
    **/
    componentDidMount = () => {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getDetalles();
    }


    /**
    * @static
    * @memberof DetalleAgente
    * @method   getDetalles
    * @description Consulta de detalles de agencia / agente
    * Se consulta por id y tipo, viene desde de las propiedades de la vista
    **/
    getDetalles = async () => {

        console.log('this.props', this.props);
        var id = this.props.match.params.id;
        if (id !== undefined && id !== "") {
            var tipo = id.split('_')[0];
            id = id.split('_')[1];
            console.log(id);

            if (tipo === "agencia" || id === "" || id === undefined) {

                if (id === undefined) { id = tipo; }
                axios.get("/agencias/get", {
                    headers: {
                        Authorization: sessionStorage.getItem("token")
                    },
                    params: {
                        id: id, tipo: 1
                    },
                })
                    .then(({ data }) => {

                        if (data.success) {
                            var dataAgencia = data.data;
                            var data = {
                                id: dataAgencia._id,
                                foto: dataAgencia.logo,
                                nombre: dataAgencia.nombre,
                                telefono: dataAgencia.lada + " " + dataAgencia.telefono,
                                whatsApp: dataAgencia.lada + " " + dataAgencia.telefono,
                                correo: dataAgencia.facebook,
                                ubicacion: dataAgencia.localizacion?.place_name,
                                favorito: false,
                                insignia: false,
                                calificacion: dataAgencia.rating,
                                reviews: dataAgencia.rating,
                                descripcion: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ",

                            };

                            var titulo = "";
                            switch (dataAgencia.tipo) {
                                case 1:
                                    titulo = "Agencia";
                                    break;
                                case 2:
                                    titulo = "Contratista";
                                    break;

                                case 3:
                                    titulo = "Administrador";
                                    break;
                                case 4:
                                    titulo = "Constructora";
                                    break;
                                case 5:
                                    titulo = "Estudio Fotográfico";
                                    break;
                                case 6:
                                    titulo = "Inspector";
                                    break;
                                case 7:
                                    titulo = "Otro";
                                    break;

                                default:
                                    titulo = "Detalle";
                                    break;
                            }

                            this.setState({
                                agente_id:  dataAgencia._id,
                                tipoVista: "Agencia",
                                agente: data,
                                dataHorarios: dataAgencia.horarios,
                                tituloVista: titulo
                            })

                        }
                    })

                    .catch((e) => {
                        console.log('er', e)
                        this.setState({
                            dataAgencias: [],
                            loading: false
                        })
                    })
            }

            else if (tipo === 'agente') {
                axios.get("/usuario/get", {
                    headers: {
                        Authorization: sessionStorage.getItem("token")
                    },
                    params: {
                        id: id
                    },
                })
                    .then(({ data }) => {
                        console.log(data);

                        if (data.success) {

                            var dataAgente = data.data;
                            console.log(dataAgente);

                            var user = {
                                id: dataAgente._id,
                                foto: dataAgente.logo,
                                nombre: dataAgente.nombres + " " + dataAgente.apellidos,
                                telefono: dataAgente.lada + " " + dataAgente.telefono,
                                favorito: false,
                                insignia: false,
                                calificacion: dataAgente.rating,
                                reviews: dataAgente.rating,
                                descripcion: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ",
                            };

                            var dataAgencia = data.data.agencia_id;
                            var agencia = {};

                            if (dataAgencia !== undefined && dataAgencia !== null) {

                                var titulo = "";
                                switch (dataAgencia.tipo) {
                                    case 1:
                                        titulo = "Agencia";
                                        break;
                                    case 2:
                                        titulo = "Contratista";
                                        break;
                                    case 3:
                                        titulo = "Administrador";
                                        break;
                                    case 4:
                                        titulo = "Constructora";
                                        break;
                                    case 5:
                                        titulo = "Estudio Fotográfico";
                                        break;
                                    case 6:
                                        titulo = "Inspector";
                                        break;
                                    case 7:
                                        titulo = "Otro";
                                        break;

                                    default:
                                        titulo = "Detalle";
                                        break;
                                }

                                agencia = {
                                    id: dataAgencia._id,
                                    foto: dataAgencia.logo,
                                    nombre: dataAgencia.nombre,
                                    telefono: dataAgencia.lada + " " + dataAgencia.telefono,
                                    whatsApp: dataAgencia.lada + " " + dataAgencia.telefono,
                                    correo: dataAgencia.facebook,
                                    ubicacion: dataAgencia.localizacion?.place_name,
                                    favorito: false,
                                    insignia: false,
                                    calificacion: dataAgencia.rating,
                                    reviews: dataAgencia.rating,
                                    descripcion: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ",
                                    tipo: titulo
                                };

                            }

                            this.setState({
                                agente_id : dataAgente._id,
                                tipoVista: "Agencia",
                                agente: user,
                                agencia: agencia,
                                dataHorarios: dataAgencia.horarios,
                                tituloVista: "Agente"
                            })

                        }
                    })

                    .catch((e) => {
                        console.log('er', e)
                        this.setState({
                            dataAgencias: [],
                            loading: false
                        })
                    })
            }

        }
        var id = this.props.match.params.id;
        this.getAgente(id)
    }


    /**
    * @static
    * @memberof DetalleAgente
    * @method   getAgente
    * @description Consulta de detalles de un agente
    **/
    getAgente = (id) => {
        axios.get("/usuario/get/public", {
            params: {
                id: id,
                url: id,
            },
        }).then(response => {
            let agente = response.data.data
            this.setState({
                nombre: `${agente.nombres} ${agente.apellidos}`,
                telefono: `${agente.lada} ${agente.telefono}`,
                email: agente.email,
                descripcion: agente.descripcion,
                calificacion: agente.rating,
                foto_perfil: agente.foto_perfil,
                tituloVista: this.getTipo(agente.agencia_id.tipo),
                horarios: agente.agencia_id.horarios ? agente.agencia_id.horarios : [],
                agencia: agente.agencia_id,

            })

        }).catch(error => {
            message.error('Error al traer la información')
            console.log(error)
        })
    }


    /**
    * @static
    * @memberof DetalleAgente
    * @method   getAgencia
    * @description Consulta de detalles de un agente
    **/
    getAgencia = (id) => {
        axios.get("/agencias/get", {
            params: {
                id: id,
            },
        }).then(response => {
            let agencia = response.data.data
            this.setState({
                nombre: agencia.nombre,
                descripcion: agencia.descripcion,
                foto_perfil: agencia.logo,
                telefono: `${agencia.lada} ${agencia.telefono}`,
                horarios: agencia.horarios,
                calificacion: agencia.rating,
                tituloVista: this.getTipo(agencia.tipo),
                showAgencia: true,
            })

        }).catch(error => {
            message.error('Error al traer la información')
            console.log(error)
        })

    }

    /**
    * @static
    * @memberof DetalleAgente
    * @method   renderCardContratistas
    * @description Se crea el card tipo contratista
    **/
    renderCardContratistas = (contratista) => {
        var { _id, logo, nombre, telefono, calificacion, reviews, descripcion, insignia } = contratista;
        if (logo === undefined || logo === "") { logo = "/images/cnt-01.png" }

        return <Card
                hoverable
                className="list-card-agente">
                <Row onClick={()=>this.getAgencia(_id)}>
                    <Col xs={24} md={10} xl={8} className="col-img-agente">
                        <img src={logo} width={100} alt="imgCircle" className="imgCircle" />
                    </Col>

                    <Col xs={24} md={14} xl={16} className="col-info-agente-2">
                            <Title level={3} className="text-blue">{nombre}</Title>
                            <div className="flex-start">
                                <IconWatsAgente /> <Text style={{marginLeft: '10px'}}>{telefono}</Text>
                            </div>
                            <div>
                                <Rate defaultValue={parseInt(calificacion)} disabled key={"rt" + nombre} /> {reviews} Reviews
                            </div>


                    </Col>
                    <Col span={24} className="col-card-descripcion">
                        <Text>{descripcion} </Text>
                    </Col>
                </Row>
        </Card>
    }


    /**
    * @static
    * @memberof DetalleAgente
    * @method   renderCardComunidades
    * @description Se crea el card tipo comunidades
    **/
    renderCardComunidades = (comunidad) => {
        var { _id, casa, logo, nombre, comunidad, estado } = comunidad;

        return <ComunidadCard
            id={_id}
            casa={casa}
            logo={logo}
            nombre={nombre}
            comunidad={comunidad}
            estado={estado}
        />
    }


    /**
    * @static
    * @memberof DetalleAgente
    * @method   renderHorario
    * @description Se crea formato para render de horarios
    **/
    renderHorario = (horario, index) => {
        let horarios = []

        let dias = {
            0: 'Lunes',
            1: 'Martes',
            2: 'Miercoles',
            3: 'Jueves',
            4: 'Viernes',
            5: 'Sabado',
            6: 'Domingo',
        }

        if(horario.length > 0){
            for (let index = 0; index < horario.length; index++) {

                let inicio = `${horario[index]?.inicio?.hora} : ${horario[index]?.inicio?.minuto < 10 ? '0'+horario[index]?.inicio?.minuto : horario[index]?.inicio?.minuto}`
                let fin = `${horario[index]?.fin?.hora} : ${horario[index]?.fin?.minuto < 10 ? '0'+horario[index]?.fin?.minuto : horario[index]?.fin?.minuto}`

                horarios.push(<Col xs={24} md={12} xl={9} className="pt-10px" key={index}>
                    <Title level={5}>{dias[index]}</Title>
                    <Text>{inicio + " - " + fin}</Text>
                </Col>)
            }

        }

        return horarios

    }


     /**
    * @static
    * @memberof DetalleAgente
    * @method   getTipo
    * @description obtiene el tipo de usuario que es dependiendo del tipo
    **/
    getTipo = (tipo) => {

        let DEFAULT_TIPO = 'Detalle'
        let tipos = {
            1: 'Agencia',
            2: 'Contratista',
            3: 'Administrador',
            4: 'Constructora',
            5: 'Estudio Fotográfico',
            6: 'Inspector',
            7: 'Otro',
        }

        return tipo ? tipos[tipo] : DEFAULT_TIPO
    }


    EnviarMensaje = async (values) => {
        console.log(values)


        let  agente_id = this.props.match.params.id;
        let userId = sessionStorage.getItem("userId")

        axios.post('/mensajes/add', {
            params: {
                destinatario_id: agente_id,
                mensaje: values.mensaje,
                remitente_id: userId,

                apellidos: values.apellidos,
                correo: values.correo,
                mensaje: values.mensaje,
                nombre: values.nombre,
                telefono: values.telefono,
                formulario : true
            }
        })
            .then(response => {
                if (response.data.success) {
                    message.success(response.data.message);
                }
                else {
                    message.error({ content: "Error al enviar mensaje.", className: 'message-error' })
                }

            }).catch(error => {
                console.log('error al enviar mensaje', error)
                message.error({ content: 'Error al enviar mensaje, asegurate de estar registrado para poder contactar a un agente.', className: 'message-error' })
            })

    }



    render() {
        const { nombre, email, telefono, descripcion, calificacion, dataComunidades, foto_perfil } = this.state;
        let img_perfil = "/images/img-default.png" 

        if(this.state.showAgencia){
            img_perfil = "/images/cnt-01.png" 
        }  

        if (foto_perfil !== undefined && foto_perfil !== null){
            img_perfil = foto_perfil
        }


        return (

            <Layout className="landing-layout">
                <Content className="list-content" >
                    <Row className="row-bg-image" style={{ backgroundImage: `url('/images/img-lista.png')` }} >
                        <Title level={2} className="text-white mg-auto" >{this.state.tituloVista}</Title>
                    </Row>
                    <Row className="bg-white">
                        <Row className="cnt-center center">
                            <Col span={20}>
                                <Row>
                                    <Col xs={24} xl={16} >
                                        <Row className="col-contenedor-border ">

                                            <Row className="center wd-100prs" >

                                                <Col span={8} className="col-agente-img">
                                                    <div className="img-perfil" style={{backgroundImage: `url('${img_perfil}')`}}>
                                                        {this.state.showAgencia ? null : <> <img src={"/images/icon-img-insignia.png"} alt="iconInsignia" className="iconImgInsignia row-iconimgInsignia" />
                                                        <span className="spanRedesSociales">
                                                            <IconADFacebook />
                                                            <IconADInstagram />
                                                        </span></>}
                                                    </div>
                                                </Col>

                                                <Col className="col-agente-info">
                                                    <Col span={23}>
                                                        <Title level={3} className="">{nombre}</Title>
                                                        <Button className="btn-agenteFavorito">
                                                           {/* {(agente_favorito == !undefined && agente_favorito) ? <IconADCorazonSeleccionado className="IconFavorito" /> : <IconADCorazon className="IconFavorito" />}*/}
                                                        </Button>

                                                    </Col>

                                                    <Col span={23} className="col-info">
                                                        {this.state.tituloVista} <Rate defaultValue={calificacion} disabled />
                                                    </Col>
                                                    <Col span={23} className="col-info flex-start">
                                                        <IconADTelefono className="svg-item"/> <Text>{telefono}</Text>
                                                    </Col>

                                                    <Col span={23} className="col-info flex-start">
                                                        <IconADCorreo className="svg-item"/> <Text>{email}</Text>
                                                    </Col>

                                                    <Col span={23} className="col-info flex-start">
                                                        <IconADWats className="svg-item"/> <Text></Text>
                                                    </Col>
                                                    <Col span={23} className="col-info flex-start">
                                                        <IconADUbicacion className="svg-item"/> <Text></Text>
                                                    </Col>



                                                </Col>

                                            </Row>


                                            <Col span={22} className="col-card-descripcion">
                                                <Title level={5}>Sobre {nombre}</Title>
                                                <span className="muesca-blue"></span>
                                            <Text>{descripcion} </Text>
                                            </Col>

                                            <Col span={24} className="left mt-50px">
                                                <Title level={5}>Propiedades y Comunidades</Title>
                                                <span className="muesca-blue"></span>

                                                <Row className="wd-100prs left pd-10">
                                                    {dataComunidades.map((comunidad, index) => {
                                                        return this.renderCardComunidades(comunidad)
                                                    })}

                                                    {(dataComunidades.length === 0) ?
                                                        <Empty
                                                            description="Sin  propiedades y comunidades" className="wd-100prs" /> : null}
                                                </Row>
                                            </Col>


                                            <Col span={24} className="left mt-50px pd-10">
                                                <Title level={5}>Horario</Title>
                                                <span className="muesca-blue"></span>
                                                <Row className="wd-100prs left">

                                                    {this.renderHorario(this.state.horarios)}
                                                </Row>
                                            </Col>


                                            {/*<Col span={24} className="left mt-50px pd-10">
                                                <Title level={5}>Testimonios</Title>
                                                <span className="muesca-blue"></span>

                                                <Row className="wd-100prs left">
                                                    <Col span={24} className="col-carusel-detAgente">
                                                        <Carousel autoplay className="carusel-testimonios">

                                                            <Row className="row-cliente mg-auto row-testimonio">
                                                                <Col xs={20} xl={14} className="col-info ">
                                                                    <Col className="col-txt-fecha">
                                                                        <Text>2021-05-01</Text>
                                                                    </Col>
                                                                    <Col className="col-txt-testimonio">
                                                                        <Text>“Lorem Ipsum is simply dummy text of the printing and typesetting industry.”</Text>
                                                                    </Col>
                                                                    <Col className="col-txt-autor">
                                                                        <Text >Luis Rivera</Text>
                                                                    </Col>
                                                                </Col>
                                                            </Row>

                                                            <Row className="row-cliente mg-auto row-testimonio">
                                                                <Col xs={20} xl={14} className="col-info ">
                                                                    <Col className="col-txt-fecha">
                                                                        <Text>2021-05-01</Text>
                                                                    </Col>
                                                                    <Col className="col-txt-testimonio">
                                                                        <Text>“Lorem Ipsum is simply dummy text of the printing and typesetting industry.”</Text>
                                                                    </Col>
                                                                    <Col className="col-txt-autor">
                                                                        <Text >Luis Rivera</Text>
                                                                    </Col>
                                                                </Col>
                                                            </Row>
                                                            <Row className="row-cliente mg-auto row-testimonio">
                                                                <Col xs={20} xl={14} className="col-info ">
                                                                    <Col className="col-txt-fecha">
                                                                        <Text>2021-05-01</Text>
                                                                    </Col>
                                                                    <Col className="col-txt-testimonio">
                                                                        <Text>“Lorem Ipsum is simply dummy text of the printing and typesetting industry.”</Text>
                                                                    </Col>
                                                                    <Col className="col-txt-autor">
                                                                        <Text >Luis Rivera</Text>
                                                                    </Col>
                                                                </Col>
                                                            </Row>
                                                        </Carousel>
                                                    </Col>
                                                </Row>
                                            </Col>*/}
                                        </Row>
                                    </Col>

                                    <Col xs={24} xl={8} >

                                        {(this.state.agencia._id !== "" && this.state.showAgencia !== true) ?
                                            <Row className="col-contenedor-border" style={{ marginBottom: "20px" }}>
                                                {this.renderCardContratistas(this.state.agencia)}
                                            </Row> : null}

                                        <Row className="col-contenedor-border">
                                            <Col span={22} className="col-card-descripcion">
                                                <Title level={5}>Contacta a {nombre}</Title>
                                                <span className="muesca-blue"></span>
                                            </Col>


                                            <Form layout="vertical" className="frm-contacto" name="formulario-contacto" onFinish={this.EnviarMensaje} >
                                                <Row align="center">

                                                    <Col span={24} >
                                                        <Form.Item label="Nombres" name="nombre"
                                                            rules={[{
                                                                required: true,
                                                                message: "Por favor, ingrese nombre"
                                                            }]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={24} >
                                                        <Form.Item label="Apellidos" name="apellidos"
                                                            rules={[{
                                                                required: true,
                                                                message: "Por favor, ingrese apellidos"
                                                            }]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={24} >
                                                        <Form.Item label="Correo Electronico" name="correo"
                                                            rules={[{
                                                                required: true,
                                                                message: "Por favor, ingrese correo"
                                                            }]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={24} >
                                                        <Form.Item label="Teléfono" name="telefono"
                                                            rules={[{
                                                                required: true,
                                                                message: "Por favor, ingrese teléfono"
                                                            }]}
                                                        >
                                                            <InputPhone />
                                                        </Form.Item>
                                                    </Col>


                                                    <Col span={24} >
                                                        <Form.Item label="Mensaje" name="mensaje"
                                                            rules={[{
                                                                required: true,
                                                                message: "Por favor, ingrese mensaje"
                                                            }]}

                                                        >
                                                            <Input.TextArea style={{ height: "100px", maxHeight: "100px" }}
                                                                placeholder="Estoy interesado en contactar a la constructora" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>


                                                <Row style={{ textAlign: "center" }}>
                                                    <Col span={24}>
                                                        <Form.Item >
                                                            <Button htmlType="submit" type="primary" className="btn-limon wd-100prs" >
                                                                Solicitar
                                                            </Button>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Form>

                                            {/*<FormContacto/>*/}

                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Row>
                </Content>
            </Layout>
        )
    }
}
