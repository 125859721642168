import React, { Component } from 'react'
import { Route, Switch , Redirect} from "react-router-dom";
import { Layout, Result} from 'antd';

// componentes
import { User, SetUser } from '../Context/User'
import {  AgenteHeader} from "../components/Header/Header";
import Propiedades from "../components/Agente/Propiedades/Propiedades";
import Dashboard_Agente from '../components/Agente/Dashboard';
import Steps_Container from '../components/Agente/Propiedades/Propiedad/Steps';
import CalendarioCitas from '../components/Agente/Citas/Calendario';
import Citas from '../components/Agente/Citas/Citas';
import Ajustes from '../components/Ajustes/ajustes';
import Prospectos from '../components/Agente/Prospectos/Prospectos'
import Editar from '../components/Agente/Propiedades/Propiedad/Editar';


import Mensajes from "../components/Agente/Mensajes/Mensajes";
const { Content } = Layout;

export default class AdminRoutes extends Component {
	static contextType = User

	render() {
	const user = this.context;

	if(user === null || user === undefined){
		return <Redirect to="/" /> 
	}

		return (

			<Layout className="layout">
				<Content>
					<AgenteHeader/>
					<Switch>
						
						<Route exact path="/professionals/dashboard" render={(props)=> <Dashboard_Agente  {...props} />} />
						
						<Route exact path="/professionals/propiedades" render={(props)=> <Propiedades  	 {...props} />} />					
						
						<Route exact path="/professionals/propiedades/:filtro"  render={(props)=> <Propiedades  	 {...props} />} />

						<Route exact path="/professionals/propiedad/crear" render={(props)=> <Steps_Container   {...props} />} />

						<Route exact path="/professionals/propiedad/steps/:id" render={(props)=> <Steps_Container   {...props} />} />

						<Route exact path="/professionals/calendario/citas" render={(props)=> <CalendarioCitas   {...props} />} />
						
						<Route exact path="/professionals/propiedad/:id" render={(props)=> <Editar   {...props} />} />
						
						<Route exact path="/professionals/prospectos" render={(props) => <Prospectos   	 {...props} />} />
						
						<Route exact path="/professionals/citas" render={(props) => <Citas	{...props} />} />
						
						<Route exact path="/professionals/mensajes" render={(props)=> <Mensajes   {...props} />} />
						
						<Route path="/professionals/ajustes" render={(props) => <Ajustes {...props} />} />
					

						{/* <Route render={props => <Result
							status="404"
							title="404"
							subTitle="La pagina a la que estás intentando acceder no existe."
							{...props}
						/>} /> */}
					</Switch>
				</Content>
			</Layout>

		)
	}
}